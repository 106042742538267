import React, { useState,useEffect } from 'react';
import '../css/normalize.css';
import '../App.css';

import Calendar from '../component/app_view/calendar';
function CalendarPage() {
  useEffect(() => {
    //.contents_boxにopenクラスを付与
    const contents_box = document.getElementsByClassName('contents_box');
    for (let i = 0; i < contents_box.length; i++) { 
        contents_box[i].classList.add('open');
    }
  }, []);

  return (
    <>
        <Calendar />
    </>
  );
}

export default CalendarPage;