import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//Smooth Scroll
import { Link } from 'react-scroll';

function Contents7() {
  useEffect(() => { }, []);

  return (
    <>
      <h2 className="tag-title">その他お役立ち情報</h2>
      <h3 className='icon-title type-pencil' id='image'>オンラインMTG用壁紙</h3>
      <p>
        ZoomやGoogle
        Meet等、オンラインミーティング時にご使用頂ける背景画像となります。以下より背景画像をダウンロード出来ます。
      </p>
      <Row className='mt-5'>
        <Col sm={3}>
          <p>エントランス1</p>
          <img
            src={
              process.env.PUBLIC_URL + '/johsys_pic/content4_entrance.jpg'
            }
            alt='エントランス１'
          />
          <a
            className='btn main-btn type-download h-auto mt-3'
            id='entrance'
            href='/johsys_pic/content4_entrance.jpg'
            download
          >
            ダウンロード<br />(217KB)
          </a>
        </Col>
        <Col sm={3}>
          <p>エントランス2<small>（Zenkenロゴあり）</small></p>
          <img
            src={
              process.env.PUBLIC_URL + '/johsys_pic/content4_entrance2.jpg'
            }
            alt='エントランス２（Zenkenロゴあり）'
          />
          <a
            className='btn main-btn type-download h-auto mt-3'
            id='entrance2'
            href='/johsys_pic/content4_entrance2.jpg'
            download
          >
            ダウンロード<br />(88KB)
          </a>
        </Col>
        <Col sm={3}>
          <p>モノグラム</p>
          <img
            src={
              process.env.PUBLIC_URL + '/johsys_pic/content4_monogram.jpg'
            }
            alt='モノグラム'
          />
          <a
            className='btn main-btn type-download h-auto mt-3'
            id='monogram'
            href='/johsys_pic/content4_monogram.jpg'
            download
          >
            ダウンロード<br />(269KB)
          </a>
        </Col>
        <Col sm={3}>
          <p>ラウンジ</p>
          <img
            src={process.env.PUBLIC_URL + '/johsys_pic/content4_lounge.jpg'}
            alt='ラウンジ'
          />
          <a
            className='btn main-btn type-download h-auto mt-3'
            id='lounge'
            href='/johsys_pic/content4_lounge.jpg'
            download
          >
            ダウンロード<br />(370KB)
          </a>
        </Col>
      </Row>
    </>
  );
}
export default Contents7;