import React, { useState, useEffect } from 'react';
import * as Icon from 'react-bootstrap-icons';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


function Contents1() {

  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  useEffect(() => {

  }, []);

  return (
    <div id="johsys-content1">
      <h2 className="tag-title">情シスへのお問い合わせ</h2>
      <h3 className='icon-title type-pencil'>情報システム課への問い合わせルール</h3>
      <div className="ruleBox">
        <div className='flowimg--box'>
          <img src={process.env.PUBLIC_URL + '/johsys_pic/content1_flow.svg'} alt="" />
        </div>
        <div className="d-flex justify-content-between point--box">
          <div className="box1">
            <div className="head-box d-flex">
              <img src={process.env.PUBLIC_URL + '/johsys_pic/point.svg'} alt="" />
              <h4>情報システム課へ問い合わせは<span>原則、各部署のIT担当者を通して</span>ご連絡ください。</h4>
            </div>
            <p>
              ※IT担当者とは、部署内のシステム系のトラブルや依頼内容を取りまとめている人です。
            </p>
          </div>
          <div className="box2">
            <div className="head-box d-flex">
              <img src={process.env.PUBLIC_URL + '/johsys_pic/black_tryangle.svg'} alt="" />
              <h4><span>「PC／スマートフォンのログインID・パスワード忘れ」に関しては、情シス</span>まで直接ご連絡頂いて構いません。</h4>
            </div>
            <p>内線番号：346<br />情シスの場所は<a href="https://drive.google.com/file/d/1mCm5o7uUHYxocBysmm7GaZwUjsATJZlS/" target='_blank' className='text-link type-external'>こちら</a></p>
          </div>
        </div>
      </div>

      <Row className='mt-3' id='itPerson'>
        <Col sm={7}>
          <h3 className='icon-title type-pencil mt-small'>IT担当者一覧</h3>
        </Col>
        <Col sm={5}>
          <a href="https://docs.google.com/document/d/1UknFAJG-CCrXM0hO1T4dVLggk4qVa4IwcyQJ2hzp2zg/edit" target="_blank" className="btn main-btn type-external h-auto">作業依頼テンプレート<small>（※申請については各IT担当者にご相談ください。）</small></a>
        </Col>
      </Row>
      <div className="listBox">
        <table className='johsys-table table'>
          <thead>
            <tr >
              <th scope='col col-sm-3' style={{ "width": "25%" }}>本部名</th>
              <th scope='col col-sm-4' style={{ "width": "33.3%" }}>担当部署</th>
              <th scope='col' style={{ "width": "41.7%" }}>氏名</th>
            </tr>
          </thead>
          <tbody>
            <tr><td>内部監査室</td><td>-</td><td className="name-column">黒田 泰規</td></tr>
            <tr><td>人材戦略統括本部</td><td>人事部</td><td className="name-column">前田 和敏、西野 真佑子</td></tr>
            <tr><td>eマーケティング事業本部</td><td>グローバルニッチトップ事業部</td><td className="name-column">上野 敦士、北川 裕訓</td></tr>
            <tr><td>eマーケティング事業本部</td><td>ヒューマンキャピタル事業部</td><td className="name-column">上野 敦士、北川 裕訓</td></tr>
            <tr><td>eマーケティング事業本部</td><td>メディアクリエイション事業部</td><td className="name-column">上野 敦士、北川 裕訓</td></tr>
            <tr><td>eマーケティング事業本部</td><td>沖縄オフィス</td><td className="name-column">末吉 翔子、藤永 愛理奈、上原 浩司、比嘉 一貴</td></tr>
            <tr><td>HRインキュベーション事業本部</td><td>ダイバーシティ事業部 - ダイバーシティ課</td><td className="name-column">袴田 堅司</td></tr>
            <tr><td>HRインキュベーション事業本部</td><td>ダイバーシティ事業部 - SSW課</td><td className="name-column">野村 智恵</td></tr>
            <tr><td>HRインキュベーション事業本部</td><td>HRビジネスパートナー事業部</td><td className="name-column">佐藤 美月</td></tr>
            <tr><td>グローバル教育事業本部</td><td>グローバルキャリア事業部 - 留学課</td><td className="name-column">染谷 吉範</td></tr>
            <tr><td>グローバル教育事業本部</td><td>グローバルキャリア事業部 - 日本語課</td><td className="name-column">山本 南奈</td></tr>
            <tr><td>グローバル教育事業本部</td><td>グローバルキャリア事業部 - CMS課</td><td className="name-column">武藤 勇太</td></tr>
            <tr><td>グローバル教育事業本部</td><td>リンゲージ事業部</td><td className="name-column">小川 真州美</td></tr>
            <tr><td>管理本部</td><td>経理部</td><td className="name-column">森下 卓也</td></tr>
            <tr><td>管理本部</td><td>総務部</td><td className="name-column">黒田 泰規</td></tr>
            <tr><td>管理本部</td><td>経営企画部</td><td className="name-column">岡田 知子</td></tr>
          </tbody>
        </table>
        <p>各担当者の内線番号/携帯番号の確認は、<a href={baseUrl + "/employee_list"}>従業員名簿</a>よりご確認ください。</p>
      </div>
    </div >
  );
}

export default Contents1;