// @ts-nocheck
//Smooth Scroll
import { Link } from 'react-scroll';

// レイアウト
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// モーダル
import { useState } from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function F_fixture() {

  // モーダル
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  return (
    <div className="facility-lower">
      <h2 className='tag-title'>備品・ゴミ・落とし物について</h2>
      <Row className="my-4" as='dl'>
        <Col sm={3} as='dt' className='toc-btn-title'>
          備品について
        </Col>
        <Col sm={9} as='dd' className='mb-0'>
          <Row className='toc-btnArea'>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter01"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                備品庫の<br />場所について
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter02"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                備品の<br />発注方法について
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter03"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                備品破損の<br />相談について
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="my-4" as='dl'>
        <Col sm={3} as='dt' className='toc-btn-title'>
          鍵について
        </Col>
        <Col sm={9} as='dd' className='mb-0'>
          <Row className='toc-btnArea'>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter04"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                鍵管理（ルール）<br />について
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter05"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                鍵紛失・複製<br />について
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter06"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                鍵のスペアを借りる
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="my-4" as='dl'>
        <Col sm={3} as='dt' className='toc-btn-title'>
          席替えについて
        </Col>
        <Col sm={9} as='dd' className='mb-0'>
          <Row className='toc-btnArea'>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter07"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                席替えについて
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter08"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                デスク・椅子の<br />追加について
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="my-4" as='dl'>
        <Col sm={3} as='dt' className='toc-btn-title'>
          ゴミについて
        </Col>
        <Col sm={9} as='dd' className='mb-0'>
          <Row className='toc-btnArea'>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter11"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                ゴミの出し方<br />について
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter12"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                ゴミの分別について
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter13"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                雑誌や新聞を<br />どのように捨てるか
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter14"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                ゴミの回収時間<br />について
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="my-4" as='dl'>
        <Col sm={3} as='dt' className='toc-btn-title'>
          その他
        </Col>
        <Col sm={9} as='dd' className='mb-0'>
          <Row className='toc-btnArea'>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter10"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                名刺の発注方法<br />について
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter09"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                複合機のトナーが<br />切れた場合について
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter15"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                本社オフィス内での<br />落し物について
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>

      <section className="sec-fixtures">
        <h3 id="chapter01" className='icon-title type-pencil'>備品庫の場所について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>POINT</span>備品庫の場所は下記地図をご確認ください。</p>
        </div>
        <div className="img-wrap ct type-map">
          <div className="img-title">※本社18階</div>
          <Button
            variant="modal"
            onClick={() => setShow1(true)}
          >
            <iframe src="https://drive.google.com/file/d/1V5l-I64uPXqjCDElkiHEGF6XzfCa32Df/preview" width="1000" height="500" allow="autoplay"></iframe>
          </Button>
          <Modal
            show={show1}
            onHide={() => setShow1(false)}
            className="facility-modal"
            size="lg"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <iframe src="https://drive.google.com/file/d/1V5l-I64uPXqjCDElkiHEGF6XzfCa32Df/preview" width="1000" height="500" allow="autoplay"></iframe>
            </Modal.Body>
          </Modal>
        </div>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>【今後】19階管理本部側の備品庫以外の備品庫につきましては、今後集約してまいります。</p>
        </div>
        <div className="img-wrap ct type-map">
          <div className="img-title">※本社19階</div>
          <Button
            variant="modal"
            onClick={() => setShow2(true)}
          >
            <iframe src="https://drive.google.com/file/d/1XVhGoUls2SsSYJ9pNE6rFrxEDD3gS3SU/preview" width="1000" height="500" allow="autoplay"></iframe>
          </Button>
          <Modal
            show={show2}
            onHide={() => setShow2(false)}
            className="facility-modal"
            size="lg"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <iframe src="https://drive.google.com/file/d/1XVhGoUls2SsSYJ9pNE6rFrxEDD3gS3SU/preview" width="1000" height="500" allow="autoplay"></iframe>
            </Modal.Body>
          </Modal>
        </div>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>19階管理本部の備品庫をご使用の際は、【備品管理表】へご記入願います。<br />18階のみなさまも19階の備品庫・コピー用紙どちらもお使いください。</p>
        </div>

        <h3 id="chapter02" className='icon-title type-pencil'>備品の発注方法について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>【文房具】自部署の備品担当者が、総務宛てに出庫依頼を行っております。</p>
        </div>
        <div className="img-wrap ct">
          <div className="flame">
            {/* <img
              src={process.env.PUBLIC_URL + '/facility_pic/fixture01.jpg'}
              alt="備品の発注方法について"
              className="img-wrap__img"
            /> */}
            <iframe src="https://docs.google.com/spreadsheets/d/1MpVyLH5ssfRbU5hHrUtadJGXwnZKhSZL/preview#gid=1829250731" width="1000" height="500" allow="autoplay"></iframe>
          </div>
        </div>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>【什器備品】総務課にご相談ください。</p>
        </div>

        <h3 id="chapter03" className='icon-title type-pencil'>備品破損の相談について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span></p>
          <div className="l-number-wrapper">
            <ol>
              <li>上司へ報告</li>
              <li>
                始末書を連絡書で提出してください(始末書のフォーマットはお任せします)
                <div className="box-list">
                  <ul>
                    <li>始末書へは、破損の経緯や再発防止策等を記載してください。</li>
                    <li>始末書へは、ご自身の記名をお願いいたします。<br />※始末書の原本提出は不要です。</li>
                  </ul>
                </div>
              </li>
            </ol>
          </div>
        </div>
        <div className="img-wrap ct">
          <div className="img-title">（楽楽精算⇒①申請・承認⇒②ワークフロー⇒③連絡書⇒④連絡書）※下記画像参照</div>
          <div className="flame">
            <img
              src={process.env.PUBLIC_URL + '/facility_pic/fixture03.jpg'}
              alt="18F"
              className="img-wrap__img"
            />
          </div>
        </div>

        <h3 id="chapter04" className='icon-title type-pencil'>鍵管理（ルール）について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>デスクと引き出しの鍵はナンバリングをして管理している為、引き出しの交換を行わないでください。<br />席替えの際は人のみの移動としてください。</p>
        </div>

        <h3 id="chapter05" className='icon-title type-pencil'>鍵紛失・複製について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>鍵紛失時のフロー</p>
          <div className="l-number-wrapper">
            <ol>
              <li>紛失された鍵については、複製を作成する必要があります。<br />※鍵の作成費用目安は、おおよそ1,500円～2,500円(税別)です。</li>
              <li>上司及び、ロケーション管理の担当者へご報告の上、まずは始末書を連絡書で申請してください。</li>
              <li>始末書を連絡書で申請後、鍵の見積を取りますので、総務へご連絡ください。</li>
              <li>見積を受け取られましたら、金額を記載して購入稟議書と給与天引きの人事稟議書を申請してください。</li>
            </ol>
            <p>
              <br />
              <a href='https://docs.google.com/document/d/1W_iotVpKlP8_-xXpxNK-WiYUSFIqE3AFXK63Wtqe8oA/edit' target='_brank'>詳細</a>は、こちらからご確認ください。
            </p>
          </div>
        </div>

        <h3 id="chapter06" className='icon-title type-pencil'>鍵のスペアを借りる</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>エビデンスを残す必要があるため、連絡書の起案をお願いいたします。
          </p>
        </div>
        <div className="img-wrap ct">
          <div className="img-title">（楽楽精算⇒①申請・承認⇒②ワークフロー⇒③連絡書⇒④連絡書）※下記画像参照</div>
          <div className="flame">
            <img
              src={process.env.PUBLIC_URL + '/facility_pic/fixture03.jpg'}
              alt="18F"
              className="img-wrap__img"
            />
          </div>
        </div>

        <h3 id="chapter07" className='icon-title type-pencil'>席替えについて</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>デスクと引き出しの鍵はナンバリングして管理をしているため、引き出しの交換は行わないでください。<br />席替えの際は人だけの移動でお願いします。
          </p>
        </div>
        <h3 id="chapter08" className='icon-title type-pencil'>デスク・椅子の追加について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>まずは希望商品と数量を総務課へご連絡願います。<br />余剰在庫が在るかを確認後、無ければ見積を取りますので見積もり取得後、購入稟議起案をお願いします。</p>
        </div>
        <div className="img-wrap ct">
          <div className="img-title">（楽楽精算⇒①申請・承認⇒②ワークフロー⇒③稟議書⇒④購入稟議書）※下記</div>
          <div className="flame">
            <img
              src={process.env.PUBLIC_URL + '/facility_pic/fixture02.jpg'}
              alt="18F"
              className="img-wrap__img"
            />
          </div>
        </div>

        <h3 id="chapter09" className='icon-title type-pencil'>複合機のトナーが切れた場合について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>※セントラルパークタワー18階・19階<br></br>保守契約を締結しておりますので、複合機周辺に在庫がございます。<br />在庫が見当たらない場合は総務課までご連絡ください  </p>
        </div>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>※その他の事業所<br></br>保守契約を締結しておりますので、複合機周辺に在庫がございます。<br />在庫が見当たらない場合は備品担当者までご連絡ください。</p>
        </div>
        <div className="img-wrap ct">
          <div className="flame">
            {/* <img
              src={process.env.PUBLIC_URL + '/facility_pic/fixture01.jpg'}
              alt="18F"
              className="img-wrap__img"
            /> */}
            <iframe src="https://docs.google.com/spreadsheets/d/1MpVyLH5ssfRbU5hHrUtadJGXwnZKhSZL/preview#gid=1829250731" width="1000" height="500" allow="autoplay"></iframe>
          </div>
        </div>

        <h3 id="chapter10" className='icon-title type-pencil'>名刺の発注方法について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>自部署の名刺担当者へ連絡してください。部長承認後、名刺担当者にて作成を行います。</p>
        </div>
        <div className="img-wrap ct">
          <div className="flame">
            {/* <img
              src={process.env.PUBLIC_URL + '/facility_pic/meishi_new.jpg'}
              alt="18F"
              className="img-wrap__img"
            /> */}
            <iframe src="https://docs.google.com/spreadsheets/d/1MpVyLH5ssfRbU5hHrUtadJGXwnZKhSZL/preview#gid=869730607" width="1000" height="500" allow="autoplay"></iframe>
          </div>
        </div>
      </section>

      <section className="sec-trash">
        <h3 id="chapter11" className='icon-title type-pencil'>ゴミの出し方について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>※セントラルパークタワー<br></br>ビルより発行されている黄色「廃棄」シールを貼りゴミ箱の周辺に置いてください。<br />廃棄シールは19階総務課へお問い合わせください。
          </p>
        </div>
        <div className="img-wrap ct">
          <div className="flame">
            <img
              src={process.env.PUBLIC_URL + '/facility_pic/trash01.png'}
              alt="ゴミの出し方について"
              className="img-wrap__img"
            />
          </div>
        </div>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>※その他事業所<br></br>入居ビルにより異なりますので総務課へご連絡ください。
          </p>
        </div>

        <h3 id="chapter12" className='icon-title type-pencil'>ゴミの分別について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>※セントラルパークタワー<br></br>使用済みの乾電池については19階管理本部入口にあるテーブルの上に回収カゴがあります。<br />その他のゴミに関しての分別は、ゴミ箱にも表記しておりますのでご確認ください。</p>
        </div>
        {/* <div className="img-wrap ct type-map">
          <Button
            variant="modal"
            onClick={() => setShow3(true)}
          >
            <img
              src={process.env.PUBLIC_URL + '/facility_pic/trash02.jpg'}
              alt="ゴミの分別について"
              className="img-wrap__img"
              width={1816}
              height={833}
              loading='lazy'
            />
          </Button>
          <Modal
            show={show3}
            onHide={() => setShow3(false)}
            className="facility-modal"
            size="lg"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <img
                src={process.env.PUBLIC_URL + '/facility_pic/trash02.jpg'}
                alt="ゴミの分別について"
                className="img-wrap__img"
                width={1816}
                height={833}
                loading='lazy'
              />
            </Modal.Body>
          </Modal>
        </div> */}
        <div className="img-wrap ct type-map">
          <div className="img-title">※本社19階</div>
          <Button
            variant="modal"
            onClick={() => setShow2(true)}
          >
            <iframe src="https://drive.google.com/file/d/1XVhGoUls2SsSYJ9pNE6rFrxEDD3gS3SU/preview" width="1000" height="500" allow="autoplay"></iframe>
          </Button>
          <Modal
            show={show2}
            onHide={() => setShow2(false)}
            className="facility-modal"
            size="lg"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <iframe src="https://drive.google.com/file/d/1XVhGoUls2SsSYJ9pNE6rFrxEDD3gS3SU/preview" width="1000" height="500" allow="autoplay"></iframe>
            </Modal.Body>
          </Modal>
        </div>
        <div className="img-wrap ct">
          <div className="flame">
            <img
              src={process.env.PUBLIC_URL + '/facility_pic/trash03.png'}
              alt="ゴミの分別について"
              className="img-wrap__img"
            />
          </div>
        </div>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>※その他の事業所<br></br>各自治体のホームページをご確認ください。
          </p>
        </div>

        <h3 id="chapter13" className='icon-title type-pencil'>雑誌や新聞をどのように捨てるか</h3>
        <div className="border-box">
          <p className="todo-catch"><span>POINT</span>※セントラルパークタワー<br></br>19階の入口横に専用のゴミ箱が設置しております。
          </p>
        </div>
        <div className="img-wrap ct type-map">
          <div className="img-title">※本社19階</div>
          <Button
            variant="modal"
            onClick={() => setShow2(true)}
          >
            <iframe src="https://drive.google.com/file/d/1XVhGoUls2SsSYJ9pNE6rFrxEDD3gS3SU/preview" width="1000" height="500" allow="autoplay"></iframe>
          </Button>
          <Modal
            show={show2}
            onHide={() => setShow2(false)}
            className="facility-modal"
            size="lg"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <iframe src="https://drive.google.com/file/d/1XVhGoUls2SsSYJ9pNE6rFrxEDD3gS3SU/preview" width="1000" height="500" allow="autoplay"></iframe>
            </Modal.Body>
          </Modal>
        </div>
        {/* <div className="img-wrap ct type-map">
          <Button
            variant="modal"
            onClick={() => setShow4(true)}
          >
            <img
              src={process.env.PUBLIC_URL + '/facility_pic/trash04.jpg'}
              alt="ゴミの分別について"
              className="img-wrap__img"
              width={1816}
              height={833}
              loading='lazy'
            />
          </Button>
          <Modal
            show={show4}
            onHide={() => setShow4(false)}
            className="facility-modal"
            size="lg"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <img
                src={process.env.PUBLIC_URL + '/facility_pic/trash04.jpg'}
                alt="ゴミの分別について"
                className="img-wrap__img"
                width={1816}
                height={833}
                loading='lazy'
              />
            </Modal.Body>
          </Modal>
        </div> */}

        <h3 id="chapter14" className='icon-title type-pencil'>ゴミの回収時間について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>POINT</span>※セントラルパークタワー<br></br>原則として16：00以降の回収となります。
          </p>
        </div>
        <div className="border-box">
          <p className="todo-catch"><span>POINT</span>※セントラルパークタワー以外について<br></br>入居ビルによりルールが異なりますので総務課へご連絡ください。
          </p>
        </div>

        <h3 id="chapter15" className='icon-title type-pencil'>本社オフィス内での落し物について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>POINT</span>19階の総務課へお持ちください</p>
        </div>

      </section>

    </div>
  );
}

export default F_fixture;

