// @ts-nocheck
//Smooth Scroll
import { Link } from 'react-scroll';

// レイアウト
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// モーダル
import { useState } from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function F_lounge() {

  // モーダル
  const [showSeatmap1, setShowSeatmap1] = useState(false);

  return (
    <div className="facility-lower">
      <h2 className="tag-title">オフィスラウンジ・会議室</h2>
      <Row className="my-4" as='dl'>
        <Col sm={3} as='dt' className='toc-btn-title'>
          オフィスラウンジ
        </Col>
        <Col sm={9} as='dd' className='mb-0'>
          <Row className='toc-btnArea'>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter01"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                お弁当の販売時間<br />について
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter02"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                ヤクルトの販売日時<br />について
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter03"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                給茶機のトラブル<br />について
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter04"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                コーヒーサーバーの<br />洗浄について
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter05"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                自動販売機の<br />トラブルについて
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter06"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                ラウンジの専有利用<br />について
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="my-4" as='dl'>
        <Col sm={3} as='dt' className='toc-btn-title'>
          会議室
        </Col>
        <Col sm={9} as='dd' className='mb-0'>
          <Row className='toc-btnArea'>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter07"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                会議室の場所<br />について
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter08"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                会議室内の備品<br />について
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>

      <section className="sec-lounge">
        <h3 id="chapter01" className='icon-title type-pencil'>お弁当の販売時間について</h3>
        <div className="img-wrap ct">
          <div className="flame">
            <img
              src={process.env.PUBLIC_URL + '/facility_pic/lounge01.png'}
              alt="お弁当の販売時間について"
              className="img-wrap__img"
            />
          </div>
        </div>

        <h3 id="chapter02" className='icon-title type-pencil'>ヤクルトの販売日時について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>POINT</span>販売日時：毎週「火曜日」「水曜日」「金曜日」の【13：25～13：40】
          </p>
          <p className="todo-catch"><span>To Do</span>支払方法：ヤクルトレディへ直接お支払いください。<br></br>※ヤクルト以外にも宅配専用品や食品などもございますので、ご利用ください。
          </p>
        </div>

        <h3 id="chapter03" className='icon-title type-pencil'>給茶機のトラブルについて</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>お茶の粉末や水があるかを確認していただき、<br />それでも解決しない場合は機械トラブルの可能性がありますので総務課へご連絡ください。
          </p>
        </div>

        <h3 id="chapter04" className='icon-title type-pencil'>コーヒーサーバーの洗浄について</h3>
        <div className="img-wrap ct">
          <div className="flame">
            <img
              src={process.env.PUBLIC_URL + '/facility_pic/lounge02.png'}
              alt="コーヒーサーバーの洗浄について"
              className="img-wrap__img"
            />
          </div>
          <div className="flame">
            <img
              src={process.env.PUBLIC_URL + '/facility_pic/lounge03.png'}
              alt="コーヒーサーバーの洗浄について"
              className="img-wrap__img"
            />
          </div>
        </div>
        <div className="border-box">
          <p className="todo-catch"><span>POINT</span>※「マシンを洗浄して下さい」と表示された場合
          </p>
        </div>
        <div className="img-wrap ct">
          <div className="flame">
            <img
              src={process.env.PUBLIC_URL + '/facility_pic/lounge04.png'}
              alt="コーヒーサーバーの洗浄について"
              className="img-wrap__img"
            />
          </div>
        </div>
        <div className="border-box">
          <p className="todo-catch"><span>POINT</span>※フィルターマークが赤く点灯した場合
          </p>
        </div>
        <div className="img-wrap ct">
          <div className="flame">
            <img
              src={process.env.PUBLIC_URL + '/facility_pic/lounge05.png'}
              alt="コーヒーサーバーの洗浄について"
              className="img-wrap__img"
            />
          </div>
        </div>

        <h3 id="chapter05" className='icon-title type-pencil'>自動販売機のトラブルについて</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>対象の自販機とその時の状況を総務課へご連絡ください。<br />その状況が分かる写メなどがあると助かります。
          </p>
        </div>

        <h3 id="chapter06" className='icon-title type-pencil'>ラウンジの専有利用について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>以下の「Googleカレンダー運用ルール」をご参照ください。【№３】
          </p>
        </div>
        <div className="img-wrap ct">
          <div className="flame">
            <img
              src={process.env.PUBLIC_URL + '/facility_pic/lounge06.png'}
              alt="ラウンジの専有利用について"
              className="img-wrap__img"
            />
          </div>
        </div>
        <Row className="btnArea justify-content-center">
          <Col sm={5}>
            <a
              href="https://docs.google.com/presentation/d/1OuqyBt9erFPrEqn68h0aXym7fK1YoQjLPYgbikRbik8/present?slide=id.p3"
              target="_blank"
              className='btn main-btn type-external'
            >
              Googleカレンダー 運用ルール
            </a>
          </Col>
        </Row>

        <h3 id="chapter07" className='icon-title type-pencil'>会議室の場所について</h3>
        <div className="img-wrap ct type-map">
          <div className="img-title">※本社18階</div>
          <Button
            variant="modal"
            onClick={() => setShowSeatmap1(true)}
          >
            <iframe src="https://drive.google.com/file/d/1V5l-I64uPXqjCDElkiHEGF6XzfCa32Df/preview" width="1000" height="500" allow="autoplay"></iframe>
          </Button>
          <Modal
            show={showSeatmap1}
            onHide={() => setShowSeatmap1(false)}
            className="facility-modal"
            size="lg"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <iframe src="https://drive.google.com/file/d/1V5l-I64uPXqjCDElkiHEGF6XzfCa32Df/preview" width="1000" height="500" allow="autoplay"></iframe>
            </Modal.Body>
          </Modal>
        </div>
        <div className="img-wrap ct type-map">
          <div className="img-title">※本社19階</div>
          <Button
            variant="modal"
            onClick={() => setShowSeatmap1(true)}
          >
            <iframe src="https://drive.google.com/file/d/1XVhGoUls2SsSYJ9pNE6rFrxEDD3gS3SU/preview" width="1000" height="500" allow="autoplay"></iframe>
          </Button>
          <Modal
            show={showSeatmap1}
            onHide={() => setShowSeatmap1(false)}
            className="facility-modal"
            size="lg"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <iframe src="https://drive.google.com/file/d/1XVhGoUls2SsSYJ9pNE6rFrxEDD3gS3SU/preview" width="1000" height="500" allow="autoplay"></iframe>
            </Modal.Body>
          </Modal>
        </div>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>※予約方法はGoogleカレンダーで予約願います。</p>
        </div>

        <h3 id="chapter08" className='icon-title type-pencil'>会議室内の備品について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>会議室内の消耗品は総務課にて管理しております。総務課へご連絡ください。</p>
        </div>

      </section>

    </div>

  );
}

export default F_lounge;

