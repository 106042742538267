//ヘッダー
import Header from '../component/page_parts/header';
// フッター
import Footer from '../component/page_parts/footer';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';

// パンくずリスト
import TableOfContents from "../component/page_parts/common/table_of_contents";

const baseUrl = process.env.REACT_APP_PUBLIC_URL;

function template() {
  return (
    <>
      <Header />
      <div className="theme-hogehoge">{/* ページ独自のクラスで囲む(theme-) */}
        <div className="content-header">
          <div className="container">
            <Row>
              <Col sm={8}>
                <h1 className='page-title type-desktop'>テンプレートページ</h1>{/* ページタイトル */}
                <p className="page-description">テンプレート格納ページ。✌︎('ω'✌︎ )</p>{/* ページの説明 */}
              </Col>
              <Col sm={4}>
                <p className="position-fixed" style={{ top: "91px" }}>固定ボタンとか</p>{/* ページタイトルの横にコンテンツがある場合はこちらに記入 */}
                <p className='mt-5'>ほげほげ</p>
              </Col>
            </Row>
          </div>
        </div>
        <TableOfContents />
        <Container className='mt-5'>
          <section className="mb-5">
            <h2 className="text-danger font-weight-bold" id="cts01">▼テキスト</h2>
            <p>リンクは<a href="#">こちら</a>。テキストが入ります。<a href="#" className='text-link type-external'>外部リンクアイコン付き</a>テキストが入ります。<strong>強調テキスト</strong>テキストが入ります。テキストが入ります。</p>
            <p className="fs-1">.fs-1 文字サイズを変えられる</p>
            <p className="fs-4 fw-bold">.fs-4 文字サイズを変えられる 太字にできる</p>
            <p>その他テキストの装飾は<a href="https://getbootstrap.jp/docs/5.0/utilities/text/" target="_blank" rel="noopener noreferrer" className='text-link type-external'>こちら</a>を見るとよい</p>
          </section>
          <section className="mb-5">
            <h2 className="text-danger font-weight-bold" id="cts02">▼見出したち</h2>
            <p className='page-title type-pig'>下層h1</p>
            <p className='page-title type-bag'>下層h1</p>
            <p className='page-title type-info'>下層h1</p>
            <p className='page-title type-building'>下層h1</p>
            <p className='page-title type-media'>下層h1</p>
            <p className='page-title type-desktop'>下層h1</p>
            <p className='page-title type-briefcase'>下層h1</p>
            <p className='page-title type-address-book'>下層h1</p>
            <p className='page-title type-human'>下層h1</p>
            <p className='page-title type-sitemap'>下層h1</p>
            <p className="tag-title">ページ内タグタイトル(緑の見出し)</p>
            <p className="icon-title type-information">ページ内小見出し(h3相当)</p>
            <p className="icon-title type-custom">ページ内小見出し(h3相当)</p>
            <p className="icon-title type-calendar">ページ内小見出し(h3相当)</p>
            <p className="icon-title type-pencil mt-small">ページ内小見出し(h3相当)(.mt-smallを追加するとmtが50pxになる)</p>
            <p className="bg-title">ページ内小見出し(h4相当)</p>
            <div className="numbering-wrap mb-5 pb-5">
              <p className="numbering-title">ナンバリング見出し</p>
              <p className="numbering-title">ナンバリング見出し<br />ナンバリング見出し</p>
              <p className="numbering-title">ナンバリング見出し</p>
              <p className="numbering-title">ナンバリング見出し</p>
            </div>
            <div className="numbering-wrap type-step">
              <p className="numbering-title">ステップ見出し 説明会動画を視聴する</p>
              <p>約35分の動画となります。まずはどういった内容、メリットがあるかをご確認ください。</p>
              <Row className="my-5">
                <Col sm={7}>
                  <p className="numbering-title mt-0">ログインアカウント取得後、<br />楽楽精算へログインしてみましょう。</p>
                  <p>適宜マージンのユーティリティでマージンを調整してくださいまし</p>
                </Col>
                <Col sm={5}>
                  <img src="https://placehold.jp/400x300.png" alt="" />
                </Col>
              </Row>
              <Row className="my-5">
                <Col sm={7}>
                  <p className="numbering-title mt-0">ログイン後、定期区間を設定しましょう。</p>
                  <p>吾輩は猫である。名前はまだ無い。どこで生れたかとんと見当がつかぬ。何でも薄暗いじめじめした所でニャーニャー泣いていた事だけは記憶している。吾輩はここで始めて人間というものを見た。しかもあとで聞くとそれは書生という人間中で一番獰悪な種族であったそうだ。この書生というのは時々我々を捕えて煮て食うという話である。しかしその当時は何という考もなかったから別段恐しいとも思わなかった。ただ彼の掌に載せられてス</p>
                  <p>吾輩は猫である。名前はまだ無い。どこで生れたかとんと見当がつかぬ。何でも薄暗いじめじめした所でニャーニャー泣いていた事だけは記憶している。吾輩はここで始めて人間というものを見た。しかもあとで聞くとそれは書生という人間中で一番獰悪な種族であったそうだ。この書生というのは時々我々を捕えて煮て食うという話である。しかしその当時は何という考もなかったから別段恐しいとも思わなかった。ただ彼の掌に載せられてス</p>
                </Col>
                <Col sm={5}>
                  <img src="https://placehold.jp/400x300.png" alt="" />
                </Col>
              </Row>
            </div>
          </section>
          <section className="mb-5">
            <h2 className="text-danger font-weight-bold" id="cts04">▼リスト</h2>
            <ul>
              <li><strong>クラスのないul。</strong>上下のマージンはユーティリティで調整してください。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。</li>
              <li>このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。</li>
              <li>このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。このテキストはダミーです。</li>
            </ul>
            <ol>
              <li>楽楽精算画面の右上の「個人設定」クリック→「定期区間」クリックあああああああああああああああああああああああああああああああああああ</li>
              <li>あああああああああ</li>
              <li>あああああああああ</li>
              <li>あああああああああ</li>
              <li>あああああああああ</li>
            </ol>
          </section>
          <section className="mb-5">
            <h2 className="text-danger font-weight-bold" id="cts03">▼ボタンリンク</h2>
            <a href="#" target="_blank" className="btn main-btn">
              グリッドシステムを使う前提なのでノラだとびろ～ん
            </a>
            <Row className='mt-4'>
              <Col sm={4}>
                <a href="#" target="_blank" className="btn main-btn type-external">
                  外部リンク<br />リンク
                </a>
              </Col>
              <Col sm={4}>
                <a href="#" className="btn main-btn type-internal">
                  内部リンク<small>サブテキストサブテキスト</small>
                </a>
              </Col>
              <Col sm={4}>
                <a href="#" className="btn main-btn type-download">
                  ダウンロード
                </a>
              </Col>
              <Col sm={4} className="mt-4">
                <a href="#" className="btn main-btn type-movie">
                  動画リンク<br /><small>0:30</small>
                </a>
              </Col>
            </Row>
            <p className="mt-3 small">Bootstrapの「<a href="https://react-bootstrap.netlify.app/docs/layout/grid/" target="_blank" rel="noopener noreferrer" className='text-link type-external'>グリッドシステム</a>」で横並びにできる</p>
          </section>
          <section className="mb-5">
            <h2 className="text-danger font-weight-bold" id="cts04">▼アコーディオン(未着手)</h2>
            <p>FAQをこれに差し替えたいです…</p>
            {/* <p>※サイドバーのクラス名を変えないと使えないのでコメントアウト中</p> */}
            <Accordion defaultActiveKey={['0']} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Accordion Item #1</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                  minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in
                  reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Accordion Item #2</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                  minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in
                  reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </section>
          <section className="mb-5">
            <h2 className="text-danger font-weight-bold" id="cts04">▼テーブル(未着手)</h2>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Username</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td colSpan={2}>Larry the Bird</td>
                  <td>@twitter</td>
                </tr>
              </tbody>
            </Table>
          </section>
          <section className="mb-5">
            <h2 className="text-danger font-weight-bold" id="cts04">▼タブ(未着手)</h2>
            <p>hogehoge</p>
          </section>
          <section className="mb-5">
            <h2 className="text-danger font-weight-bold" id="cts04">▼リンク</h2>
            <p><a href="http://localhost:3000/">http://localhost:3000/</a></p>
            <p><a href="http://localhost:3000/keiri">http://localhost:3000/keiri</a></p>
            <p><a href="http://localhost:3000/employee_list">http://localhost:3000/employee_list</a></p>
            <p><a href="http://localhost:3000/division">http://localhost:3000/division</a></p>
            <p><a href="http://localhost:3000/media">http://localhost:3000/media</a></p>
            <p><a href="http://localhost:3000/johsys">http://localhost:3000/johsys</a></p>
            <p><a href="http://localhost:3000/facility">http://localhost:3000/facility</a></p>
          </section>
        </Container>
      </div>
      <Footer />
      <p><a href="http://localhost:3000/jinjer">http://localhost:3000/jinjer</a></p>
      <p><a href="http://localhost:3000/welfare">http://localhost:3000/welfare</a></p>
      <p><a href="http://localhost:3000/lifestyle">http://localhost:3000/lifestyle</a></p>
    </>
  );
}

export default template;