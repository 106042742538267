// @ts-nocheck
import { useState, useEffect } from "react";
import jwt_decode from 'jwt-decode';
import { useCookies } from 'react-cookie';
import { useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { ToastContainer, toast } from 'react-toastify';

// css
import 'react-toastify/dist/ReactToastify.css';

//ヘッダー
import Header from '../../component/page_parts/header';

// フッター
import Footer from '../../component/page_parts/footer';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// パンくずリスト
import TableOfContents from "../../component/page_parts/common/table_of_contents";

//json読み込み
import phoneBookJson from '../../files/phoneBook.json';

function Employee_list() {
  const [cookies, setCookie, removeCookie] = useCookies(['sessionToken']);

  //社員一覧用のstate
  const [employee_list, setEmployee_list] = useState([]); // 取得従業員情報を格納するステート
  const [employee_bk_list, setEmployee_bk_list] = useState([]); // 取得従業員情報を格納するステート
  const [codeList, setCodeList] = useState([]);
  const [listNum, setListNum] = useState(0); // 取得従業員数を格納するステート

  //初期所属部名コードを取得 ※初期値は101の全研本社
  const [defaultDepartmentCode, setDefaultDepartmentCode] = useState("101");

  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  const defaultOptionParam = new URLSearchParams(useLocation().search).get('defaultOption');

  // 部/課/チーム別の電話番号
  const [phoneNumberTextList, setPhoneNumberTextList] = useState(["部課単位でご確認ください"]);

  // メールアドレスアドレスコピー時のトースト
  const notify = (emailaddress) => {
    toast.success(emailaddress + "をコピーしました。", {
      autoClose: 3000,
    });
  };

  useEffect(() => {
    //トークンの有無を確認
    if (!cookies.sessionToken) {
      //トークンがない
    } else {
      const decoded = jwt_decode<{ [name: string]: string }>(cookies.sessionToken);
      const myEmail = decoded.data.EMAIL;
      //初期所属部名コード ※初期値は101の全研本社
      setDefaultDepartmentCode(
        defaultOptionParam !== null ? defaultOptionParam
          :
          localStorage.getItem("dept") ? localStorage.getItem("dept")
            :
            decoded.data.DEPARTMENT_CODE_ID == "" ? "101" : decoded.data.DEPARTMENT_CODE_ID
      );
      // 従業員情報取得(事業部名絞り込み)呼び出し

      getEmployeeList(myEmail,
        defaultOptionParam !== null ? defaultOptionParam
          :
          localStorage.getItem("dept") ? localStorage.getItem("dept")
            :
            decoded.data.DEPARTMENT_CODE_ID == "" ? "101" : decoded.data.DEPARTMENT_CODE_ID
      );
    }
    if (localStorage.getItem("dept") == "205") {
      // 内部監査のみ部レベルで電話番号がある為、表示処理をいれた
      setPhoneNumberTextList([phoneBookJson[10]["phoneNumberText"]]);
    }
  }, []);

  // 従業員情報取得(事業部名絞り込み)
  async function getEmployeeList(myEmail, departmentCode) {
    // クエリパラメータ(所属部名コード)を使用し、従業員情報取得
    var response = await fetch(process.env.REACT_APP_API_PATH + 'employeelist/getUserList.php?email=' + myEmail);
    var json = await response.json();

    var users = JSON.parse(json.user_data);
    var code = JSON.parse(json.code_data);

    var del_count = 0;
    //無効なデータを削除
    for (let i = 0; i < users.length; i++) {
      if (users[i].ACTIVE == "1") {
        users.splice(i, 1);
        del_count++;
      }
    }
    //POSITION_CODE_IDが599のデータを退避させる
    var users599 = [];
    for (let i = 0; i < users.length; i++) {
      if (users[i].POSITION_CODE_ID === "599") {
        //退避
        users599.push(users[i]);
      }
    }

    for (let i = 0; i < users599.length; i++) {
      //退避させたデータをusersから削除
      for (let j = 0; j < users.length; j++) {
        if (users599[i].USER_ID === users[j].USER_ID) {
          users.splice(j, 1);
        }
      }
      //退避させたデータをusersの最後に追加
      users.push(users599[i]);
    }

    //従業員情報
    setEmployee_list(users);
    //絞り込み用 従業員情報
    setEmployee_bk_list(users);
    //コード情報
    setCodeList(code);
    //初期表示用に絞り込み関数
    searchDepartment(users, departmentCode);
  }

  // 従業員情報取得(事業部名絞り込み)
  const changeDepartment = (event) => {
    const departmentCode = event.target.value;
    setDefaultDepartmentCode(departmentCode);
    //ここで選択されたコードを利用して従業員stateを更新する
    //stateのbkをmotoniに変更する
    searchDepartment(employee_bk_list, departmentCode);

    var phoneNumberTextList = phoneBookJson[0]["phoneNumberTextList"][0]["phoneNumberText"].split(',');

    // 部/課/チーム別の電話番号切替処理
    var phoneDispArea = document.getElementById("phoneDispArea");
    phoneDispArea.style.display = "block";
    var phoneDisp = document.getElementById("phoneDisp");
    phoneDisp.style.display = "flex";
    for (let i = 0; i < phoneBookJson.length; i++) {
      if (departmentCode == phoneBookJson[i]["departmentCode"]) {
        // jsonファイルに切り替えたdepartmentCodeと同値が存在する場合、電話番号を取得する
        var phoneNumberTextList = phoneBookJson[i]["phoneNumberTextList"][0]["phoneNumberText"].split(',');
        setPhoneNumberTextList(phoneNumberTextList);
        if (departmentCode == "102" || departmentCode == "103" || departmentCode == "104") {
          // Zenken India LLP、Linguage inc、全研ケア株式会社の場合のみ、要素自体を非表示にする
          var phoneDisp = document.getElementById("phoneDisp");
          phoneDisp.style.display = "none";
        }
      }
    }
  };

  // メールアドレスの吹き出し表示／非表示処理
  function mailDispChange(event) {

    // 押したメールアドレスの要素取得
    let mailElement = document.getElementById(event.currentTarget.id);
    navigator.clipboard.writeText(mailElement.id);
    notify(mailElement.id);

    // 押したメールアドレスが既に表示状態の場合は、非表示にする
    if (mailElement.classList[2] == 'open') {
      mailElement.classList.toggle("open");
      mailElement.classList.remove("open");
    } else {
      mailElement.classList.toggle("open");
      mailElement.classList.add("open");
    }

    // メールアドレスの要素全取得
    const mailElements = document.getElementsByClassName("list-mail");

    for (var i = mailElements.length - 1; i >= 0; i--) {
      mailBtnAction(mailElements[i], i);
    }

    function mailBtnAction(mailBtnDOM, mailBtnId) {
      mailBtnDOM.addEventListener("click", function () {
        // 押したメールアドレスの要素以外を全て非表示にする
        for (var i = mailElements.length - 1; i >= 0; i--) {
          if (mailBtnId !== i) {
            if (mailElements[i].classList.contains("open")) {
              mailElements[i].classList.remove("open");
            }
          }
        }
      });
    }
  }

  //部署ごと絞り込み
  function searchDepartment(list_data, code_id) {
    //各種if文で絞り込み
    var searchResult = [];
    for (let i = 0; i < list_data.length; i++) {
      if (code_id == 100) {
        searchResult.push(list_data[i]);
      } else if (code_id >= 100 && code_id < 200) {
        if (list_data[i].OFFICE_CODE_ID == code_id) {
          searchResult.push(list_data[i]);
        }
      } else if (code_id >= 200 && code_id < 300) {
        if (list_data[i].DEPARTMENT_CODE_ID == code_id) {
          searchResult.push(list_data[i]);
        }
      } else if (code_id >= 300 && code_id < 400) {
        if (list_data[i].SECTION_CODE_ID1 == code_id) {
          searchResult.push(list_data[i]);
        }
      } else if (code_id >= 400 && code_id < 500) {
        if (list_data[i].SECTION_CODE_ID2 == code_id) {
          searchResult.push(list_data[i]);
        }
      }
    }

    setListNum(searchResult.length);
    setEmployee_list(searchResult);
  }

  function copyBtn(test) {
    const btn = document.getElementById('info-text'); // button要素取得
    let copyBtn = document.getElementById(test.target.id).textContent;
    navigator.clipboard.writeText(copyBtn); // ★ テキストをクリップボードに書き込み（＝コピー）
  }

  return (
    <>
      <Helmet>
        <title>Z-link 従業員名簿</title>
      </Helmet>
      <Header />
      <div className="theme-facility theme-employee_list">
        <div className="content-header">
          <div className="container">
            <Row>
              <Col sm={12}>
                <h1 className='page-title type-address-book mb-1'>従業員名簿</h1>{/* ページタイトル */}
              </Col>
            </Row>
          </div>
        </div>
        <ToastContainer toastClassName="custom-toast" />
        <TableOfContents />
        <Container className="main-wrapper list pt-0">
          {/* 初期表示する内容を記載する */}
          <div className="content">
            <div className="row">
              <div className="mt-4 py-4 col-sm-4">
                <div className="cp_sl01">
                  <select className="px-3"
                    value={defaultDepartmentCode}
                    onChange={changeDepartment}
                  >
                    {
                      codeList.map((post) => {
                        const {
                          CODE_ID,
                          CODE_MEAN
                        } = post;
                        return (
                          <>
                            {
                              CODE_ID > 99 && CODE_ID < 450 ? <option value={CODE_ID}>{CODE_MEAN}
                                {
                                  defaultDepartmentCode == CODE_ID ? "（" + listNum + "）" : ""
                                }
                              </option> : <></>
                            }
                          </>
                        );
                      })
                    }
                  </select>
                </div>
              </div>
              <div className="mt-4 py-4 col-sm-5" id="phoneDispArea">
                <div className="phoneDisp" id="phoneDisp">
                  <div className="phoneDispText">電話番号：</div>
                  <div className="phoneDispList">
                    <div>
                      <span id="phoneNumberText">
                        {phoneNumberTextList[0]}
                      </span>
                      <br />
                    </div>
                    <div>
                      <span className="phoneNumberTextTwo">
                        {phoneNumberTextList[1]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="icon-title type-mailaddress col-sm-3 mt-4 py-4">をクリックすると、<br />メールアドレスをコピーできます。</div>
            </div>
            <div className="py-1 px-0">
              <div className="employee-table px-0">
                {
                  employee_list.length == 0 ? <p className="text-center py-4">該当する社員がいません</p> :
                    <table className="table">
                      <thead>
                        <tr className="row d-table-row">
                          <th className="col col-sm-4 type-name ps-3">氏名</th>
                          <th className="col col-sm-3 type-department">所属部署</th>
                          <th className="col col-sm-2 type-position">役職</th>
                          <th className="col col-sm-2 type-extension">内線番号/携帯番号</th>
                          <th className="col col-sm-1 type-mail ps-3 pe-1">メール<br />アドレス</th>
                        </tr>
                      </thead>
                      <tbody tbody className="employee-content" id="employee-content-tbody" >
                        {employee_list.map((post) => {
                          const {
                            USER_ID,
                            USER_NAME,
                            POSITION_MEAN,
                            EXTENSION,
                            EMAIL,
                            OFFICE_MEAN,
                            DEPARTMENT_MEAN,
                            SECTION_MEAN,
                            SECTION_MEAN2,
                            KANA,
                          } = post;
                          return (
                            <tr className="employee-list row d-table-row">
                              <td className="col col-sm-12 type-name">
                                <>{USER_NAME}<span className="type-kana">{(KANA ? "(" + KANA + ")" : "")}</span></>
                              </td>
                              <td className="col col-sm-3 type-department">
                                {
                                  SECTION_MEAN2 ? SECTION_MEAN2 :
                                    SECTION_MEAN ? SECTION_MEAN :
                                      DEPARTMENT_MEAN ? DEPARTMENT_MEAN :
                                        OFFICE_MEAN ? OFFICE_MEAN : "-"
                                }
                              </td>
                              <td className="col col-sm-2 type-position">
                                {POSITION_MEAN ? POSITION_MEAN : "-"}
                              </td>
                              <td className="col col-sm-2 type-extension">
                                {EXTENSION ? EXTENSION : "-"}
                              </td>
                              <td className="col col-sm-1 type-mail ps-4">
                                <div className="wrapper text-center">
                                  <button
                                    type="button"
                                    className="list-mail btn"
                                    onClick={mailDispChange}
                                    id={EMAIL}
                                  >
                                    <img
                                      src={process.env.PUBLIC_URL + '/facility_pic/mail01_hover.png'}
                                      alt="メール"
                                      className="employee-list__img on"
                                    />
                                    <img
                                      src={process.env.PUBLIC_URL + '/facility_pic/mail01.png'}
                                      alt="メール"
                                      className="employee-list__img off"
                                    />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                }
              </div>
            </div>
          </div>
        </Container >
      </div >
      <Footer />
    </>
  );
}
export default Employee_list;