// @ts-nocheck
// フッター
import Footer from '../../component/page_parts/footer';

// CSSの読み込み
import "../../css/style.min.css";
import "../../css/App.css";

function maintenance() {
    return (
    <>
      <div className="theme-login">
        <div className="contents">
          <div className="corporate-wrap">
            <div className="purpose-area">
              <div className="purpose-text">PURPOSE</div>
              <span>そこにない未来を創る</span>
              <div className="purpose-link"><a href="https://www.zenken.co.jp/our-goal/purpose/" target="_blank">詳しく見る</a></div>
            </div>
            <div className="vision-area">
              <div className="vision-text">VISION</div>
              <span>持続可能な社会の創出</span>
              <div className="vision-link"><a href="https://www.zenken.co.jp/our-goal/vision/" target="_blank">詳しく見る</a></div>
            </div>
          </div>
          <div className="login-wrap">
            <img
              src={process.env.PUBLIC_URL + '/logo_positive.svg'}
              alt="Z-LINK"
              className="Z-LINK"
            />
            <div className="login-btn">
              <h2>現在メンテナンス中です。</h2>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default maintenance;
