import React, { useState, useEffect } from "react";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Contents2() {
  useEffect(() => { }, []);

  return (
    <>
      <h2 className="tag-title">機器の使用時 注意事項</h2>
      <h3 className='icon-title type-pencil'>PC使用時に必ずお読みください</h3>
      <Row>
        <Col sm={4}>
          <a href="https://drive.google.com/file/d/1TYu4Qqd9KZ4PePhgMvf4UuzEjs8jac5c/" target="_blank" className="btn main-btn type-external">
            ハンドブック<small>PC・付属機器の取り扱い注意事項</small>
          </a>
        </Col>
        <Col sm={4}>
          <a href="https://docs.google.com/document/d/1tedvuhk83cgMoxvUcG1pvOybbPi41EjflbXSKE2vNJk/edit#" target="_blank" className="btn main-btn type-external">
            ノートPC貸与時にやること
          </a>
        </Col>
        <Col sm={4}>
          <a href="https://docs.google.com/presentation/d/17rv2pN2WsG00U9lJf_atljHsO1AddlzbuFtwm3DNF5I/edit#slide=id.g2be6be4fea6_0_4" target="_blank" className="btn main-btn type-external">
            貸与PCの付属品チェック表
          </a>
        </Col>
      </Row>
      <h3 className='icon-title type-pencil'>各機器使用時に役立つ情報</h3>
      <Row>
        <Col sm={4}>
          <a href="https://docs.google.com/document/d/1wLNYHl3rVp3at1HRI6M1ajdzCfvWyUE_/edit" target="_blank" className="btn main-btn type-external">
            複合機BOX使用マニュアル
          </a>
        </Col>
        <Col sm={4}>
          <a href="https://docs.google.com/document/d/12U8kNyW5fHr-q7lzUkCOp9YJ206z1hEPb4TkWY0nksE/edit#" target="_blank" className="btn main-btn type-external">
            スマホ・タブレット端末の<br />取り扱い注意事項
          </a>
        </Col>
        <Col sm={4}>
          <a href="https://www.au.com/online-manual/kyg02/" target="_blank" className="btn main-btn type-external">
            社用スマホ：DIGNO SX3<br />操作マニュアル
          </a>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <a href="https://docs.google.com/document/d/1T1E70vvd0ej6oCv_xyb_E8-8yukNdMVt1bY-_9ACubY/edit?usp=sharing" target="_blank" className="btn main-btn type-external">
            複合機の印刷手順
          </a>
        </Col>
        <Col sm={4}>
          <a href="https://docs.google.com/document/d/1J221up9ece6o16vyHnWepdwhqi0OdEnDh3HY5gfoZCg/edit?usp=sharing" target="_blank" className="btn main-btn type-external">
            「通常使うプリンター」の設定方法
          </a>
        </Col>
        <Col sm={4}>
          <a href="https://drive.google.com/file/d/1TcBhpBKE-3NafApjVJ5D4ZSGBaSyVbKw/view?usp=sharing" target="_blank" className="btn main-btn type-external">
            会議室PCアカウント一覧
          </a>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <a href="https://docs.google.com/document/d/1glUXNeeFrUVPgp7hpGU_7e5BbtHbd4u7ggjdJMjX0wo/edit?usp=sharing" target="_blank" className="btn main-btn type-external">
            その他　PC使用時の役立ちTips
          </a>
        </Col>
      </Row>
    </>
  );
}

export default Contents2;
