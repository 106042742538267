// @ts-nocheck
import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useCookies } from "react-cookie";
import { Helmet } from 'react-helmet-async';
//ヘッダー
import Header from "../../component/page_parts/header";

// フッター
import Footer from "../../component/page_parts/footer";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

/**
 * MEMO:
 *
 */

function AdministratorEdit() {
  const [cookies, setCookie, removeCookie] = useCookies(["sessionToken"]);
  const [userList, setUserList] = useState([]);
  const [userList_bk, setUserList_bk] = useState([]);
  const [codeList, setCodeList] = useState([]);

  //react-select用
  const [rs, setRs] = useState([]);

  //メールアドレス
  const [updateMail, setUpdateMail] = useState("");

  //名前
  const [updateUserName, setUpdateUserName] = useState("");

  //ふりがな
  const [kana, setKana] = useState("");

  //事業所
  const [office, setOffice] = useState("");

  //所属本部
  const [department, setDepartment] = useState("");

  //部署の配列
  const [section1, setSection1] = useState("");
  const [section2, setSection2] = useState("");
  const [section3, setSection3] = useState("");

  //フラグ用
  const [lastedFlag, setLastedFlag] = useState(false);
  const [userChoice, setUserChoice] = useState(false);
  const [selectAndSearch, setSelectAndSearch] = useState(true);

  //役職
  const [position, setPosition] = useState("");
  const [myPosition, setMyPosition] = useState("");

  //自身のメールアドレス
  const [myEmail, setMyEmail] = useState("");

  //対象ユーザーのメールアドレス
  const [targetEmail, setTargetEmail] = useState("");

  //アカウント状態
  const [accountStatus, setAccountStatus] = useState("");

  const { control } = useForm<{
    name: string;
  }>({
    defaultValues: {
      name: "選択してください",
    },
  });


  useEffect(() => {
    //トークンの有無を確認
    if (!cookies.sessionToken) {
      //トークンがない
    } else {
      const decoded = jwt_decode<{ [name: string]: string }>(
        cookies.sessionToken
      );
      console.log(decoded);
      //管理者以外はアクセス不可(507~509)
      if (decoded.data.POSITION_CODE_ID < 507) {
        //トップページへ遷移
        window.location.href = "/";
      } else {
        setMyEmail(decoded.data.EMAIL);
        //プロフィール情報取得(初回実行)
        getUserProfile(decoded.data.EMAIL);
        //役職をセット
        setMyPosition(decoded.data.POSITION_CODE_ID);
      }
    }
  }, []);

  //プロフィール情報取得
  async function getUserProfile(param) {
    //ユーザー情報取得
    var res = await fetch(
      process.env.REACT_APP_API_PATH +
      "user/profile/administratorGet.php?email=" +
      param +
      "&mode=1"
    );
    var data = await res.json();
    // console.log(JSON.parse(data.user_data));
    // console.log(JSON.parse(data.code_data));

    //ユーザー情報から自分の情報は削除
    var users = JSON.parse(data.user_data);
    var index = users.findIndex((user) => user.EMAIL === param);
    users.splice(index, 1);

    //ユーザー情報をセット
    setUserList(users);
    //ユーザー情報をバックアップ
    setUserList_bk(users);
    //コード情報をセット
    setCodeList(JSON.parse(data.code_data));

    //react-select用に整形
    var rs = [];
    users.map((user) => {
      rs.push({ value: user.EMAIL, label: user.USER_NAME });
    });
    setRs(rs);
  }

  function choiceMode(e) {
    //各種フラグを初期化
    setLastedFlag(false);
    setUserChoice(false);


    //「すべての部署」以外が選択された場合、検索フラグをtrueにする
    if (e.target.value != "") {
      setSelectAndSearch(false);
    } else {
      setSelectAndSearch(true);
    }

    //userプルダウンを「選択してください」に戻す
    var userSelect = document.getElementById("user") as HTMLSelectElement;
    userSelect.value = "";

    // console.log(e.target.value);
    //IDにより分岐
    if (e.target.value >= 101 && e.target.value < 200) {
      //事業所
      //userListのOFFICE_CODE_IDが一致するものを取得
      var officeList = userList_bk.filter(
        (user) =>
          user.OFFICE_CODE_ID === e.target.value
      );
      // console.log(officeList);
      //officeListをセット
      setUserList(officeList);
    } else if (e.target.value >= 200 && e.target.value < 300) {
      //所属部署
      //userListのDEPARTMENT_CODE_IDが一致するものを取得
      var departmentList = userList_bk.filter(
        (user) =>
          user.DEPARTMENT_CODE_ID === e.target.value
      );
      // console.log(departmentList);
      //departmentListをセット
      setUserList(departmentList);
    } else if (e.target.value >= 300 && e.target.value < 400) {
      //セクション1
      //userListのSECTION_CODE_ID1が一致するものを取得
      var section1List = userList_bk.filter(
        (user) =>
          user.SECTION_CODE_ID1 === e.target.value
      );
      // console.log(section1List);
      //section1Listをセット
      setUserList(section1List);
    } else if (e.target.value >= 400 && e.target.value < 450) {
      //セクション2
      //userListのSECTION_CODE_ID2が一致するものを取得
      var section2List = userList_bk.filter(
        (user) =>
          user.SECTION_CODE_ID2 === e.target.value
      );
      // console.log(section2List);
      //section2Listをセット
      setUserList(section2List);
    } else if (e.target.value >= 450 && e.target.value < 500) {
      //セクション3
      //userListのSECTION_CODE_ID3が一致するものを取得
      var section3List = userList_bk.filter(
        (user) =>
          user.SECTION_CODE_ID3 === e.target.value
      );
      // console.log(section3List);
      //section3Listをセット
      setUserList(section3List);
    } else {
      //リセット
      setUserList(userList_bk);
    }
  }
  //ユーザー選択
  function userSelect(e) {
    console.log(e.target.value);
    //ユーザー情報(userList)から取得
    var user = userList.find((user) => user.EMAIL === e.target.value);
    // console.log(user);
    //事業所
    setOffice(user.OFFICE_CODE_ID != null ? user.OFFICE_CODE_ID : "");
    //所属本部
    setDepartment(
      user.DEPARTMENT_CODE_ID != null ? user.DEPARTMENT_CODE_ID : ""
    );
    //部署の配列
    setSection1(user.SECTION_CODE_ID1 != null ? user.SECTION_CODE_ID1 : "");
    setSection2(user.SECTION_CODE_ID2 != null ? user.SECTION_CODE_ID2 : "");
    setSection3(user.SECTION_CODE_ID3 != null ? user.SECTION_CODE_ID3 : "");
    setPosition(user.POSITION_CODE_ID != null ? user.POSITION_CODE_ID : "");

    //対象ユーザーのメールアドレスをセット
    setTargetEmail(user.EMAIL);

    //アカウント状態
    setAccountStatus(user.ACTIVE);
    // console.log(user.ACTIVE);

    //フラグ
    if (user != "") {
      setUserChoice(true);
    } else {
      setUserChoice(false);
    }

    setUpdateMail(user.EMAIL == "" ? "" : user.EMAIL);
    setUpdateUserName(user.USER_NAME == "" ? "" : user.USER_NAME);
    setKana(user.KANA == "" ? "" : user.KANA);
  }

  //選択
  const handleChange = (e) => {
    console.log(e.target.value);

    //空ではない場合(選択してくださいではない場合)

    //選択された事業所のコードIDにより分岐
    //クソコード
    var nowChoice = "";
    if (e.target.value >= 100 && e.target.value < 200) {
      // console.log('事業所');
      setOffice(e.target.value);
    } else if (e.target.value >= 200 && e.target.value < 300) {
      // console.log('所属本部');
      setDepartment(e.target.value);
    } else if (e.target.value >= 300 && e.target.value < 400) {
      // console.log('所属課1');
      setSection1(e.target.value);
    } else if (e.target.value >= 400 && e.target.value < 450) {
      // console.log('所属課2');
      setSection2(e.target.value);
    } else if (e.target.value >= 450 && e.target.value < 500) {
      // console.log('所属課3');
      setSection3(e.target.value);
      setLastedFlag(true);
    }

    nowChoice = e.target.getAttribute("data-select-child");
    //選択された内容よりあとの階層を初期化
    if (nowChoice == "office") {
      setDepartment("");
      setSection1("");
      setSection2("");
      setSection3("");
    } else if (nowChoice == "department") {
      setSection1("");
      setSection2("");
      setSection3("");
    } else if (nowChoice == "section1") {
      setSection2("");
      setSection3("");
    } else if (nowChoice == "section2") {
      setSection3("");
    }

    //選択されたコードIDに子要素がない場合、最終選択フラグをtrueにする
    if (
      !codeList.filter((code) => code.PARENT_ID == e.target.value).length > 0 &&
      e.target.value != ""
    ) {
      setLastedFlag(true);
    } else {
      setLastedFlag(false);
    }
  };
  //役職が変更された場合
  const positionChenge = (e) => {
    // console.log(e.target.value);
    setPosition(e.target.value);
  };

  //profile更新処理
  const handleClick = (e) => {
    e.preventDefault();

    // 所属に応じたビル内外を設定
    let building = "";
    if (department === "" && section1 === "" && section2 === "" && section3 === "") { // office までの組織
      building = "601"; // ビル外で設定
    } else if (section1 === "" && section2 === "" && section3 === "") { // department までの組織
      building = "600"; // デフォルトでビル内を設定
    } else if (section2 === "" && section3 === "") { // section1 までの組織
      building = "600"; // デフォルトでビル内を設定
      // ビル外組織のみ、ビル外用のコードを更新
      if (section1 === "305") {
        building = "601";
      }
    } else if (section3 === "") { // section2 までの組織
      building = "600"; // デフォルトでビル内を設定
      // ビル外組織のみ、ビル外用のコードを更新
      if (section2 === "401" || section2 === "402" || section2 === "403" || section2 === "412" || section2 === "413") {
        building = "601";
      }
    }
    // console.log("ビル内外: ", building);

    //更新用URLパラメータを作成
    var param =
      "?email=" +
      targetEmail +
      "&updateEmail=" +
      updateMail +
      "&userName=" +
      updateUserName +
      "&office=" +
      office +
      "&department=" +
      department +
      "&section1=" +
      section1 +
      "&section2=" +
      section2 +
      "&section3=" +
      section3 +
      "&position=" +
      position +
      "&accountStatus=" +
      accountStatus +
      "&kana=" +
      kana +
      "&building=" +
      building;
    console.log(param);
    //API
    fetch(
      process.env.REACT_APP_API_PATH +
      "user/profile/administratorEdit.php" +
      param
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        alert("更新しました");
      });

  };

  //アカウント状態変更
  const toggleActive = (e) => {
    setAccountStatus(e.target.value);
  };

  const updateEmailChange = (e) => {
    setUpdateMail(e.target.value);
    setLastedFlag(true);
  };

  const updateUserNameChange = (e) => {
    setUpdateUserName(e.target.value);
    setLastedFlag(true);
  };

  const kanaChange = (e) => {
    setKana(e.target.value);
    setLastedFlag(true);
  };

  return (
    <>
      <Helmet>
        <title>Z-link 管理者用ユーザー編集</title>
      </Helmet>
      <Header />
      <div className="user-wrapper">
        <div className="container">
          <h1>管理者用ユーザー編集</h1>
        </div>
        <hr />

        <div className="container contents-wrapper">
          <div>
            <h2>編集対象のユーザー選択</h2>
            <label htmlFor="user-dev">変更するユーザーを選んでください</label>
            <div className="select-wrapper d-flex">
              <div className="put_arrow w-50">
                <select
                  className="form-control"
                  name="user-dev"
                  id="user-dev"
                  onChange={choiceMode}
                >
                  <option value="">すべての部署</option>
                  {codeList.map((code) => (
                    <>
                      {code.CODE_ID >= 101 && code.CODE_ID < 500 ? (
                        <option value={code.CODE_ID}>{code.CODE_MEAN}</option>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                </select>
              </div>

              {
                selectAndSearch ? (
                  <>
                    <Controller
                      name="user"
                      control={control}
                      render={({ field }) => (
                        <Select
                          className="rs w-50"
                          id="user"
                          value={rs.find((x) => x.value === field.value)}
                          onChange={(e) => {
                            console.log(e.value);
                            userSelect({ target: { value: e.value } });
                          }
                          }
                          options={rs}
                          classNamePrefix="rs"
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "0.375rem",
                          })}
                          placeholder="ユーザー名を入力or選択してください"
                          noOptionsMessage={() => "一致するユーザーが見つかりません"}
                        />
                      )}
                    />
                  </>
                ) : (
                  <select
                    className="form-control w-50"
                    name="user"
                    id="user"
                    onChange={userSelect}
                  >
                    <option value="">選択してください</option>
                    {
                      //ユーザー一覧
                      userList.map((user) => (
                        <option value={user.EMAIL}>{user.USER_NAME}</option>
                      ))
                    }
                  </select>
                )
              }


            </div>
          </div>
          {userChoice ? (
            <div>
              <h2>編集対象のユーザー情報</h2>
              <>
                {/* メールアドレス*/}
                <div className="form-group" style={{ width: "80%" }}>
                  <label htmlFor="updateMail" style={{ whiteSpace: "nowrap" }}>メールアドレス</label>
                  <input
                    type="text"
                    className="form-control wrap-for-input"
                    onChange={updateEmailChange}
                    defaultValue={myEmail ? myEmail : ""}
                    value={updateMail}
                  ></input>
                </div>
                {/* 名前*/}
                <div className="form-group" style={{ width: "80%" }}>
                  <label htmlFor="updateUserName" style={{ whiteSpace: "nowrap" }}>名前</label>
                  <input
                    type="text"
                    className="form-control wrap-for-input"
                    onChange={updateUserNameChange}
                    defaultValue={updateUserName ? updateUserName : ""}
                    value={updateUserName}
                  ></input>
                </div>
                {/* よみがな*/}
                <div className="form-group" style={{ width: "80%" }}>
                  <label htmlFor="kana" style={{ whiteSpace: "nowrap" }}>よみがな</label>
                  <input
                    type="text"
                    className="form-control wrap-for-input"
                    onChange={kanaChange}
                    defaultValue={kana ? kana : ""}
                    value={kana}
                  ></input>
                </div>
                {/* 事業所*/}
                <div className="form-group put_arrow">
                  <label htmlFor="office">事業所</label>
                  <select
                    className="form-control"
                    id="office"
                    data-select-child="office"
                    onChange={handleChange}
                    value={office}
                  >
                    <option>選択してください</option>
                    {
                      //CODE_IDが100番台のものを表示
                      codeList.map((code) => {
                        if (code.CODE_ID >= 101 && code.CODE_ID < 200) {
                          return (
                            <option value={code.CODE_ID}>
                              {code.CODE_MEAN}
                            </option>
                          );
                        }
                      })
                    }
                  </select>
                </div>
                {
                  //TODO: リリース時は全研本社のみ以降の処理を実行
                  office != "" && office == "101" ? (
                    <>
                      {/* 所属本部 */}
                      <div className="form-group put_arrow">
                        <label htmlFor="department">所属本部</label>
                        <select
                          className="form-control"
                          id="department"
                          data-select-child="department"
                          onChange={handleChange}
                          value={department}
                        >
                          <option value="">選択してください</option>
                          {
                            //CODE_IDが200番台のものを表示
                            codeList.map((code) => {
                              if (code.CODE_ID >= 200 && code.CODE_ID < 300) {
                                return (
                                  <option value={code.CODE_ID}>
                                    {code.CODE_MEAN}
                                  </option>
                                );
                              }
                            })
                          }
                        </select>
                      </div>
                    </>
                  ) : (
                    <></>
                  )
                }
                {
                  //所属本部が選択されている場合かつ内部監査室(203)ではない場合
                  department != "" && department != "205" ? (
                    <>
                      {/* 所属部署 */}
                      <div className="form-group put_arrow">
                        <label htmlFor="section">所属部署1</label>
                        <select
                          className="form-control"
                          id="section"
                          onChange={handleChange}
                          data-select-child="section1"
                          value={section1}
                        >
                          <option value="">選択してください</option>
                          {
                            //CODE_IDが300番台のものを表示
                            codeList.map((code) => {
                              if (
                                code.CODE_ID >= 300 &&
                                code.CODE_ID < 400 &&
                                code.PARENT_ID == department
                              ) {
                                return (
                                  <option value={code.CODE_ID}>
                                    {code.CODE_MEAN}
                                  </option>
                                );
                              }
                            })
                          }
                        </select>
                      </div>
                    </>
                  ) : (
                    <></>
                  )
                }
                {
                  //所属部署1が選択されているかつさらに下層部署がある場合
                  section1 != "" &&
                    codeList.filter((code) => code.PARENT_ID == section1).length >
                    0 ? (
                    <>
                      {/* 所属部署2 */}
                      <div className="form-group put_arrow">
                        <label htmlFor="section">所属部署2</label>
                        <select
                          className="form-control"
                          id="section"
                          onChange={handleChange}
                          data-select-child="section2"
                          value={section2}
                        >
                          <option value="">選択してください</option>
                          {
                            //CODE_IDが400番台のものを表示
                            codeList.map((code) => {
                              if (
                                code.CODE_ID >= 400 &&
                                code.CODE_ID < 500 &&
                                code.PARENT_ID == section1
                              ) {
                                return (
                                  <option value={code.CODE_ID}>
                                    {code.CODE_MEAN}
                                  </option>
                                );
                              }
                            })
                          }
                        </select>
                      </div>
                    </>
                  ) : (
                    <></>
                  )
                }
                {
                  //所属部署2が選択されているかつさらに下層部署がある場合
                  section2 != "" &&
                    codeList.filter((code) => code.PARENT_ID == section2).length >
                    0 ? (
                    <>
                      {/* 所属部署3 */}
                      <div className="form-group put_arrow">
                        <label htmlFor="section">所属部署3</label>
                        <select
                          className="form-control"
                          id="section"
                          onChange={handleChange}
                          data-select-child="section3"
                          value={section3}
                        >
                          <option value="">選択してください</option>
                          {
                            //CODE_IDが400番台のものを表示
                            codeList.map((code) => {
                              if (
                                code.CODE_ID >= 400 &&
                                code.CODE_ID < 500 &&
                                code.PARENT_ID == section2
                              ) {
                                return (
                                  <option value={code.CODE_ID}>
                                    {code.CODE_MEAN}
                                  </option>
                                );
                              }
                            })
                          }
                        </select>
                      </div>
                    </>
                  ) : (
                    <></>
                  )
                }
                {/* 役職*/}
                <div className="form-group put_arrow">
                  <label htmlFor="office">役職</label>
                  <select
                    className="form-control"
                    id="office"
                    data-select-child="office"
                    onChange={positionChenge}
                    value={position}
                  >
                    <option value="">選択してください</option>
                    {
                      //CODE_IDが100番台のものを表示
                      codeList.map((code) => {
                        if (code.CODE_ID >= 500 && code.CODE_ID <= myPosition) {
                          return (
                            <option value={code.CODE_ID}>
                              {code.CODE_MEAN}
                            </option>
                          );
                        }
                      })
                    }
                  </select>
                </div>
                {/*アカウントが有効か否か */}
                <div className="form-group account-wrap">
                  <p>アカウント</p>
                  {console.log(accountStatus)}
                  <button
                    type="button"
                    onClick={toggleActive}
                    value="0"
                    className={accountStatus == 0 ? "btn btn-primary" : "btn"}
                  >
                    有効
                  </button>
                  <button
                    type="button"
                    onClick={toggleActive}
                    value="1"
                    className={accountStatus == 1 ? "btn btn-primary" : "btn"}
                  >
                    無効
                  </button>
                </div>
              </>
              {/* 送信ボタン */}
              <button
                type="button"
                className="btn w-50 shadow update-btn"
                onClick={handleClick}
              >
                更新
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
export default AdministratorEdit;
