// @ts-nocheck
import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet-async';

//ヘッダー
import Header from "../../component/page_parts/header";

// フッター
import Footer from "../../component/page_parts/footer";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// モーダル
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// パンくずリスト
import TableOfContents from "../../component/page_parts/common/table_of_contents";

function Welfare() {
  const [switchButtonTwoValue, setSwitchButtonOneValue] = useState<string>("1");
  const [explanationChangeValue, setSwitchButtonTwoValue] =
    useState<string>("6");

  const [activitiesType, setActivitiesType] = useState<string>("top");
  const [activitiesBtnText, setActivitiesBtnText] = useState<string>("部活動運営について");

  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  // 動画ボタン フットサル
  const videoButtonListOne = [
    {
      title: "部活動紹介動画",
      movieUrl: `${process.env.PUBLIC_URL}/jinji_mov/club_futsal_mv.mp4`,
    },
  ];

  // 動画ボタン JOG
  const videoButtonListTwo = [
    {
      title: "部活動紹介動画",
      movieUrl: `${process.env.PUBLIC_URL}/jinji_mov/club_jog_mv.mp4`,
    },
  ];

  // 動画ボタン バドミントン
  const videoButtonListThree = [
    {
      title: "部活動紹介動画",
      movieUrl: `${process.env.PUBLIC_URL}/jinji_mov/club_badminton_mv.mp4`,
    },
  ];

  // 動画ボタン ボルダリング
  const videoButtonListFour = [
    {
      title: "部活動紹介動画",
      movieUrl: `${process.env.PUBLIC_URL}/jinji_mov/club_bouldering_mv.mp4`,
    },
  ];

  //モーダル用のstate
  const [show, setShow] = useState(false);
  const [modalMovieUrl, setModalMovieUrl] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // モーダルの表示
  const showModal = (modalMovieUrl, currentId) => {
    // モーダルの中の動画のURLを変更する
    setModalMovieUrl(modalMovieUrl);
    setShow(true);
  };

  // GAID
  const gaId = process.env.REACT_APP_GA_ID;

  var element = document.getElementById("1");
  var userName = localStorage.getItem("user_name");
  ReactGA.initialize(gaId);
  ReactGA.send({
    hitType: "event",
    eventCategory: userName,
    eventAction: '福利厚生-タブ切り替え',
    eventLabel: element?.textContent,
  });

  // 上部ボタンの切り替え処理
  function dispCangeOne(event) {

    //全てのbtnToggleからis-activeを外す
    const btnToggle = document.getElementsByClassName("btnToggle");
    for (var i = btnToggle.length - 1; i >= 0; i--) {
      if (btnToggle[i].classList.contains("is-active")) {
        btnToggle[i].classList.remove("is-active");
      }
    }
    //押されたボタンにis-activeを付与
    const btnToggleId = document.getElementById(event.target.id);
    btnToggleId.classList.add("is-active");

    if (event.target.id == "1") {
      // console.log("部活動が押された");
      setSwitchButtonOneValue("1");
      setSwitchButtonTwoValue("6");
    } else if (event.target.id == "2") {
      // console.log("財産形成が押された");
      setSwitchButtonOneValue("2");
      setSwitchButtonTwoValue("1");
    } else if (event.target.id == "3") {
      // console.log("メンタルヘルスが押された");
      setSwitchButtonOneValue("3");
      setSwitchButtonTwoValue("6");
    } else if (event.target.id == "4") {
      // console.log("祝金・休暇制度が押された");
      setSwitchButtonOneValue("4");
      setSwitchButtonTwoValue("3");
    } else if (event.target.id == "5") {
      // console.log("クローズドマートが押された");
      setSwitchButtonOneValue("5");
      setSwitchButtonTwoValue("6");
    }

    // GA4タブ押下時計測
    var element = document.getElementById(event.target.id);
    var userName = localStorage.getItem("user_name");
    console.log(element?.textContent);
    ReactGA.initialize(gaId);
    ReactGA.send({
      hitType: "event",
      eventCategory: userName,
      eventAction: '福利厚生-タブ切り替え',
      eventLabel: element?.textContent,
    });
  }

  // 部活動内のコンテンツ切り替え
  function ChangeActivities(event) {
    // console.log(event.target.dataset.activitiesType);

    if (event.target.dataset.activitiesType === "top") {
      setActivitiesType("low");
      setActivitiesBtnText("部活動トップに戻る");
    } else {
      setActivitiesType("top");
      setActivitiesBtnText("部活動運営について");
    }
  }

  return (
    <>
      <Header />
      <Helmet>
        <title>Z-link 福利厚生</title>
      </Helmet>
      <div className="content-header">
        <div className="container">
          <Row>
            <Col sm={12}>
              <h1 className='page-title type-bag'>福利厚生</h1>{/* ページタイトル */}
              <p className="page-description">仕事のフォローだけじゃない、各種財産形成制度から休暇制度まで、便利な福利厚生が充実。</p>{/* ページの説明 */}
            </Col>
          </Row>
        </div>
      </div>
      <TableOfContents />

      <div className="main-wrapper jinji-related theme-jinji-welfare">
        {/* 見出し部分 */}
        <div className="contents-wrap container mt-5">
          {/* 上部ボタン郡 */}
          <div className="text-center">
            <div className="d-flex flex-wrap justify-content-between select-tags">
              <div
                className="tag flex-fill btnToggle is-active px-2"
                onClick={dispCangeOne}
                id="1"
              >
                部活動
              </div>
              <div
                className="tag flex-fill btnToggle px-2"
                onClick={dispCangeOne}
                id="2"
              >
                財産形成
              </div>
              <div
                className="tag flex-fill btnToggle px-2"
                onClick={dispCangeOne}
                id="3"
              >
                メンタルヘルス
              </div>
              <div
                className="tag flex-fill btnToggle px-2"
                onClick={dispCangeOne}
                id="4"
              >
                祝金・休暇制度
              </div>
              <div
                className="tag flex-fill btnToggle px-2"
                onClick={dispCangeOne}
                id="5"
              >
                CLOSED Mart
              </div>
            </div>
          </div>

          <div>
            {/* 部活動 */}
            {switchButtonTwoValue === "1" ? (
              <div className="content-activities row-12">
                <h2 className="tag-title">部活動</h2>
                <p className="lead-text">
                  部署間を超えたコミュニケーションの活性化と心と身体のリフレッシュを目的として、部活動があります。<br />
                  より多くの方に活動を楽しんでいただけるよう、部活動の入部は1人【３部活まで】となります。
                </p>
                <div className="switch-btn-wrap">
                  <button
                    type="button"
                    className="btn main-btn type-activities ms-auto"
                    onClick={ChangeActivities}
                    data-activities-type={activitiesType}
                  >
                    {activitiesBtnText}
                  </button>
                </div>
                <div className={"contents-body" + (activitiesType === "top" ? " is-active" : "")}>
                  <div className="schedule-box">
                    <h3 className="icon-title type-pencil mt-small">直近の活動予定</h3>
                    <div className="body">
                      <img
                        src={process.env.PUBLIC_URL + '/jinji_pic/schedule.png'}
                        alt="直近の活動予定"
                        width={1053}
                        height={200}
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <Row className="menu-btn-wrap first">
                    <Col sm={3}>
                      <Link
                        to="basketball"
                        smooth={true}
                        duration={500}
                        offset={-100}
                        className="btn main-btn type-internal"
                      >
                        バスケ部
                      </Link>
                    </Col>
                    <Col sm={3}>
                      <Link
                        to="futsal"
                        smooth={true}
                        duration={500}
                        offset={-100}
                        className="btn main-btn type-internal"
                      >
                        フットサル部
                      </Link>
                    </Col>
                    <Col sm={3}>
                      <Link
                        to="jog"
                        smooth={true}
                        duration={500}
                        offset={-100}
                        className="btn main-btn type-internal"
                      >
                        JOG部
                      </Link>
                    </Col>
                    <Col sm={3}>
                      <Link
                        to="badminton"
                        smooth={true}
                        duration={500}
                        offset={-100}
                        className="btn main-btn type-internal"
                      >
                        バドミントン部
                      </Link>
                    </Col>
                  </Row>
                  <Row className="menu-btn-wrap second">
                    <Col sm={3}>
                      <Link
                        to="bouldering"
                        smooth={true}
                        duration={500}
                        offset={-100}
                        className="btn main-btn type-internal"
                      >
                        ボルダリング部
                      </Link>
                    </Col>
                  </Row>
                  <div className="activities-box-wrap">
                    <div className="activities-box type-basketball" id="basketball">
                      <h3 className="icon-title type-pencil mt-0">バスケ部</h3>
                      <div className="body">
                        <div className="img-wrap text-center">
                          <img
                            src={process.env.PUBLIC_URL + '/jinji_pic/basketball.png'}
                            alt="バスケ部概要"
                            width={2000}
                            height={1414}
                            loading="lazy"
                          />
                        </div>
                        <div className="activity-record">
                          <h4 className="bg-title">活動記録</h4>
                          <ul>
                            <li><a href="https://www.ztree.cfbx.jp/info_lists/deteil?id=1937"><span className="record-date">2024年4月2日</span>【🏀バスケ部】2024年バスケ部始動です！</a></li>
                            <li><a href="https://www.ztree.cfbx.jp/info_lists/deteil?id=1285"><span className="record-date">2023年10月17日</span>【🏀バスケ部】年内最後のEnjoyバスケ！</a></li>
                          </ul>
                        </div>
                        <div className="joining text-center">
                          <p>バスケ部へ参加希望の方は、こちらのフォームより送信ください。<br />後日、部から個別にご連絡します。</p>
                          <Row className="btn-wrap justify-content-center">
                            <Col sm={6}>
                              <a href="https://docs.google.com/forms/d/e/1FAIpQLSd7pUK3dxmlgAktTMR76dDl60ruiRwz-PG1DE4U5gS8gRnmEg/viewform" target="_blank" className="btn main-btn type-external">
                                バスケ部に参加する！
                              </a>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                    <div className="activities-box type-futsal" id="futsal">
                      <h3 className="icon-title type-pencil mt-0">フットサル部</h3>
                      <div className="body">
                        <div className="img-wrap text-center">
                          <img
                            src={process.env.PUBLIC_URL + '/jinji_pic/futsal.png'}
                            alt="フットサル部概要"
                            width={2000}
                            height={1414}
                            loading="lazy"
                          />
                        </div>
                        <Row className="btn-wrap justify-content-center">
                          <Col sm={6}>
                            {videoButtonListOne.map((movie, index) => (
                              <Button
                                id={"movie-btn-" + (index + 1)}
                                className='btn main-btn type-movie btn btn-primary'
                                key={index}
                                variant="primary"
                                onClick={(e) => showModal(movie.movieUrl, e.currentTarget.id)}
                                dangerouslySetInnerHTML={{
                                  __html: movie.title,
                                }}
                              >
                              </Button>
                            ))}
                            <Modal
                              show={show}
                              onHide={handleClose}
                              className="modal-lg evaluation-modal"
                              size="lg"
                              centered
                            >
                              <Modal.Header closeButton></Modal.Header>
                              <Modal.Body>
                                <video
                                  className="evaluation-modal__video"
                                  preload="none"
                                  autoPlay
                                  controls
                                  loop
                                  muted
                                  playsInline
                                  poster="#"
                                >
                                  <source src={modalMovieUrl} type="video/mp4" />
                                </video>
                              </Modal.Body>
                            </Modal>
                          </Col>
                        </Row>
                        <div className="activity-record">
                          <h4 className="bg-title">活動記録</h4>
                          <ul>
                            <li><a href="https://www.ztree.cfbx.jp/info_lists/deteil?id=2527"><span className="record-date">2024年8月31日</span>【⚽フットサル部】午前中からフットサル！</a></li>
                            <li><a href="https://www.ztree.cfbx.jp/info_lists/deteil?id=2130"><span className="record-date">2024年5月10日</span>【⚽フットサル部】わちゃ蹴りフットサル会！！@新宿</a></li>
                            <li><a href="https://www.ztree.cfbx.jp/info_lists/deteil?id=1820"><span className="record-date">2024年2月17日</span>【⚽フットサル部】わちゃ蹴りフットサル会！！＠新宿</a></li>
                            <li><a href="https://www.ztree.cfbx.jp/info_lists/deteil?id=1368"><span className="record-date">2023年11月26日</span>【⚽フットサル部】わちゃ蹴りフットサル会！！＠新宿</a></li>
                          </ul>
                        </div>
                        <div className="joining text-center">
                          <p>フットサル部へ参加希望の方は、こちらのフォームより送信ください。<br />後日、部から個別にご連絡します。</p>
                          <Row className="btn-wrap justify-content-center">
                            <Col sm={6}>
                              <a href="https://docs.google.com/forms/d/e/1FAIpQLSfCSnOSQ9mavVbMmWL_LDii6vH4yQ5HDXBj1nhyhJBgPvskeA/viewform" target="_blank" className="btn main-btn type-external">
                                フットサル部に参加する！
                              </a>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                    <div className="activities-box type-jog" id="jog">
                      <h3 className="icon-title type-pencil mt-0">JOG部</h3>
                      <div className="body">
                        <div className="img-wrap text-center">
                          <img
                            src={process.env.PUBLIC_URL + '/jinji_pic/jog.png'}
                            alt="JOG部概要"
                            width={2000}
                            height={1414}
                            loading="lazy"
                          />
                        </div>
                        <Row className="btn-wrap justify-content-center">
                          <Col sm={6}>
                            {videoButtonListTwo.map((movie, index) => (
                              <Button
                                id={"movie-btn-" + (index + 1)}
                                className='btn main-btn type-movie btn btn-primary'
                                key={index}
                                variant="primary"
                                onClick={(e) => showModal(movie.movieUrl, e.currentTarget.id)}
                                dangerouslySetInnerHTML={{
                                  __html: movie.title,
                                }}
                              >
                              </Button>
                            ))}
                            <Modal
                              show={show}
                              onHide={handleClose}
                              className="modal-lg evaluation-modal"
                              size="lg"
                              centered
                            >
                              <Modal.Header closeButton></Modal.Header>
                              <Modal.Body>
                                <video
                                  className="evaluation-modal__video"
                                  preload="none"
                                  autoPlay
                                  controls
                                  loop
                                  muted
                                  playsInline
                                  poster="#"
                                >
                                  <source src={modalMovieUrl} type="video/mp4" />
                                </video>
                              </Modal.Body>
                            </Modal>
                          </Col>
                        </Row>
                        <div className="activity-record">
                          <h4 className="bg-title">活動記録</h4>
                          <ul>
                            <li><a href="https://www.ztree.cfbx.jp/info_lists/deteil?id=2348"><span className="record-date">2024年7月21日</span>【🏃‍♂️JOG部】汗をかくのって気持ちがいい！</a></li>
                            <li><a href="https://www.ztree.cfbx.jp/info_lists/deteil?id=1910"><span className="record-date">2024年1~4月</span>【🏃‍♂️JOG部】待ちに待ったマラソンシーズン！！大会レポート</a></li>
                            <li><a href="https://www.ztree.cfbx.jp/info_lists/deteil?id=1284"><span className="record-date">2023年10月22日</span>【🏃‍♂️JOG部】今年も参戦！国立競技場リレーマラソン！</a></li>
                          </ul>
                        </div>
                        <div className="joining text-center">
                          <p>JOG部へ参加希望の方は、こちらのフォームより送信ください。<br />後日、部から個別にご連絡します。</p>
                          <Row className="btn-wrap justify-content-center">
                            <Col sm={6}>
                              <a href="https://docs.google.com/forms/d/e/1FAIpQLSfZLeq8042wASqyYEm2iXwJVW-eHIIlq7NtQaK6DDVUkwJRIg/viewform" target="_blank" className="btn main-btn type-external">
                                JOG部に参加する！
                              </a>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                    <div className="activities-box type-badminton" id="badminton">
                      <h3 className="icon-title type-pencil mt-0">バドミントン部</h3>
                      <div className="body">
                        <div className="img-wrap text-center">
                          <img
                            src={process.env.PUBLIC_URL + '/jinji_pic/badminton.png'}
                            alt="バドミントン部概要"
                            width={2000}
                            height={1414}
                            loading="lazy"
                          />
                        </div>
                        <Row className="btn-wrap justify-content-center">
                          <Col sm={6}>
                            {videoButtonListThree.map((movie, index) => (
                              <Button
                                id={"movie-btn-" + (index + 1)}
                                className='btn main-btn type-movie btn btn-primary'
                                key={index}
                                variant="primary"
                                onClick={(e) => showModal(movie.movieUrl, e.currentTarget.id)}
                                dangerouslySetInnerHTML={{
                                  __html: movie.title,
                                }}
                              >
                              </Button>
                            ))}
                            <Modal
                              show={show}
                              onHide={handleClose}
                              className="modal-lg evaluation-modal"
                              size="lg"
                              centered
                            >
                              <Modal.Header closeButton></Modal.Header>
                              <Modal.Body>
                                <video
                                  className="evaluation-modal__video"
                                  preload="none"
                                  autoPlay
                                  controls
                                  loop
                                  muted
                                  playsInline
                                  poster="#"
                                >
                                  <source src={modalMovieUrl} type="video/mp4" />
                                </video>
                              </Modal.Body>
                            </Modal>
                          </Col>
                        </Row>
                        <div className="activity-record">
                          <h4 className="bg-title">活動記録</h4>
                          <ul>
                            <li><a href="https://www.ztree.cfbx.jp/info_lists/deteil?id=2373"><span className="record-date">2024年7月23日</span>【🏸バドミントン部】初心者の方でも楽しめます！！</a></li>
                            <li><a href="https://www.ztree.cfbx.jp/info_lists/deteil?id=1960"><span className="record-date">2024年3~4月</span>【🏸バドミントン部】積極開催中です！！</a></li>
                            <li><a href="https://www.ztree.cfbx.jp/info_lists/deteil?id=1283"><span className="record-date">2023年9月6日</span>【🏸バドミントン部】シャッフル試合に、白熱！！</a></li>
                          </ul>
                        </div>
                        <div className="joining text-center">
                          <p>バドミントン部へ参加希望の方は、こちらのフォームより送信ください。<br />後日、部から個別にご連絡します。</p>
                          <Row className="btn-wrap justify-content-center">
                            <Col sm={6}>
                              <a href="https://docs.google.com/forms/d/e/1FAIpQLScptMUgckCcnajGE6A_mnZGn8pagILeJX8H2KlJWWZto8oSGQ/viewform" target="_blank" className="btn main-btn type-external">
                                バドミントン部に参加する！
                              </a>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                    <div className="activities-box type-bouldering" id="bouldering">
                      <h3 className="icon-title type-pencil mt-0">ボルダリング部</h3>
                      <div className="body">
                        <div className="img-wrap text-center">
                          <img
                            src={process.env.PUBLIC_URL + '/jinji_pic/bouldering.png'}
                            alt="ボルダリング部概要"
                            width={2000}
                            height={1414}
                            loading="lazy"
                          />
                        </div>
                        <Row className="btn-wrap justify-content-center">
                          <Col sm={6}>
                            {videoButtonListFour.map((movie, index) => (
                              <Button
                                id={"movie-btn-" + (index + 1)}
                                className='btn main-btn type-movie btn btn-primary'
                                key={index}
                                variant="primary"
                                onClick={(e) => showModal(movie.movieUrl, e.currentTarget.id)}
                                dangerouslySetInnerHTML={{
                                  __html: movie.title,
                                }}
                              >
                              </Button>
                            ))}
                            <Modal
                              show={show}
                              onHide={handleClose}
                              className="modal-lg evaluation-modal"
                              size="lg"
                              centered
                            >
                              <Modal.Header closeButton></Modal.Header>
                              <Modal.Body>
                                <video
                                  className="evaluation-modal__video"
                                  preload="none"
                                  autoPlay
                                  controls
                                  loop
                                  muted
                                  playsInline
                                  poster="#"
                                >
                                  <source src={modalMovieUrl} type="video/mp4" />
                                </video>
                              </Modal.Body>
                            </Modal>
                          </Col>
                        </Row>
                        <div className="activity-record">
                          <h4 className="bg-title">活動記録</h4>
                          <ul>
                            <li><a href="https://www.ztree.cfbx.jp/info_lists/deteil?id=2577"><span className="record-date">2024年9月13日</span>【ボルダリング部】週末の夜は頭をリフレッシュ！仕事のモヤモヤを解消💛</a></li>
                            <li><a href="https://www.ztree.cfbx.jp/info_lists/deteil?id=2352"><span className="record-date">2024年7月5・19日</span>【ボルダリング部】順調に成長しています！</a></li>
                          </ul>
                        </div>
                        <div className="joining text-center">
                          <p>ボルダリング部へ参加希望の方は、こちらのフォームより送信ください。<br />後日、部から個別にご連絡します。</p>
                          <Row className="btn-wrap justify-content-center">
                            <Col sm={6}>
                              <a href="https://docs.google.com/forms/d/e/1FAIpQLSdyYqNbZ3Zrz3n_VZi9YGLhcCDwtDP1PjgkJyPFvYkDeuhodw/viewform" target="_blank" className="btn main-btn type-external">
                                ボルダリング部に参加する！
                              </a>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"contents-body" + (activitiesType !== "top" ? " is-active" : "")}>
                  <div className="operation-box-wrap">
                    <h3 className="icon-title type-pencil mt-0">部活動の運営について</h3>
                    <div className="body">
                      <div className="operation-box">
                        <h4 className="bg-title">部活動設立の要件</h4>
                        <ol>
                          <li>部活動の所属メンバーは15名以上で構成されていること</li>
                          <li>部活動運営に必要な、責任者及び副責任者、会計担当を選任していること</li>
                          <li>営利を目的としたものや、政治活動、宗教活動に関わるものでないこと</li>
                          <li>活動内容が重複する部活でないこと</li>
                        </ol>
                      </div>
                      <div className="operation-box">
                        <h4 className="bg-title">対象者</h4>
                        <p>対象者は雇用形態に関わらず全従業員を対象とするが、原則として1人3部活までの参加とする</p>
                      </div>
                      <div className="operation-box">
                        <h4 className="bg-title">補助金の申請</h4>
                        <p>補助金は、設備等（場所代・レンタル代）の代金及び活動に伴う懇親会の飲食料金に限り<br />月額70,000円を上限として実費を支給する<br />※3ヵ月以上に渡り活動がない部活は支給対象外とします</p>
                      </div>
                      <div className="operation-box">
                        <h4 className="bg-title">活動時の留意点</h4>
                        <ol>
                          <li>活動は所定時間外に実施すること</li>
                          <li>活動実績の有無に関わらず、毎月月末までに活動報告書にて報告すること</li>
                          <li>活動スケジュールを所定のGoogleカレンダーに記入すること</li>
                          <li>活動状況を社内に広く周知するため、活動記録を定期的にブログに掲載すること</li>
                        </ol>
                      </div>
                      <div className="operation-box">
                        <h4 className="bg-title">各書類</h4>
                        <p><a href="https://sites.google.com/zenken.co.jp/contracts-regulations/regulations/regulations_related_to_general_affairs/-for-the-operation-of-club-activities?authuser=0" target="_blank" rel="noopener noreferrer" className='text-link type-external'>契約書・規程BOX</a>よりアクセスの上、ご確認ください</p>
                      </div>
                      <div className="operation-box">
                        <h4 className="bg-title">申請方法について</h4>
                        <p>申請方法：<a href="https://rsdesign.rakurakuseisan.jp/_5ZwGywYVPa/" target="_blank" rel="noopener noreferrer" className='text-link type-external'>楽楽精算</a>のワークフロー「部活動申請書」から申請してください。</p>
                        <div className="img-wrap">
                          <img
                            src={process.env.PUBLIC_URL + '/jinji_pic/rakuraku.jpg'}
                            alt="楽楽精算の画面キャプチャ"
                            width={604}
                            height={455}
                            loading="lazy"
                          />
                        </div>
                        <p>部活動の運営に関するお問い合わせは、人材戦略統括本部　人事部　人材戦略チーム　までお願いします。</p>
                        <p>担当者の内線番号/携帯番号の確認は、<a href={process.env.REACT_APP_PUBLIC_URL + "/employee_list?defaultOption=203"}>従業員名簿</a>よりご確認ください。</p>
                        <p className="mt-4 pt-3">なお、本規則は令和5年7月1日から施行とします。</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {/* 財産形成(デフォルト) */}
            {switchButtonTwoValue === "2" ? (
              <div className="contentOne row-12">
                <h2 className="tag-title">財産形成</h2>
                <p>
                  2つの財産形成制度。どちらも給与から自動拠出されるので、手間なく積み立てが可能。<br />
                  資産形成にぜひお役立てください。選択制DCは3月と9月、従業員持ち株会は2月と8月に案内をいたします。
                </p>
                <Row className='my-4'>
                  <Col sm={6}>
                    <Link
                      to="dc"
                      smooth={true}
                      duration={500}
                      offset={-100}
                      className="btn main-btn type-internal"
                    >
                      選択制DC（企業型確定拠出年⾦）
                    </Link>
                  </Col>
                  <Col sm={6}>
                    <Link
                      to="stock"
                      smooth={true}
                      duration={500}
                      offset={-100}
                      className="btn main-btn type-internal"
                    >
                      従業員持株会
                    </Link>
                  </Col>
                </Row>
              </div>
            ) : (
              <></>
            )}

            {/* メンタルヘルス */}
            {switchButtonTwoValue === "3" ? (
              <div className="contentTwo">
                <h2 className="tag-title">メンタルヘルス</h2>
                <p>
                  仕事や人間関係の悩み、会社に相談できないこともアンリなら無料で気軽に相談できます。<br />
                  なお、相談した内容は会社には秘密にできます。
                </p>
                <Row className='mb-4'>
                  <Col sm={6}>
                    <Link
                      to="anri"
                      smooth={true}
                      duration={500}
                      offset={-100}
                      className="btn main-btn type-internal"
                    >
                      外部相談窓口サービス［アンリ］
                    </Link>
                  </Col>
                </Row>


                <h3 id="anri" className="icon-title type-pencil">外部相談窓口サービス［アンリ］</h3>
                <h4 className="bg-title mt-small"> 外部相談窓口サービス［アンリ］とは？</h4>

                <div className="d-flex flex-wrap-area justify-content-between">
                  <div className="flex-fill no-link-btn p-3">
                    匿名で相談可能
                  </div>
                  <div className="flex-fill no-link-btn p-3">
                    家族のことも相談可能
                  </div>
                  <div className="flex-fill no-link-btn p-3">
                    会社には詳細報告なし
                  </div>
                </div>

                <div className="">
                  相談員は保健師、精神保健福祉士、公認心理士、管理栄養士、保育士といったすべて国家資格保有者です。育児のことも相談できます。
                </div>

                <h4 className="bg-title">【アンリ】 ご利用の流れ</h4>
                <div className="numbering-wrap type-step">
                  <h5 className="numbering-title">外部相談窓口アンリにアクセスする</h5>
                  <p>
                    <a href="https://doctor-trust.co.jp/hokenshi/anri/anri-sp.html" target="_blank" className='text-link type-external'>こちら</a>
                    よりアクセスしてください。どういった方が担当してくれるのかもサイト上で確認できますので、安心です。<br />
                    ※アクセスに必要な事業所番号は【9720-3461】となります。
                  </p>

                  <h5 className="numbering-title">電話、メール、Zoom面談で相談する</h5>
                  <p>電話（平日10～18時）、メール（24時間受付）、Zoom面談（平日10～20時）の3通りからお選びいただけます。</p>

                  <h5 className="numbering-title">繰り返しや家族の相談も可能</h5>
                  <p>1回の相談では解決できない悩みも、繰り返し相談することで解決の糸口が見えてくることも。<br />ご家族のことも相談できますので、幅広くご利用いただけます。</p>
                </div>

              </div>
            ) : (
              <></>
            )}

            {/* 祝金・休暇制度 */}
            {switchButtonTwoValue === "4" ? (
              <div className="contentThree">
                <h2 className="tag-title">祝金・休暇制度</h2>
                <p>
                  皆さんにとってうれしい制度をピックアップ。福利厚生はお得でなくっちゃ意味がない！{" "}
                </p>
                <Row className='mb-4'>
                  <Col sm={4}>
                    <Link
                      to="marriage"
                      smooth={true}
                      duration={500}
                      offset={-100}
                      className="btn main-btn type-internal"
                    >
                      結婚・出産祝金
                    </Link>
                  </Col>
                  <Col sm={4}>
                    <Link
                      to="paid"
                      smooth={true}
                      duration={500}
                      offset={-100}
                      className="btn main-btn type-internal"
                    >
                      時間有給
                    </Link>
                  </Col>
                  <Col sm={4}>
                    <Link
                      to="childcareleave"
                      smooth={true}
                      duration={500}
                      offset={-100}
                      className="btn main-btn type-internal"
                    >
                      男性の育児休業
                    </Link>
                  </Col>
                </Row>
              </div>
            ) : (
              <></>
            )}

            {/* クローズドマート */}
            {switchButtonTwoValue === "5" ? (
              <div className="contentFour">
                <h2 className="tag-title">CLOSED Mart</h2>
                <p>
                  食品や日用品などの廃棄ロスを削減し、SDGsにも貢献できる試みとしてCLOSED Mart（クローズドマート）の利用をスタートしました！
                </p>
                <Row className='mb-4'>
                  <Col sm={6}>
                    <Link
                      to="closedmart"
                      smooth={true}
                      duration={500}
                      offset={-100}
                      className="btn main-btn type-internal"
                    >
                      社会貢献型職域販売サイト
                    </Link>
                  </Col>
                </Row>
                <div className="">
                </div>
                <h3 id="closedmart" className="icon-title type-pencil">【CLOSED Mart】</h3>
                <h4 className="bg-title mt-small">【CLOSED Mart】とは？</h4>

                <div className="d-flex flex-wrap-area justify-content-between">
                  <div className="flex-fill no-link-btn p-3">
                    廃棄ロスの削減
                  </div>
                  <div className="flex-fill no-link-btn p-3">
                    途上国支援に貢献
                  </div>
                  <div className="flex-fill no-link-btn p-3">
                    何より格安！
                  </div>
                </div>

                <p>
                  賞味期限が近づいた食品や、仕様変更や型落ちになった日用品、余剰在庫による処分品など、さまざまな理由により販売することが困難な商品を格安で購入でき、SDGsにも貢献できるのが、社会貢献型職域販売サイト「CLOSED
                  Mart」。Zenkenは食品・日用品等を購入することで廃棄ロス削減や途上国支援に取り組んでいきます。
                </p>

                <h4 className="bg-title"> 【CLOSED Mart】ご利用の流れ</h4>
                <div className="numbering-wrap type-step">
                  <h5 className="numbering-title">CLOSED Martにアクセスし、ログインする</h5>
                  <p><a href="https://www.closedmart-members.com/" target="_blank" className='text-link type-external'>こちら</a>よりアクセスし、法人コード「9011B002」を入力し、ログインしてください。</p>

                  <h5 className="numbering-title">お買い物をお楽しみください</h5>
                  <p>SDGsに取り組みつつ、お得なお買い物ができるといいですね！</p>
                </div>
              </div>
            ) : (
              <></>
            )}

            {/* 財産形成-選択制DC */}
            {explanationChangeValue === "1" ? (
              <div className="contentOne-One">
                <h3 id="dc" className="icon-title type-pencil">選択制DC（企業型確定拠出年⾦）</h3>
                <h4 className="bg-title mt-small">選択制DC（企業型確定拠出年金）とは？</h4>
                <div className="d-flex flex-wrap-area justify-content-between">
                  <div className="flex-fill no-link-btn p-3">
                    月 50,000円まで拠出OK
                  </div>
                  <div className="flex-fill no-link-btn p-3">
                    税メリットあり
                  </div>
                  <div className="flex-fill no-link-btn p-3">
                    奨励金あり（最大4,000円）
                  </div>
                </div>

                <p className="">
                  選択制DC制度は任意加入となりますが、給与のうち5万円までの範囲でDC掛金に振り向けることで、税メリットを受けながら着実に老後の資産形成を行うことができるお得な制度です。
                </p>

                <h4 className="bg-title">【選択制DC】お手続きの流れ</h4>
                <div className="numbering-wrap type-step">
                  <h5 className="numbering-title">事務局からの案内後、説明会動画を視聴する</h5>
                  <p>約35分の動画となります。まずはどういった内容、メリットがあるかをご確認ください。</p>
                  <div className="iframe-wrapper my-4">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/_ySIa5vvErU"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>

                  <h5 className="numbering-title">申込みフォームより、回答する</h5>
                  <p>
                    別途Googleフォームよりお申し込みいただきます。（時期になったら都度ご案内いたします）。<br />翌月初旬にユーザーIDがハガキで届きます。加入者向けサイトにログインできるかどうかご確認ください。
                  </p>

                  <h5 className="numbering-title">投資勉強会動画を視聴する</h5>
                  <p>約80分の動画となります。ご自身の資産形成に関することですので、是非とも真剣にご視聴ください。</p>
                  <div className="iframe-wrapper my-4">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/P5vnH_yuqGw"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>

                  <h5 className="numbering-title">加入通知書兼運用指図書を提出する</h5>
                  <p>DC加入者向けサイトにログインし、投資商品一覧を確認の上、掛金割合等を記載し、人事事務課松本（19F）まで提出をお願いいたします。</p>

                  <h5 className="numbering-title">掛金が給与から拠出される</h5>
                  <p>3月のお申込みの場合、初回は4月給与から拠出額が天引きされ、5月分として拠出されます。以降毎月拠出額変更をするまでは同額での拠出となります。</p>

                  <h5 className="numbering-title">新規申込や拠出額変更は年2回</h5>
                  <p>申込、拠出額変更のタイミングは年2回。3月と9月に募集いたします。</p>
                </div>
              </div>
            ) : (
              <></>
            )}

            {/* 財産形成-持株会 */}
            {explanationChangeValue === "1" ? (
              <div className="contentOne-Two">
                <h3 id="stock" className="icon-title type-pencil">従業員持株会</h3>
                <h4 className="bg-title mt-small">従業員持株会とは？</h4>
                <div className="d-flex flex-wrap-area justify-content-between">
                  <div className="flex-fill no-link-btn p-3">
                    月1,000円から投資可能
                  </div>
                  <div className="flex-fill no-link-btn p-3">
                    配当金も再投資
                  </div>
                  <div className="flex-fill no-link-btn p-3">
                    奨励金あり（一律5%）
                  </div>
                </div>
                <p>
                  従業員持株会制度は任意加入となりますが、給与のうち5万円までの範囲で自社株に投資をすることができます。
                  <br />また、投資額の5%の奨励金もありますので、そのまま投資額に上乗せすることもできます。
                </p>
                <p>
                  申込は年2回、『毎年2月および8月末日を締切日』とし、3月および9月の給与より拠出を開始します。
                </p>
                <h4 className="bg-title">【従業員持株会】　お手続きの流れ</h4>
                <div className="numbering-wrap type-step">
                  <h5 className="numbering-title">持株会新規募集動画を視聴する</h5>
                  <p>約2分の動画となります。まずはどのようなメリットがあるかをご確認ください。</p>
                  <div className="iframe-wrapper my-4">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/9IyboaJsDrw"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>

                  <h5 className="numbering-title">説明会動画を視聴する</h5>
                  <p>約23分の動画となります。具体的な内容についてご確認ください。</p>
                  <div className="iframe-wrapper my-4">
                    <video
                      className="w-100 h-auto"
                      preload="none"
                      controls
                      loop
                      muted
                      poster={`${process.env.PUBLIC_URL}/jinji_pic/jinji_w01.jpg`}
                    >
                      <source
                        src={`${process.env.PUBLIC_URL}/jinji_mov/jinji_w01.mp4`}
                        type="video/mp4"
                      ></source>
                    </video>
                  </div>
                  <h5 className="numbering-title">申込みフォームより、エントリーする</h5>
                  <p>別途<a href="https://docs.google.com/forms/d/e/1FAIpQLSe5ggW__ustUGtGia9x-lVVVZumedEPbU_fmhharKQJokWp7A/viewform" target="_blank" className='text-link type-external'>Googleフォーム</a>よりお申し込みいただきます.事務局から「持株会届出書」をお渡しいたします。</p>

                  <h5 className="numbering-title">「持株会届出書」を持株会事務局に提出する</h5>
                  <p>必要事項の記入及び捺印後、持株会事務局担当者に届出書をご提出ください。2月申込みであれば3月給与から、8月申込みであれば9月給与から届出に基づき拠出されます。</p>
                  <p>
                    【事務局担当者】<br />
                    ・森下卓也（経理部）:管理本部・HRインキュベーション事業本部・グローバル教育事業本部<br />
                    ・田代勇輔（人材戦略チーム）:eマーケティング事業本部・人材戦略統括本部
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}

            {/* 祝金・休暇制度-結婚・出産祝金 */}
            {explanationChangeValue === "3" ? (
              <div className="contentThree-One">
                <h3 id="marriage" className="icon-title type-pencil">Zenkenの【結婚・出産祝金】</h3>
                <h4 className="bg-title mt-small">Zenkenの【結婚・出産祝金】とは？</h4>

                <div className="d-flex flex-wrap-area justify-content-between">
                  <div className="flex-fill no-link-btn p-3">結婚で10万円</div>
                  <div className="flex-fill no-link-btn p-3">
                    出産で1人10万円
                  </div>
                  <div className="flex-fill no-link-btn p-3">
                    社員同士だと更に倍！！
                  </div>
                </div>
                <p>
                  おめでたいのに費用はかさむ、そんな皆さんに少しでも報いたい思いで、祝金額を増額いたしました！社員同士なら更におめでたいということで、お二人ともに祝金を贈呈いたします。
                </p>

                <h4 className="bg-title">【祝金申請】 お手続きの流れ</h4>
                <div className="numbering-wrap type-step">
                  <h5 className="numbering-title">以下の金額を確認し、「慶弔金支給申請書」を申請する</h5>
                  <p>申請方法は<a href="https://rsdesign.rakurakuseisan.jp/_5ZwGywYVPa/sapWorkflowDenpyo/initializeView?workflowId=20&refId=20" target="_blank" className='text-link type-external'>楽楽清算</a>よりアクセスしてください。</p>
                  <p>
                    結婚祝金：10万円（※入籍のみも含みます）<br />
                    出産祝金：1人目10万円→2人目20万円→3人目以降都度30万円<br />
                    ※出産のみ、2022/4/1以降入社の社員は「入社後1年以上」という在籍条件が付きます。
                  </p>

                  <h5 className="numbering-title">承認後2週間以内に給与支給口座に振り込まれる</h5>
                  <p>
                    楽楽精算で申請書の承認後に、給与支給口座に振り込まれます。<br />
                    ※経費精算と同じタイミングでの処理となります。
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}

            {/* 祝金・休暇制度-時間有給 */}
            {explanationChangeValue === "3" ? (
              <div className="contentThree-Two">
                <h3 id="paid" className="icon-title type-pencil">【時間有休】</h3>
                <h4 className="bg-title mt-small">【時間有休】とは？</h4>

                <div className="d-flex flex-wrap-area justify-content-between">
                  <div className="flex-fill no-link-btn p-3">
                    1時間単位で有休取得OK
                  </div>
                  <div className="flex-fill no-link-btn p-3">
                    年40時間まで利用可能
                  </div>
                  <div className="flex-fill no-link-btn p-3">
                    遅刻早退時に便利
                  </div>
                </div>

                <p>
                  今までは子どもの授業参観やちょっとした用事など、1～2時間で終わるようなケースでも、1日や半日の有休を取るしかありませんでした。<br />
                  でも、時間年休があれば用事を終えてから出勤したりと、長時間の休みを取らなくてもライフスタイルに合わせての出社が可能となりました。
                </p>

                <h4 className="bg-title">【時間有休】 申請の流れ</h4>
                <div className="numbering-wrap type-step">
                  <h5 className="numbering-title">時間休が付与されているのをジンジャー勤怠で確認し、「休日休暇申請」を申請する</h5>
                  <p>申請方法は<a href="https://jinjer-kintai.zendesk.com/hc/ja/articles/900002011863" target="_blank" className='text-link type-external'>こちら</a>よりアクセスしてください。</p>

                  <h5 className="numbering-title">当日は時間通りに休む！</h5>
                  <p>遅刻早退ではなく、歴とした有休ですので、遠慮なくご用をお済ませください。</p>
                </div>
              </div>
            ) : (
              <></>
            )}

            {/* 祝金・休暇制度-男性の育児休業 */}
            {explanationChangeValue === "3" ? (
              <div className="contentThree-Three">
                <h3 id="childcareleave" className="icon-title type-pencil">【男性の育児休業】</h3>
                <h4 className="bg-title mt-small">【男性の育児休業】とは？</h4>

                <div className="d-flex flex-wrap-area justify-content-between">
                  <div className="flex-fill no-link-btn p-3">
                    育児休業の分割取得が可能に
                  </div>
                  <div className="flex-fill no-link-btn p-3">
                    産後パパ育休
                  </div>
                  <div className="flex-fill no-link-btn p-3">
                    休業中も就業可能
                  </div>
                </div>
                <p>
                  育児をワンオペ？今や男性も積極的に育児にかかわる時代です。2022年には法改正もあり、男性の育児休業が取得しやすくなりました。Zenkenも男性の育児休業取得を推進していきます。
                </p>

                <h4 className="bg-title">【育児休業申請】 お手続きの流れ</h4>
                <div className="numbering-wrap type-step">
                  <h5 className="numbering-title">育児休業取得の意思を上長に伝える</h5>
                  <p>一番悩むところかもしれませんが、大丈夫。先延ばしにしてもいいことは全くありませんし、なるべく早めにお伝えください。</p>

                  <h5 className="numbering-title">出生時育児休業（産後パパ育休）取得について人事より説明を受ける</h5>
                  <p>説明後、ご不明な点は遠慮なくご確認ください。</p>

                  <h5 className="numbering-title">育児休業の申請をする</h5>
                  <p>人事から受け取った「育児休業取得申請書」に必要事項記入後、上長宛に申請書をご提出ください。</p>

                  <h5 className="numbering-title">育児休業スタート</h5>
                  <p>引継ぎ等を終えたら、あとは会社のことは気にせず、育児休業をスタートさせましょう！</p>
                </div>
              </div>
            ) : (
              <></>
            )}
            {/* メンタルヘルス、祝金・休暇制度の押下時の退避用 */}
            {explanationChangeValue === "6" ? <div className=""></div> : <></>}
          </div>
        </div >
      </div >
      <Footer />
    </>
  );
}
export default Welfare;
