// @ts-nocheck
import { useState, useEffect } from 'react';
import { Tab } from 'bootstrap';
import { Link as Scroll } from 'react-scroll';
import jwt_decode from 'jwt-decode';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet-async';

// ヘッダー
import Header from '../component/page_parts/header';

// フッター
import Footer from '../component/page_parts/footer';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

//json読み込み
import name2Json from '../files/division.json';

import { Link } from "react-router-dom";

// パンくずリスト
import TableOfContents from "../component/page_parts/common/table_of_contents";

function Media() {

  const [json2, set2Json] = useState(name2Json);
  // console.log(json[0].name);
  //console.log(json[6].department)

  /* 配信先指定と認証に必要なstate */
  // Cookieから取得したトークンを保持
  const [cookies, setCookie, removeCookie] = useCookies(["sessionToken"]);
  // トークンをデコードして取得したjtiを保持（パラメータに付与する用）
  const [cookieJti, setCookieJti] = useState();


  // activeの切替 ※適当に引っ張ってきた状態なので、classとかmyTabみたいな名称とかは適宜直したい
  // https://getbootstrap.jp/docs/5.0/components/navs-tabs/
  var triggerTabList = [].slice.call(document.querySelectorAll('#myTab button'));
  triggerTabList.forEach(function (triggerEl) {
    // var tabTrigger = new bootstrap.Tab(triggerEl);
    var tabTrigger = new Tab(triggerEl);

    triggerEl.addEventListener('click', function (event) {
      event.preventDefault();
      tabTrigger.show();
    });
  });

  //restAPI取得
  const [posts, setPosts] = useState([]);

  // WP RESTAPI 環境別ベースURL
  const urlBase = process.env.REACT_APP_WP_REST_API_PATH_MEDIA;

  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  // ログイン時に生成されたトークンをデコードしたデータをstateに格納
  useEffect(() => {
    // console.log('cookieJti 1: ', cookieJti);
    if (cookieJti === undefined) {
      SettingLonginData();
    }
  }, []);

  /* ログイン時に生成されたトークンをデコードしたデータをstateに保持 */
  function SettingLonginData() {
    // console.log('【実行】SettingLonginData');
    // Cookieからトークンを取得
    const token = cookies.sessionToken;
    // デコード
    const decodedToken = jwt_decode<{ [name: string]: string }>(token);
    // console.log(decodedToken);

    /* パラメータ（zenken）にセットしてリクエストするためのjtiの値を取得してstateにセット
    →WP側で値の確認を行う（確認できないリクエストには401を返すようにする）*/
    setCookieJti(decodedToken.jti);
  }

  useEffect(() => {

    var wpRestUrl = urlBase + 'wp-json/wp/v2/posts?per_page=100&zenken='

    if (cookieJti !== undefined) {
      const fetchUrl = wpRestUrl + cookieJti;
      fetch(fetchUrl, { method: 'GET' })
        .then(res => res.json())
        .then(data => {
          setPosts(data)
          category(data)
        })
    }
  }, [cookieJti])

  const [mediaName, setMediaName] = useState([]);
  const [jsonData_name, setJsonData_name] = useState([]);

  const category = (data) => {
    /*やりたいこと
    *jsonからnameを取り出す
    *カテゴリーをキーにして、ソートして、jsonを分ける
    *分けたjsonを表示する
    */
    //nameを取り出す
    var namevalue = data.map((data) => data.media_department.name);

    //重複を削除
    // var category_value_unique = category_value.filter(
    //   (x, i, self) => self.indexOf(x) === i
    // );

    //重複を削除したカテゴリーをstateに格納
    setMediaName(namevalue);
    //restAPIのname
    //  console.log(namevalue);

    var data_sort = [];
    //投稿の数だけfor文を回す
    for (let i = 0; i < namevalue.length; i++) {
      //投稿の名前をキーにして、jsonを分ける
      var json_name = data.filter(
        (data) => data.media_department.name === namevalue[i]
      );
      //投稿の名前ごとに分けたjsonを配列に格納
      data_sort.push(json_name);
    }
    //投稿の名前ごとに分けた配列をstateに格納
    setJsonData_name(data_sort);
  };

  // console.log(jsonData_name);

  // {json.map((media) => 
  //   posts.map((json) => (
  //     json.media_department.name === media.name && json.acf.media_sns.twitter !== "" ?(
  //     console.log(json.title.rendered)
  //     ): (
  //       <></>
  //     )
  //   )
  // ))}
  // console.log(media_data)

  //name取り出し
  // {posts.map((json) => (
  //   console.log(json.title.rendered)
  // ))}

  var toukouName = json2.map((data) => data.department);
  var toukouName2 = posts.map((data) => data.media_department);
  // console.log(toukouName);
  // console.log(toukouName);


  //データの受け渡しテスト



  return (
    <>
      <Helmet>
        <title>Z-link メディア一覧</title>
      </Helmet>
      <Header />
      <div className="theme-division">
        <div className="content-header">
          <div className="container">
            <Row>
              <Col sm={12}>
                <h1 className='page-title type-media'>メディア一覧</h1>{/* ページタイトル */}
                <p className="page-description">各事業部が運営している「メディア」と、管理している「外部システム・サービス」をご紹介します。<br className="d-xl-none" />メディアの追加は<a href='https://secure.okbiz.jp/zenken/faq/show/1834' target='_blank' rel='noopener noreferrer' className='text-link type-external'>WEB統制委員会</a>まで。</p>{/* ページの説明 */}
              </Col>
            </Row>
          </div>
        </div>
        <TableOfContents />
        <div className="container division-area">
          <div className="row">
            {/* 左側メニュー */}
            <div className='col-left'>
              <div className='list-group nav nav-tabs' id='myTab' role='tablist'>
                {json2.map((test) => (
                  test.department_id === 1 ? (
                    <div className="group-box">
                      <div className="depName">{test.name}</div>
                      {test.department.map((test1) => (
                        <div>
                          <Scroll to={test1.slug} smooth={true} duration={600} offset={-90}>
                            <button
                              type='button'
                              className={'division-name list-group-item list-group-item-action nav-link ' + test1.state}
                              aria-current='true'
                              id={test1.id}
                              data-bs-toggle='tab'
                              data-bs-target={'#' + test1.slug}
                              role='tab'
                              aria-controls={test1.slug}
                              aria-selected='true'
                              value='00'
                            >
                              {test1.name}
                            </button>
                          </Scroll>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )
                ))}

                {json2.map((test) => (
                  test.department_id === 2 ? (
                    <div className="group-box">
                      <div className="depName">{test.name}</div>
                      {test.department.map((test1) => (
                        <div>
                          <Scroll to={test1.slug} smooth={true} duration={600} offset={-90}>
                            <button
                              type='button'
                              className={'division-name list-group-item list-group-item-action nav-link ' + test1.state}
                              aria-current='true'
                              id={test1.id}
                              data-bs-toggle='tab'
                              data-bs-target={'#' + test1.slug}
                              role='tab'
                              aria-controls={test1.slug}
                              aria-selected='true'
                              value='00'
                            >
                              {test1.name}
                            </button>
                          </Scroll>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )
                ))}

                {json2.map((test) => (
                  test.department_id === 3 ? (
                    <div className="group-box">
                      <div className="depName">{test.name}</div>
                      {test.department.map((test1) => (
                        <div>
                          <Scroll to={test1.slug} smooth={true} duration={600} offset={-90}>
                            <button
                              type='button'
                              className={'division-name list-group-item list-group-item-action nav-link ' + test1.state}
                              aria-current='true'
                              id={test1.id}
                              data-bs-toggle='tab'
                              data-bs-target={'#' + test1.slug}
                              role='tab'
                              aria-controls={test1.slug}
                              aria-selected='true'
                              value='00'
                            >
                              {test1.name}
                            </button>
                          </Scroll>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )
                ))}

                {json2.map((test) => (
                  test.department_id === 4 ? (
                    <div className="group-box">
                      <div className="depName">{test.name}</div>
                      {test.department.map((test1) => (
                        <div>
                          <Scroll to={test1.slug} smooth={true} duration={600} offset={-90}>
                            <button
                              type='button'
                              className={'division-name list-group-item list-group-item-action nav-link ' + test1.state}
                              aria-current='true'
                              id={test1.id}
                              data-bs-toggle='tab'
                              data-bs-target={'#' + test1.slug}
                              role='tab'
                              aria-controls={test1.slug}
                              aria-selected='true'
                              value='00'
                            >
                              {test1.name}
                            </button>
                          </Scroll>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )
                ))}

                {json2.map((test) => (
                  test.department_id === 5 ? (
                    <div className="group-box">
                      <div className="depName">{test.name}</div>
                      {test.department.map((test1) => (
                        <div>
                          <Scroll to={test1.slug} smooth={true} duration={600} offset={-90}>
                            <button
                              type='button'
                              className={'division-name list-group-item list-group-item-action nav-link ' + test1.state}
                              aria-current='true'
                              id={test1.id}
                              data-bs-toggle='tab'
                              data-bs-target={'#' + test1.slug}
                              role='tab'
                              aria-controls={test1.slug}
                              aria-selected='true'
                              value='00'
                            >
                              {test1.name}
                            </button>
                          </Scroll>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )
                ))}

                {json2.map((test) => (
                  test.department_id === 6 ? (
                    <div className="group-box">
                      <div className="depName">{test.name}</div>
                    </div>
                  ) : (
                    <></>
                  )
                ))}
              </div>
            </div>

            {/* 右側コンテンツ */}
            <div className='col-sm-9 col-right'>
              <div className='media-box-area'>
                {/* e-マーケティング事業部 */}
                {json2.map((test) => (
                  test.department_id === 1 ? (
                    <div className=''>
                      {test.department.map((test1) => (
                        <div className=''>
                          {/* <h3 id={test1.slug} className='tag-title mt-0 mb-4'><Link to={baseUrl + "/division"} state={{ test: test1.slug }}><span className='name2'>{test1.name}</span></Link></h3> */}
                          <h3 id={test1.slug} className='tag-title mt-0 mb-4'><span className='name2'>{test1.name}</span></h3>
                          <div className="media-box">
                            {posts.length !== 0 && posts.map((json) => (
                              json.media_department.name === test1.name || json.media_department.second_name === test1.name ? (
                                <div className='card media-list'>
                                  <a href={json.acf.media_url} target="_blank" rel="noopener noreferrer">
                                    <div className="media-logo">
                                      <img
                                        src={json.acf.media_img.logo ? json.acf.media_img.logo : json.acf.media_img.capture}
                                        alt='キャプチャ画像'
                                      />
                                    </div>

                                    <div className="media-title">{json.title.rendered}</div>

                                    <div className="media-text">{json.acf.media_description}</div>
                                  </a>
                                  <div className="media-sns">

                                    <ul className=''>
                                      {/* twitter */}
                                      {
                                        json.acf.media_sns.twitter !== "" ? (
                                          <li><a target="_blank" href={json.acf.media_sns.twitter}><img src="./twitter.svg" alt="Twitter画像" /></a></li>
                                        ) : (
                                          <></>
                                        )
                                      }
                                      {/* instagram */}
                                      {
                                        json.acf.media_sns.instagram !== "" ? (
                                          <li><a target="_blank" href={json.acf.media_sns.instagram}><img src="./instagram.svg" alt="Instagram画像" /></a></li>
                                        ) : (
                                          <></>
                                        )
                                      }
                                      {/* facebook */}
                                      {
                                        json.acf.media_sns.facebook !== "" ? (
                                          <li><a target="_blank" href={json.acf.media_sns.facebook}><img src="./facebook.svg" alt="facebook画像" /></a></li>
                                        ) : (
                                          <></>
                                        )
                                      }
                                      {/* youtube */}
                                      {
                                        json.acf.media_sns.youtube !== "" ? (
                                          <li><a target="_blank" href={json.acf.media_sns.youtube}><img src="./yutube.svg" alt="youtube画像" /></a></li>
                                        ) : (
                                          <></>
                                        )
                                      }

                                    </ul>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )
                ))}
                {/* HRインキュベーション事業部 */}
                {json2.map((test) => (
                  test.department_id === 2 ? (
                    <div className=''>
                      {test.department.map((test1) => (
                        <div className=''>
                          {/* <h3 id={test1.slug} className='tag-title mt-0 mb-4'><Link to={baseUrl + "/division"} state={{ test: test1.slug }}><span className='name2'>{test1.name}</span></Link></h3> */}
                          <h3 id={test1.slug} className='tag-title mt-0 mb-4'><span className='name2'>{test1.name}</span></h3>
                          <div className="media-box">
                            {posts.length !== 0 && posts.map((json) => (
                              json.media_department.name === test1.name || json.media_department.second_name === test1.name ? (
                                <div className='card media-list'>
                                  <a href={json.acf.media_url} target="_blank" rel="noopener noreferrer">
                                    <div className="media-logo">
                                      <img
                                        src={json.acf.media_img.logo ? json.acf.media_img.logo : json.acf.media_img.capture}
                                        alt='キャプチャ画像'
                                      />
                                    </div>
                                    <div className="media-title">{json.title.rendered}</div>
                                    <div className="media-text">{json.acf.media_description}</div>
                                  </a>
                                  <div className="media-sns">
                                    <ul className=''>
                                      {/* twitter */}
                                      {
                                        json.acf.media_sns.twitter !== "" ? (
                                          <li><a target="_blank" href={json.acf.media_sns.twitter}><img src="./twitter.svg" alt="Twitter画像" /></a></li>
                                        ) : (
                                          <></>
                                        )
                                      }
                                      {/* instagram */}
                                      {
                                        json.acf.media_sns.instagram !== "" ? (
                                          <li><a target="_blank" href={json.acf.media_sns.instagram}><img src="./instagram.svg" alt="Instagram画像" /></a></li>
                                        ) : (
                                          <></>
                                        )
                                      }
                                      {/* facebook */}
                                      {
                                        json.acf.media_sns.facebook !== "" ? (
                                          <li><a target="_blank" href={json.acf.media_sns.facebook}><img src="./facebook.svg" alt="facebook画像" /></a></li>
                                        ) : (
                                          <></>
                                        )
                                      }
                                      {/* youtube */}
                                      {
                                        json.acf.media_sns.youtube !== "" ? (
                                          <li><a target="_blank" href={json.acf.media_sns.youtube}><img src="./yutube.svg" alt="youtube画像" /></a></li>
                                        ) : (
                                          <></>
                                        )
                                      }
                                    </ul>
                                  </div>

                                </div>
                              ) : (
                                <></>
                              )
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )
                ))}
                {/* グローバル教育事業本部 */}
                {json2.map((test) => (
                  test.department_id === 3 ? (
                    <div className=''>
                      {test.department.map((test1) => (
                        <div className=''>
                          {/* <h3 id={test1.slug} className='tag-title mt-0 mb-4'><Link to={baseUrl + "/division"} state={{ test: test1.slug }}><span className='name2'>{test1.name}</span></Link></h3> */}
                          <h3 id={test1.slug} className='tag-title mt-0 mb-4'><span className='name2'>{test1.name}</span></h3>
                          <div className="media-box">
                            {posts.length !== 0 && posts.map((json) => (
                              json.media_department.name === test1.name || json.media_department.second_name === test1.name ? (
                                <div className='card media-list'>
                                  <a href={json.acf.media_url} target="_blank" rel="noopener noreferrer">
                                    <div className="media-logo">
                                      <img
                                        src={json.acf.media_img.logo ? json.acf.media_img.logo : json.acf.media_img.capture}
                                        alt='キャプチャ画像'
                                      />
                                    </div>
                                    <div className="media-title">{json.title.rendered}</div>
                                    <div className="media-text">{json.acf.media_description}</div>
                                  </a>
                                  <div className="media-sns">
                                    <ul className=''>
                                      {/* twitter */}
                                      {
                                        json.acf.media_sns.twitter !== "" ? (
                                          <li><a target="_blank" href={json.acf.media_sns.twitter}><img src="./twitter.svg" alt="Twitter画像" /></a></li>
                                        ) : (
                                          <></>
                                        )
                                      }
                                      {/* instagram */}
                                      {
                                        json.acf.media_sns.instagram !== "" ? (
                                          <li><a target="_blank" href={json.acf.media_sns.instagram}><img src="./instagram.svg" alt="Instagram画像" /></a></li>
                                        ) : (
                                          <></>
                                        )
                                      }
                                      {/* facebook */}
                                      {
                                        json.acf.media_sns.facebook !== "" ? (
                                          <li><a target="_blank" href={json.acf.media_sns.facebook}><img src="./facebook.svg" alt="facebook画像" /></a></li>
                                        ) : (
                                          <></>
                                        )
                                      }
                                      {/* youtube */}
                                      {
                                        json.acf.media_sns.youtube !== "" ? (
                                          <li><a target="_blank" href={json.acf.media_sns.youtube}><img src="./yutube.svg" alt="youtube画像" /></a></li>
                                        ) : (
                                          <></>
                                        )
                                      }

                                    </ul>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )
                ))}
                {/* 人材戦略統括本部 */}
                {json2.map((test) => (
                  test.department_id === 4 ? (
                    <div className=''>
                      {test.department.map((test1) => (
                        <div className=''>
                          {/* <h3 id={test1.slug} className='tag-title mt-0 mb-4'><Link to={baseUrl + "/division"} state={{ test: test1.slug }}><span className='name2'>{test1.name}</span></Link></h3> */}
                          <h3 id={test1.slug} className='tag-title mt-0 mb-4'><span className='name2'>{test1.name}</span></h3>
                          <div className="media-box">
                            {posts.length !== 0 && posts.map((json) => (
                              json.media_department.name === test1.name || json.media_department.second_name === test1.name ? (
                                <div className='card media-list'>
                                  <a href={json.acf.media_url} target="_blank" rel="noopener noreferrer">
                                    <div className="media-logo">
                                      <img
                                        src={json.acf.media_img.logo ? json.acf.media_img.logo : json.acf.media_img.capture}
                                        alt='キャプチャ画像'
                                      />
                                    </div>
                                    <div className="media-title">{json.title.rendered}</div>
                                    <div className="media-text">{json.acf.media_description}</div>
                                  </a>
                                  <div className="media-sns">
                                    <ul className=''>
                                      {/* twitter */}
                                      {
                                        json.acf.media_sns.twitter !== "" ? (
                                          <li><a target="_blank" href={json.acf.media_sns.twitter}><img src="./twitter.svg" alt="Twitter画像" /></a></li>
                                        ) : (
                                          <></>
                                        )
                                      }
                                      {/* instagram */}
                                      {
                                        json.acf.media_sns.instagram !== "" ? (
                                          <li><a target="_blank" href={json.acf.media_sns.instagram}><img src="./instagram.svg" alt="Instagram画像" /></a></li>
                                        ) : (
                                          <></>
                                        )
                                      }
                                      {/* facebook */}
                                      {
                                        json.acf.media_sns.facebook !== "" ? (
                                          <li><a target="_blank" href={json.acf.media_sns.facebook}><img src="./facebook.svg" alt="facebook画像" /></a></li>
                                        ) : (
                                          <></>
                                        )
                                      }
                                      {/* youtube */}
                                      {
                                        json.acf.media_sns.youtube !== "" ? (
                                          <li><a target="_blank" href={json.acf.media_sns.youtube}><img src="./yutube.svg" alt="youtube画像" /></a></li>
                                        ) : (
                                          <></>
                                        )
                                      }

                                    </ul>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )
                ))}
                {/* 管理本部 */}
                {json2.map((test) => (
                  test.department_id === 5 ? (
                    <div className=''>
                      {test.department.map((test1) => (
                        <div className=''>
                          {/* <h3 id={test1.slug} className='tag-title mt-0 mb-4'><Link to={baseUrl + "/division"} state={{ test: test1.slug }}><span className='name2'>{test1.name}</span></Link></h3> */}
                          <h3 id={test1.slug} className='tag-title mt-0 mb-4'><span className='name2'>{test1.name}</span></h3>
                          <div className="media-box">
                            {posts.length !== 0 && posts.map((json) => (
                              json.media_department.name === test1.name || json.media_department.second_name === test1.name ? (
                                <div className='card media-list'>
                                  <a href={json.acf.media_url} target="_blank" rel="noopener noreferrer">
                                    <div className="media-logo">
                                      <img
                                        src={json.acf.media_img.logo ? json.acf.media_img.logo : json.acf.media_img.capture}
                                        alt='キャプチャ画像'
                                      />
                                    </div>
                                    <div className="media-title">{json.title.rendered}</div>
                                    <div className="media-text">{json.acf.media_description}</div>
                                  </a>
                                  <div className="media-sns">
                                    <ul className=''>
                                      {/* twitter */}
                                      {
                                        json.acf.media_sns.twitter !== "" ? (
                                          <li><a target="_blank" href={json.acf.media_sns.twitter}><img src="./twitter.svg" alt="Twitter画像" /></a></li>
                                        ) : (
                                          <></>
                                        )
                                      }
                                      {/* instagram */}
                                      {
                                        json.acf.media_sns.instagram !== "" ? (
                                          <li><a target="_blank" href={json.acf.media_sns.instagram}><img src="./instagram.svg" alt="Instagram画像" /></a></li>
                                        ) : (
                                          <></>
                                        )
                                      }
                                      {/* facebook */}
                                      {
                                        json.acf.media_sns.facebook !== "" ? (
                                          <li><a target="_blank" href={json.acf.media_sns.facebook}><img src="./facebook.svg" alt="facebook画像" /></a></li>
                                        ) : (
                                          <></>
                                        )
                                      }
                                      {/* youtube */}
                                      {
                                        json.acf.media_sns.youtube !== "" ? (
                                          <li><a target="_blank" href={json.acf.media_sns.youtube}><img src="./yutube.svg" alt="youtube画像" /></a></li>
                                        ) : (
                                          <></>
                                        )
                                      }

                                    </ul>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )
                ))}

              </div>
            </div>

          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Media;