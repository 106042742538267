// @ts-nocheck
//Smooth Scroll
import { Link } from 'react-scroll';

// レイアウト
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function F_employee() {

  return (
    <div className="facility-lower">
      <h2 className="tag-title">社員証・セキュリティ</h2>
      <Row className="my-4" as='dl'>
        <Col sm={3} as='dt' className='toc-btn-title'>
          社員証
        </Col>
        <Col sm={9} as='dd' className='mb-0'>
          <Row className='toc-btnArea'>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter01"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                社員証を<br />忘れた場合について
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter02"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                社員証を紛失した<br />場合について
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter03"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                ストラップの<br />交換方法について
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="my-4" as='dl'>
        <Col sm={3} as='dt' className='toc-btn-title'>
          セキュリティ
        </Col>
        <Col sm={9} as='dd' className='mb-0'>
          <Row className='toc-btnArea'>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter04"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                本社オフィスの<br /><span className='custom-space'>セキュリティについて</span>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>

      <section className="sec-employee">
        <h3 id="chapter01" className='icon-title type-pencil'>社員証を忘れた場合について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span></p>
          <div className="l-number-wrapper">
            <ol>
              <li>総務課へ連絡</li>
              <li>エビデンスを残す必要があるため、連絡書の起案をする<br />（楽楽精算⇒①申請・承認⇒②ワークフロー⇒③連絡書⇒④連絡書）※下記画像参照</li>
              <li>新宿セントラルパークタワーではオフィスのセキュリティカードでもありますので、<br />仮カード貸与前の入退出時は他の社員に声をかけて入退出するようにしてください。</li>
            </ol>
          </div>
        </div>
        <div className="img-wrap ct">
          <div className="flame">
            <img
              src={process.env.PUBLIC_URL + '/facility_pic/employee03.jpg'}
              alt="社員証を忘れた場合について"
              className="img-wrap__img"
            />
          </div>
        </div>

        <h3 id="chapter02" className='icon-title type-pencil'>社員証を紛失した場合について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span></p>
          <div className="l-number-wrapper">
            <ol>
              <li>上司へ報告</li>
              <li>
                各部門責任者へ報告をし、以下の書類を提出
                <div className="box-list">
                  <ul>
                    <li>インシデント報告書</li>
                    <li>始末書（林社長宛てのもの、フォームはお任せいたします。稟議書にもPDF添付）</li>
                  </ul>
                </div>
                <div className="img-wrap ct">
                  <div className="flame">
                    {/* <img
                      src={process.env.PUBLIC_URL + '/facility_pic/employee02.jpg'}
                      alt="備品の発注方法について"
                      className="img-wrap__img"
                    /> */}
                    <iframe src="https://docs.google.com/spreadsheets/d/1MpVyLH5ssfRbU5hHrUtadJGXwnZKhSZL/preview#gid=1561498681" width="500" height="500" allow="autoplay"></iframe>
                  </div>
                </div>
              </li>
              <li>
                紛失した社員証については再発行する必要があるため稟議書を起案<br />（楽楽精算⇒①申請・承認⇒②ワークフロー⇒③稟議書⇒④購入稟議書）※下記画像参照
                <div className="img-wrap ct">
                  <div className="flame">
                    <img
                      src={process.env.PUBLIC_URL + '/facility_pic/employee01.jpg'}
                      alt="社員証を紛失した場合について"
                      className="img-wrap__img"
                    />
                  </div>
                </div>
              </li>
            </ol>
          </div>
        </div>

        <h3 id="chapter03" className='icon-title type-pencil'>ストラップの交換方法について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>総務課にて在庫がございますのでご連絡ください。</p>
        </div>

        <h3 id="chapter04" className='icon-title type-pencil'>本社オフィスのセキュリティについて</h3>
        <div className="border-box">
          <p className="todo-catch"><span>POINT</span>セキュリティロック権限のある方は限られておりますので、<br />必ずセキュリティロック権限者が最終退出社になるようにしてください。</p>
        </div>

      </section>

    </div>

  );
}

export default F_employee;

