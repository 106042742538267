import React from 'react';
import { useState, useLayoutEffect, useEffect } from "react";
import AppViewHead from '../page_parts/app_view_head';
function Calendar() {
    return (

        <div className="contents_box" id="calendar">
            {/** 画面描画箇所 */}
            <AppViewHead />
            <h1>Calendarコンテンツです</h1>
            <div className="contents">
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
                ダミー<br />
            </div>
        </div>
    );
}
export default Calendar;