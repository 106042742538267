// @ts-nocheck
import React from "react";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet-async';

//ヘッダー
import Header from "../../component/page_parts/header";

// フッター
import Footer from "../../component/page_parts/footer";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// パンくずリスト
import TableOfContents from "../../component/page_parts/common/table_of_contents";

function JinjiLifestyle() {
  const [explanationChangeValue, setExplanationChangeValue] =
    useState<string>("1");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // GAID
  const gaId = process.env.REACT_APP_GA_ID;

  var element = document.getElementById("1");
  var userName = localStorage.getItem("user_name");
  ReactGA.initialize(gaId);
  ReactGA.send({
    hitType: "event",
    eventCategory: userName,
    eventAction: '人事手続き-タブ切り替え',
    eventLabel: element?.textContent,
  });

  const selectCategory = (value) => (e) => {
    setExplanationChangeValue(value);

    //全てのbtnToggleのis-activeを外す
    const btnToggleAll = document.querySelectorAll(".btnToggle");
    btnToggleAll.forEach((btnToggle) => {
      btnToggle.classList.remove("is-active");
    });

    //クリックしたbtnToggleにis-activeを付与
    const btnToggle = document.getElementById(value);
    btnToggle.classList.add("is-active");

    // GA4タブ押下時計測
    var element = document.getElementById(e.target.id);
    var userName = localStorage.getItem("user_name");
    console.log(element?.textContent);
    ReactGA.initialize(gaId);
    ReactGA.send({
      hitType: "event",
      eventCategory: userName,
      eventAction: '人事手続き-タブ切り替え',
      eventLabel: element?.textContent,
    });
  };

  //テスト環境
  const baseUrl = process.env.REACT_APP_PUBLIC_URL || '';

  return (
    <>
      <Helmet>
        <title>Z-link 人事手続き</title>
      </Helmet>
      <Header />
      <div className="theme-jinji-lifestyle">
        <div className="content-header">
          <div className="container pe-4">
            <Row>
              <Col sm={9} >
                <h1 className='page-title type-human'>人事手続き</h1>{/* ページタイトル */}
                <p className="page-description pt-1">入社から結婚、出産、退職まで様々なライフイベントからのお手続きをご案内いたします。</p>{/* ページの説明 */}
              </Col>
              <Col sm={3} className="d-flex align-bottom align-self-center mb-4">
                <div className="jinji-related">
                  <div className="page-title-area">
                    <button className="btn-link-area py-3">
                      <a href={baseUrl + '/lifestyle/evaluation'}>人事評価制度について</a>
                    </button>
                  </div >
                </div >
              </Col >
            </Row >
          </div >
        </div >
      </div >
      <TableOfContents />
      < div className="main-wrapper jinji-related theme-jinji-lifestyle" >
        <div className="contents-wrap mt-5 container">
          <div className="d-flex flex-wrap justify-content-between mt-5 select-tags">
            <div
              className="tag flex-fill px-2 btnToggle is-active"
              id="1"
              onClick={selectCategory("1")}
            >
              入社
            </div>
            <div
              className="tag flex-fill px-2 btnToggle"
              id="2"
              onClick={selectCategory("2")}
            >
              結婚
            </div>
            <div
              className="tag flex-fill px-2 btnToggle"
              id="3"
              onClick={selectCategory("3")}
            >
              出産・子育て
            </div>
            <div
              className="tag flex-fill px-2 btnToggle"
              id="4"
              onClick={selectCategory("4")}
            >
              マイホーム購入
            </div>
            <div
              className="tag flex-fill px-2 btnToggle"
              id="5"
              onClick={selectCategory("5")}
            >
              退職
            </div>
            <div
              className="tag flex-fill px-2 btnToggle"
              id="7"
              onClick={selectCategory("7")}
            >
              引越し
            </div>
            <div
              className="tag flex-fill px-2 btnToggle"
              id="8"
              onClick={selectCategory("8")}
            >
              家族の就職・退職
            </div>
            <div
              className="tag flex-fill px-2 btnToggle"
              id="9"
              onClick={selectCategory("9")}
            >
              介護
            </div>
            <div
              className=" tag flex-fill px-2 btnToggle"
              id="10"
              onClick={selectCategory("10")}
            >
              身内の不幸
            </div>
          </div>

          {/* 入社 */}
          {explanationChangeValue === "1" ? (
            <div className="contentOne">
              <h2 className="tag-title">入社</h2>
              <h3 className="icon-title type-pencil mt-5 mb-3">入社された場合</h3>
              <p className="mb-4">
                ご入社おめでとうございます。
                <br />
                これからZenkenの一員として、ともに励みましょう！
              </p>
              <div className="todo-list-border mb-3">
                <div className="todo-list mb-2">
                  <span>To Do</span>入社手続きをお願いいたします
                </div>
                <ul>
                  <li>
                    入社にともない、雇用契約締結、入社情報の登録が必要です。
                  </li>
                  <li>
                    法定条項として、入社前健康診断の受診結果が必要となります。
                  </li>
                </ul>
              </div>
              <h4 className="bg-title" id="chapter06">
                お手続きの流れ
              </h4>
              <div className="numbering-wrap type-step">
                <div className="numbering-title">
                  雇用契約締結
                </div>
                <div className="">
                  入社前にContractS経由で雇用契約書が届きますので、内容確認の上契約締結をお願いいたします。
                </div>

                <div className="numbering-title">
                  入社情報登録
                </div>

                <div className="">
                  別途ジンジャーより入社情報登録メールが届きますので、入力し登録をお願いいたします。
                </div>

                <div className="numbering-title">
                  入社前検診受診
                </div>

                <div className="">
                  入社前に健康診断の受診が必要となりますので、お近くの医療機関で「入社前健康診断」を受診お願いいたします。税込1万円までは会社が負担いたしますので、診断結果と領収証をセットで人事までお渡しください。
                </div>

                <div className="numbering-title">
                  入社日に出社
                </div>

                <div className="">
                  入社当日に、会社メールアドレス宛にジンジャー勤怠ログイン情報が届いているのを確認し、打刻をお願いいたします。
                </div>
              </div>

            </div>
          ) : (
            <></>
          )}
          {/* 結婚 */}
          {explanationChangeValue === "2" ? (
            <div className="contentTwo">
              <h2 className="tag-title">結婚</h2>
              <h3 className="icon-title type-pencil mt-5 mb-3">結婚された場合</h3>
              <p className="mb-4">
                ご結婚おめでとうございます。
                <br />
                お二人の門出を心からお祝いいたします！
              </p>
              <div className="todo-list-border mb-3">
                <div className="todo-list mb-2">
                  <span>To Do</span>各種申請手続きをお願いいたします
                </div>
                <ul>
                  <li>
                    ご結婚にともない、結婚祝金の申請をしてください。慶弔金規程により10万円が支給されます。
                  </li>
                  <li>
                    入籍後、転居や氏名変更があった場合は、<a href='https://tvyj3455.talent-p.net/' target='blank' className='text-link type-external'>タレントパレット</a>より申請をお願いいたします。また、扶養追加等があった場合は人事にお知らせください。
                  </li>
                  <li>結婚（入籍）された場合、特別休暇が5日付与されます。</li>
                </ul>
              </div>
              <h4 className="bg-title" id="chapter06">
                お手続きの流れ
              </h4>
              <div className="numbering-wrap type-step">
                <div className="numbering-title">
                  慶弔金（結婚祝金）申請
                </div>

                <div className="">
                  <p>
                    慶弔金規程に伴い、10万円が支給されます。<a href='https://tvyj3455.talent-p.net/' target='blank' className='text-link type-external'>タレントパレット</a>にて慶弔金支給申請をお願いいたします。タレントパレットで申請書の承認後に、2週間以内に給与支給口座に振り込まれます。<br />※経費精算と同じタイミングでの処理となります。
                  </p>
                </div>

                <div className="numbering-title">
                  社員情報変更登録
                </div>
                <div className="">
                  <p>
                    <a href='https://tvyj3455.talent-p.net/' target='blank' className='text-link type-external'>タレントパレット</a>の登録内容を修正し申請をお願いいたします。転居や氏名変更があった場合は、公的書類（住民票等）を添付していただく必要がございます。
                  </p>
                </div>

                <div className="numbering-title">
                  慶弔休暇の付与
                </div>
                <div className="">
                  <p>
                    結婚（入籍）の場合、特別休暇として5日間付与されます（正社員のみ）。休暇取得は<a href='https://kintai.jinjer.biz/' target='_brank'>ジンジャー勤怠</a>にて申請をお願いいたします。
                  </p>
                </div>
              </div>

            </div>
          ) : (
            <></>
          )}
          {/* 出産・子育て*/}
          {explanationChangeValue === "3" ? (
            <div className="contentThree">
              <h2 className="tag-title">出産・子育て</h2>
              <h3 className="icon-title type-pencil mt-5 mb-3">出産された場合</h3>

              <p className="mb-4">
                お子様のお誕生、おめでとうございます！！
                <br />
                お子様の健やかな健康を心からお祈りいたします。
              </p>
              <div className="todo-list-border mb-3">
                <div className="todo-list mb-2">
                  <span>To Do</span>各種申請手続きをお願いいたします
                </div>
                <ul>
                  <li>
                    お子様誕生にともない、出産祝金の申請をしてください。慶弔金規程により10～30万円が支給されます。
                  </li>
                  <li>
                    出産後、扶養追加等があった場合は人事にお知らせください。ジンジャーより変更連絡メールが届きます。
                  </li>
                  <li>奥様が出産された場合、特別休暇が1日付与されます。</li>
                  <li>
                    育児休業、育児短時間勤務をご希望の場合は、別途申請が必要となります。
                  </li>
                </ul>
              </div>
              <h4 className="bg-title" id="chapter06">
                お手続きの流れ
              </h4>
              <div className="numbering-wrap type-step">
                <div className="numbering-title">
                  慶弔金（出産祝金）申請
                </div>
                <div className="">
                  <p>
                    慶弔金規程に伴い、10～30万円が支給されます。<a href='https://tvyj3455.talent-p.net/' target='blank' className='text-link type-external'>タレントパレット</a>にて慶弔金支給申請をお願いいたします。出産祝金：1人目10万円→2人目20万円→3人目以降都度30万円<br />※2022/4/1以降入社の社員は「入社後1年以上」という在籍条件が付きます。<br />楽楽精算で申請書の承認後に、2週間以内に給与支給口座に振り込まれます。<br />※経費精算と同じタイミングでの処理となります。
                  </p>
                </div>

                <div className="numbering-title">
                  社員情報変更登録
                </div>

                <div className="">
                  <p>
                    別途ジンジャーより社員情報変更登録メールが届きますので、入力し登録をお願いいたします。なお、お子様を扶養に入れる場合は、健康保険証作成のため「社員本人とお子さんそれぞれのマイナンバー」が必要となります。
                  </p>
                </div>

                <div className="numbering-title">
                  慶弔休暇の付与（男性社員のみ）
                </div>

                <div className="">
                  <p>
                    配偶者が出産した場合、特別休暇として1日付与されます（正社員のみ）。休暇取得は<a href='https://kintai.jinjer.biz/' target='_brank'>ジンジャー勤怠</a>にて申請をお願いいたします。
                  </p>
                </div>

                <div className="numbering-title">
                  育児休業（育休）申請
                </div>
                <div className="">
                  <p>
                    産後休暇（産休）後に、1歳に満たない子を養育する場合、育児休業の取得が可能です。産後休暇の翌日（男性社員の場合は出産日）から1歳の誕生日を迎える前日までが休業期間となり、保育所への入所ができない場合は半年～1年の延長が可能となっています。
                    <br />
                    申請は人事から受け取った「育児休業取得申請書」に必要事項をご記入いただき、<a href='https://rsdesign.rakurakuseisan.jp/_5ZwGywYVPa/' target='_brank'>楽楽精算</a>&gt;ワークフロー&gt;人事稟議書に添付して申請してください。
                  </p>
                </div>

                <div className="numbering-title">
                  育児短時間勤務（時短）申請
                </div>
                <div className="">
                  <p>
                    育児休業からの復職後、3歳に満たない子を養育する場合、育児短時間（時短）勤務が可能です。時間短縮は最大2時間までとなります。申請は人事から受け取った「育児短時間勤務申出書」に必要事項をご記入いただき、<a href='https://rsdesign.rakurakuseisan.jp/_5ZwGywYVPa/' target='_brank'>楽楽精算</a>&gt;ワークフロー&gt;人事稟議書に添付して申請してください。
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* マイホーム購入 */}
          {explanationChangeValue === "4" ? (
            <div className="contentFour">
              <h2 className="tag-title">マイホームを購入</h2>
              <h3 className="icon-title type-pencil mt-5 mb-3">マイホームを購入された場合</h3>

              <p className="mb-4">
                新居のご購入おめでとうございます！！
                <br />
                これからの生活が一層楽しみなものになりますね！
              </p>
              <div className="todo-list-border mb-3">
                <div className="todo-list mb-2">
                  <span>To Do</span>各種申請手続きをお願いいたします
                </div>
                <ul>
                  <li>
                    マイホーム購入、転居等があった場合、人事へご連絡ください。住所変更は<a href='https://tvyj3455.talent-p.net/' target='blank' className='text-link type-external'>タレントパレット</a>より申請をお願いいたします。
                  </li>
                  <li>
                    通勤費の清算は定期代支給、IC料金支給によって処理が異なります。
                  </li>
                  <li>定期区間の登録は「楽楽精算」でも必須となります。</li>
                  <li>
                    住宅ローンの借入れを行った場合、初年度の年末調整は行いますが、別途ご自身で確定申告が必要となります。
                  </li>
                </ul>
              </div>
              <h4 className="bg-title" id="chapter06">
                お手続きの流れ
              </h4>
              <div className="numbering-wrap type-step">
                <div className="numbering-title">
                  社員情報変更登録
                </div>

                <div className="">
                  <a href='https://tvyj3455.talent-p.net/' target='blank' className='text-link type-external'>タレントパレット</a>の登録内容を修正し申請をお願いいたします。
                </div>

                <div className="numbering-title">
                  通勤費（定期代・IC料金）精算
                </div>

                <div className="">
                  <p>
                    住所の変更日が、1日か月途中かにより定期代の変更月が異なります。1日変更の場合はその月から、月度途中の場合は翌月から変更となります。半年定期代で支給されている場合、上記変更月から残り月数の新・旧定期代差分を精算し、変更月度の給与にて支給または控除となります。
                    <br />
                    なお、時給契約社員等、出勤日数にて通勤費を支給している社員の場合、変更日までを旧IC料金、変更日以降から新IC料金で計算して支給いたします。
                  </p>
                  <p>
                    ※転居の際の定期区間登録は、楽楽精算でも必須となります。ジンジャーとはリンクしておりませんので、<a href='https://rsdesign.rakurakuseisan.jp/_5ZwGywYVPa/' target='_brank'>楽楽精算ログイン</a>&gt;個人設定&gt;定期区間にて変更手続きを行ってください。
                  </p>
                </div>

                <div className="numbering-title">
                  （住宅ローン対象者のみ）初年度：ご自身で確定申告
                </div>

                <div className="">
                  通常通り年末調整を会社で行いますが、住宅ローン初年度は、最寄りの税務署に会社発行の源泉徴収票持参の上、ご自身で確定申告を行っていただきます。その際に「年末調整のための（特定増改築等）住宅借入金等特別控除証明書」が発行されますので、大事に保管をお願いいたします。
                </div>

                <div className="numbering-title">
                  （住宅ローン対象者のみ）2年目以降：会社で年末調整
                </div>

                <div className="">
                  前年度に税務署で発行された「年末調整のための（特定増改築等）住宅借入金等特別控除証明書」の該当年度分と、金融機関から毎年発行される「住宅取得資金に係る借入金の年末残高等証明書」を合わせて年末調整の際にご提出ください。
                </div>
              </div>

            </div>
          ) : (
            <></>
          )}
          {/* 退職 */}
          {explanationChangeValue === "5" ? (
            <div className="contentFive">
              <h2 className="tag-title">退職</h2>
              <h3 className="icon-title type-pencil mt-5 mb-3">退職される場合</h3>

              <p className="mb-4">
                これまで大変お世話になりました。
                <br />
                新天地でのご活躍を祈念しております。
              </p>
              <div className="todo-list-border mb-3">
                <div className="todo-list mb-2">
                  <span>To Do</span>各種申請手続きをお願いいたします
                </div>
                <ul>
                  <li>
                    退職が決まった場合、退職関連の書類を人事宛にご提出いただきます。
                  </li>
                  <li>
                    社員持株会の会員は、持株会事務局まで事前にお問い合わせください。（担当者は<a href="/welfare">福利厚生</a>ページからご確認ください）
                  </li>
                  <li>選択制DC加入者は、DC事務局までお問い合わせください。（人事事務課：松本 晃明）</li>
                </ul>
              </div>
              <h4 className="bg-title" id="chapter06">
                お手続きの流れ
              </h4>
              <div className="numbering-wrap type-step">
                <div className="numbering-title">
                  退職書類の提出
                </div>

                <div className="">
                  <p>以下をご提出いただきます。</p>
                  <ol>
                    <li>退職届（会社指定用紙）</li>
                    <li>退職時の守秘義務に関する誓約書</li>
                    <li>時間外勤務確認書</li>
                    <li>
                      証明書発行願い　※社会保険資格喪失証明書や退職書類等、会社発行の証明書が必要な場合
                    </li>
                  </ol>
                </div>

                <div className="numbering-title">
                  退職時にご返却いただくもの
                </div>

                <ol>
                  <li>社員証　※勤務最終日の翌日以降</li>
                  <li>健康保険被保険者証（保険証）</li>
                </ol>

                <div className="numbering-title">
                  退職前に手続きが必要なもの
                </div>

                <div className="">
                  <p>
                    持株会：退職されると持株会員としての資格を失いますので、持株会事務局までお知らせください。退会書類受理後、退会手続きを行います。手続き後、売買単位（100株）の株式は、会員口座へ振替を行います。売買単位未満の株式は、以下のいずれかの処理となります。
                  </p>
                  <ol>
                    <li>時価で売却し現金で受け取る</li>
                    <li>
                      100株に達する臨時拠出を行い（足りない分を現金入金）会員口座へ振替
                    </li>
                  </ol>
                </div>

                <div className="numbering-title">
                  退職後に手続きが必要なもの
                </div>

                <div className="">
                  <p>
                    選択制DC：60歳までは預け入れた掛金は受け取れないため、以下のいずれかの処理を行っていただきます。
                  </p>
                  <ol>
                    <li>
                      転職先がDCを導入していれば、転職先で移換手続きを行ってください。
                    </li>
                    <li>
                      転職先がDCを導入していない場合、個人型確定拠出年金（iDeCo）に移換し資産運用を継続してください。
                    </li>
                  </ol>
                </div>
              </div>

            </div>
          ) : (
            <></>
          )}
          {/* 引越し */}
          {explanationChangeValue === "7" ? (
            <div className="contentSeven">
              <h2 className="tag-title">引越し</h2>
              <h3 className="icon-title type-pencil mt-5 mb-3">引越しされた場合</h3>

              <p className="mb-4">
                心機一転、新たな地でのリスタートですね。
                <br />
                新しいホームタウンがよい街でありますように！
              </p>
              <div className="todo-list-border mb-3">
                <div className="todo-list mb-2">
                  <span>To Do</span>各種申請手続きをお願いいたします
                </div>
                <ul>
                  <li>
                    転居された場合、<a href='https://tvyj3455.talent-p.net/' target='blank' className='text-link type-external'>タレントパレット</a>に登録されている住所を修正し申請をお願いいたします。
                  </li>
                  <li>
                    通勤費の清算は定期代支給、IC料金支給によって処理が異なります。
                  </li>
                  <li>定期区間の登録は「楽楽精算」でも必須となります。</li>
                </ul>
              </div>
              <h4 className="bg-title" id="chapter06">
                お手続きの流れ
              </h4>
              <div className="numbering-wrap type-step">
                <div className="numbering-title">
                  社員情報変更登録
                </div>
                <div className="">
                  <a href='https://tvyj3455.talent-p.net/' target='blank' className='text-link type-external'>タレントパレット</a>の登録内容を修正し申請をお願いいたします。
                </div>

                <div className="numbering-title">
                  通勤費（定期代・IC料金）精算
                </div>
                <div className="">
                  <p>
                    住所の変更日が、1日か月途中かにより定期代の変更月が異なります。1日変更の場合はその月から、月度途中の場合は翌月から変更となります。半年定期代で支給されている場合、上記変更月から残り月数の新・旧定期代差分を精算し、変更月度の給与にて支給または控除となります。
                    <br />
                    なお、時給契約社員等、出勤日数にて通勤費を支給している社員の場合、変更日までを旧IC料金、変更日以降から新IC料金で計算して支給いたします。
                  </p>
                  <p>
                    ※転居の際の定期区間登録は、楽楽精算でも必須となります。ジンジャーとはリンクしておりませんので、<a href='https://rsdesign.rakurakuseisan.jp/_5ZwGywYVPa/' target='_brank'>楽楽精算ログイン</a>&gt;個人設定&gt;定期区間にて変更手続きを行ってください。
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* 家族の就職・退職 */}
          {explanationChangeValue === "8" ? (
            <div className="contentEight">
              <h2 className="tag-title">家族の就職・退職</h2>
              <h3 className="icon-title type-pencil mt-5 mb-3">ご家族が就職／退職された場合</h3>

              <p className="mb-4">
                ご家族に変化が訪れるタイミング。
                <br />
                リスタートに向けて応援のし甲斐がありますね。
              </p>
              <div className="todo-list-border mb-3">
                <div className="todo-list mb-2">
                  <span>To Do</span>各種申請手続きをお願いいたします
                </div>
                <ul>
                  <li>
                    ご家族を扶養から外される場合、または扶養に追加する場合は人事へご連絡ください。ジンジャーより変更連絡メールが届きます。
                  </li>
                  <li>
                    ご家族を扶養から外す場合は保険証の返却が必要となります。また、ご家族を扶養に追加する場合は、後日保険証が届きます。
                  </li>
                </ul>
              </div>
              <h4 className="bg-title" id="chapter06">
                お手続きの流れ
              </h4>
              <div className="numbering-wrap type-step">
                <div className="numbering-title">
                  社員情報変更登録
                </div>
                <div className="">
                  ジンジャーより社員情報変更登録メールが届きますので、入力し登録をお願いいたします。なお、ご家族をご自身の扶養に追加する場合は、健康保険証作成のため「社員本人と被扶養者となる方それぞれのマイナンバー」が必要となります。
                </div>

                <div className="numbering-title">
                  保険証の返却／お届け
                </div>
                <div className="">
                  ご家族を扶養から外す場合、保険証の返却が必要となります。人事に返却いただければ、日本年金機構経由で協会けんぽへ返却いたします。
                  <br />
                  また、ご家族を扶養に入れる場合は保険証が作成されますが、お手元に届くまでに2週間程度かかります。
                </div>
              </div>

            </div>
          ) : (
            <></>
          )}
          {/* 身内の介護 */}
          {explanationChangeValue === "9" ? (
            <div className="contentNine">
              <h2 className="tag-title">介護</h2>
              <h3 className="icon-title type-pencil mt-5 mb-3">身内の介護が必要となった場合</h3>

              <p className="mb-4">
                お世話になっていた側からお世話する側へ。
                <br />
                会社として手助けできることをお伝えいたします。
              </p>

              <div className="todo-list-border mb-3">
                <div className="todo-list mb-2">
                  <span>To Do</span>各種申請手続きをお願いいたします
                </div>
                <ul>
                  <li>
                    要介護状態により異なりますが、ご家族の介護が必要となった場合は人事にお知らせください。
                  </li>
                  <li>
                    介護休暇（無給）は要介護者1名につき年5日まで利用できます。介護休暇は時間休も可能。
                  </li>
                  <li>
                    介護休業、介護短時間勤務をご希望の場合は、別途申請が必要となります。
                  </li>
                </ul>
              </div>
              <h4 className="bg-title" id="chapter06">
                お手続きの流れ
              </h4>
              <div className="numbering-wrap type-step">
                <div className="numbering-title">
                  介護休暇申請
                </div>
                <div className="">
                  <p>
                    2週間以上にわたり常時介護を必要とする家族を介護する場合、介護休暇の取得が可能です。対象者1名につき年5日まで（2名の場合は年10日まで）取得できますが、無給です。介護休暇は1時間単位の取得が可能となっています。休暇取得は<a href='https://kintai.jinjer.biz/' target='_brank'>ジンジャー勤怠</a>にて申請をお願いいたします。
                  </p>
                </div>

                <div className="numbering-title">
                  介護休業申請
                </div>
                <div className="">
                  <p>
                    申請は人事から受け取った「介護休業取得申請書」に必要事項をご記入いただき、<a href='https://rsdesign.rakurakuseisan.jp/_5ZwGywYVPa/' target='_brank'>楽楽精算</a>&gt;ワークフロー&gt;人事稟議書に添付して申請してください。
                  </p>
                </div>

                <div className="numbering-title">
                  介護短時間勤務申請
                </div>

                <div className="">
                  <p>
                    介護休業からの復職後も家族の介護を行う場合、介護短時間（時短）勤務が可能です。時間短縮は最大2時間までとなります。申請は人事から受け取った「介護短時間勤務申出書」に必要事項をご記入いただき、<a href='https://rsdesign.rakurakuseisan.jp/_5ZwGywYVPa/' target='_brank'>楽楽精算</a>&gt;ワークフロー&gt;人事稟議書に添付して申請してください。
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* 身内の不幸 */}
          {explanationChangeValue === "10" ? (
            <div className="contentTen">
              <h2 className="tag-title">身内の不幸</h2>
              <h3 className="icon-title type-pencil mt-5 mb-3">ご家族/ご親族に不幸があった場合</h3>
              <p className="mb-4">
                この度はお悔やみ申し上げます。
                <br />
                会社にできることをお伝えいたしますね。
              </p>

              <div className="todo-list-border mb-3">
                <div className="todo-list mb-2">
                  <span>To Do</span>各種申請手続きをお願いいたします
                </div>
                <ul>
                  <li>
                    ご逝去にともない、弔慰金の申請をしてください。続柄により慶弔金規程にともない慶弔金が支給されます。
                  </li>
                  <li>
                    ご逝去後、転居や氏名変更、扶養変更があった場合は人事にお知らせください。ジンジャーより変更連絡メールが届きます。
                  </li>
                  <li>
                    不幸があった場合、続柄により特別休暇が1～7日付与されます。
                  </li>
                </ul>
              </div>
              <h4 className="bg-title" id="chapter06">
                お手続きの流れ
              </h4>
              <div className="numbering-wrap type-step">
                <div className="numbering-title">
                  慶弔金（死亡弔慰金）申請
                </div>

                <div className="">
                  <p>
                    慶弔金規程に伴い、1～3万円が支給されます。<a href='https://tvyj3455.talent-p.net/' target='blank' className='text-link type-external'>タレントパレット</a>にて慶弔金支給申請をお願いいたします。
                  </p>
                </div>

                <div className="numbering-title">
                  社員情報変更登録
                </div>
                <div className="">
                  <p>
                    別途ジンジャーより社員情報変更登録メールが届きますので、入力し登録をお願いいたします。
                  </p>
                </div>

                <div className="numbering-title">
                  慶弔休暇の付与
                </div>

                <div className="">
                  <p>
                    身内に不幸があった場合、特別休暇として1～7日付与されます（正社員のみ）。休暇取得は<a href='https://kintai.jinjer.biz/' target='_brank'>ジンジャー勤怠</a>にて申請をお願いいたします。
                  </p>
                </div>
              </div>

            </div>
          ) : (
            <></>
          )}
        </div>
      </div >
      <Footer />
    </>
  );
}

export default JinjiLifestyle;
