import React, { useState, useEffect } from "react";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link } from 'react-scroll';

// 委員会一覧ページ 衛生委員会タブ
function Contents2() {

  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  useEffect(() => { }, []);

  return (
    <div id="committee-content1">
      <h2 className="tag-title">衛生委員会</h2>
      <div className="committee-related">
        <div className="point-list-border mb-3">
          <div className="point-title">
            <span>CHECK</span>衛生委員会とは
          </div>
          <ul className="dot-list type-list">
            <li>衛生委員会は、一定の規模の企業において設置を義務づけられているもので、<br />働く人の健康や安全を維持することや健康障害や労働災害を防ぐことを目的としています。</li>
            <li>社内や社外から委員会のメンバ―を選出し、会社の衛生に関わることについての課題や解決のための対策を話し合う場となっています。</li>
            <li>月に１回、産業医による衛生講話を聴いたり、職場の状況（感染症発生状況や長時間労働者の発生状況等）の共有なども行っています。</li>
          </ul>
        </div>
      </div>
      <Row className="toc-btnArea">
        <Col sm={4} className='toc-btn'>
          <Link
            to="chapter05"
            smooth={true}
            duration={500}
            offset={-100}
            className='btn main-btn type-internal w-100'
          >
            衛生委員会 委員一覧
          </Link>
        </Col>
        <Col sm={4} className='toc-btn'>
          <Link
            to="chapter06"
            smooth={true}
            duration={500}
            offset={-100}
            className='btn main-btn type-internal w-100'
          >
            産業医紹介
          </Link>
        </Col>
        <Col sm={4} className='toc-btn'>
          <Link
            to="chapter07"
            smooth={true}
            duration={500}
            offset={-100}
            className='btn main-btn type-internal w-100'
          >
            面談希望フォーム
          </Link>
        </Col>
      </Row>
      <Row>
        <Col sm={7}>
          <h3 id="chapter05" className='icon-title type-pencil mt-small'>衛生委員会 委員一覧</h3>
        </Col>
        <Col sm={5} className="side">
          <a href="https://drive.google.com/drive/folders/1ugidqwRXWkpZ6nrAbMMYGlcdr7Jbifp7" target="_blank" className="btn main-btn type-external h-auto">衛生委員会議事録</a>
        </Col>
      </Row>
      <p>Zenken株式会社の衛生委員会は、議長1名、衛生管理者1名、産業医1名、委員4名で構成されています。</p>
      <div className="listBox">
        <table className='committee-table table'>
          <thead>
            <tr >
              <th scope='col col-sm-3' style={{ "width": "25%" }}>役割</th>
              <th scope='col col-sm-3' style={{ "width": "25%" }}>本部名</th>
              <th scope='col col-sm-3' style={{ "width": "33.3%" }}>所属部署</th>
              <th scope='col' style={{ "width": "41.7%" }}>氏名</th>
            </tr>
          </thead>
          <tbody>
            <tr><td>議長</td><td>人材戦略統括本部</td><td>人事部 人事労務チーム</td><td className="name-column">松本 晃明</td></tr>
            <tr><td>衛生管理者</td><td>管理本部</td><td>総務部</td><td className="name-column">柗田 望</td></tr>
            <tr><td>産業医</td><td>-</td><td>-</td><td className="name-column">内田 香織</td></tr>
            <tr><td>委員</td><td>人材戦略統括本部</td><td>人事部 人事労務チーム</td><td className="name-column">佐々木 香織</td></tr>
            <tr><td>委員</td><td>人材戦略統括本部</td><td>人事部 人材戦略チーム</td><td className="name-column">仁科 智子</td></tr>
            <tr><td>委員</td><td>eマーケティング事業本部</td><td>グローバルニッチトップ事業部</td><td className="name-column">村木 祐子</td></tr>
            <tr><td>委員</td><td>グローバル教育事業本部</td><td>-</td><td className="name-column">小川 真州美</td></tr>
          </tbody>
        </table>
      </div>
      <p>各担当者の内線番号の確認は、<a href={baseUrl + "/employee_list"}>従業員名簿</a>よりご確認ください。</p>
      <Row>
        <Col sm={7}>
          <h3 id="chapter06" className='icon-title type-pencil'>産業医紹介</h3>
        </Col>
        <Col sm={5} className="side">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLScbHtyRnXK_839S_yY3VT9epjTrPaOiX1zhOesqSAdQjj1j1Q/viewform?usp=send_form" target="_blank" className="btn main-btn type-form h-auto">面談希望フォーム</a>
        </Col>
      </Row>
      <div className="ruleBox">
        <div className="d-flex justify-content-between point--box">
          <div className="box1">
            <div className="head-box d-flex">
              <img src={process.env.PUBLIC_URL + '/committee_pic/check.svg'} alt="" />
              <h4>産業医業務のご紹介</h4>
            </div>
            <ul className="dot-list type-list">
              <li>Zenken株式会社では、嘱託産業医として内田香織先生に毎月１回、１時間の職場訪問をお願いしています。</li>
              <li>社員の健康管理や、安全に働ける環境の保持増進について医師の立場からサポートいただいています。</li>
            </ul>
          </div>
          <div className="box2">
            <div className="discription row">
              <img src={process.env.PUBLIC_URL + '/committee_pic/heart.svg'} alt="" className="col-sm-2" />
              <h4 className="col-sm-10">産業医の先生のご紹介</h4>
            </div>
            <div className="discription row">
              <div className="item col-sm-1">
                お名前
              </div>
              <div className="col-sm-11">
                内田 香織先生
              </div>
            </div>
            <div className="discription row">
              <div className="item col-sm-1">
                先生のご紹介
              </div>
              <div className="col-sm-11">
                弊社で長年産業医として数多くのアドバイスをいただいてきました。お優しく、話しをしやすい先生です。英語での面談も可能です。
              </div>
            </div>
          </div>
        </div>
      </div>

      <h4 className="bg-title">
        産業医の業務内容
      </h4>
      <div className="numbering-wrap type-boder">
        <div className="numbering-title">
          衛生委員会への出席
        </div>
        <div className="">
          当社では、毎月1回、衛生委員会を開催しています。 <br />産業医の先生にも出席いただき、産業医学の専門的な立場から、意見を述べていただいております。
        </div>

        <div className="numbering-title">
          健診結果のチェック
        </div>
        <div className="">
          健診結果の確認・必要であれば受診勧奨・面接指導を行います。<br />就業状況も確認したうえで、就業の可否や必要な措置の有無について判定、意見を述べていただいております。
        </div>

        <div className="numbering-title">
          職場巡視
        </div>
        <div className="">
          災害時の非難経路の安全性や作業環境の整備などといった観点からオフィス内を巡視し、<br />危険な箇所はないか、衛生面・作業環境に問題ないかを確認いただきます。
        </div>

        <div className="numbering-title">
          ストレスチェックの実施・高ストレス者への直接指導
        </div>
        <div className="">
          ストレスチェックの実施者になっていただきます。<br />また、分析結果についてご意見をいただいたり、高ストレス者のうち、面談を希望する従業員に対して面接指導を行ったりしていただきます。
        </div>

        <div className="numbering-title">
          面談・面接指導
        </div>
        <ul className="dot-list type-list">
          <li>
            <div className="item">
              健康相談
            </div>
            健診結果や、日常生活のなかで気になる心身の状態について相談対応いただきます。
          </li>
          <li>
            <div className="item">
              過重労働者面談
            </div>
            時間外労働が月に80時間を超える従業員で面談を希望する方に対して、面接指導を行っていただきます。
            必要に応じて、会社側に意見を述べていただきます。
          </li>
          <li>
            <div className="item">
              産業医意見書
            </div>
            上記の結果、何らかの対応が必要な場合、休業判定を行っていただきます。<br />
            休業後の復職判定、復職後のフォロー面談などで、従業員を継続的にサポートしていただきます。
          </li>
        </ul>
        <div className="numbering-title">
          健康教育
        </div>
        <div className="">
          メンタルヘルスや季節に応じたテーマで講話いただき、従業員への健康教育を行っていただきます。
        </div>
      </div>

      <h4 id="chapter07" className="bg-title">
        健康情報の取扱い
      </h4>
      <p>心身の健康状態に関する情報は「機微な個人情報」に該当します。また、産業医は法律により守秘義務が課せられているため、皆さんの個人情報については、会社に対しても正当な理由なしに漏らすことはありません。</p>
      <p>一方で、何らかの対応が必要な場合には、安全配慮義務の観点から、会社に対して報告していただくこともございます。ただし、その際には、事前に本人の同意を取得いただきますので、ご安心ください。</p>
      <p>※　なお、現状を放置していれば悪化することが明らかな場合、医師倫理の立場より、本人の同意なしに報告していただくこともございます。</p>

      <div className="joining text-center">
        <p>内田先生には、毎月第４金曜日の13時～14時に来社いただいております。<br />面談を希望される方は、以下フォームよりご連絡ください。<br />※別日時での調整をさせていただくこともございますので、予めご了承ください。</p>
        <Row className="btn-wrap justify-content-center">
          <Col sm={6}>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLScbHtyRnXK_839S_yY3VT9epjTrPaOiX1zhOesqSAdQjj1j1Q/viewform?usp=send_form" target="_blank" className="btn main-btn type-external">
              面談希望フォーム
            </a>
          </Col>
        </Row>
      </div>



    </div >
  );
}

export default Contents2;
