import React, { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function Contents3() {
  useEffect(() => {

  }, []);

  return (
    <>
      <div className="equipment_using">
        <h2 className="tag-title">トラブルが発生したら</h2>
        <h3 className='icon-title type-pencil'>トラブル対応手順　一覧</h3>
        <Container>
          <Row>
            <Col sm={4}>
              <a href="https://docs.google.com/document/d/1MyYCwBT1TvGthEPa0FlAmZhvgHSVzJ-rT5-1W7Dskug/" target="_blank" className="btn main-btn type-external">PCにトラブルが発生したら</a>
            </Col>
            <Col sm={4}>
              <a href="https://docs.google.com/document/d/1mVec38cuViltju3U4vV74YFoA-jUJSyww0j55LNDaec/" target="_blank" className="btn main-btn type-external">紛失・破損・セキュリティで<br />トラブルが発生したら</a>
            </Col>
            <Col sm={4}>
              <a href="https://docs.google.com/document/d/1SotQu1GSqDy-NVgd8YJ_VHLg5FNMmjEon9DYHGjKWVo/" target="_blank" className="btn main-btn type-external">テレワークで<br />トラブルが発生したら</a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Contents3;