// @ts-nocheck
import React, { useEffect, useState } from "react";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet-async';

// フッター
import Footer from '../component/page_parts/footer';

// CSSの読み込み
import "../css/style.min.css";
import "../css/App.css";

function Login() {
  //トークン保存用
  const [cookies, setCookie, removeCookie] = useCookies(['sessionToken']);
  const onSuccess = (response: any) => {
    /* ログイン成功時の処理*/
    login(response);
  };

  const [errorMessages, setErrorMessages] = useState("");

  //テスト環境
  const baseUrl = process.env.REACT_APP_PUBLIC_URL || '';

  useEffect(() => {
    //ローカルストレージ「urlList」の削除
    localStorage.removeItem("urlList");
  }, []);

  async function login(res) {
    //ログインAPIにトークンを送信
    const response = await fetch(process.env.REACT_APP_API_PATH + 'user/login.php?token=' + res.credential);
    const data = await response.json();
    //ログイン成功
    if (data.status === "error") {
      //エラー処理
      //バリデーション表示とか？
      setErrorMessages("ログインに失敗しました")
    } else {
      //デコード
      const decoded = jwt_decode<{ [name: string]: string }>(data);

      //トークンをcookieに保存
      setCookie("sessionToken", data, {
        path: '/',
        maxAge: 36000, // 10時間
      });
      setCookie("userId", decoded.data.USER_ID);
      setCookie("email", decoded.data.EMAIL);
      setCookie("buildingSlug", decoded.data.BUILDING_SLUG);

      //ログイン後に遷移するページのURLを取得
      var url = localStorage.getItem('loginAfterJump');
      if (url !== null) {
        //ログイン後に遷移するページのURLがある場合
        //ローカルストレージから削除
        localStorage.removeItem('loginAfterJump');
        //ログイン後に遷移するページに遷移
        window.location.href = url;
      } else {
        // //topページに遷移
        // window.location.href = "/dev";

        //topページに遷移
        window.location.href = baseUrl + "/";
      }

    }
  }

  return (
    <>
      <Helmet>
        <title>Z-link ログイン</title>
      </Helmet>
      <div className="theme-login">
        <div className="contents">
          <div className="corporate-wrap">
            <div className="purpose-area">
              <div className="purpose-text">PURPOSE</div>
              <span>そこにない未来を創る</span>
              <div className="purpose-link"><a href="https://www.zenken.co.jp/our-goal/purpose/" target="_blank">詳しく見る</a></div>
            </div>
            <div className="vision-area">
              <div className="vision-text">VISION</div>
              <span>持続可能な社会の創出</span>
              <div className="vision-link"><a href="https://www.zenken.co.jp/our-goal/vision/" target="_blank">詳しく見る</a></div>
            </div>
          </div>
          <div className="login-wrap">
            <img
              src={process.env.PUBLIC_URL + '/logo_positive.svg'}
              alt="Z-LINK"
              className="Z-LINK"
            />
            <h2>LOGIN</h2>
            <div className="login-btn">
              <GoogleOAuthProvider
                clientId={process.env.REACT_APP_CLIENT_ID}
              >
                <GoogleLogin
                  onSuccess={onSuccess}
                  size="large"

                />
              </GoogleOAuthProvider>
              {
                errorMessages !== "" ?
                  <div className="errorMessages">
                    {errorMessages}
                  </div>
                  :
                  <></>
              }
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
export default Login;
