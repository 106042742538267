// @ts-nocheck
//Smooth Scroll
import { Link } from 'react-scroll';

// レイアウト
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// モーダル
import { useState } from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";


function F_delivery() {

  // モーダル
  const [showDelivery1, setShowDelivery1] = useState(false);
  const [showDelivery2, setShowDelivery2] = useState(false);

  return (
    <div className="facility-lower">
      <h2 className="tag-title">郵便・宅急便</h2>
      <Row className="my-4" as='dl'>
        <Col sm={3} as='dt' className='toc-btn-title'>
          郵便について
        </Col>
        <Col sm={9} as='dd' className='mb-0'>
          <Row className='toc-btnArea'>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter01"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                郵便の出し方
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter02"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                郵便は<br />どこに届くのか
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter03"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                切手・レターパック<br />について
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="my-4" as='dl'>
        <Col sm={3} as='dt' className='toc-btn-title'>
          宅急便について
        </Col>
        <Col sm={9} as='dd' className='mb-0'>
          <Row className='toc-btnArea'>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter04"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                封筒・段ボール<br />について
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter05"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                宅急便の送付方法<br />について
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter06"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                宅急便の集荷場所<br />について
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter07"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                荷物の大きさ、<br />重さの制限について
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter08"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                ヤマト運輸の集荷<br />について
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="my-4" as='dl'>
        <Col sm={3} as='dt' className='toc-btn-title'>
          社内便について
        </Col>
        <Col sm={9} as='dd' className='mb-0'>
          <Row className='toc-btnArea'>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter09"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                社内便について
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>

      <section className="sec-delivery">
        <h3 id="chapter01" className='icon-title type-pencil'>郵便の出し方</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>社内では郵便物の集荷はありません。オフィス近隣の郵便局やポストをご利用ください。
          </p>
        </div>
        <div className="img-wrap ct">
          <div className="img-title">※本社近くの郵便局<br></br>新宿アイタウン郵便局　【9：00～17：00】<br></br>東京都新宿区西新宿6丁目21-1</div>
          <div className="flame">
            <img
              src={process.env.PUBLIC_URL + '/facility_pic/delivery01.jpg'}
              alt="郵便の出し方"
              className="img-wrap__img"
            />
          </div>
        </div>
        <h3 id="chapter02" className='icon-title type-pencil'>郵便はどこに届くのか</h3>
        <div className="border-box">
          <p className="todo-catch"><span>POINT</span>各オフィスビルの郵便ポストに13時頃届きます。<br />仕分けをして、14時頃に配布をしております。</p>
        </div>
        <h3 id="chapter03" className='icon-title type-pencil'>切手・レターパックについて</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>切手は総務課が管理しています。<br />切手出庫依頼書を記入し、上長検印後、総務課に提出してください。</p>
        </div>
        <h3 id="chapter04" className='icon-title type-pencil'>封筒・段ボールについて</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>封筒は各事業部に在庫があります。<br />足りない場合は、発注しますので総務までご相談ください。<br />段ボールは総務にご相談ください。
          </p>
        </div>
        <h3 id="chapter05" className='icon-title type-pencil'>宅急便の送付方法について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>本社では佐川急便を利用しています。<br />伝票を荷物に貼り、平日17時までに集荷場所へ置いてください。<br />伝票は部署毎に異なりますので、各担当者にご確認ください。</p>
        </div>
        <div className="img-wrap ct">
          <div className="flame">
            {/* <img
              src={process.env.PUBLIC_URL + '/facility_pic/delivery04.jpg'}
              alt="郵便の出し方"
              className="img-wrap__img"
            /> */}
            <iframe src="https://docs.google.com/spreadsheets/d/1MpVyLH5ssfRbU5hHrUtadJGXwnZKhSZL/preview#gid=1852395799" width="1000" height="500" allow="autoplay"></iframe>
          </div>
        </div>
        <h3 id="chapter06" className='icon-title type-pencil'>宅急便の集荷場所について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>宅急便の集荷場所は下記地図をご確認ください。</p>
        </div>
        <div className="img-wrap ct type-map">
          <div className="img-title">※本社18階</div>
          <Button
            variant="modal"
            onClick={() => setShowDelivery1(true)}
          >
            <iframe src="https://drive.google.com/file/d/1V5l-I64uPXqjCDElkiHEGF6XzfCa32Df/preview" width="1000" height="500" allow="autoplay"></iframe>
          </Button>
          <Modal
            show={showDelivery1}
            onHide={() => setShowDelivery1(false)}
            className="facility-modal"
            size="lg"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <iframe src="https://drive.google.com/file/d/1V5l-I64uPXqjCDElkiHEGF6XzfCa32Df/preview" width="1000" height="500" allow="autoplay"></iframe>
            </Modal.Body>
          </Modal>
        </div>
        <div className="img-wrap ct type-map">
          <div className="img-title">※本社19階</div>
          <Button
            variant="modal"
            onClick={() => setShowDelivery2(true)}
          >
            <iframe src="https://drive.google.com/file/d/1XVhGoUls2SsSYJ9pNE6rFrxEDD3gS3SU/preview" width="1000" height="500" allow="autoplay"></iframe>
          </Button>
          <Modal
            show={showDelivery2}
            onHide={() => setShowDelivery2(false)}
            className="facility-modal"
            size="lg"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <iframe src="https://drive.google.com/file/d/1XVhGoUls2SsSYJ9pNE6rFrxEDD3gS3SU/preview" width="1000" height="500" allow="autoplay"></iframe>
            </Modal.Body>
          </Modal>
        </div>

        <h3 id="chapter07" className='icon-title type-pencil'>荷物の大きさ、重さの制限について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>直接、佐川急便へお問い合わせ頂くか、<br />毎日集荷にいらっしゃるドライバーさんへご確認ください。<br></br>セントラルパークタワー管轄営業所 佐川急便株式会社 城西営業所 TEL:0570-010-364 </p>
        </div>
        <h3 id="chapter08" className='icon-title type-pencil'>ヤマト運輸の集荷について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>ヤマト運輸の集荷はありません。 当社では、コスト面から佐川急便を推奨しております。<br></br> 返信用にヤマトの伝票を頂いた場合は、伝票にある電話番号に直接お問い合わせください。<br></br>セントラルパークタワー管轄営業所 佐川急便株式会社 城西営業所 TEL:0570-010-364 </p>
          <p className="todo-catch">※サービスご利用の流れ</p>
          <div className="l-number-wrapper">
            <ol>
              <li>サービスセンター（固定電話の方：0120-01-9625、携帯電話の方：0570-200-000）にお電話ください。</li>
              <li>自動音声ガイダンスに従って、 ［2：お電話をご希望］ → ［1：集荷のご依頼］をご選択ください。</li>
              <li>AIオペレータに繋がります。ご希望の集荷場所や時間をお伝えください。<br />※○○でよろしいでしょうか？とAIがお聞きした際には、「はい」か「いいえ」でお答えください。</li>
            </ol>
          </div>
        </div>
        <h3 id="chapter09" className='icon-title type-pencil'>社内便について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>本社⇔大阪オフィス、本社⇔名古屋オフィスについては、<br />「社内便」として書類等を取りまとめて宅急便で送付しています。 <br></br>送付物が発生したタイミングで、発送していますので利用したい場合には担当者までご連絡下さい。</p>
          <p className="todo-catch"><span>本社</span>リンゲージ小川さん</p>
          <p className="todo-catch"><span>大阪オフィス</span>リンゲージ三浦さん</p>
          <p className="todo-catch"><span>名古屋オフィス</span>リンゲージ福村さん</p>
        </div>
      </section>
    </div>

  );
}

export default F_delivery;

