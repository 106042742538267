// @ts-nocheck
//Smooth Scroll
import { Link } from 'react-scroll';

// レイアウト
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function F_telex() {

  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  return (
    <div className="facility-lower">
      <h2 className='tag-title'>電話・内線</h2>
      <Row className="toc-btnArea">
        <Col sm={4} className='toc-btn'>
          <Link
            to="chapter01"
            smooth={true}
            duration={500}
            offset={-100}
            className='btn main-btn type-internal w-100'
          >
            内線電話の使い方について
          </Link>
        </Col>
        <Col sm={4} className='toc-btn'>
          <Link
            to="chapter02"
            smooth={true}
            duration={500}
            offset={-100}
            className='btn main-btn type-internal w-100'
          >
            内線番号/携帯番号の調べ方について
          </Link>
        </Col>
        <Col sm={4} className='toc-btn'>
          <Link
            to="chapter03"
            smooth={true}
            duration={500}
            offset={-100}
            className='btn main-btn type-internal w-100'
          >
            電話機の追加方法について
          </Link>
        </Col>
      </Row>
      <section className="sec-telex">
        <h3 id="chapter01" className='icon-title type-pencil'>内線電話の使い方について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>※セントラルパークタワー<br />音量の調節は、通話中に固定電話の十字キー（上下）で操作を行ってください。<br />その他の設定は下記操作方法をご確認ください。</p>
        </div>
        <div className="img-wrap ct">
          <div className="flame">
            <img
              src={process.env.PUBLIC_URL + '/facility_pic/telex01.jpg'}
              alt="内線電話の使い方について"
              className="img-wrap__img"
            />
          </div>
          <div className="flame">
            <img
              src={process.env.PUBLIC_URL + '/facility_pic/telex02.jpg'}
              alt="内線電話の使い方について"
              className="img-wrap__img"
            />
          </div>
        </div>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>※セントラルパークタワー以外<br />固定電話の種類により異なりますので対象機種をWeb検索してください。<br />それでもご不明な場合は、総務課へご連絡ください。</p>
        </div>

        <h3 id="chapter02" className='icon-title type-pencil'>内線番号/携帯番号の調べ方について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span><a href={baseUrl + "/employee_list"}>従業員名簿</a>ページにてご確認ください。</p>
        </div>

        <h3 id="chapter03" className='icon-title type-pencil'>電話機の追加方法について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span></p>
          <div className="l-number-wrapper">
            <ol>
              <li>設置理由</li>
              <li>設置場所</li>
              <li>台数</li>
            </ol>
            <div className="box-list">
              <ul>
                <li>上記情報を総務課へお知らせください。</li>
                <li>電話機在庫及び設置場所確認のうえ、必要に応じて工事見積もりを取得しますので稟議起案をお願いします。</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>

  );
}

export default F_telex;

