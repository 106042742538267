// @ts-nocheck
import { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet-async';

// ヘッダー
import Header from '../component/page_parts/header';

// フッター
import Footer from '../component/page_parts/footer';

//json読み込み
import nameJson from '../files/sample_department.json';
import divisionJson from '../files/division.json';


import { useLocation } from "react-router-dom";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// モーダル
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// パンくずリスト
import TableOfContents from "../component/page_parts/common/table_of_contents";

// 事業部一覧
function Division() {

  /* 認証に必要 なstate */
  // Cookieから取得したトークンを保持
  const [cookies, setCookie, removeCookie] = useCookies(['sessionToken']);
  // トークンをデコードして取得したjtiを保持（パラメータに付与する用）
  const [cookieJti, setCookieJti] = useState();

  const [divisionData, setDivisionData] = useState(divisionJson);
  const [displayFlg, setDisplayFlg] = useState(true);

  // WP RESTAPI 環境別ベースURL
  const urlBase = process.env.REACT_APP_WP_REST_API_PATH_MEDIA;

  //restAPI取得
  const [posts, setPosts] = useState([]);

  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  // 事業部ブログ切り替え用パラメーター
  const [blogFromDivision, setblogFromDivision] = useState("2");

  // 動画ボタン 1段目
  const videoButtonListOne = [
    {
      title: "e-マーケティング事業本部",
      movieUrl: `${process.env.PUBLIC_URL}/division_mov/division_zed_mv_em.mp4`,
    },
    {
      title: "HRインキュベーション事業本部",
      movieUrl: `${process.env.PUBLIC_URL}/division_mov/division_zed_mv_hr.mp4`,
    },
    {
      title: "グローバル教育事業本部",
      movieUrl: `${process.env.PUBLIC_URL}/division_mov/division_zed_mv_gc.mp4`,
    },
  ];

  // 動画ボタン 2段目
  const videoButtonListTwo = [
    {
      title: "沖縄・GNT・HC・MC事業本部",
      movieUrl: `${process.env.PUBLIC_URL}/division_mov/division_1_minute_mv_okinawa_mc_hc_gnt.mp4`,
    },
    {
      title: "SSW・DS事業部",
      movieUrl: `${process.env.PUBLIC_URL}/division_mov/division_1_minute_mv_ssw_ds.mp4`,
    },
    {
      title: "リンゲージ事業部",
      movieUrl: `${process.env.PUBLIC_URL}/division_mov/division_1_minute_mv_linkage.mp4`,
    },
  ];

  //モーダル用のstate
  const [show, setShow] = useState(false);
  const [modalMovieUrl, setModalMovieUrl] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // モーダルの表示
  const showModal = (modalMovieUrl, currentId) => {
    // モーダルの中の動画のURLを変更する
    setModalMovieUrl(modalMovieUrl);
    setShow(true);
  };

  // ログイン時に生成されたトークンをデコードしたデータをstateに格納
  useEffect(() => {
    if (cookieJti === undefined) {
      SettingLonginData();
    }
  }, []);

  /* ログイン時に生成されたトークンをデコードしたデータをstateに保持 */
  function SettingLonginData() {
    // Cookieからトークンを取得
    const token = cookies.sessionToken;
    // デコード
    const decodedToken = jwt_decode<{ [name: string]: string }>(token);

    /* パラメータ（zenken）にセットしてリクエストするためのjtiの値を取得してstateにセット
    →WP側で値の確認を行う（確認できないリクエストには401を返すようにする）*/
    setCookieJti(decodedToken.jti);
  }

  useEffect(() => {

    var wpRestUrl = urlBase + 'wp-json/wp/v2/posts?per_page=100&zenken=';

    if (cookieJti !== undefined) {
      const fetchUrl = wpRestUrl + cookieJti;
      fetch(fetchUrl, { method: 'GET' })
        .then(res => res.json())
        .then(data => {
          setPosts(data)
        })
    }
  }, [cookieJti])


  //データの受け渡しテスト
  const location = useLocation();

  const [LocationTest, setLocationTest] = useState();

  useEffect(() => {
    if (location.state !== null) {
      const point = document.querySelector(".tab-pane");
      const point2 = document.querySelector(".list-group-item");
      point.classList.remove("active");
      point2.classList.remove("active");
      const { data1 } = location.state as State;
      setLocationTest(data1)
      window.scrollTo({ top: 0 })
    }
  }, [])

  // active切り替え処理
  const selectTab = (e) => {

    //押されたボタンと同じ階層の要素を全て取得
    const elements1 = e.target.parentNode.children;
    //全ての要素からactiveクラスを削除
    Array.prototype.forEach.call(elements1, function (element1) {
      element1.classList.remove("active");
    });
    //押されたボタンにactiveクラスを追加
    e.target.classList.add("active");

    /**
     * 以下タブ内切り替え処理
     */
    //押されたボタンの親要素の兄弟要素を全て取得
    const elements2 = e.target.parentNode.parentNode.children;
    //兄弟要素である「viewChangeArea」を全て非表示にする
    Array.prototype.forEach.call(elements2, function (element2) {
      if (element2.classList.contains("viewChangeArea")) {
        element2.style.display = "none";
      }
    });
    //"tabs_" +e.target.valueをIDに持つ要素を表示する
    document.getElementById("tabs_" + e.target.value).style.display = "block";

    /**
     * サービスの紹介の表示切り替え処理
     */
    //押されたボタンの親要素の兄弟要素を全て取得
    const elements3 = e.target.parentNode.parentNode.children;

    //兄弟要素から「media-box」を取得する
    Array.prototype.forEach.call(elements3, function (element3) {
      if (element3.classList.contains("media-box")) {

        var count = 0;
        //media-box以下の要素のクラスとvalueが一致する要素を取得
        for (let i = 0; i < element3.children.length; i++) {
          if (element3.children[i].classList.contains(e.target.value)) {
            //一致する要素を表示する
            element3.children[i].style.display = "block";
          } else {
            //一致しない要素を非表示にする
            element3.children[i].style.display = "none";
            count++;
          }
        }
        //media-box以下の要素数と一致する要素数が同じ場合は「サービス紹介」を非表示にする
        if (element3.children.length === count) {
          //element3の要素以下にある「serviceTitle」を非表示にする
          element3.parentNode.querySelector(".serviceTitle").style.display = "none";
        } else {
          //element3の要素以下にある「serviceTitle」を表示する
          element3.parentNode.querySelector(".serviceTitle").style.display = "block";
        }
      }
    });

  }

  function changeMap(e) {

    let elements = document.getElementsByClassName("tab-pane");
    Array.prototype.forEach.call(elements, function (element) {
      element.classList.remove("active");
    });

    document.getElementById(e.target.value).classList.add("active");

    //サイドバーのボタンを押した時の処理
    //押されたボタンのvalueと同じIDを持つ要素を取得
    const point = document.getElementById(e.target.value);
    //取得した要素内子要素のmedia-box以下の要素数を取得
    const point2 = point.querySelector(".media-box").children.length;
    //0以下の場合は「サービス紹介」を表示しない
    if (point2 <= 0) {
      //pointの要素以下にある「serviceTitle」を非表示にする
      point.querySelector(".serviceTitle").style.display = "none";
    }

    var selectCategory = e.target.value;

    //valueにより処理を分岐
    switch (e.target.value) {
      case "keieikikaku":
        selectCategory = "keieikikakuka";
        break;
      case "soumubu":
        selectCategory = "soumu";
        break;
      case "global":
        selectCategory = "coop";
        break;
      case "global_career":
        selectCategory = "ryugaku";
        break;
      case "ds":
        selectCategory = "city";
        break;
      default:
        break;
    }

    // 事業部ブログ切り替え用パラメーターのセット処理
    switch (e.target.value) {
      case "jinzai":
        setblogFromDivision("1");
        break;
      case "gnt":
      case "hc":
      case "mc":
      case "okinawa":
        setblogFromDivision("2");
        break;
      case "ds":
      case "hrbp":
        setblogFromDivision("3");
        break;
      case "global_career":
      case "linkage":
        setblogFromDivision("4");
        break;
      case "soumubu":
      case "keiri":
      case "keieikikaku":
        setblogFromDivision("5");
        break;
      default:
        break;
    }

    //取得した要素内子要素のmedia-box以下の要素を取得
    const point3 = point.querySelector(".media-box").children;

    //押されたボタンと同じvalueのクラスを持つ要素を取得
    for (let i = 0; i < point3.length; i++) {

      if (point3[i].classList.contains(selectCategory)) {
        //一致する要素を表示する
        point3[i].style.display = "block";
      } else {
        //一致しない要素を非表示にする
        point3[i].style.display = "none";
      }

    }

    // ページ上部にスクロール
    window.scroll({ top: 0, behavior: 'smooth' })
  }

  return (
    <>
      <Helmet>
        <title>Z-link 事業部一覧</title>
      </Helmet>
      <Header />
      <div className="theme-division">
        <div className="content-header">
          <div className="container">
            <Row>
              <Col sm={12}>
                <h1 className='page-title type-sitemap'>事業部一覧</h1>{/* ページタイトル */}
                <p className="page-description">各事業部の「事業・業務内容」をご紹介します。場所は『MAP』をご覧ください。<br />2024年全納会動画は、下記より振り返り頂けます。</p>{/* ページの説明 */}
              </Col>
            </Row>
            <Row>
              <div className="d-flex flex-wrap mx-auto movie-btn-wrap first">
                <div className='document-leadArea first'>
                  <p className='document-leadText'>ZEDダイジェスト<br /><small>(資料は<a href="https://drive.google.com/drive/folders/1GGDwqc5bwwCjUDIXin9ULOhKD_Tv4Zfv" target="_blank" rel="noopener noreferrer" className='text-link type-external'>こちら</a>)</small></p>
                </div>
                {videoButtonListOne.map((movie, index) => (
                  <Button
                    id={"movie-btn-" + (index + 1)}
                    className='btn main-btn type-movie btn btn-primary'
                    key={index}
                    variant="primary"
                    onClick={(e) => showModal(movie.movieUrl, e.currentTarget.id)}
                    dangerouslySetInnerHTML={{
                      __html: movie.title,
                    }}
                  >
                  </Button>
                ))}
              </div>
              <Modal
                show={show}
                onHide={handleClose}
                className="modal-lg evaluation-modal"
                size="lg"
                centered
              >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <video
                    className="evaluation-modal__video"
                    preload="none"
                    autoPlay
                    controls
                    loop
                    muted
                    playsInline
                    poster="#"
                  >
                    <source src={modalMovieUrl} type="video/mp4" />
                  </video>
                </Modal.Body>
              </Modal>
            </Row>
            <Row>
              <div className="d-flex flex-wrap mx-auto movie-btn-wrap second">
                <div className='document-leadArea second'>
                  <p className='document-leadText'>事業部1分動画</p>
                </div>
                {videoButtonListTwo.map((movie, index) => (
                  <Button
                    id={"movie-btn-" + (index + 1)}
                    className='btn main-btn type-movie btn btn-primary'
                    key={index}
                    variant="primary"
                    onClick={(e) => showModal(movie.movieUrl, e.currentTarget.id)}
                    dangerouslySetInnerHTML={{
                      __html: movie.title,
                    }}
                  >
                  </Button>
                ))}
              </div>
              <Modal
                show={show}
                onHide={handleClose}
                className="modal-lg evaluation-modal"
                size="lg"
                centered
              >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <video
                    className="evaluation-modal__video"
                    preload="none"
                    autoPlay
                    controls
                    loop
                    muted
                    playsInline
                    poster="#"
                  >
                    <source src={modalMovieUrl} type="video/mp4" />
                  </video>
                </Modal.Body>
              </Modal>

            </Row>
          </div>
        </div >
        < TableOfContents />
        <div className="container division-area">
          <div className="row">
            {/* 左側メニュー */}
            <div className='col-left'>
              <div className='list-group nav nav-tabs' id='myTab' role='tablist'>
                {divisionData.map((data1) => (
                  <div className="group-box">
                    <div className="depName">{data1.name}</div>
                    {data1.department.map((data2) => (
                      <div>
                        <button
                          type='button'
                          className={'division-name list-group-item list-group-item-action nav-link ' + data2.state}
                          aria-current='true'
                          id={data2.id}
                          data-bs-toggle='tab'
                          data-bs-target={'#' + data2.slug}
                          role='tab'
                          aria-controls={data2.slug}
                          aria-selected='true'
                          value={data2.slug}
                          onClick={(e) => { changeMap(e); }}
                        >
                          {data2.name}
                        </button>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>

            {/* 右側コンテンツ */}
            <div className='col-sm-9 division-box-area division division-01'>
              <div>
                <div className='tab-content tab-content-wrap'>
                  {divisionData.map((data1) => (
                    <div className="tab-content tab-content-inner">
                      {data1.department.map((data2, index) => (
                        <div
                          className={'tab-pane ' + data2.state + (LocationTest === data2.slug ? "active" : "")}
                          id={data2.slug}
                          role='tabpanel'
                          aria-labelledby='home-tab'
                        >
                          <div className='division'>
                            <h2 className='tag-title mt-0 mb-4'><span className='name2'>{data2.name}</span></h2>

                            <div className='division-body'>
                              <p>{data2.explanation}</p>
                              <div className='link'>
                                <a href={data2.desk} className={"btn main-btn content" + ("okinawa" === data2.slug || "global_career" === data2.slug ? " none-btn" : "")} target="_blank">座席表</a>
                                <a href={baseUrl + '/deptBlog?blogFromDivision=' + blogFromDivision} className={"btn main-btn content type-external blog-lead page-departmentBlog" + ("naibukansa" === data2.slug ? " none-btn" : "")}>事業部ブログ</a>
                              </div>
                              {

                                (data2.department).length != 0 ? (
                                  <>
                                    {/*部の直下に細かく課が設定されている場合*/}
                                    {/*ボタンエリア */}

                                    <div className="button-area">
                                      {data2.department.map((data2, i) => (
                                        <button
                                          type='button'
                                          className={i == 0 ? data2.department_button_value + ' active' : data2.department_button_value}
                                          value={data2.department_value}
                                          onClick={(e) => { selectTab(e); }}
                                        >
                                          <span className=''>{data2.department_name}</span>
                                          <span className='' dangerouslySetInnerHTML={{ __html: data2.floor }}></span>
                                        </button>
                                      ))}
                                    </div>
                                    {
                                      /*ボタン以下各コンテンツ内部 */
                                      data2.department.map((data1, i) => (
                                        <div
                                          className={"viewChangeArea" + (i == 0 ? " active" : "")}
                                          id={"tabs_" + data1.department_value}
                                          style={
                                            i == 0 ? { display: "block" } : { display: "none" }}>
                                          <div className="icon-title type-pencil mt-small">
                                            {data1.department_name}
                                            <span className="bee1" dangerouslySetInnerHTML={{ __html: data1.floor }}></span>
                                          </div>
                                          <p className={"departmentDescription"}>
                                            {data1.department_text}
                                          </p>
                                          {
                                            data1.img_value != "" ? (
                                              <img src={"division_pic/" + 'position_' + data1.img_value + '.jpg'} alt="マップ画像" />
                                            ) : (<></>)
                                          }
                                          {
                                            //留学課の場合の処理
                                            data1.department_value === "ryugaku" ? (
                                              <p>地図は<a href="https://www.global-study.jp/about/company.html" target="_blank" rel="noopener noreferrer" className="text-link type-external">こちら</a>よりご確認ください。</p>
                                            ) :
                                              (<></>)
                                          }
                                          {
                                            //日本語課の場合の処理
                                            data1.department_value === "nihongo" ? (
                                              <p>地図は<a href="https://www.linguage.jp/japanese-school/about/access.html" target="_blank" rel="noopener noreferrer" className="text-link type-external">こちら</a>よりご確認ください。</p>
                                            ) :
                                              (<></>)
                                          }

                                        </div>
                                      ))
                                    }
                                  </>
                                ) : (
                                  <>
                                    {/*部の直下に細かく課が設定されていない場合*/}
                                    {
                                      data2.img_value != "" ? (
                                        <img src={"division_pic/" + 'position_' + data2.img_value + '.jpg'} alt="マップ画像" />
                                      ) : (<></>)
                                    }

                                    {
                                      //沖縄の場合の処理
                                      data2.slug === "okinawa" ?
                                        (
                                          <p>地図は<a href="https://www.zenken.co.jp/company/access/#accessTarget_wrap4" target="_blank" rel="noopener noreferrer" className="text-link type-external">こちら</a>よりご確認ください。</p>
                                        ) :
                                        (<></>)
                                    }
                                  </>)
                              }



                              {
                                posts.length !== 0 && displayFlg === true
                                  ? (
                                    <>
                                      <div className="bg-title text media-on active serviceTitle">サービス紹介</div>
                                      <div className="media-box">
                                        {posts.map((json, i) => (
                                          json.media_department.name === data2.name || json.media_department.second_name === data2.name ? (

                                            <div className={'card media-list ' + json.media_department.slug + (json.media_department.slug != "gnt" ? " none" : "")}>

                                              <a href={json.acf.media_url} target="_blank" rel="noopener noreferrer">
                                                <div className="media-head">
                                                  <div className="media-cap">
                                                    <img
                                                      src={json.acf.media_img.capture}
                                                      alt='キャプチャ画像'
                                                    />
                                                  </div>
                                                  <div className="media-right">
                                                    <div className="media-logo">
                                                      <img
                                                        src={json.acf.media_img.logo}
                                                        alt='ロゴ画像'
                                                      />
                                                    </div>
                                                    <div className="media-title">{json.title.rendered}</div>
                                                  </div>
                                                </div>

                                                <div className="media-text">{json.acf.media_description}</div>
                                              </a>
                                              <div className="media-sns">
                                                <ul>
                                                  {/* twitter */}
                                                  {
                                                    json.acf.media_sns.twitter !== "" ? (
                                                      <li><a target="_blank" href={json.acf.media_sns.twitter}><img src="./twitter.svg" alt="Twitter画像" /></a></li>
                                                    ) : (
                                                      <></>
                                                    )
                                                  }
                                                  {/* instagram */}
                                                  {
                                                    json.acf.media_sns.instagram !== "" ? (
                                                      <li><a target="_blank" href={json.acf.media_sns.instagram}><img src="./instagram.svg" alt="Instagram画像" /></a></li>
                                                    ) : (
                                                      <></>
                                                    )
                                                  }
                                                  {/* facebook */}
                                                  {
                                                    json.acf.media_sns.facebook !== "" ? (
                                                      <li><a target="_blank" href={json.acf.media_sns.facebook}><img src="./facebook.svg" alt="facebook画像" /></a></li>
                                                    ) : (
                                                      <></>
                                                    )
                                                  }
                                                  {/* youtube */}
                                                  {
                                                    json.acf.media_sns.youtube !== "" ? (
                                                      <li><a target="_blank" href={json.acf.media_sns.youtube}><img src="./yutube.svg" alt="youtube画像" /></a></li>
                                                    ) : (
                                                      <></>
                                                    )
                                                  }
                                                </ul>
                                              </div>
                                              {json.acf.media_person_in_charge !== "" &&
                                                <p className="media-tantou">
                                                  サービス担当者　{json.acf.media_person_in_charge}
                                                </p>
                                              }
                                            </div>
                                          ) : (
                                            <></>
                                          )
                                        ))}
                                      </div>

                                    </>
                                  ) : (<> </>)
                              }


                            </div >
                          </div >


                        </div >
                      ))
                      }
                    </div >
                  ))}
                </div >
                {/* tab-content */}
              </div >
            </div >
          </div >
        </div >
      </div >

      <Footer />
    </>
  );
}

export default Division;