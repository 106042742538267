import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-scroll';

// 委員会一覧ページ 在宅勤務（リモートワーク）タブ
function Contents1() {

  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  return (
    <div id="committee-content1">
      <h2 className="tag-title">在宅勤務（リモートワーク）</h2>
      <Row className="toc-btnArea">
        <Col sm={4} className='toc-btn'>
          <Link
            to="chapter01"
            smooth={true}
            duration={500}
            offset={-100}
            className='btn main-btn type-internal w-100'
          >
            在宅勤務制度
          </Link>
        </Col>
        <Col sm={4} className='toc-btn'>
          <Link
            to="chapter02"
            smooth={true}
            duration={500}
            offset={-100}
            className='btn main-btn type-internal w-100'
          >
            申請方法・注意点
          </Link>
        </Col>
      </Row>
      <h3 id="chapter01" className='icon-title type-pencil mt-small'>在宅勤務制度</h3>
      <p className='alerm'>※12月に制度変更を予定しています。</p>
      <p>在宅勤務（リモートワーク）は、自宅で業務を行うワークスタイルです。<br />Zenkenでは、2つの在宅勤務の方法を設けています。</p>
      <div className="committee-related">
        <div className="point-list-border no-title mb-3">
          <ul className="dot-list type-list">
            <li>通常リモートワーク：原則在宅勤務、3ヶ月単位の申請</li>
            <li>臨時リモートワーク：原則出社（やむを得ない事情の場合、会社が認めた範囲でリモートOK）、都度の申請</li>
          </ul>
        </div>
        <p>※リモートワークに当たり、特別な手当はありません。<br />※みなし残業代支給の社員は1日のみなし残業時間（1時間）を超える残業はNG。また、残業前に
          ジンジャーにて残業申請を必ず行ってください。みなし残業代が支給されていない社員は残業自体NG。</p>
        <h3 id="chapter02" className='icon-title type-pencil mt-small'>申請方法</h3>
        <div className="point-list-border mb-3">
          <div className="point-title">
            <span>CHECK</span>リモートワーク開始までの流れ
          </div>
          <ol>
            <li>通常リモートワークは、上長（マネジャー以上）との面談※が必須です。
              臨時リモートワークは、上長に事情を相談の上、1日～ 1週間の単位で承諾を得てください。
              <br />※上長面談には部署により一定の基準がありますので、上長にご確認ください。</li>
            <li>申請は2段階。まずは誓約書を兼ねた<a href="https://forms.gle/1GKSbU9jmuQuPA149" className='text-link type-external' target='_brank'>Googleフォーム</a>に必要事項を入力し送信してください。</li>
            <li>登録内容がメールで届いたら、文面をコピー＆ペーストし、<a href="https://rsdesign.rakurakuseisan.jp/_5ZwGywYVPa/" target="_blank" rel="noopener noreferrer" className='text-link type-external'>楽楽精算</a>の人事稟議を申請してください。</li>
            <li>原則承認後、リモートワークへ。</li>
            <li>なお、リモートを継続する場合※は改めて申請を行ってください（通常は3ヶ月毎、臨時は都度）。
              <br />※生産性低下等の上長判断により、継続不可となることもあります。</li>
          </ol>
          <p>必ず下記の規定を確認してから申請してください。</p>
          <Row className="mt-5">
            <Col sm={6}>
              <a
                href="https://sites.google.com/zenken.co.jp/contracts-regulations/regulations/regulations_related_to_personnel_and_labor_affairs/Telecommuting-Regulations?authuser=0"
                target="_blank"
                className="btn main-btn type-external"
              >
                在宅勤務規程
              </a>
            </Col>
          </Row>
        </div>
        <div className="point-list-border mb-3">
          <div className="point-title">
            <span>CHECK</span>リモートワーク出退勤時の必須事項
          </div>
          <ul className="dot-list type-list">
            <li>通常出勤と区別をするために、ジンジャーでの打刻時に、打刻区分で「在宅勤務」に必ずチェックを入れて出勤、退勤打刻を行ってください。</li>
          </ul>
          <p>通常リモート勤務の場合、ジンジャーの打刻区分「在宅勤務」にチェックが入っていない勤務日=出社日=通勤手当支給対象日として通勤手当の計算を行います。<br />通勤手当の誤支給防止のため、勤務状況に合わせて、打刻区分「在宅勤務」のチェックまたはチェックを外す処理をお忘れなくお願いいたします。</p>
          <p>もし「打刻区分を間違えていた！」というときは、打刻修正申請で修正が可能です。<br />下記マニュアルをご参考ください。</p>
          <Row className="mt-5">
            <Col sm={6}>
              <a
                href="https://www.ztree.cfbx.jp/info-wp/wp-content/uploads/2024/03/%E5%9C%A8%E5%AE%85%E5%8B%A4%E5%8B%99%E5%8C%BA%E5%88%86%E9%81%8B%E7%94%A8%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB.pdf"
                target="_blank"
                className="btn main-btn type-external"
              >
                在宅勤務区分運用マニュアル
              </a>
            </Col>
          </Row>
        </div>
        <div className="point-list-border mb-3">
          <div className="point-title">
            <span>CHECK</span>リモートワーク時の通勤費について
          </div>
          <ul className="dot-list type-list">
            <li>通常リモートワーク：実費支給
              （上限1日3,000円）※現時点で1日3,000円超過の社員は変更なし。</li>
            <li>臨時リモートワーク：定期代支給
              （1ケ月の半数以上がリモートワークの場合、定期代の返金を求める場合あり）</li>
          </ul>
        </div>
        <div className="point-list-border mb-3">
          <div className="point-title">
            <span>CHECK</span>更新手続きについて
          </div>
          <p>※通常リモートワークで勤務をされている方は、3ヶ月毎に更新手続きが必要です。
          </p>
          <ul className="dot-list type-list">
            <li>通常リモートワークを申請し、実施している方が対象です。</li>
            <li>更新を申請される方は、原則期間満了する月の「10日」までに、誓約書を兼ねた<a href="https://forms.gle/1GKSbU9jmuQuPA149" className='text-link type-external' target='_brank'>Googleフォーム</a>を送信、<a href="https://rsdesign.rakurakuseisan.jp/_5ZwGywYVPa/" target="_blank" rel="noopener noreferrer" className='text-link type-external'>楽楽精算</a>の人事稟議申請をお願いいたします。</li>
            <li>原則承認後、リモートワークを継続してください。</li>
          </ul>
        </div>
      </div>
    </div >
  );
}

export default Contents1;