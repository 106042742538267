// react
// @ts-nocheck
import React from "react";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet-async';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// デコード
import jwt_decode from "jwt-decode";

// css
import "../css/normalize.css";

//ヘッダー
import Header from "../component/page_parts/header";

// フッター
import Footer from "../component/page_parts/footer";

// json
// dev環境
import departmentWpJsonDev from '../files/wp_info_department_dev.json';
import departmentWpJsonProd from "../files/wp_info_department.json";
// 本番環境
import buildingWpJsonDev from '../files/wp_building_dev.json';
import buildingWpJsonProd from "../files/wp_building.json";

// パンくずリスト
import TableOfContents from "../component/page_parts/common/table_of_contents";

// インフォメーション一覧
function InfomationLists() {

  // WP RESTAPI 環境別ベースURL
  const urlBase = process.env.REACT_APP_WP_REST_API_PATH_INFO;

  // 環境に応じたJSONの内容をstate初期値にする変数に格納
  let departmentWpJson, buildingWpJson;
  if (urlBase === 'https://ztree.cfbx.jp/info-wp-dev/') { // 開発環境の場合
    departmentWpJson = departmentWpJsonDev;
    buildingWpJson = buildingWpJsonDev;
  } else { // 本番環境の場合
    departmentWpJson = departmentWpJsonProd;
    buildingWpJson = buildingWpJsonProd;
  }

  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  const [info_data, setInfoData] = useState<any>([]);

  const [readInfoList, setReadInfoList] = useState([]); // 既読情報を格納するステート

  const [inputValue, setInputValue] = useState(""); // 検索入力値を格納するステート

  const [clickCategory, setClickCategory] = useState(""); // カテゴリー切り替え用のステート

  const [parentCategory, setParentCategory] = useState([]); // 親カテゴリー用のステート

  const [defaultFlag, setDefaultFlag] = useState(true); // 初期表示用のステート

  const [errorFlag, setErrorFlag] = useState(false); // エラー表示用のステート

  const [searchCount, setSearchCount] = useState(""); // カテゴリー切り替え用のステート

  const [serchFlag, setSerchFlag] = useState(false);

  const [notPostText, setNotPostText] = useState("このカテゴリーの記事はございません。"); // カテゴリーか期日あり記事が0件時のテキストステート

  const [notSerchPostText, setNotSerchPostText] = useState("「検索ワード」に一致する情報は見つかりませんでした。"); // 検索結果の記事が0件時のテキストステート

  const [notPostFlag, setNotPostFlag] = useState(false); // 記事0件のフラグ

  /* 配信先指定と認証に必要なstate */
  // Cookieから取得したトークンを保持
  const [cookies, setCookie, removeCookie] = useCookies(["sessionToken"]);
  // トークンをデコードして取得したユーザー情報を保持
  const [loginData, setLoginData] = useState({});
  // トークンをデコードして取得したjtiを保持（パラメータに付与する用）
  const [cookieJti, setCookieJti] = useState();

  // WP設定値の写しを保持
  const [departmentWpAll, setDepartmentWpAll] = useState(departmentWpJson);
  const [buildingWp, setBuildingWp] = useState(buildingWpJson);

  // 組織idと、その組織が属する先祖組織idの配列を保持
  const [departmentIds, setDepartmentIds] = useState([]);

  // ビル内外に応じてパラメータを保持
  const [buildingParam, setBuildingParam] = useState("");

  // 検索Enterキー実行用
  const handleKeyDown = (event) => {

    const kijiNoResult = document.getElementById('kiji_no_txt');
    const kijiNoSerchResult = document.getElementById('kiji_no_serch_txt');
    const defaultMessage = document.getElementById('default_message');


    defaultMessage.style.display = "block";

    if (kijiNoResult?.classList[1] == "is-show") {
      kijiNoResult.classList.remove("is-show");
    }

    if (kijiNoSerchResult?.classList[1] == "is-show") {
      kijiNoSerchResult.classList.remove("is-show");
    }

    if (event.key === 'Enter') {

      var wpRestUrl = urlBase + "wp-json/wp/v2/information?per_page=100&information_department=";

      // WP REST APIの検索オプション基本URL
      const WPRestApiDefaultUrl =
        wpRestUrl +
        departmentIds +
        buildingParam +
        "&zenken=" +
        cookieJti +
        "&search=";

      // 検索用入力フォームの入力値のnullまたは、空確認
      if (!inputValue) {

        fetch(WPRestApiDefaultUrl)
          .then((searchResult) => searchResult.json())
          .then((searchResult) => {

            // 検索結果を格納
            setInfoData(searchResult);
          });
        return;
      } else {
        // WP REST APIの検索オプション基本URLに検索用入力フォームの入力値を結合
        const WPRestApiUrl = WPRestApiDefaultUrl + inputValue;

        // WP REST APIの検索オプション実行
        fetch(WPRestApiUrl)
          .then((searchResult) => searchResult.json())
          .then((searchResult) => {
            // 検索結果件数の確認
            if (searchResult.length == 0) {
              // 検索結果件数のが、0件の場合
              kijiNoSerchResult.classList.add("is-show");
              defaultMessage.style.display = "none";
              // 検索結果を格納
              setInfoData(searchResult);
              return;
            }
            var count = "（" + searchResult.length + "）";
            setSearchCount(count);

            // 検索結果を格納
            setInfoData(searchResult);

            setSerchFlag(true);
          });
      }
    }
  };

  useEffect(() => {
    if (clickCategory === "") return;


    const showKiji = document.getElementsByClassName("show-kiji");
    const kijiNoResult = document.getElementById('kiji_no_txt');
    const defaultMessage = document.getElementById('default_message');

    if (showKiji.length == 0) {
      kijiNoResult.classList.add("is-show");
      defaultMessage.style.display = "none";
    } else {
      kijiNoResult.classList.remove("is-show");
    }

    const kijiNoSerchResult = document.getElementById('kiji_no_serch_txt');
    if (kijiNoSerchResult?.classList[1] == "is-show") {
      kijiNoSerchResult.classList.remove("is-show");
    }
  }, [clickCategory]);

  //背景色つける処理
  useEffect(() => {

    // 既読ローカルストレージを取得
    if (localStorage.getItem("readInfoIds") == undefined) {
      // 既読ローカルストレージがundefineの場合は、空の既読ローカルストレージを作成する
      localStorage.setItem('readInfoIds', "0");
    } else {
      // 既読ローカルストレージがundefineではない場合は、既読ローカルストレージから既読情報を取得し、既読情報ステートに格納する
      setReadInfoList(localStorage.getItem("readInfoIds"));
    }
    //.show-kijiを取得
    const showKiji = document.getElementsByClassName("show-kiji");
    //.kiji-contentを取得
    const kijiContent = document.getElementsByClassName("kiji-content");

    //kijiContentの背景色をリセット
    for (let i = 0; i < kijiContent.length; i++) {
      kijiContent[i].style.backgroundColor = "";
    }

    if (showKiji.length != 0) {
      //show-kijiがある場合
      //偶数番目のshow-kijiに背景色をつける
      for (let i = 0; i < showKiji.length; i++) {
        if (i % 2 == 0) {
          showKiji[i].style.backgroundColor = "#F6F9E4";
        }
      }
    } else {
      //show-kijiがない場合
      //kiji-contentを取得
      //偶数番目のkiji-contentに背景色をつける
      for (let i = 0; i < kijiContent.length; i++) {
        if (i % 2 == 0) {
          kijiContent[i].style.backgroundColor = "#F6F9E4";
        }
      }
    }

    //クリックしたカテゴリーに背景色をつける
    if (clickCategory != "") {
      //全ての.btn-tag-clickからis-activeを削除
      const btnTagClick = document.getElementsByClassName("btn-tag-click");
      for (let i = 0; i < btnTagClick.length; i++) {
        btnTagClick[i].classList.remove("is-active");
      }
      //clickCategoryをvalueに持つ.btn-tag-clickにis-activeをつける
      const btnTagClick2 = document.getElementsByClassName("btn-tag-click");
      for (let i = 0; i < btnTagClick2.length; i++) {
        if (btnTagClick2[i].getAttribute("value") == clickCategory) {
          btnTagClick2[i].classList.add("is-active");
        }
      }
    }
  });

  // ログイン時に生成されたトークンをデコードしたデータをstateに格納
  useEffect(() => {
    SettingLonginData();
  }, []);

  // 配信先指定に必要なパラメータ情報を取得
  useEffect(() => {
    if (Object.keys(loginData).length) {
      // loginData が空ではない時
      getParameter();
    }
  }, [loginData]);

  useEffect(() => {
    if (departmentIds.length !== 0 && cookieJti !== undefined) {
      getPosts();
    }
  }, [departmentIds]);

  /* ログイン時に生成されたトークンをデコードしたデータをstateに保持 */
  function SettingLonginData() {
    // Cookieからトークンを取得
    const token = cookies.sessionToken;
    // デコード
    const decodedToken = jwt_decode<{ [name: string]: string }>(token);

    setLoginData(decodedToken.data);

    /* パラメータ（zenken）にセットしてリクエストするためのjtiの値を取得してstateにセット
        →WP側で値の確認を行う（確認できないリクエストには401を返すようにする）*/
    setCookieJti(decodedToken.jti);
  }

  /* - ユーザーの所属する組織id（WP側）を取得 */
  /* - 所属組織のビル内外に応じてパラメータをセット */
  function getParameter() {
    // トークンから、所属組織のビル内外に応じてパラメータをセット
    if (loginData.BUILDING_SLUG !== "inBuilding") {
      // 所属がビル内でない場合
      const excludeBuildingId = buildingWp[0].id;
      setBuildingParam("&information_building_exclude=" + excludeBuildingId);
    }

    // DB（トークン）の所属組織のスラッグから、WP側の所属情報を取得
    let departmentSlug = null;
    if (loginData.SECTION_SLUG3) {
      departmentSlug = loginData.SECTION_SLUG3;
    } else if (loginData.SECTION_SLUG2) {
      departmentSlug = loginData.SECTION_SLUG2;
    } else if (loginData.SECTION_SLUG1) {
      departmentSlug = loginData.SECTION_SLUG1;
    } else if (loginData.DEPARTMENT_SLUG) {
      departmentSlug = loginData.DEPARTMENT_SLUG;
    } else if (loginData.OFFICE_SLUG) {
      departmentSlug = loginData.OFFICE_SLUG;
    } else { // 所属スラッグがない場合、 all 所属扱いにする
      departmentSlug = 'all';
    }
    // DBのスラッグは大文字で登録されていることがあるので、WPの仕様に合わせて小文字変換
    departmentSlug = departmentSlug.toLowerCase();

    let wpDepartment = departmentWpAll.find((v) => v.slug == departmentSlug);

    // wpDepartmentがnullの場合は、all 所属扱いにする
    if (wpDepartment == null) {
      wpDepartment = departmentWpAll.find((v) => v.slug == 'all');
    }

    // WP側の所属組織とその祖先組織idの配列を取得し、stateを更新
    const departmentIdsArr = wpDepartment.ancestors_ids;
    setDepartmentIds(departmentIdsArr);
  }

  async function getPosts() {

    var wpRestUrl = urlBase + "wp-json/wp/v2/information?per_page=100&information_department=";

    const responce = await fetch(
      wpRestUrl +
      departmentIds +
      buildingParam +
      "&zenken=" +
      cookieJti
    );
    //エラーハンドリング
    if (!responce.ok) {
      setErrorFlag(true);
      return;
    }
    const info_data = await responce.json();

    console.log(info_data);

    setInfoData(info_data);

    const today = new Date();

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // 月は0から始まるので+1します
    const day = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    // 親カテゴリーの取得
    var parCat = [];
    parCat = ["internal-notice", "news", "committee"];
    setParentCategory(parCat);
  }

  // カテゴリータグのクリック処理
  const handleClick = (e: any) => {
    const defaultMessage = document.getElementById('default_message');
    defaultMessage.style.display = "block";
    e.preventDefault();
    const category = e.target.value;

    setClickCategory(e.target.value);
    setDefaultFlag(false);

  };

  // 記事投稿日の日付フォマット▶　n年n月j日(D)
  function doDateFormat(date) {
    // const WeekChars = ["日", "月", "火", "水", "木", "金", "土"];
    let dateFormat = new Date(date);
    dateFormat =
      dateFormat.getFullYear() +
      "/" +
      ("0" + (dateFormat.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + dateFormat.getDate()).slice(-2)
    return dateFormat;
  }

  // 検索入力値格納処理
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {


    // 検索入力値を検索入力値ステートに格納
    setInputValue(e.target.value);

  };

  // 検索機能
  const Search = () => {

    const kijiNoResult = document.getElementById('kiji_no_txt');
    const kijiNoSerchResult = document.getElementById('kiji_no_serch_txt');
    const defaultMessage = document.getElementById('default_message');

    console.log(defaultMessage);
    defaultMessage.style.display = "block";

    if (kijiNoResult?.classList[1] == "is-show") {
      kijiNoResult.classList.remove("is-show");
    }

    if (kijiNoSerchResult?.classList[1] == "is-show") {
      kijiNoSerchResult.classList.remove("is-show");
    }

    var wpRestUrl = urlBase + "wp-json/wp/v2/information?per_page=100&information_department=";

    // WP REST APIの検索オプション基本URL
    const WPRestApiDefaultUrl =
      wpRestUrl +
      departmentIds +
      buildingParam +
      "&zenken=" +
      cookieJti +
      "&search=";

    // 検索用入力フォームの入力値のnullまたは、空確認
    if (!inputValue) {

      fetch(WPRestApiDefaultUrl)
        .then((searchResult) => searchResult.json())
        .then((searchResult) => {

          // 検索結果を格納
          setInfoData(searchResult);
        });
      return;
    } else {
      // WP REST APIの検索オプション基本URLに検索用入力フォームの入力値を結合
      const WPRestApiUrl = WPRestApiDefaultUrl + inputValue;


      // WP REST APIの検索オプション実行
      fetch(WPRestApiUrl)
        .then((searchResult) => searchResult.json())
        .then((searchResult) => {
          // 検索結果件数の確認
          if (searchResult.length == 0) {
            // 検索結果件数のが、0件の場合
            kijiNoSerchResult.classList.add("is-show");
            const defaultMessage = document.getElementById('default_message');
            console.log(defaultMessage);
            defaultMessage.style.display = "none";
            setInfoData(searchResult);
            return;
          }
          kijiNoSerchResult.classList.remove("is-show");
          const defaultMessage = document.getElementById('default_message');
          var count = "（" + searchResult.length + "）";
          setSearchCount(count);
          // 検索結果を格納
          setInfoData(searchResult);
          setSerchFlag(true);
        });
    }
  };

  // 残日数の返却処理
  function calculateRemainingDays(deadline: string) {
    const today = new Date();
    const deadlineDate = new Date(deadline);
    const timeDiff = deadlineDate.getTime() - today.getTime();
    const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysRemaining;
  }

  return (
    <>
      <Helmet>
        <title>Z-link インフォメーション 一覧</title>
      </Helmet>
      <Header />
      <div className="theme-info">
        <div className="content-header">
          <div className="container">
            <Row>
              <Col sm={8}>
                <h1 className='page-title type-info no-description'>インフォメーション</h1>{/* ページタイトル */}
              </Col>
              <Col sm={4}>
                {/* 検索 */}
                {
                  errorFlag ? (
                    <></>)
                    : (
                      <div className="top-wrap mt-4">
                        <div className="top-parts p-0">
                          <div className="input-group search-area pc">
                            <input
                              className="search-btn"
                              type="text"
                              value={inputValue}
                              onChange={handleChange}
                              placeholder={"検索"}
                              onKeyDown={handleKeyDown}
                            />
                            <button onClick={Search} className="search-icon"></button>
                          </div>
                        </div>
                      </div>
                    )
                }
              </Col>
            </Row>
          </div>
        </div>
        <TableOfContents />

        {/* 記事一覧表示 */}
        {errorFlag ? (
          <main>
            <div className="main-contents container">
              <p>
                記事の取得時にエラーが発生しました。<br />
                お手数ですが、ページのリロードをお願い致します。<br />
                それでも解決しない場合は、管理者までお問い合わせください。
              </p>
            </div>
          </main>
        ) : (

          <main className="">
            <div className="main-contents container">

              <div className="bottom-parts">
                {/* カテゴリーのタグ表示 */}
                <div className="btn-tag-area">
                  <div className="btn-left">
                    <button className='btn-tag-click parent-cat all is-active' value="all" onClick={handleClick}>ALL</button>
                  </div>
                  <div className="btn-center">
                    <button className='btn-tag-click parent-cat all' value="deadline" onClick={handleClick}>期日<br />あり</button>
                  </div>
                  <div className="btn-right">
                    <div className="btn-row">
                      <button
                        className="btn-tag-click parent-cat"
                        value="internal-notice"
                        onClick={handleClick}
                      >
                        社内通達
                      </button>
                      <button
                        className="btn-tag-click"
                        value="soumu"
                        onClick={handleClick}
                      >
                        総務
                      </button>
                      <button
                        className="btn-tag-click"
                        value="jinji"
                        onClick={handleClick}
                      >
                        人事
                      </button>
                      <button
                        className="btn-tag-click"
                        value="keiei-kikaku"
                        onClick={handleClick}
                      >
                        経営企画
                      </button>
                      <button
                        className="btn-tag-click"
                        value="keiri"
                        onClick={handleClick}
                      >
                        経理
                      </button>
                    </div>
                    <div className="btn-row">
                      <button
                        className="btn-tag-click parent-cat"
                        value="committee"
                        onClick={handleClick}
                      >
                        委員会等
                      </button>
                      <button
                        className="btn-tag-click"
                        value="kenko-keiei"
                        onClick={handleClick}
                      >
                        健康経営推進隊
                      </button>
                      <button
                        className="btn-tag-click"
                        value="mochikabu"
                        onClick={handleClick}
                      >
                        従業員持株会
                      </button>
                      <button
                        className="btn-tag-click"
                        value="kojinjoho-hogo-kanri"
                        onClick={handleClick}
                      >
                        個人情報保護管理委員会
                      </button>
                      <button
                        className="btn-tag-click"
                        value="club"
                        onClick={handleClick}
                      >
                        部活動
                      </button>
                      <button
                        className="btn-tag-click"
                        value="jb"
                        onClick={handleClick}
                      >
                        ジュニアボード
                      </button>
                    </div>
                    <div className="btn-row">
                      <button
                        className="btn-tag-click parent-cat"
                        value="news"
                        onClick={handleClick}
                      >
                        ニュース・情報
                      </button>
                      <button
                        className="btn-tag-click"
                        value="pr"
                        onClick={handleClick}
                      >
                        PR情報
                      </button>
                      <button
                        className="btn-tag-click"
                        value="system"
                        onClick={handleClick}
                      >
                        システム情報
                      </button>
                    </div>
                  </div>
                </div>
                <div className="released-information-wrap">
                  <p id="kiji_no_txt" className="kiji_no">{notPostText}</p>
                  <p id="kiji_no_serch_txt" className="kiji_no">{notSerchPostText}</p>
                  {info_data.map((post) => {
                    const { id, title, date, acf, content } = post;

                    // インフォメーション一覧に出す文言設定
                    let description;
                    if (acf.i_list_description) {
                      //一覧ページ用テキストがカスタムフィールドで設定されている場合は、そのテキストを設定
                      description = acf.i_list_description;
                    } else {
                      // 一覧ページ用テキストがカスタムフィールドで設定されていない場合、本文冒頭70文字を出すような設定
                      description = content.rendered
                        .replace(/(<([^>]+)>)/gi, "")
                        .slice(0, 70);
                    }

                    return (
                      <a href={baseUrl + "/info_lists/deteil?id=" + post.id}
                        key={id}
                        id={post.information_taxonomy_slug}
                        className={
                          (readInfoList.includes(id) == true || new Date(date) < new Date(new Date(localStorage.getItem('bulk_read_last_execution_date')).getTime())
                            ? "kiji-content readed-kiji" +
                            " " +
                            post.information_taxonomy_slug
                            : "kiji-content unreaded-kiji" +
                            " " +
                            post.information_taxonomy_slug)
                          +
                          (defaultFlag
                            ? " " +
                            " "
                            : //初期表示ではない場合(ボタンが押された場合)
                            parentCategory.indexOf(clickCategory) != -1
                              ?
                              clickCategory == "internal-notice"
                                ?
                                post.information_taxonomy_slug == "jinji" ||
                                  post.information_taxonomy_slug == "keiei-kikaku" ||
                                  post.information_taxonomy_slug == "keiri" ||
                                  post.information_taxonomy_slug == "soumu"
                                  ? " show-kiji" +
                                  " "
                                  : " hide-kiji" +
                                  " "
                                : clickCategory == "news"
                                  ?
                                  post.information_taxonomy_slug == "pr" ||
                                    post.information_taxonomy_slug == "system"
                                    ? " show-kiji" +
                                    " "
                                    : " hide-kiji" +
                                    " "
                                  : clickCategory == "committee"
                                    ?
                                    post.information_taxonomy_slug == "kojinjoho-hogo-kanri" ||
                                      post.information_taxonomy_slug == "kenko-keiei" ||
                                      post.information_taxonomy_slug == "mochikabu" ||
                                      post.information_taxonomy_slug == "club" ||
                                      post.information_taxonomy_slug == "jb"
                                      ? " show-kiji" +
                                      " "
                                      : " hide-kiji" +
                                      " "

                                    : " hide-kiji" +
                                    " "

                              : clickCategory == "all" ?

                                post.information_taxonomy_slug == "jinji" ||
                                  post.information_taxonomy_slug == "keiei-kikaku" ||
                                  post.information_taxonomy_slug == "keiri" ||
                                  post.information_taxonomy_slug == "soumu" ||

                                  post.information_taxonomy_slug == "pr" ||
                                  post.information_taxonomy_slug == "system" ||

                                  post.information_taxonomy_slug == "kojinjoho-hogo-kanri" ||
                                  post.information_taxonomy_slug == "kenko-keiei" ||
                                  post.information_taxonomy_slug == "mochikabu" ||
                                  post.information_taxonomy_slug == "club" ||
                                  post.information_taxonomy_slug == "jb"
                                  ? " show-kiji" +
                                  " "
                                  : " hide-kiji" +
                                  " "

                                : clickCategory == "deadline" ?

                                  acf.important_decision === true
                                    ? " show-kiji" +
                                    " "
                                    : " hide-kiji" +
                                    " "

                                  : //押されたボタンが子カテゴリーの場合
                                  post.information_taxonomy_slug == clickCategory
                                    ? " show-kiji" +
                                    " "
                                    : " hide-kiji" +
                                    " ")
                        }
                      >
                        <div className="tag-area">
                          <p className="tag-category-name">
                            {post.information_taxonomy_name}
                          </p>
                        </div>
                        <div className="data-area">
                          <span>{doDateFormat(date)}</span>
                        </div>
                        <div>
                          <div className="title-area">
                            {new Date(post.date) >= new Date(Date.now() - 3 * 24 * 60 * 60 * 1000) && (
                              <div dangerouslySetInnerHTML={{
                                __html: '<span class="new-icon">NEW</span>'
                              }} />
                            )}
                            {acf.important_decision && (
                              <div className="info_deadline_date">
                                {acf.important_decision === true && (
                                  <span className="deadline-mark">期日あり</span>
                                )}
                              </div>
                            )}
                            {acf.important_decision && (
                              <div className="info_deadline_date">
                                {acf.deadline_date && new Date(acf.deadline_date) >= new Date(new Date().setHours(0, 0, 0, 0)) && (
                                  <p>
                                    {acf.deadline_date}まで</p>
                                )}
                              </div>
                            )}
                            <h3 className="info-title" dangerouslySetInnerHTML={{
                              __html: post.title.rendered.replace(/<span class="new-icon">NEW<\/span>/g, '')
                            }} />
                          </div>
                          <p className="info-description-txt">{description}</p>
                        </div>
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
            <p className="text-center mt-3" id="default_message">最大100件を表示しています</p>
          </main>
        )}
      </div >
      <Footer />
    </>
  );
}
export default InfomationLists;
