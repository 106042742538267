import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
//Rooting
import Home from './pages/home';
import Test from './pages/test';
import Template from './pages/template';
import Login from './pages/login';
import CalendarPage from './pages/calendar_page';
import InfoLists from './pages/info_lists';
import InfoDetail from './pages/info_detail';
import JinjiEvaluation from './pages/jinji/evaluation';
import JinjiWelfare from './pages/jinji/welfare';
import JinjiLifestyle from './pages/jinji/lifestyle';
import JinjiJinjer from './pages/jinji/jinjer';

//keiri
import Keiri from './pages/keiri_content/keiri';
import Keiri_cms from './pages/keiri_content/keiri_cms';
import Keiri_startGuide from './pages/keiri_content/start_guide';
import Division from './pages/division';
import Media from './pages/media';

//johsys
import Johsys from './pages/johsys_content/johsys';

// lunchmap
import Lunchmap from './pages/lunchmap';
import LunchmapEdit from './pages/lunchmap/edit';

//404
//@ts-ignore
import NoMatch_404 from './pages/nomatch_404';
import Facility from './pages/facility/facility';
import Employee_list from './pages/general/employeelist';

//プロフ編集
import NormalEdit from './pages/user/normal_edit';
import AdministratorEdit from './pages/user/administrator_edit';

//取締役ブログ
import TorisimariyakuBlog from './pages/bigWig/torisimariyakuBlog';

//委員会
import Committee from './pages/committee/committee';

//事業部ブログ
import DeptBlog from './pages/blog/departmentBlog';

//ワークスタイル
import Workstyle from './pages/workstyle_content/workstyle';

//メンテナンス
import Maintenance from './pages/maintenance/index';

function App() {
  //テスト環境
  const baseUrl = process.env.REACT_APP_PUBLIC_URL || '';

  return (
    <>
      <Routes>
        <Route path={`${baseUrl}/`} element={<Home />} />
        <Route path={`${baseUrl}/test`} element={<Test />} />
        <Route path={`${baseUrl}/template`} element={<Template />} />
        <Route path={`${baseUrl}/login`} element={<Login />} />
        <Route path={`${baseUrl}/calendar`} element={<CalendarPage />} />
        <Route path={`${baseUrl}/*`} element={<NoMatch_404 />} />
        <Route path={`${baseUrl}/info_lists/`} element={<InfoLists />} />
        <Route path={`${baseUrl}/facility/`} element={<Facility />} />
        <Route path={`${baseUrl}/employee_list`} element={<Employee_list />} />
        <Route path={`${baseUrl}/info_lists/:slug`} element={<InfoDetail />} />
        <Route path={`${baseUrl}/lifestyle/evaluation`} element={<JinjiEvaluation />} />
        <Route path={`${baseUrl}/welfare`} element={<JinjiWelfare />} />
        <Route path={`${baseUrl}/lifestyle`} element={<JinjiLifestyle />} />
        <Route path={`${baseUrl}/jinjer`} element={<JinjiJinjer />} />
        <Route path={`${baseUrl}/keiri`} element={<Keiri />} />
        <Route path={`${baseUrl}/keiri/cms`} element={<Keiri_cms />} />
        <Route path={`${baseUrl}/keiri/start_guide`} element={<Keiri_startGuide />} />
        <Route path={`${baseUrl}/division/`} element={<Division />} />
        <Route path={`${baseUrl}/media/`} element={<Media />} />
        <Route path={`${baseUrl}/johsys`} element={<Johsys />} />
        <Route path={`${baseUrl}/lunchmap`} element={<Lunchmap />} />
        <Route path={`${baseUrl}/lunchmap/edit`} element={<LunchmapEdit />} />
        <Route path={`${baseUrl}/user/edit`} element={<NormalEdit />} />
        <Route path={`${baseUrl}/user/administrator_edit`} element={<AdministratorEdit />} />
        <Route path={`${baseUrl}/board-voice`} element={<TorisimariyakuBlog />} />
        <Route path={`${baseUrl}/committee`} element={<Committee />} />
        <Route path={`${baseUrl}/deptBlog`} element={<DeptBlog />} />
        <Route path={`${baseUrl}/workstyle`} element={<Workstyle />} />
        <Route path={`${baseUrl}/maintenance`} element={<Maintenance />} />
      </Routes>
    </>
  );
}

export default App;
