import React, { useState, useEffect } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Contents5() {
  useEffect(() => { }, []);

  return (
    <>
      {/* <h2 className="tag-title">良くある質問FAQコーナー</h2>
      <Row>
        <Col sm={4}>
          <a href="https://docs.google.com/spreadsheets/d/1WC_R6OryoFYIHoqTIRXdtNGgHfvIhk3s/edit#gid=254705956" target="_blank" className="btn main-btn type-external">
            ノートPC貸与時に<br />よくある質問集
          </a>
        </Col>
      </Row>
      <p className='mt-4'>
        その他のFAQは、
        <a href='https://secure.okbiz.jp/zenken/' target='blank' className='text-link type-external'>Z-BOSS</a>
        でご検索ください。
      </p> */}
      <h2 className="tag-title">GoogleWorkSpace 使い方</h2>
      <p>
        社内向けのGoogleWorkspaceドキュメントです。
        <br />
        各アプリ（Gmail、Googleドライブ等）の基本的な情報は、公式のヘルプをご参照ください。かなりわかりやすい説明になっています。
      </p>
      <h4 className='bg-title'>公式ヘルプ</h4>
      <div id='johsys-content4' className='johsys-content4 pb-5'>
        <div className='continer johsys-related'>
          <div className='d-flex justify-content-start'>
            <img
              src={process.env.PUBLIC_URL + '/johsys_pic/content4_help.svg'}
              alt=''
            />
            <p>
              各アプリの画面右上にある「？」アイコンをクリックすることでも確認頂けます。
              <br />
              「ヘルプ」・・・調べたいことについて検索できます。
              <br />
              「トレーニング」・・・クイックリファレンスやヒントなど、より細かい使い方を調べることができます。
            </p>
          </div>
        </div>
      </div>
      <h4 className='bg-title'>アプリ別 使い方ガイド</h4>
      <Row className="mt-4">
        <Col sm={3}>
          <a href="https://mail.google.com/chat/u/0/#chat/home" target="_blank" rel="noopener noreferrer">
            <img
              src={process.env.PUBLIC_URL + '/johsys_pic/content4_gchat.svg'}
              alt='Googleチャット'
            />
          </a>
        </Col>
        <Col sm={9}>
          <Row>
            <Col sm={4} className='mb-2 px-1'>
              <a href="https://drive.google.com/file/d/1hnwpMDeVr3EH1t-RWr86ZeO2Ajaxl4cf/view" target="_blank" className="btn main-btn type-external">
                【社内編】GoogleChat<br />使い方ガイド①
              </a>
            </Col>
            <Col sm={4} className='mb-2 px-1'>
              <a href="https://drive.google.com/file/d/1TKxeKKKTvfxQLBIE9yuE2REY9KIAyOeD/view" target="_blank" className="btn main-btn type-external">
                【社外編】GoogleChat<br />使い方ガイド②
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row className="mt-5">
        <Col sm={3}>
          <a href="https://mail.google.com/mail/u/0/#inbox" target="_blank" rel="noopener noreferrer">
            <img
              src={process.env.PUBLIC_URL + '/johsys_pic/content4_gmail.svg'}
              alt='Gmail'
            />
          </a>
        </Col>
        <Col sm={9}>
          <Row>
            <Col sm={4} className='mb-2 px-1'>
              <a href="https://drive.google.com/file/d/1Utr9f8TYwaqoBZBvmqNJ4obw_4Ph_lZF/" target="_blank" className="btn main-btn type-external">
                メーリングリストの<br />設定手順
              </a>
            </Col>
            <Col sm={4} className='mb-2 px-1'>
              <a href="https://drive.google.com/file/d/1YfIZLtoBl1r1dx0ShijLWLNKUKrz_Vj1/" target="_blank" className="btn main-btn type-external">
                ラベル名称ルール
              </a>
            </Col>
            <Col sm={4} className='mb-2 px-1'>
              <a href="https://drive.google.com/file/d/17mo4sdKqGE6qskjCEs4E8y61e4DCT6_Q/" target="_blank" className="btn main-btn type-external">
                外部ドメインのメール<br />アカウント設定手順
              </a>
            </Col>
            <Col sm={4} className='mb-2 px-1'>
              <a href="https://drive.google.com/file/d/195_O-fNk3Y4UjapXlhh2v6i7htT0UNkJ/" target="_blank" className="btn main-btn type-external">
                Active!gateSSの<br />利用方法
              </a>
            </Col>
            <Col sm={4} className='mb-2 px-1'>
              <a href="https://drive.google.com/file/d/1_BzAElEGujWGPsGOKthg4I5KjzeNoO8_/" target="_blank" className="btn main-btn type-external">
                Gmail 大容量ファイルの<br />添付方法
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <Col sm={3}>
          <a href="https://drive.google.com/drive/u/0/" target="_blank" rel="noopener noreferrer">
            <img
              src={process.env.PUBLIC_URL + '/johsys_pic/content4_gdrive.svg'}
              alt='Googleドライブ'
            />
          </a>
        </Col>
        <Col sm={9}>
          <Row>
            <Col sm={4} className='mb-2 px-1'>
              <a href="https://drive.google.com/file/d/1lD9z6h_hdVBu-3ReZn-O8Veg8Q5lCTKb/" target="_blank" className="btn main-btn type-external">
                運用ルールと<br />注意事項
              </a>
            </Col>
            <Col sm={4} className='mb-2 px-1'>
              <a href="https://drive.google.com/file/d/1ldZs8NyO9xRF5DEmdzLmg2YW58cayZdb/" target="_blank" className="btn main-btn type-external">
                Google共有ドライブ<br />使用方法
              </a>
            </Col>
            <Col sm={4} className='mb-2 px-1'>
              <a href="https://drive.google.com/file/d/1d6uxdmN5_yf65ZOhjLj3O-Am5BknyGgz/" target="_blank" className="btn main-btn type-external">
                パソコン版Google<br />ドライブ利用マニュアル
              </a>
            </Col>
            <Col sm={4} className='mb-2 px-1'>
              <a href="https://drive.google.com/file/d/1bXzoLY_v8PItFf9L6zaur1ng-ZpHMzUx/" target="_blank" className="btn main-btn type-external">
                パソコン版Google<br />ドライブ 同期停止手順
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <Col sm={3}>
          <a href="https://calendar.google.com/calendar/u/0/r" target="_blank" rel="noopener noreferrer">
            <img
              src={process.env.PUBLIC_URL + '/johsys_pic/content4_gcare.svg'}
              alt='Googleカレンダー'
            />
          </a>
        </Col>
        <Col sm={9}>
          <Row>
            <Col sm={4} className='mb-2 px-1'>
              <a href="https://docs.google.com/presentation/d/1OuqyBt9erFPrEqn68h0aXym7fK1YoQjLPYgbikRbik8/present?slide=id.p3" target="_blank" className="btn main-btn type-external">
                Googleカレンダー<br />運用ルール
              </a>
            </Col>
            <Col sm={4} className='mb-2 px-1'>
              <a href="https://drive.google.com/file/d/17rVaQSE7j3Jm6Ogq6es_sR6em_UGWdSh/" target="_blank" className="btn main-btn type-external">
                Googleカレンダー<br />操作マニュアル
              </a>
            </Col>
            <Col sm={4} className='mb-2 px-1'>
              <a href="https://sites.google.com/zenken.co.jp/gcalender/qa?authuser=0" target="_blank" className="btn main-btn type-external">
                Googleカレンダー<br />Q＆A
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <Col sm={3}>
          <a href="https://groups.google.com/my-groups" target="_blank" rel="noopener noreferrer">
            <img
              src={process.env.PUBLIC_URL + '/johsys_pic/content4_ggroup.svg'}
              alt='Googleグループ'
            />
          </a>
        </Col>
        <Col sm={9}>
          <Row>
            <Col sm={4} className='mb-2 px-1'>
              <a href="https://drive.google.com/file/d/17vOS_JctiHEKyRmKc3vWfSgsmBqK8N-b/" target="_blank" className="btn main-btn type-external px-0">
                Googleグループの<br />追加・修正・削除
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <Col sm={3}>
          <img
            src={process.env.PUBLIC_URL + '/johsys_pic/content4_gclass.svg'}
            alt='Google classroom'
          />
        </Col>
        <Col sm={9}>
          <Row>
            <Col sm={4} className='mb-2 px-1'>
              <a href="https://drive.google.com/file/d/17x4ST_QnBACicjk3scLRydW6fIOfMVx9/" target="_blank" className="btn main-btn type-external">
                Google classroomの<br />使用
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
    </>
  );
}

export default Contents5;
