// @ts-nocheck
import { useState, useLayoutEffect, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";

import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

// レイアウト
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

//アプリ一覧
// import AppList from './app_list';

//認証チェック
import View_auth from "./common/view_auth";

import "../../css/App.css";

//ユーザー情報
import jwt_decode from "jwt-decode";
import { useCookies } from "react-cookie";

//ブログ新着
import NewPostInHeader from "./common/newPostInHeader";

//サイドバー
import Sidebar from "../../component/page_parts/common/sidebar";

// ベル押下時の未読一覧
import AnreadList from "../../component/page_parts/common/anreadList";

// おみくじのファイル
import fortuneListJson from "./fortune.json";

function Header() {

  //Cookie
  const [cookies, setCookie, removeCookie] = useCookies(["sessionToken"]);

  //ユーザー情報
  const [user_name, setUser_name] = useState("");
  const [user_Image, setUser_Image] = useState("");

  //ユーザーの役職
  const [user_position, setUser_position] = useState("");

  //テスト環境
  const baseUrl = process.env.REACT_APP_PUBLIC_URL || '';

  // 年、月、日、曜日
  const [oneDay, setOneDay] = useState("");

  {
    /* 検索フォーム */
  }
  <div className="header-search col-3">
    <div className="input-group border rounded-pill">
      <button className="btn" type="button" id="button-addon2">
        <Icon.Search />
      </button>
      <input type="text" className="form-control border-0 bg-transparent" />
    </div>
  </div>;


  {
    /* ユーザー */
  }
  <div className="header-user col-1 text-center">
    <Icon.PersonFill className="border rounded-circle " />
  </div>;
  {
    /* ./ユーザー */
  }

  // ユーザー情報取得
  function getUserInfo() {
    //ここに来れるのはログイン済みの場合のみ

    //ローカルストレージにユーザー情報がある場合
    if (
      localStorage.getItem("user_name") !== null &&
      localStorage.getItem("user_Image") !== null
    ) {
      //ローカルストレージに保存されているユーザー情報をセット
      setUser_name(localStorage.getItem("user_name"));
      setUser_Image(localStorage.getItem("user_Image"));
    } else {
      //ローカルストレージにユーザー情報がない場合
      const decoded = jwt_decode(cookies.sessionToken);
      //更にトークンをデコードしてユーザー情報を取得
      const gauth = jwt_decode(decoded.token);

      //ユーザー名と画像をセット
      setUser_name(decoded.data.USER_NAME);
      setUser_Image(gauth.picture);

      //ローカルストレージにユーザー情報を保存
      localStorage.setItem("user_name", decoded.data.USER_NAME);
      localStorage.setItem("user_Image", gauth.picture);
    }
  }

  function anreadInfoDisp() {
    const element = document.getElementById("anreadArea");
    //@ts-ignore
    // element?.style.transform = 'translateX(516px)';
    if (element.style.transform == "translateX(516px)") {
      element.style.transform = "translateX(0)";
      element.style.opacity = "1";
      element.style.visibility = "visible";
    } else {
      element.style.transform = "translateX(516px)";
      element.style.opacity = "0";
      element.style.visibility = "hidden";
    }
  }

  // ログイン時に生成されたトークンをデコードしたデータをstateに格納
  useEffect(() => {
    //ユーザー情報取得
    getUserInfo();

    const token = cookies.sessionToken;
    // デコード
    const decodedToken = jwt_decode<{ [name: string]: string }>(token);
    setUser_position(decodedToken.data.POSITION_CODE_ID);

    var space = "."
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const date = today.getDate();
    const day = today.getDay();
    const weekday = ["日", "月", "火", "水", "木", "金", "土"];
    var dayOfWeek = weekday[day];
    setOneDay(year + space + month + space + date + "(" + dayOfWeek + ")");
  }, []);

  const showMenu = () => {
    //.header-linksの状態を取得
    const headerLinks = document.getElementsByClassName("header-links")[0];
    //.header-linksの親要素を取得
    const parent = headerLinks.parentElement;

    if (headerLinks.classList.contains("show")) {
      headerLinks.classList.remove("show");
      parent.classList.remove("show-wrapper");
    } else {
      headerLinks.classList.add("show");
      parent.classList.add("show-wrapper");
    }
  };

  const logout = () => {
    // ログアウト処理
    //以下のローカルストレージのデータを削除
    //「readList」「deleteCount」「user_name」「urlList」「user_Image」「user_id」
    localStorage.removeItem("readList");
    localStorage.removeItem("deleteCount");
    localStorage.removeItem("user_name");
    localStorage.removeItem("urlList");
    localStorage.removeItem("user_Image");
    localStorage.removeItem("user_id");
    localStorage.removeItem("birthday");
    //以下のCookieを削除
    //「sessionToken」「userId」「email」
    removeCookie("sessionToken");

    //ログイン画面に遷移
    window.location.href = baseUrl + "/login";
  };

  /**
   * おみくじ
   */
  // クラス名つけ外しのステート
  const [active, setActive] = useState(false);

  // 一言表示用のステート
  const [results, setResults] = useState("");
  // JSONファイル表示用のステート
  const [fortuneItem, setfortuneItem] = useState("");
  const [fortuneColor, setfortuneColor] = useState("");

  const publicBaseUrl = process.env.REACT_APP_PUBLIC_URL;

  const resultsList = [
    { id: 1, title: "大大吉" },
    { id: 2, title: "大吉" },
    { id: 3, title: "向大吉" },
    { id: 4, title: "末大吉" },
    { id: 5, title: "吉凶末分末大吉" },
    { id: 6, title: "吉" },
    { id: 7, title: "中吉" },
    { id: 8, title: "小吉" },
    { id: 9, title: "後吉" },
    { id: 10, title: "末吉" },
    { id: 11, title: "平" },
    { id: 12, title: "ミラクル大吉" },
    { id: 13, title: "そろそろ大吉" },
    { id: 14, title: "スーパー大吉" },
    { id: 15, title: "幸運吉" },
    { id: 16, title: "普通吉" },
    { id: 17, title: "特大吉" },
    { id: 18, title: "超大吉" },
    { id: 19, title: "強運吉" },
    { id: 20, title: "幸福吉" },
    { id: 21, title: "未来吉" },
    { id: 22, title: "希望吉" },
    { id: 23, title: "成就吉" },
    { id: 24, title: "繁栄吉" },
    { id: 25, title: "恵みの吉" },
    { id: 26, title: "天恵吉" },
    { id: 27, title: "神恩吉" },
    { id: 28, title: "恩寵吉" },
    { id: 29, title: "祝福吉" },
    { id: 30, title: "光輝吉" }
  ];

  const onClickOmikuji = () => {
    // クラス名とつけ外しする
    setActive(!active);
    // localStorageを確認して値が登録されていたらその値を使って今日の運勢を表示する
    const result2 = localStorage.getItem("result_id");
    const result3 = localStorage.getItem("result_item_id");
    if (result2 !== null && result3 !== null) {
      // result3からJSONの中身を取得して表示する
      const fortuneItem = fortuneListJson[0][result3][0];
      setfortuneItem(fortuneItem);
      const fortuneColor = fortuneListJson[0][result3][1];
      setfortuneColor(fortuneColor);

      // resultsListのidとresult_idが一致していればtitleを表示する
      const result_id = Number(result2);
      const result = resultsList.find((list) => list.id === result_id);
      if (result) {
        setResults(result.title);
      }
      // 処理終了
      return;
    }

    // 値が登録されていなかったらランダムな値を抽出し、localStorageに設定して今日の運勢を表示する
    const index = Math.floor(Math.random() * resultsList.length);
    const result = resultsList[index];

    const result_item_id = Math.floor(Math.random() * Object.keys(fortuneListJson[0]).length);
    // title
    const fortuneItem = fortuneListJson[0][result_item_id][0];
    setfortuneItem(fortuneItem);
    // content
    const fortuneColor = fortuneListJson[0][result_item_id][1];
    setfortuneColor(fortuneColor);

    localStorage.setItem("result_id", result.id);
    localStorage.setItem("result_item_id", result_item_id.toString());
    setResults(result.title);
  };

  return (
    <>
      <header
        className="header sticky-top"
        aria-label="メインメニュー"
      >
        <View_auth />
        <div className="header-inner container-fluid">
          <div className="header-inner__left">
            {/* ロゴ */}
            <div className="header-logo">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip
                >TOPにもどる</Tooltip>}
              >
                <a href={publicBaseUrl + "/"} className="d-block">
                  <img
                    src={process.env.PUBLIC_URL + "/logo_negative.svg"}
                    alt="Z-LINK"
                    width={100}
                    height={25}
                  />
                </a>
              </OverlayTrigger>
            </div>
            {/* ./ロゴ */}
            <dl className="header-slogan">
              <dt>2024年<br />スローガン</dt>
              <dd>
                <img
                  src={process.env.PUBLIC_URL + "/slogan.svg"}
                  alt="世界志向 WORLD-ORiENTED"
                  className="slogan"
                  width={221}
                  height={30}
                />
              </dd>
            </dl>
          </div>
          <div className="header-inner__center">
            <NewPostInHeader />
          </div>
          <div className="header-inner__right">
            {/* おみくじ */}
            <button
              onClick={onClickOmikuji}
              className="btn header-fortune"
            >
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>今日の運勢</Tooltip>}
              >
                <span className="btn-fortune__img">
                  <img
                    src={process.env.PUBLIC_URL + "/fortune.svg"}
                    alt="今日の運勢"
                    width={23}
                    height={23}
                  />
                </span>
              </OverlayTrigger>
              <div
                className={
                  "fortune-wrapper" +
                  (active === true ? "" : " none")
                }
              >
                <Row className="fortune-head">
                  <Col sm={12}>
                    <p className="fortune-title">{oneDay}の運勢</p>
                    <p className="fortune-text">{results}</p>
                  </Col>
                </Row>
                <Row className="fortune-body mx-0">
                  <Col sm={6} className="px-0">
                    <p className="fortune-sub-title">ラッキーアイテム</p>
                    <p className="fortune-sub-text">{fortuneItem}</p>
                  </Col>
                  <Col sm={6} className="ps-2 pe-0">
                    <p className="fortune-sub-title">ラッキーカラー</p>
                    <p className="fortune-sub-text">{fortuneColor}</p>
                  </Col>
                </Row>
              </div>
            </button>
            {/* 未読通知ベル（未読一覧表示も行う） */}
            <div className="header-notice arart">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>未読一覧</Tooltip>}
              >
                <Button onClick={anreadInfoDisp} className="p-0">
                  <img
                    src={process.env.PUBLIC_URL + "/global/ic-bell-fill.svg"}
                    alt="未読"
                    className="notice-bell"
                    width={29}
                    height={34}
                  />
                  <span id="bellInfoCount" className="notice"></span>
                </Button>
              </OverlayTrigger>
            </div>
            {/* 未読通知ベル（未読一覧表示も行う） */}

            {/* ユーザー */}
            <button className="header-user-profile btn" onClick={showMenu}>
              {user_Image ? (
                <img
                  className="user-image rounded-circle"
                  src={user_Image}
                  onError={(e) =>
                    (e.target.src = `${process.env.PUBLIC_URL}/zenkenlogo_01.png`)
                  }
                  style={{ background: "white" }}
                />
              ) : (
                <Icon.PersonFill className="rounded-circle p-2 icon" />
              )}
              {user_name ? (
                <span className="user-name d-none d-md-block">{user_name}</span>
              ) : (
                <></>
              )}
              <div className="header-links">
                <ul className="header-links-list">
                  <li>
                    <a href={baseUrl + '/user/edit'}>ユーザー編集</a>
                  </li>
                  {
                    //役職IDが506より大きく600より小さい場合は、役職者用ユーザー編集を表示する
                    user_position > 506 && user_position < 600 ? (
                      <li>
                        <a href={baseUrl + '/user/administrator_edit'}>
                          役職者用ユーザー編集
                        </a>
                      </li>
                    ) : (
                      <></>
                    )
                  }
                  <li className='logout' onClick={logout}>ログアウト</li>
                  <li>
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSf8J_qCvwGH2VtHBYTznvpzj_gLlwUg1BT5WVAFmrymr69ovQ/viewform"
                      target="_blank"
                    >
                      お問い合わせ
                    </a>
                  </li>
                </ul>
              </div>
            </button>
            {/* ./ユーザー */}
          </div>
        </div>
      </header>
      <AnreadList />
      <Sidebar />
    </>
  );
}
export default Header;
