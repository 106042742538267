// @ts-nocheck
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet-async';
import "../css/normalize.css";

//ヘッダー
import Header from "../component/page_parts/header";

// インフォメーション一覧

import TopInformationList from "../component/page_parts/top_information_list";


//myカレンダー
import MyCalendar from "../component/page_parts/myCalendar";

// カスタマイズ

import CustomizeHome from "../component/page_parts/customize_home";

import Luckyitem from "../component/page_parts/luckyitem";

// フッター

import Footer from "../component/page_parts/footer";

// GAID
const gaId = process.env.REACT_APP_GA_ID;

const usePageTracking = () => {
  const location = useLocation();


  useEffect(() => {

    // Google Analytics 測定 ID を入力して設定
    ReactGA.initialize(gaId);
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: pageTitle
    });
  }, [location]);
};

function Home() {

  return (

    <>

      <Helmet>

        <title>Z-link ホーム</title>

      </Helmet>
      <Header />

      <div className="theme-index container">

        <TopInformationList />

        <MyCalendar />

        <CustomizeHome />

      </div>
      <Footer />

    </>
  );

}

export default Home;
