
//ヘッダー
import Header from '../../component/page_parts/header';
// フッター
import Footer from '../../component/page_parts/footer';

// bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

//タブコンテンツ
import Contents1 from './tab-content/content1';
import Contents2 from './tab-content/content2';

// パンくずリスト
import TableOfContents from "../../component/page_parts/common/table_of_contents";

import React from 'react';
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet-async';

// ワークスタイルページ
function Workstyle() {

  // GAID
  var gaId = process.env.REACT_APP_GA_ID;

  const element = document.getElementById("left-tabs-example-tab-first1");
  const userName = localStorage.getItem("user_name") || 'Unknown User'; // Provide a default value
  if (gaId) { // Check if gaId is defined
    ReactGA.initialize(gaId);
    ReactGA.send({
      hitType: "event",
      eventCategory: userName,
      eventAction: 'ワークスタイル-タブ切り替え',
      eventLabel: element?.textContent,
    });
  }

  // GA4タブ押下時計測
  function sendTabNameToGoogleAnalytics(e: React.MouseEvent<HTMLButtonElement>) {
    var element = document.getElementById(e.currentTarget.id); // 'e.currentTarget' を使用して安全に要素にアクセス
    var userName = localStorage.getItem("user_name") || 'Unknown User'; // Provide a default value
    console.log(element?.textContent);
    if (gaId) { // Check if gaId is defined
      ReactGA.initialize(gaId);
      ReactGA.send({
        hitType: "event",
        eventCategory: userName,
        eventAction: 'ワークスタイル-タブ切り替え',
        eventLabel: element?.textContent,
      });
    }
  }

  return (
    <>
      <Helmet>
        <title>Z-link ワークスタイル</title>
      </Helmet>
      <Header />
      <div className="theme-committee">
        <div className="content-header">
          <div className="container">
            <Row>
              <Col sm={9}>
                <h1 className='page-title type-workstyle'>ワークスタイル</h1>
                <p className="description">在宅勤務、時差出勤など働き方についてご紹介します。</p>
              </Col>
              <Col sm={3} className='btn-wrap jinji-related'>
                <a
                  href="https://kintai.jinjer.biz/staffs/top"
                  className="btn-link-area jinjer position-fixed"
                  target="_blank"
                >
                  利⽤する
                </a>
              </Col>
            </Row>
          </div>
        </div>
        <TableOfContents />
        <div className="container">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first1">
            <Nav variant="pills" className="mt-5">
              <Nav.Item>
                <Nav.Link className="text-center" eventKey="first1" onClick={sendTabNameToGoogleAnalytics}>在宅勤務（リモートワーク）</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="text-center" eventKey="first2" onClick={sendTabNameToGoogleAnalytics}>時差出勤（スライドワーク）</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first1">
                <Contents1 />
              </Tab.Pane>
              <Tab.Pane eventKey="first2">
                <Contents2 />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Workstyle;