// @ts-nocheck
import { useState, useLayoutEffect, useEffect } from "react";
import jwt_decode from 'jwt-decode';
import { useCookies } from 'react-cookie';
import { UAParser } from "ua-parser-js";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function MyCalendar() {
    const [cookies, setCookie, removeCookie] = useCookies(['sessionToken']);
    const [myEmail, setMyEmail] = useState("");

    // 使用ブラウザ
    const [browserInfo, setBrowserInfo] = useState('');

    useEffect(() => {
        const uaParserResult = UAParser(window.navigator.userAgent);

        // 使用ブラウザがChromeか判定
        if (uaParserResult.browser['name'] != 'Chrome') {
            setBrowserInfo('notChrome');
        }

    }, []);

    useLayoutEffect(() => {
        if (!cookies.sessionToken) {
            //トークンがない
        } else {
            const decoded = jwt_decode<{ [name: string]: string }>(cookies.sessionToken);
            var myEmail = decoded.data.EMAIL;
            //@zenken.co.jpを削除
            myEmail = myEmail.replace("@zenken.co.jp", "");

            setMyEmail(myEmail);
        }
    }, []);

    return (
        <>
            <Row className="align-items-end title-area">
                <Col sm={8} className="position-relative">
                    <div className="d-flex align-items-end">
                        <h2 className="icon-title type-calendar my-0">スケジュール</h2>
                        {browserInfo === 'notChrome' ? (
                            <div className="not-chrome">※chromeのみ利用可</div>
                        ) : (
                            <></>
                        )}
                    </div>
                </Col>
                <Col sm={4}>
                    <p className="text-end to_list"><a href="https://calendar.google.com/" target="_blank" className="type-blank">Googleカレンダーを開く</a></p>
                </Col>
            </Row>
            <div className="calendar_wrap">
                <div className="contents_wrap">
                    <iframe src={"https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Asia%2FTokyo&showTz=0&showPrint=0&showTitle=0&showNav=1&showDate=1&showCalendars=1&src=" + myEmail + "%40zenken.co.jp&color=%23039BE5"}
                        style={{ "borderWidth": 0 }} width="100%" height="600" frameBorder="0" scrolling="no"></iframe>
                </div>
            </div>
        </>);
}

export default MyCalendar;