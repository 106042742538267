// @ts-nocheck
import { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { useCookies } from 'react-cookie';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

//JSONデータ
import BoardVoice from '../../../files/board-voice.json';
import DepartmentBlogJson from '../../../files/departmentBlog.json';

function NewPostInHeader() {

  //テスト環境
  const baseUrl = process.env.REACT_APP_PUBLIC_URL || '';
  /* 配信先指定と認証に必要なstate */
  // Cookieから取得したトークンを保持
  const [cookies, setCookie, removeCookie] = useCookies(["sessionToken"]);
  // トークンをデコードして取得したjtiを保持（パラメータに付与する用）
  const [cookieJti, setCookieJti] = useState();

  //トークンをデコードして取得したemailを保持（パラメータに付与する用）
  const [cookieEmail, setCookieEmail] = useState();

  //ローディング
  const [loading, setLoading] = useState(true);

  //restAPI取得
  const [posts, setPosts] = useState([]);

  // WP RESTAPI 環境別ベースURL 役員ブログ用
  const urlBaseBigWig = process.env.REACT_APP_WP_REST_API_PATH_BIGWIG;

  // WP RESTAPI 環境別ベースURL 事業部ブログ用
  const urlBaseDepartmentBlog = process.env.REACT_APP_WP_REST_API_PATH_DEPARTMENTBLOG;

  // 記事投稿の1週間内有無フラグ
  const [postFlg, setPostFlg] = useState(true);

  // ログイン時に生成されたトークンをデコードしたデータをstateに格納
  useEffect(() => {
    if (cookieJti === undefined) {
      SettingLonginData();
    }
  }, []);
  /* ログイン時に生成されたトークンをデコードしたデータをstateに保持 */
  function SettingLonginData() {
    // Cookieからトークンを取得
    const token = cookies.sessionToken;
    // デコード
    const decodedToken = jwt_decode<{ [name: string]: string }>(token);

    /* パラメータ（zenken）にセットしてリクエストするためのjtiの値を取得してstateにセット
    →WP側で値の確認を行う（確認できないリクエストには401を返すようにする）*/
    setCookieJti(decodedToken.jti);

    /* パラメータ（email）にセットしてリクエストするためのemailの値を取得してstateにセット*/
    setCookieEmail(decodedToken.data.EMAIL);
  }

  useEffect(() => {
    //初期表示時用
    //URLパラメータ「default」の有無をチェック
    const params = new URLSearchParams(window.location.search);
    const defaultParam = params.get('default');
    getPosts();
  }, [cookieJti])

  // ただのソート関数
  function descTimeSort(a, b) {
    return a < b ? 1 : -1;
  }

  function getPosts() {

    // ISO 8601形式で現在時刻より7日前の日付を取得
    const now = new Date();
    now.setDate(now.getDate() - 7);
    var timeISO = now.toISOString();

    // 7日前の日付をRESTAPIのクエリパラメータ用に整形
    var postAfter = '&after=' + timeISO.substring(0, timeISO.indexOf('.'));

    var wpRestUrlBigWig = urlBaseBigWig + 'wp-json/wp/v2/posts?_fields=title,date,media_department,id' + postAfter + '&zenken='

    var postList = [];

    if (cookieJti !== undefined) {
      const fetchUrl = wpRestUrlBigWig + cookieJti;
      fetch(fetchUrl, { method: 'GET' })
        .then(res => res.json())
        .then(data => {
          //ここで取得したデータにBoardVoiceのtitleを追加する
          for (var i = 0; i < data.length; i++) {
            for (var j = 0; j < BoardVoice.length; j++) {
              if (data[i].media_department.id == BoardVoice[j].catId) {
                //新規項目を追加
                data[i].blogTitle = "ブログ「" + BoardVoice[j].title + "」）";
                data[i].blogUrl = "/board-voice?id=" + data[i].id + "&default=";
              }
            }
          }
          for (var i = 0; i < data.length; i++) {
            postList.push(data[i]);
          }
          if (data.length != 0) {

            // 1週間以内の投稿がある場合
            setPostFlg(true);
            setLoading(false);
          } else {

            // 1週間以内の投稿がない場合
            setPostFlg(false);
          }
        })
    }

    var wpRestUrlDepartmentBlog = urlBaseDepartmentBlog + 'wp-json/wp/v2/posts?_fields=title,date,media_department,id,parent_info' + postAfter + '&zenken='

    if (cookieJti !== undefined) {
      const fetchUrl = wpRestUrlDepartmentBlog + cookieJti;
      fetch(fetchUrl, { method: 'GET' })
        .then(res => res.json())
        .then(data => {
          for (var i = 0; i < data.length; i++) {
            var termId = data[i]["parent_info"]["term_id"];
            for (let j = 0; j < DepartmentBlogJson.length; j++) {
              if (termId == DepartmentBlogJson[j]["parent"]) {
                //新規項目を追加
                data[i].blogTitle = "）";
                data[i].blogUrl = "/deptBlog?id=" + data[i].id + "&default=";
              }
            }
          }
          for (var i = 0; i < data.length; i++) {
            postList.push(data[i]);
          }
          if (!postList.length == 0) {
            for (var i = 0; i < postList.length; i++) {
              var substringStr = postList[i].date.substring(0, postList[i].date.indexOf("T"));
              var replaceStr = substringStr.replaceAll('-', '/');
              postList[i].date = replaceStr;
            }
          }
          postList.sort((a, b) => descTimeSort(a.date, b.date));
          if (data.length != 0) {

            // 1週間以内の投稿がある場合
            setPostFlg(true);
            setLoading(false);

          } else {

            // 1週間以内の投稿がない場合
            setPostFlg(false);
          }
        })
    }
    setPosts(postList);
  }

  useEffect(() => {
    //すべてのbv_titleを取得
    var bv_title = document.getElementsByClassName("bv_title");

    if (!bv_title.length > 0) {
      //クラス名activeを10秒ごとに移動
      var i = 0;
      setInterval(function () {
        i++;
        if (i >= bv_title.length) {
          i = 0;
        }
        for (var j = 0; j < bv_title.length; j++) {
          bv_title[j].classList.remove("active");
        }
        if (bv_title[i] == null) {
          return
        }
        bv_title[i].classList.add("active");
      }, 7500);
    }
  }, [posts])

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <>
          {postFlg == false ? (
            <></>
          ) : (
            <>
              {
                posts.map((data, index) => {
                  return (
                    <a href={baseUrl + data.blogUrl + data.media_department.id} className={"bv_title" + (index == 0 ? " active" : "")}>
                      {
                        (data.title.rendered + "（" + data.media_department.name
                          + data.blogTitle).length > 40 ?
                          (data.title.rendered + "（" + data.media_department.name
                            + data.blogTitle).substr(0, 40) + "…" :
                          (data.title.rendered + "（" + data.media_department.name
                            + data.blogTitle)
                      }
                    </a>
                  )
                })
              }
            </>
          )}
        </>
      )}
    </>
  );





}

export default NewPostInHeader;