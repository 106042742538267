
//ヘッダー
import Header from '../../component/page_parts/header';
// フッター
import Footer from '../../component/page_parts/footer';

// bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

//タブコンテンツ
import Contents1 from './tab-content/content1';
import Contents2 from './tab-content/content2';
import Contents3 from './tab-content/content3';

// パンくずリスト
import TableOfContents from "../../component/page_parts/common/table_of_contents";

import React from 'react';
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet-async';

// 委員会ページ
function Committee() {

  // GAID
  var gaId = process.env.REACT_APP_GA_ID;

  const element = document.getElementById("left-tabs-example-tab-first1");
  const userName = localStorage.getItem("user_name") || 'Unknown User'; // Provide a default value
  if (gaId) { // Check if gaId is defined
    ReactGA.initialize(gaId);
    ReactGA.send({
      hitType: "event",
      eventCategory: userName,
      eventAction: '委員会-タブ切り替え',
      eventLabel: element?.textContent,
    });
  }

  // GA4タブ押下時計測
  function sendTabNameToGoogleAnalytics(e: React.MouseEvent<HTMLButtonElement>) {
    var element = document.getElementById(e.currentTarget.id); // 'e.currentTarget' を使用して安全に要素にアクセス
    var userName = localStorage.getItem("user_name") || 'Unknown User'; // Provide a default value
    console.log(element?.textContent);
    if (gaId) { // Check if gaId is defined
      ReactGA.initialize(gaId);
      ReactGA.send({
        hitType: "event",
        eventCategory: userName,
        eventAction: '委員会-タブ切り替え',
        eventLabel: element?.textContent,
      });
    }
  }

  return (
    <>
      <Helmet>
        <title>Z-link 委員会</title>
      </Helmet>
      <Header />
      <div className="theme-committee">
        <div className="content-header">
          <div className="container">
            <Row>
              <Col sm={7}>
                <h1 className='page-title type-committee'>委員会</h1>
                <p className="description">Zenken株式会社にある委員会をご案内します。</p>
              </Col>
              <Col sm={5}>
              </Col>
            </Row>
          </div>
        </div>
        <TableOfContents />
        <div className="container">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first1">
            <Nav variant="pills" className="mt-5">
              <Nav.Item>
                <Nav.Link className="text-center" eventKey="first1" onClick={sendTabNameToGoogleAnalytics}>個人情報保護管理委員会</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="text-center" eventKey="first2" onClick={sendTabNameToGoogleAnalytics}>衛生委員会</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="text-center" eventKey="first3" onClick={sendTabNameToGoogleAnalytics}>WEB統制委員会</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first1">
                <Contents1 />
              </Tab.Pane>
              <Tab.Pane eventKey="first2">
                <Contents2 />
              </Tab.Pane>
              <Tab.Pane eventKey="first3">
                <Contents3 />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Committee;