// @ts-nocheck
import { useState, useEffect } from "react";
import { Helmet } from 'react-helmet-async';
import ReactGA from "react-ga4";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// デコード
import jwt_decode from "jwt-decode";

// Cookies
import { useCookies } from "react-cookie";

// ヘッダー
import Header from "../component/page_parts/header";

// フッター
import Footer from "../component/page_parts/footer";

// json
// dev環境
import departmentWpJsonDev from '../files/wp_info_department_dev.json';
import buildingWpJsonDev from '../files/wp_building_dev.json';
// 本番環境
import departmentWpJsonProd from "../files/wp_info_department.json";
import buildingWpJsonProd from "../files/wp_building.json";

// css
import "../css/style.min.css";
import "../css/inline.css";
import "@wordpress/block-library/build-style/common.css";
import "@wordpress/block-library/build-style/style.css";
import "@wordpress/block-library/build-style/theme.css";

// パンくずリスト
import TableOfContents from "../component/page_parts/common/table_of_contents";

// インフォ詳細
function InfoDetail() {

  // WP RESTAPI 環境別ベースURL
  const urlBase = process.env.REACT_APP_WP_REST_API_PATH_INFO;

  // 環境に応じたJSONの内容をstate初期値にする変数に格納
  let departmentWpJson, buildingWpJson;
  if (urlBase === 'https://ztree.cfbx.jp/info-wp-dev/') { // 開発環境の場合
    departmentWpJson = departmentWpJsonDev;
    buildingWpJson = buildingWpJsonDev;
  } else { // 本番環境の場合
    departmentWpJson = departmentWpJsonProd;
    buildingWpJson = buildingWpJsonProd;
  }

  const [post, setPosts] = useState(null); // 記事情報を格納するステート
  const [data, setData] = useState([]);

  //Cookie-userId
  const [cookiesUserId, setCookiesUserId, removeCookiesUserId] = useCookies([
    "userId",
  ]);

  /* 配信先指定と認証に必要なstate */
  // Cookieから取得したトークンを保持
  const [cookies, setCookie, removeCookie] = useCookies(["sessionToken"]);
  // トークンをデコードして取得したユーザー情報を保持
  const [loginData, setLoginData] = useState({});
  // トークンをデコードして取得したjtiを保持（パラメータに付与する用）
  const [cookieJti, setCookieJti] = useState();

  // WP設定値の写しを保持
  const [departmentWpAll, setDepartmentWpAll] = useState(departmentWpJson);

  // 組織idと、その組織が属する先祖組織idの配列を保持
  const [departmentIds, setDepartmentIds] = useState([]);

  // 配信先対象かどうかのflagを保持
  const [departmentFlag, setDepartmentFlag] = useState(false);
  const [buildingFlag, setBuildingFlag] = useState(false);

  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  // GAID
  const gaId = process.env.REACT_APP_GA_ID;

  useEffect(() => {
    const h2Element = document.querySelector('h2');
    console.log(h2Element?.textContent);
    ReactGA.initialize(gaId);
    ReactGA.send({
      hitType: "event",
      eventAction: 'インフォメーション 詳細 記事タイトル',
      eventLabel: h2Element?.textContent,
    });
  }, [data]);

  /* ログイン時に生成されたトークンをデコードしたデータをstateに保持 */
  function SettingLonginData() {
    // Cookieからトークンを取得
    const token = cookies.sessionToken;
    // デコード
    const decodedToken = jwt_decode<{ [name: string]: string }>(token);
    // console.log(decodedToken);
    setLoginData(decodedToken.data);

    /* パラメータ（zenken）にセットしてリクエストするためのjtiの値を取得してstateにセット
    →WP側で値の確認を行う（確認できないリクエストには401を返すようにする）*/
    setCookieJti(decodedToken.jti);
  }

  /* - ユーザーの所属する組織id（WP側）を取得 */
  /* - 所属組織のビル内外に応じてパラメータをセット */
  function getParameter() {
    // DB（トークン）の所属組織のスラッグから、WP側の所属情報を取得
    let departmentSlug = null;
    if (loginData.SECTION_SLUG3) {
      departmentSlug = loginData.SECTION_SLUG3;
    } else if (loginData.SECTION_SLUG2) {
      departmentSlug = loginData.SECTION_SLUG2;
    } else if (loginData.SECTION_SLUG1) {
      departmentSlug = loginData.SECTION_SLUG1;
    } else if (loginData.DEPARTMENT_SLUG) {
      departmentSlug = loginData.DEPARTMENT_SLUG;
    } else if (loginData.OFFICE_SLUG) {
      departmentSlug = loginData.OFFICE_SLUG;
    } else {
      // 所属スラッグがない場合、 all 所属扱いにする
      departmentSlug = 'all';
    }
    // DBのスラッグは大文字で登録されていることがあるので、WPの仕様に合わせて小文字変換
    departmentSlug = departmentSlug.toLowerCase();
    // console.log('departmentSlug: ', departmentSlug);
    let wpDepartment = departmentWpAll.find((v) => v.slug == departmentSlug);

    // wpDepartmentがnullの場合は、all 所属扱いにする
    if (wpDepartment == null) {
      wpDepartment = departmentWpAll.find((v) => v.slug == 'all');
    }

    // WP側の所属組織とその祖先組織idの配列を取得し、stateを更新
    const departmentIdsArr = wpDepartment.ancestors_ids;
    setDepartmentIds(departmentIdsArr);
  }

  function getPosts() {
    //URLパラメーターからIDを取得
    const url = new URL(window.location.href);
    const id = url.searchParams.get("id");

    var wpRestUrl = urlBase + `wp-json/wp/v2/information/${id}?zenken=`;

    const responce = fetch(
      wpRestUrl +
      cookieJti
    );
    var res_data = responce.then((response) => response.json());
    res_data.then(function (value) {
      console.log('value: ', value);
      setData(value);

      /* 配信先対象かどうかで flag 更新 */
      // 該当記事の配信先組織にユーザーの所属組織が含まれるか
      setDepartmentFlag(
        value.information_department.some((val) => departmentIds.includes(val))
      );
      // 該当記事の配信先ビル内外にユーザーの所属組織が該当しているか
      const buildingState =
        loginData.BUILDING_SLUG === "inBuilding" ? "in-building" : "";
      if (value.information_building_slug.length === 0) {
        // ビル内外の指定がない場合
        setBuildingFlag(true);
      } else {
        // ビル内のみの指定がある場合
        setBuildingFlag(
          value.information_building_slug.some((val) => val === buildingState)
        );
      }
    });
  }

  // 曜日を取得する関数
  const getDayOfWeek = (date: Date) => {
    return new Intl.DateTimeFormat('ja-JP', { weekday: 'short' }).format(date);
  };

  // 記事投稿日の日付フォマット▶　n年n月j日(D) 時間:分
  const doDateFormat = (date: string) => {
    const dateFormat = new Date(date);
    const formattedDate = `${dateFormat.getFullYear()}/${("0" + (dateFormat.getMonth() + 1)).slice(-2)}/${("0" + dateFormat.getDate()).slice(-2)} (${getDayOfWeek(dateFormat)}) ${("0" + dateFormat.getHours()).slice(-2)}:${("0" + dateFormat.getMinutes()).slice(-2)}`;
    return formattedDate;
  };

  // 既読処理
  function readInfo() {

    // 表示投稿ID取得
    const url = new URL(window.location.href);
    const id = url.searchParams.get("id");

    // 表示投稿IDが既読ローカルストレージに存在しているか確認
    if (!localStorage.getItem("readInfoIds")) {
      // 既読ローカルストレージが存在しない場合は、既読ローカルストレージ作成、表示投稿IDを格納
      // 既読ローカルストレージに格納
      localStorage.setItem("readInfoIds", id);

    } else {
      // 既読ローカルストレージが存在している場合は、既読ローカルストレージに表示投稿IDを格納

      // 既読ローカルストレージを既読ID郡リストに格納
      const readInfoIdsList = localStorage.getItem("readInfoIds").split(",");

      // 既読ID郡リストに表示投稿IDが存在しているか確認
      if (readInfoIdsList.includes(id) == true) {
        // 既読ID郡リストに表示投稿ID存在している場合は、処理無し
      } else {
        // 既読ID郡リストに表示投稿ID存在していない場合は、既読ID郡リストに表示投稿IDを追加

        // 既読ローカルストレージと表示投稿IDを結合
        const newReadInfoIds = localStorage.getItem("readInfoIds") + "," + id;

        // 新たに既読扱いとなる表示投稿IDを結合した文字列で、既読ローカルストレージを上書き
        localStorage.setItem("readInfoIds", newReadInfoIds);
      }
    }
  }

  // 残日数の返却処理
  function calculateRemainingDays(deadline: string) {
    const today = new Date();
    const deadlineDate = new Date(deadline);
    console.log(deadlineDate);
    const timeDiff = deadlineDate.getTime() - today.getTime();
    const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24)); // ミリ秒を日数に変換
    return daysRemaining; // 残日数が負の場合はそのまま負の数字を返す
  }

  useEffect(() => {

    // 投稿別で既読かどうかを判断する
    readInfo();

    // ログイン時に生成されたトークンをデコードしたデータをstateに格納
    SettingLonginData();
  }, []);

  // 配信先指定に必要なパラメータ情報を取得
  useEffect(() => {
    if (Object.keys(loginData).length) {
      // loginData が空ではない時
      getParameter();
    }
  }, [loginData]);

  useEffect(() => {
    // // ユーザー別インフォメーション既読情報更新処理の呼び出し
    // updateInfoIdsByUser();
    if (departmentIds.length !== 0 && cookieJti !== undefined) {
      getPosts();
    }
  }, [departmentIds]);

  return (
    <div key={data.id}>
      <Helmet>
        <title>Z-link インフォメーション 詳細</title>
      </Helmet>
      <Header />
      <div className="theme-info">
        {
          //dataが空じゃない時の判定
          data.length !== 0 && (
            <>
              <div className="content-header">
                <div className="container">
                  <Row>
                    <Col sm={12}>
                      <h1 className='page-title type-info no-description'>インフォメーション</h1>{/* ページタイトル */}
                    </Col>
                  </Row>
                </div>
              </div>
              <TableOfContents />
              <div className="container">
                <div className="info_detail" id={data.id}>
                  {departmentFlag && buildingFlag ? (
                    <>
                      <div className="title-wrap">
                        <div className="">
                          <span className="category">
                            {data.information_taxonomy_name}
                          </span>
                          <datetime className="date-time">
                            {doDateFormat(data.date)}
                          </datetime>
                        </div>
                        <div className="title-area">
                          {new Date(data.date) >= new Date(Date.now() - 3 * 24 * 60 * 60 * 1000) && (
                            <div dangerouslySetInnerHTML={{
                              __html: '<span class="new-icon">NEW</span>'
                            }} />
                          )}
                          {data.acf.important_decision && (
                            <div className="info_deadline_date">
                              {data.acf.important_decision === true && (
                                <span className="deadline-mark">期日あり</span>
                              )}
                            </div>
                          )}
                          {data.acf.important_decision && (
                            <div className="info_deadline_date">
                              {data.acf.deadline_date && new Date(data.acf.deadline_date) >= new Date(new Date().setHours(0, 0, 0, 0)) && (
                                <p>
                                  {data.acf.deadline_date}まで</p>
                              )}
                            </div>
                          )}
                          <h2
                            className=""
                            dangerouslySetInnerHTML={{
                              __html: data.title.rendered.replace(/<span class="new-icon">NEW<\/span>/g, '')
                            }}
                          ></h2>
                        </div>
                      </div>

                      <div
                        className="detail-wrap"
                        dangerouslySetInnerHTML={{
                          __html: data.content && data.content.rendered,
                        }}
                      ></div>
                    </>
                  ) : (
                    <>
                      <h1 className="mt-5 mb-5">
                        該当インフォメーションの閲覧権限がありません
                      </h1>
                      <p className="mb-5">
                        一覧や検索からインフォメーションをご覧ください。
                      </p>
                    </>
                  )}
                </div>
                <div className="return_button">
                  <a href={baseUrl + "/info_lists"}>
                    一覧ページに戻る
                  </a>
                </div>
              </div>
            </>
          )
        }
      </div>
      <Footer />
    </div >
  );
}
export default InfoDetail;
