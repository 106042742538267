// @ts-nocheck
import React from 'react';
import { useState, useEffect } from 'react';
import download from 'downloadjs';

//ヘッダー
import Header from '../../component/page_parts/header';

// フッター
import Footer from '../../component/page_parts/footer';

//tabs
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Console } from 'console';

//scrolltop
import ReturnTopButton from '../../component/page_parts/common/scrolltop';
import { set } from 'react-hook-form';
import { cp } from 'fs';

type FormData = {
  category: string;
  text: string;
  layout: string;
  fileName: string;
  movieUrl: string;
  movieTitle: string;
  question: string;
  answer: string;
};

function Keiri_cms() {
  //カテゴリー
  const [categoryName_faq, setCategory_faq] = useState([]);

  //faqデータ
  const [json_faq, setJson_faq] = useState([]);

  //sinsei_ryuiデータ
  const [json_sinsei_ryui, setJson_sinsei_ryui] = useState([]);

  //manualデータ
  const [json_manual, setJson_manual] = useState([]);

  //CMSコンテンツリスト
  const [jsonData_content, setJsonData_content] = useState([]);
  const [json_content, setJson_content] = useState([]);

  //formの入力検知
  const [dev, setDv] = useState([]);
  const [formData, setFormData] = useState<FormData>({
    category: '',
    text: '',
    layout: '',
    fileName: '',
    movieUrl: '',
    movieTitle: '',
    question: '',
    answer: '',
  });

  useEffect(() => {
    //deleteCountをリセット
    localStorage.setItem('deleteCount', '0');

    fetch(process.env.REACT_APP_API_PATH + 'keiri_cms/?read')
      .then((res) => res.json())
      .then((json) => {
        localStorage.setItem('keiri_cms', JSON.stringify(json));
        //jsonのキーがcategoryListのものをセット
        setCategory_faq(json[0].categoryList);

        //jsonのキーがfaqのものをセット
        setJson_faq(json[0].faq);

        //sinsei_ryui
        setJson_sinsei_ryui(json[0].sinsei_ryui);
        // console.log(json[0].sinsei_ryui);

        //manual
        setJson_manual(json[0].manual);

        //content
        setJson_content(json[0].contentList);

        // //jsonからcategoryを取り出す
        // const category_value = json[0].faq.map((json) => json.category);
        // //重複を削除
        // const category_value_unique = category_value.filter((x, i, self) => self.indexOf(x) === i);
        // setCategory_faq(category_value_unique);
      });
  }, []);

  //CMS内容の変更を検知する
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    //変更された要素の親要素を取得
    const parent = e.target.parentNode.parentNode;

    //変更された要素の親要素のクラス名を取得
    const parent_class = parent.className;
    // console.log('parent_class:' + parent_class);
    // console.dir(formData);

    //変更された要素の親要素のクラス名によって処理を分岐
    if (parent_class.includes('qa-sinsei_ryui')) {
      //クラス名を追加
      parent.classList.add('changed_ryuui');
      let id = e.target.id;
      let val = e.target.value;
      let parentNode = e.target.parentNode;
      let jsonImage = parentNode.nextElementSibling;
      let inputImg = jsonImage.children[1];
      let jsonMovieTitle = jsonImage.nextElementSibling;
      let inputMovieURL = jsonMovieTitle.children[0];
      let inputMovieTitle = jsonMovieTitle.children[1];
      if (id == 'select' && val != 4) {
        //レイアウトが動画以外のとき
        jsonImage.style.display = 'block';
        jsonMovieTitle.style.display = 'none';
      } else if (id == 'select' && val == 4) {
        //レイアウトが動画のとき
        jsonImage.style.display = 'none';
        jsonMovieTitle.style.display = 'block';
      } else if (id == 'add' && val != 4) {
        jsonImage.style.display = 'block';
        jsonMovieTitle.style.display = 'none';
      } else if (id == 'add' && val == 4) {
        jsonImage.style.display = 'none';
        jsonMovieTitle.style.display = 'block';
      }
    } else if (parent_class.includes('qa-faq')) {
      //クラス名を追加
      parent.classList.add('changed_faq');
    } else if (parent_class.includes('qa-manual')) {
      //クラス名を追加
      parent.classList.add('changed_manual');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    /**
     * 機能について
     * フォームの中身を見て「public/json/keiri_qa.json」の内容を更新する。
     */

    let select = document.querySelector(
      '.react-tabs__tab--selected'
    ).textContent;
    console.log('select:' + select);
    if (select == '留意点' || select == 'FAQ' || select == 'マニュアル') {
      //留意点
      for (let i = 0; i < json_sinsei_ryui.length; i++) {
        //qa-sinsei_ryui内のinputを取得
        const qa = document.querySelectorAll('.qa-sinsei_ryui');
        // console.log('qa:' + qa);
        const textarea = qa[i].querySelectorAll('textarea');
        const input = qa[i].querySelectorAll('input');
        const select = qa[i].querySelectorAll('select');
        //inputの中身を取得
        const text = textarea[0].value;
        const fileName = input[1].value;
        //selectの中身を取得
        const category = select[0].value;
        const layout = select[1].value;
        let movieUrl;
        let movieTitle;
        if (layout == 4 && !movieUrl && !movieTitle) {
          //jsonの中身を更新
          json_sinsei_ryui[i].text = text;
          json_sinsei_ryui[i].category = category;
          json_sinsei_ryui[i].layout = layout;
          json_sinsei_ryui[i].movieUrl = textarea[1].value;
          json_sinsei_ryui[i].movieTitle = textarea[2].value;
        } else if (layout == 2 || layout == 3) {
          //jsonの中身を更新
          json_sinsei_ryui[i].text = text;
          json_sinsei_ryui[i].fileName = fileName;
          json_sinsei_ryui[i].category = category;
          json_sinsei_ryui[i].layout = layout;
          json_sinsei_ryui[i].movieUrl = '';
          json_sinsei_ryui[i].movieTitle = '';
        } else {
          //jsonの中身を更新
          json_sinsei_ryui[i].text = text;
          json_sinsei_ryui[i].fileName = 'なし';
          json_sinsei_ryui[i].category = category;
          json_sinsei_ryui[i].layout = layout;
          json_sinsei_ryui[i].movieUrl = '';
          json_sinsei_ryui[i].movieTitle = '';
        }
      }
      //FAQフォームの中身をすべて取得
      for (let i = 0; i < json_faq.length; i++) {
        //qa-faq内のinputを取得
        const qa = document.querySelectorAll('.qa-faq');
        const textarea = qa[i].querySelectorAll('textarea');
        const input = qa[i].querySelectorAll('input');
        const select = qa[i].querySelectorAll('select');
        //inputの中身を取得
        const question = textarea[0].value;
        const answer = textarea[1].value;
        const fileName = input[1].value;
        //selectの中身を取得
        const category = select[0].value;
        const layout = select[1].value;
        //jsonの中身を更新
        json_faq[i].question = question;
        json_faq[i].answer = answer;
        json_faq[i].fileName = fileName;
        json_faq[i].category = category;
        json_faq[i].layout = layout;
      }
      //manualフォームの中身をすべて取得
      for (let i = 0; i < json_manual.length; i++) {
        //qa-manual内のinputを取得
        const qa = document.querySelectorAll('.qa-manual');
        const input = qa[i].querySelectorAll('input');
        const select = qa[i].querySelectorAll('select');

        //inputの中身を取得
        const category = select[0].value;
        const fileName = input[2].value;
        const fileName_jp = input[0].value;

        //jsonの中身を更新
        json_manual[i].category = category;
        json_manual[i].fileName = fileName;
        json_manual[i].fileName_jp = fileName_jp;
      }
    }
    // }

    //contentListは固定値で定義
    const contentList = [
      {
        category: '留意点',
        tag: 'shinsei_add',
      },
      {
        category: 'マニュアル',
        tag: 'manual_add',
      },
      {
        category: 'FAQ',
        tag: 'faq_add',
      },
    ];

    //jsonを出力
    //各jsonをまとめる
    //それぞれ「categoryList」「tatekae_ryui」「sinsei_ryui」「faq」「manual」というキーを持つ
    const json = {
      categoryList: categoryName_faq,
      contentList: contentList,
      sinsei_ryui: json_sinsei_ryui,
      faq: json_faq,
      manual: json_manual,
      content: json_content,
    };

    //新規jsonデータ
    const json_str = JSON.stringify([json]);

    //変更対象を取得
    var changed_ryuui = document.querySelectorAll('.changed_ryuui');
    var changed_faq = document.querySelectorAll('.changed_faq');
    var changed_manual = document.querySelectorAll('.changed_manual');

    console.log(changed_ryuui);
    console.log(changed_faq);
    console.log(changed_manual);

    if (
      changed_ryuui.length == 0 &&
      changed_faq.length == 0 &&
      changed_manual.length == 0 &&
      localStorage.getItem('deleteCount') == 0
    ) {
      alert('変更点がありません');
      return;
    }

    let check = window.confirm(
      `留意点${changed_ryuui.length}件、FAQ${changed_faq.length}件、マニュアル${
        changed_manual.length
      }件の追加変更、また${
        localStorage.getItem('deleteCount')
          ? localStorage.getItem('deleteCount')
          : '0'
      }件の削除を反映します。よろしいですか？`
    );
    if (check == true) {
      //関数を呼び出す
      writeJson(json_str);
      localStorage.removeItem('deleteCount');
    }
  };

  async function writeJson(json_str) {
    const url = process.env.REACT_APP_API_PATH + 'keiri_cms/?update';
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(json_str),
    });
    const data = await response.json();
    // console.log(data);
    return data;
  }

  const handleDelete = (e) => {
    console.log('handleDeleteが実行されています');
    e.preventDefault();
    /**
     * 機能について
     * 押されたボタンの親要素の親要素を削除する。
     * また該当のjsonの要素も削除する。
     *
     */
    //削除する要素を取得
    const delete_element = e.target.parentElement.parentElement;

    //削除する要素のクラス名を取得
    const delete_class = delete_element.className;

    //削除する要素のクラス名によってjsonを分岐
    if (delete_class.includes('qa-sinsei_ryui')) {
      //削除する要素のインデックスを全体から何番目かを取得
      const delete_index = Array.prototype.indexOf.call(
        delete_element.parentElement.children,
        delete_element
      );
      let checkDelete = window.confirm(
        '留意点を一行削除します。よろしいですか？'
      );
      console.log(checkDelete);
      if (checkDelete === true) {
        //jsonから削除
        json_sinsei_ryui.splice(delete_index, 1);
      } else {
        return;
      }
    } else if (delete_class.includes('qa-faq')) {
      //削除する要素のインデックスを全体から何番目かを取得
      const delete_index = Array.prototype.indexOf.call(
        delete_element.parentElement.children,
        delete_element
      );
      let checkDelete = window.confirm('FAQを一行削除します。よろしいですか？');
      console.log(checkDelete);
      if (checkDelete === true) {
        //jsonから削除
        json_faq.splice(delete_index, 1);
      } else {
        return;
      }
    } else if (delete_class.includes('qa-manual')) {
      //削除する要素のインデックスを全体から何番目かを取得
      const delete_index = Array.prototype.indexOf.call(
        delete_element.parentElement.children,
        delete_element
      );
      let checkDelete = window.confirm(
        'マニュアルを一行削除します。よろしいですか？'
      );
      console.log(checkDelete);
      if (checkDelete === true) {
        //jsonから削除
        json_manual.splice(delete_index, 1);
      } else {
        return;
      }
    }
    console.log(delete_element);

    //ローカルストレージに削除件数を保存
    if (!localStorage.getItem('deleteCount')) {
      localStorage.setItem('deleteCount', 1);
    } else {
      //１を足して保存
      localStorage.setItem(
        'deleteCount',
        Number(localStorage.getItem('deleteCount')) + 1
      );
    }

    //削除する要素を削除
    delete_element.remove();
  };

  const onFileInputChange = (e) => {
    //ファイルが選択された時に実行される関数

    //親要素のクラス名を取得
    const parent_class =
      e.target.parentElement.parentElement.parentElement.className;
    // console.log(parent_class);

    //trタグ
    const tr =
      e.target.parentElement.parentElement.parentElement.parentElement
        .parentElement;
    // console.log(tr);

    //親要素のクラス名によってjsonを分岐
    if (parent_class.includes('qa-sinsei_ryui')) {
      //クラス名を付与
      tr.classList.add('changed_ryuui');
    } else if (parent_class.includes('qa-faq')) {
      tr.classList.add('changed_faq');
    } else if (parent_class.includes('qa-manual')) {
      tr.classList.add('changed_manual');
    }

    //ファイルが既に選択されているか確認
    console.log(e.target.files);

    //添付ファイル数を取得
    const fileCount = e.target.files.length;

    var fileName;

    //フォームデータの作成
    const formData = new FormData();

    //ファイル数により処理を分岐
    if (fileCount === 0) {
      //ファイルが選択されていない場合
      return;
    } else if (fileCount > 1) {
      //ファイルが複数選択されている場合
      var fileNames = [];
      //それぞれのファイルについて処理
      for (let i = 0; i < fileCount; i++) {
        //ファイルを追加
        formData.append('file', e.target.files[i]);
        const file = e.target.files[i];
        //ファイル名を取得
        fileName = file.name;
        //ファイル名を配列に追加
        fileNames.push(fileName);
        //API
        uploadFile(formData);
      }
      //配列を「｜」区切りの文字列に変換
      fileName = fileNames.join(' | ');
    } else {
      //ファイルが一つ選択されている場合
      const file = e.target.files[0];

      //ファイルを追加
      formData.append('file', e.target.files[0]);

      //ファイル名を取得
      fileName = file.name;
      //API
      uploadFile(formData);
    }

    //現在の要素を取得
    const currentElement = e.target.parentElement;
    console.log(currentElement);
    //次の要素のvalueを変更
    currentElement.nextElementSibling.value = fileName;
    //クラス名を追加(qa-file-name)
    currentElement.nextElementSibling.nextElementSibling.classList.add(
      'qa-file-name'
    );
    //クラス名を追加(selectFiles)
    currentElement.nextElementSibling.nextElementSibling.classList.add(
      'selectFiles'
    );

    //fileNameに「｜」が含まれている場合
    if (fileName.indexOf('|') !== -1) {
      //「｜」で分割
      const fileNames = fileName.split(' | ');
      //1つ目のファイル名をvalueに設定
      currentElement.nextElementSibling.nextElementSibling.textContent =
        fileNames[0];

      console.log(fileNames);
      //fileNamesの数-1の数だけ「currentElement.nextElementSibling.nextElementSibling」を複製
      for (let i = 0; i < fileNames.length - 1; i++) {
        //複製
        const cloneElement =
          currentElement.nextElementSibling.nextElementSibling.cloneNode(true);
        //複製した要素のvalueを変更
        cloneElement.textContent = fileNames[i + 1];
        //複製した要素を追加
        currentElement.parentElement.appendChild(cloneElement);
      }
    } else {
      //現在の要素と兄弟かつ一番下の要素のtextContentを変更
      currentElement.nextElementSibling.nextElementSibling.textContent =
        fileName;
    }
  };

  //ファイルアップロードAPI
  const uploadFile = (formData) => {
    console.log(formData.get('file'));
    const uploadFileUrl =
      process.env.REACT_APP_API_PATH + 'keiri_cms/fileUploads.php';
    console.log(uploadFileUrl);
    fetch(uploadFileUrl, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        console.log(response);
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAdd = (e) => {
    console.log('handleAddが実行されています');
    e.preventDefault();
    /**
     * 機能について
     * 押されたボタンに対応するテーブルに新しい行を追加する。
     */
    //押されたボタンのidを取得
    const id = e.target.id;
    if (id === 'shinsei_add') {
      console.log('shinsei_add');
      //sinsei_ryui-tbodyに新しい行を追加
      const tbody = document.getElementById('sinsei_ryui-tbody');
      const tr = document.createElement('tr');
      tr.className = 'qa qa-sinsei_ryui add-sinsei changed_ryuui';
      tr.innerHTML = `
          <td>
              <select class='cms-content__select selectCategory' name='category'>
            ${categoryName_faq.map(
              (categoryName) =>
                `<option value=${categoryName}>${categoryName}</option>`
            )}
            </select>
            </td>
            <td><textarea class="cms-content__textarea" name="text"></textarea></td>
            <td>
            <select class='cms-content__layout' defaultValue={json.layout} name='layout' id="add">
              <option value="1">テキスト</option>
              <option value="2">画像1枚</option>
              <option value="3">画像複数</option>
              <option value="4">動画</option>
            </select>
            </td>
            <td id='jsonImageAdd'>
            <label class='cms-content__file'>
              <input
                type='file'
                accept="image/*"
                multiple={true}
                style="display:none"
                class='cms-content__clipIcon newInput'
              />
  
              </label>            
            <input
              type='text'
              name='fileName'
              class='cms-tmpFileName__input'
              style="display:none"
            /><p class='img-label_def'>
                なし
              </p>
          </td>
          <td id='jsonMovieTitleAdd' style='display: none;'>
            <textarea
              className='cms-content__textarea'
              name='movieUrl'
              placeholder='YouTubeのURLを入力してください。'
            ></textarea>

            <textarea
              className='cms-content__textarea'
              name='movieTitle'
              placeholder='YouTubeのタイトルを入力してください。'
            ></textarea>
          </td>
            <td>
              <button type="button" class='cms-content__delete btn cms-delete btn del-btn'></button>
            </td>
          </tr>`;
      tbody.appendChild(tr);
      let addEl = document.getElementById('add');
      addEl.addEventListener('change', handleChange);
      console.log(add);
      //jsonに新しい要素を追加
      json_sinsei_ryui.push({
        category: '',
        text: '',
        layout: '',
        fileName: '',
        movieUrl: '',
        movieTitle: '',
      });
    } else if (id === 'manual_add') {
      console.log('manual_add');
      //manual-tbodyに新しい行を追加
      const tbody = document.getElementById('manual-tbody');
      const tr = document.createElement('tr');
      tr.className = 'qa qa-manual add-manual changed_manual';
      tr.innerHTML = `
          <td>
            <select class='cms-content__select' name='category'>
            ${categoryName_faq.map(
              (categoryName) =>
                `<option value=${categoryName}>${categoryName}</option>`
            )}
            </select>
            </td>
            <td>
            <input class='cms-content__textarea' type="text" name="text" defaultValue={json.fileName_jp} />
        </td>
        <td>
        <label class='cms-content__file'>
          <input
            class='cms-content__clipIcon newInput'
            type='file'
            accept="image/*"
            multiple={true}
            style="display:none"
          />

        </label>            
        <input
          type='text'
          name='fileName'
          class='cms-tmpFileName__input'
          style="display:none"
        /><p class='img-label_def'>
              なし
            </p>
      </td>
        <td>
          <button type="button" class='cms-content__delete btn cms-delete btn del-btn'></button>
        </td>
      </tr>
            `;
      tbody.appendChild(tr);
      //jsonに新しい要素を追加
      json_manual.push({
        category: '',
        text: '',
        fileName: '',
      });
    } else if (id === 'faq_add') {
      console.log('faq_add');
      //faq-tbodyに新しい行を追加
      const tbody = document.getElementById('faq-tbody');
      const tr = document.createElement('tr');
      tr.className = 'qa qa-faq add-faq changed_faq';
      tr.innerHTML = `
        <td>
          <select class='cms-content__select' name='category'>
            ${categoryName_faq.map(
              (categoryName) =>
                `<option value=${categoryName}>${categoryName}</option>`
            )}
          </select>
        </td>
        <td>
          <textarea class='cms-content__textarea' name="question" defaultValue={json.question}></textarea>
        </td>
        <td>
          <textarea class='cms-content__textarea' name="answer" defaultValue={json.answer}></textarea>
        </td>
        <td>
          <select class='cms-content__layout' defaultValue={json.layout} name='layout' id="add">
            <option value="1">テキスト</option>
            <option value="2">画像1枚</option>
            <option value="3">画像複数</option>
            </select>
        </td>
        <td>
        <label class='cms-content__file'>
          <input
            class='cms-content__clipIcon  newInput'
            type='file'
            accept="image/*"
            multiple={true}
            style="display:none"
          />

        </label>          
        <input
          type='text'
          name='fileName'
          class='cms-tmpFileName__input'
          style="display:none"
        /><p class='img-label_def'>
            なし
          </p>
      </td>
      <td>
        <button type="button" class='cms-content__delete btn cms-delete btn del-btn'></button>
      </td>
    </tr>`;
      tbody.appendChild(tr);
      //jsonに新しい要素を追加
      json_faq.push({
        category: '',
        question: '',
        answer: '',
        layout: '',
        fileName: '',
      });
    }

    console.log('イベント付与前');

    //全ての「del-btn」にイベントリスナーを追加
    const delBtns = document.getElementsByClassName('del-btn');
    console.log(delBtns);
    for (let i = 0; i < delBtns.length; i++) {
      delBtns[i].addEventListener('click', handleDelete);
    }

    //全ての「newInput」にイベントリスナーを追加
    const tmpFileNames = document.getElementsByClassName('newInput');
    console.log(tmpFileNames);
    for (let i = 0; i < tmpFileNames.length; i++) {
      tmpFileNames[i].addEventListener('change', onFileInputChange);
    }

    //全ての「img-label_def」にイベントリスナーを追加
    const imgLabels = document.getElementsByClassName('img-label_def');
    console.log(imgLabels);
    for (let i = 0; i < imgLabels.length; i++) {
      imgLabels[i].addEventListener('click', imgClick);
    }
  };

  const imgClick = (e) => {
    /**
     * 機能イメージ
     * 画像名のボックスがクリックされると、そのボックスを削除し
     * 該当行のjsonを更新する。
     *
     */
    console.log(e.target);
    //削除する要素を取得
    const delete_element = e.target.parentElement.parentElement;
    console.log(delete_element);

    //削除対象の画像がある要素のクラス名を取得
    const delete_class = delete_element.className;
    console.log(delete_class);

    //削除対象要素のテキストを取得
    var del_text = e.target.textContent;
    //スペースなどを削除
    del_text = del_text.replace(/\s+/g, '');
    console.log(del_text);

    console.log(json_sinsei_ryui);

    //削除対象文字がなしの場合は何もしない(追加要素用)
    if (del_text != 'なし') {
      //各クラス名を含むか判定
      //qa-sinsei_ryui
      //qa-manual
      //qa-faq
      if (delete_class.includes('qa-sinsei_ryui')) {
        console.log('sinsei_ryui');
        //クラス名を付与
        delete_element.classList.add('changed_ryuui');

        //jsonの該当行のfileNameを更新
        //クリックされた要素が全体から何番目かを取得
        const delete_index = Array.prototype.indexOf.call(
          delete_element.parentElement.children,
          delete_element
        );
        console.log(delete_index);

        //delete_index番目のjsonのfileNameを取得
        const delete_fileName = json_sinsei_ryui[delete_index].fileName;
        console.log('クリックされたファイル', delete_fileName);

        //delete_fileNameが空かどうか判定
        if (delete_fileName != '') {
          //空ではない場合は、削除対象のファイル名に「｜」を含むか判定
          if (delete_fileName.includes('|')) {
            //複数画像がある場合
            //「｜」で分割して配列に格納
            const delete_fileNames = delete_fileName.split('|');
            //各配列からスペースなどを削除
            for (let i = 0; i < delete_fileNames.length; i++) {
              delete_fileNames[i] = delete_fileNames[i].replace(/\s+/g, '');
            }
            console.log(delete_fileNames);

            //配列からdel_textと一致する内容を削除
            delete_fileNames.splice(delete_fileNames.indexOf(del_text), 1);
            console.log(delete_fileNames);
            //再度「｜」で結合
            const new_fileName = delete_fileNames.join('|');
            console.log(new_fileName);
            //delete_index番目のjsonのfileNameを更新
            json_sinsei_ryui[delete_index].fileName = new_fileName;

            //押された要素と同階層にある「cms-tmpFileName__input」のvalueを更新する
            var current = e.target.parentElement;
            console.log(current);
            var input = current.getElementsByClassName(
              'cms-tmpFileName__input'
            )[0];
            input.value = new_fileName;

            //押された要素を削除
            e.target.remove();
          } else {
            //単一画像の場合
            //delete_index番目のjsonのfileNameを空にする
            json_sinsei_ryui[delete_index].fileName = '';
            console.log(json_sinsei_ryui[delete_index]);
            console.log(json_sinsei_ryui);

            //押された要素と同階層にある「cms-tmpFileName__input」のvalueを空にする
            var current = e.target.parentElement;
            console.log(current);
            var input = current.getElementsByClassName(
              'cms-tmpFileName__input'
            )[0];
            input.value = '';

            //押された要素のテキストを「なし」にする
            e.target.textContent = 'なし';

            //押された要素のクラス名を削除
            e.target.classList.remove('selectFiles');
          }
        } else {
          //新規追加された要素の削除の場合は押された要素のテキストを「なし」にする
          e.target.textContent = 'なし';
        }
      } else if (delete_class.includes('qa-manual')) {
        console.log('manual');
        //クラス名を付与
        delete_element.classList.add('changed_manual');

        //jsonの該当行のfileNameを更新
        //クリックされた要素が全体から何番目かを取得
        const delete_index = Array.prototype.indexOf.call(
          delete_element.parentElement.children,
          delete_element
        );
        console.log(json_manual);
        console.log(delete_index);

        //delete_index番目のjsonのfileNameを取得
        const delete_fileName = json_manual[delete_index].fileName;
        console.log('クリックされたファイル', delete_fileName);

        //delete_fileNameが空かどうか判定
        if (delete_fileName != '') {
          //空ではない場合は、削除対象のファイル名に「｜」を含むか判定
          if (delete_fileName.includes('|')) {
            //複数画像がある場合
            //「｜」で分割して配列に格納
            const delete_fileNames = delete_fileName.split('|');
            //各配列からスペースなどを削除
            for (let i = 0; i < delete_fileNames.length; i++) {
              delete_fileNames[i] = delete_fileNames[i].replace(/\s+/g, '');
            }
            console.log(delete_fileNames);

            //配列からdel_textと一致する内容を削除
            delete_fileNames.splice(delete_fileNames.indexOf(del_text), 1);
            console.log(delete_fileNames);
            //再度「｜」で結合
            const new_fileName = delete_fileNames.join('|');
            console.log(new_fileName);
            //delete_index番目のjsonのfileNameを更新
            json_manual[delete_index].fileName = new_fileName;

            //押された要素と同階層にある「cms-tmpFileName__input」のvalueを更新する
            var current = e.target.parentElement;
            console.log(current);
            var input = current.getElementsByClassName(
              'cms-tmpFileName__input'
            )[0];
            input.value = new_fileName;

            //押された要素を削除
            e.target.remove();
          } else {
            //単一画像の場合
            //delete_index番目のjsonのfileNameを空にする
            json_manual[delete_index].fileName = '';
            console.log(json_manual[delete_index]);
            console.log(json_manual);

            //押された要素と同階層にある「cms-tmpFileName__input」のvalueを空にする
            var current = e.target.parentElement;
            console.log(current);
            var input = current.getElementsByClassName(
              'cms-tmpFileName__input'
            )[0];
            input.value = '';

            //押された要素のテキストを「なし」にする
            e.target.textContent = 'なし';

            //押された要素のクラス名を削除
            e.target.classList.remove('selectFiles');
          }
        } else {
          //新規追加された要素の削除の場合は押された要素のテキストを「なし」にする
          e.target.textContent = 'なし';
          console.log('faq');
          //jsonの該当行のfileNameを更新
          //クリックされた要素が全体から何番目かを取得
          const delete_index = Array.prototype.indexOf.call(
            delete_element.parentElement.children,
            delete_element
          );
          console.log(delete_index);

          //delete_index番目のjsonのfileNameを取得
          const delete_fileName = json_faq[delete_index].fileName;
          console.log('クリックされたファイル', delete_fileName);

          //delete_fileNameが空かどうか判定
          if (delete_fileName != '') {
            //空ではない場合は、削除対象のファイル名に「｜」を含むか判定
            if (delete_fileName.includes('|')) {
              //複数画像がある場合
              //「｜」で分割して配列に格納
              const delete_fileNames = delete_fileName.split('|');
              //各配列からスペースなどを削除
              for (let i = 0; i < delete_fileNames.length; i++) {
                delete_fileNames[i] = delete_fileNames[i].replace(/\s+/g, '');
              }
              console.log(delete_fileNames);

              //配列からdel_textと一致する内容を削除
              delete_fileNames.splice(delete_fileNames.indexOf(del_text), 1);
              console.log(delete_fileNames);
              //再度「｜」で結合
              const new_fileName = delete_fileNames.join('|');
              console.log(new_fileName);
              //delete_index番目のjsonのfileNameを更新
              json_faq[delete_index].fileName = new_fileName;

              //押された要素と同階層にある「cms-tmpFileName__input」のvalueを更新する
              var current = e.target.parentElement;
              console.log(current);
              var input = current.getElementsByClassName(
                'cms-tmpFileName__input'
              )[0];
              input.value = new_fileName;

              //押された要素を削除
              e.target.remove();
            } else {
              //単一画像の場合
              //delete_index番目のjsonのfileNameを空にする
              json_faq[delete_index].fileName = '';
              console.log(json_faq[delete_index]);
              console.log(json_faq);

              //押された要素と同階層にある「cms-tmpFileName__input」のvalueを空にする
              var current = e.target.parentElement;
              console.log(current);
              var input = current.getElementsByClassName(
                'cms-tmpFileName__input'
              )[0];
              input.value = '';

              //押された要素のテキストを「なし」にする
              e.target.textContent = 'なし';

              //押された要素のクラス名を削除
              e.target.classList.remove('selectFiles');
            }
          } else {
            //新規追加された要素の削除の場合は押された要素のテキストを「なし」にする
            e.target.textContent = 'なし';
          }
        }
      } else if (delete_class.includes('qa-faq')) {
        console.log('faq');
        //クラス名を付与
        delete_element.classList.add('changed_faq');

        //jsonの該当行のfileNameを更新
        //クリックされた要素が全体から何番目かを取得
        const delete_index = Array.prototype.indexOf.call(
          delete_element.parentElement.children,
          delete_element
        );

        //delete_index番目のjsonのfileNameを取得
        const delete_fileName = json_faq[delete_index].fileName;
        console.log('クリックされたファイル', delete_fileName);

        //delete_fileNameが空かどうか判定
        if (delete_fileName != '') {
          //空ではない場合は、削除対象のファイル名に「｜」を含むか判定
          if (delete_fileName.includes('|')) {
            //複数画像がある場合
            //「｜」で分割して配列に格納
            const delete_fileNames = delete_fileName.split('|');
            //各配列からスペースなどを削除
            for (let i = 0; i < delete_fileNames.length; i++) {
              delete_fileNames[i] = delete_fileNames[i].replace(/\s+/g, '');
            }
            console.log(delete_fileNames);

            //配列からdel_textと一致する内容を削除
            delete_fileNames.splice(delete_fileNames.indexOf(del_text), 1);
            console.log(delete_fileNames);
            //再度「｜」で結合
            const new_fileName = delete_fileNames.join('|');
            console.log(new_fileName);
            //delete_index番目のjsonのfileNameを更新
            json_faq[delete_index].fileName = new_fileName;

            //押された要素と同階層にある「cms-tmpFileName__input」のvalueを更新する
            var current = e.target.parentElement;
            console.log(current);
            var input = current.getElementsByClassName(
              'cms-tmpFileName__input'
            )[0];
            input.value = new_fileName;

            //押された要素を削除
            e.target.remove();
          } else {
            //単一画像の場合
            //delete_index番目のjsonのfileNameを空にする
            json_faq[delete_index].fileName = '';
            console.log(json_faq[delete_index]);
            console.log(json_faq);

            //押された要素と同階層にある「cms-tmpFileName__input」のvalueを空にする
            var current = e.target.parentElement;
            console.log(current);
            var input = current.getElementsByClassName(
              'cms-tmpFileName__input'
            )[0];
            input.value = '';

            //押された要素のテキストを「なし」にする
            e.target.textContent = 'なし';

            //押された要素のクラス名を削除
            e.target.classList.remove('selectFiles');
          }
        } else {
          //新規追加された要素の削除の場合は押された要素のテキストを「なし」にする
          e.target.textContent = 'なし';
          console.log('faq');
          //jsonの該当行のfileNameを更新
          //クリックされた要素が全体から何番目かを取得
          const delete_index = Array.prototype.indexOf.call(
            delete_element.parentElement.children,
            delete_element
          );
          console.log(delete_index);

          //delete_index番目のjsonのfileNameを取得
          const delete_fileName = json_faq[delete_index].fileName;
          console.log('クリックされたファイル', delete_fileName);

          //delete_fileNameが空かどうか判定
          if (delete_fileName != '') {
            //空ではない場合は、削除対象のファイル名に「｜」を含むか判定
            if (delete_fileName.includes('|')) {
              //複数画像がある場合
              //「｜」で分割して配列に格納
              const delete_fileNames = delete_fileName.split('|');
              //各配列からスペースなどを削除
              for (let i = 0; i < delete_fileNames.length; i++) {
                delete_fileNames[i] = delete_fileNames[i].replace(/\s+/g, '');
              }
              console.log(delete_fileNames);

              //配列からdel_textと一致する内容を削除
              delete_fileNames.splice(delete_fileNames.indexOf(del_text), 1);
              console.log(delete_fileNames);
              //再度「｜」で結合
              const new_fileName = delete_fileNames.join('|');
              console.log(new_fileName);
              //delete_index番目のjsonのfileNameを更新
              json_faq[delete_index].fileName = new_fileName;

              //押された要素と同階層にある「cms-tmpFileName__input」のvalueを更新する
              var current = e.target.parentElement;
              console.log(current);
              var input = current.getElementsByClassName(
                'cms-tmpFileName__input'
              )[0];
              input.value = new_fileName;

              //押された要素を削除
              e.target.remove();
            } else {
              //単一画像の場合
              //delete_index番目のjsonのfileNameを空にする
              json_faq[delete_index].fileName = '';
              console.log(json_faq[delete_index]);
              console.log(json_faq);

              //押された要素と同階層にある「cms-tmpFileName__input」のvalueを空にする
              var current = e.target.parentElement;
              console.log(current);
              var input = current.getElementsByClassName(
                'cms-tmpFileName__input'
              )[0];
              input.value = '';

              //押された要素のテキストを「なし」にする
              e.target.textContent = 'なし';

              //押された要素のクラス名を削除
              e.target.classList.remove('selectFiles');
            }
          } else {
            //新規追加された要素の削除の場合は押された要素のテキストを「なし」にする
            e.target.textContent = 'なし';
          }
        }
      }
    }
  };

  return (
    <>
      <Header />
      <div className='container keiri-related'>
        <div className='cms-wrap'>
          <input
            className='cms-submit__btn--top'
            id='cms-submit__btn--top'
            type='submit'
            value=''
            onClick={handleSubmit}
          />
          <div className='cms-top'>
            <h1>楽楽精算 経費関連管理画面</h1>
          </div>
          <p className='cms-text'>
            仕事のフォローだけじゃない、各種財産形成制度から休暇制度まで、便利な福利厚生が充実。
          </p>
          <hr></hr>

          <Tabs className='cms-tabs react-tabs' forceRenderTabPanel={true}>
            <TabList>
              {json_content.map((obj, index) => (
                <Tab key={index}>{obj.category}</Tab>
              ))}
            </TabList>
            {json_content.map((obj, index) => (
              <TabPanel
                key={index}
                className='cms-tabPanel react-tabs__tab-panel'
              >
                <div className='cms-add'>
                  <h2 className='cms-add__title'>{obj.category}</h2>
                  <button
                    type='button'
                    className='cms-add__btn btn'
                    id={obj.tag}
                    onClick={handleAdd}
                  />
                </div>
                {obj.category === '留意点' ? (
                  <>
                    <form>
                      <table className='cms-table table'>
                        <thead>
                          <tr>
                            {/* <th scope="col">#</th> */}
                            <th scope='col' style={{ width: '15%' }}>
                              カテゴリ
                            </th>
                            <th scope='col' style={{ width: '35%' }}>
                              文章
                            </th>
                            <th scope='col' style={{ width: '20%' }}>
                              レイ
                              <br />
                              アウト
                            </th>
                            <th scope='col' style={{ width: '20%' }}>
                              添付
                              <br />
                              ファイル
                            </th>
                            <th scope='col' style={{ width: '10%' }}>
                              削除
                            </th>
                          </tr>
                        </thead>
                        <tbody className='cms-content' id='sinsei_ryui-tbody'>
                          {json_sinsei_ryui.map((json, i) => (
                            <tr className='qa qa-sinsei_ryui'>
                              {/* <th className="cms-content__tag" scope="row">
                    {i + 1}
                  </th> */}
                              <td>
                                <select
                                  className='cms-content__select'
                                  defaultValue={json.category}
                                  name='category'
                                  onChange={handleChange}
                                >
                                  {categoryName_faq.map((categoryName) => (
                                    <option value={categoryName}>
                                      {categoryName}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                <textarea
                                  className='cms-content__textarea'
                                  name='text'
                                  onChange={handleChange}
                                  defaultValue={json.text}
                                />
                              </td>
                              <td>
                                <select
                                  className='cms-content__layout'
                                  id='select'
                                  defaultValue={json.layout}
                                  name='layout'
                                  onChange={handleChange}
                                >
                                  <option value='1'>テキスト</option>
                                  <option value='2'>画像1枚</option>
                                  <option value='3'>画像複数</option>
                                  <option value='4'>動画</option>
                                </select>
                              </td>
                              <td id='jsonImage'>
                                <label className='cms-content__file'>
                                  <input
                                    className='cms-content__clipIcon'
                                    type='file'
                                    accept='image/*'
                                    onChange={onFileInputChange}
                                    multiple={true}
                                    style={{ display: 'none' }}
                                  />
                                  {/* <p className={
                                    json.fileName ? 'selectFiles' : ''
                                  }>
                                    {json.fileName ? json.fileName : 'なし'}
                                  </p> */}
                                </label>

                                {/*消してはならない*/}
                                <input
                                  type='text'
                                  name='fileName'
                                  defaultValue={json.fileName}
                                  className='cms-tmpFileName__input'
                                  style={{ display: 'none' }}
                                />
                                {/*消してはならない*/}
                                {
                                  //fileNameを|区切りで分割して表示
                                  json.fileName ? (
                                    json.fileName.split('|').map((file) => (
                                      <p
                                        className='selectFiles'
                                        onClick={imgClick}
                                      >
                                        {file}
                                      </p>
                                    ))
                                  ) : (
                                    <p>なし</p>
                                  )
                                }
                              </td>
                              <td id='jsonMovieTitle'>
                                <textarea
                                  className='cms-content__textarea'
                                  name='movieUrl'
                                  onChange={handleChange}
                                  placeholder='YouTubeのURLを入力してください。'
                                  defaultValue={
                                    json.movieUrl ? json.movieUrl : ''
                                  }
                                />
                                <textarea
                                  className='cms-content__textarea'
                                  name='movieTitle'
                                  onChange={handleChange}
                                  placeholder='YouTubeのタイトルを入力してください。'
                                  defaultValue={
                                    json.movieTitle ? json.movieTitle : ''
                                  }
                                />
                              </td>
                              <td>
                                <button
                                  type='button'
                                  className='cms-content__delete btn'
                                  onClick={handleDelete}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </form>
                  </>
                ) : (
                  <></>
                )}
                {obj.category === 'マニュアル' ? (
                  <>
                    <form>
                      <table className='cms-table table'>
                        <thead>
                          <tr>
                            {/* <th scope="col">#</th> */}
                            <th scope='col' style={{ width: '25%' }}>
                              カテゴリ
                            </th>
                            <th scope='col' style={{ width: '40%' }}>
                              表示ファイル名
                            </th>
                            <th scope='col' style={{ width: '25%' }}>
                              添付ファイル
                            </th>
                            <th scope='col' style={{ width: '10%' }}>
                              削除
                            </th>
                          </tr>
                        </thead>
                        <tbody className='cms-content' id='manual-tbody'>
                          {json_manual.map((json, i) => (
                            <tr className='qa qa-manual'>
                              {/* <th className="cms-content__tag" scope="row">
                    {i + 1}
                  </th> */}
                              <td>
                                <select
                                  className='cms-content__select'
                                  defaultValue={json.category}
                                  name='category'
                                  onChange={handleChange}
                                >
                                  {categoryName_faq.map((categoryName) => (
                                    <option value={categoryName}>
                                      {categoryName}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                <input
                                  className='cms-content__textarea'
                                  type='text'
                                  name='text'
                                  onChange={handleChange}
                                  defaultValue={json.fileName_jp}
                                />
                              </td>
                              <td>
                                <label className='cms-content__file'>
                                  <input
                                    className='cms-content__clipIcon'
                                    type='file'
                                    accept='image/*'
                                    onChange={onFileInputChange}
                                    multiple={true}
                                    style={{ display: 'none' }}
                                  />
                                  {/* <p className={
                                    json.fileName ? 'selectFiles' : ''
                                  }>
                                    {json.fileName ? json.fileName : 'なし'}
                                  </p> */}
                                </label>

                                {/*消してはならない*/}
                                <input
                                  type='text'
                                  name='fileName'
                                  defaultValue={json.fileName}
                                  className='cms-tmpFileName__input'
                                  style={{ display: 'none' }}
                                />
                                {/*消してはならない*/}
                                {
                                  //fileNameを|区切りで分割して表示
                                  json.fileName ? (
                                    json.fileName.split('|').map((file) => (
                                      <p
                                        className='selectFiles'
                                        onClick={imgClick}
                                      >
                                        {file}
                                      </p>
                                    ))
                                  ) : (
                                    <p>なし</p>
                                  )
                                }
                              </td>

                              <td>
                                <button
                                  type='button'
                                  className='cms-content__delete btn'
                                  onClick={handleDelete}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </form>
                  </>
                ) : (
                  <></>
                )}
                {obj.category === 'FAQ' ? (
                  <>
                    <form>
                      <table className='cms-table table'>
                        <thead>
                          <tr>
                            {/* <th scope="col">#</th> */}
                            <th scope='col' style={{ width: '5%' }}>
                              カテゴリ
                            </th>
                            <th scope='col' style={{ width: '30%' }}>
                              質問
                            </th>
                            <th scope='col' style={{ width: '30%' }}>
                              回答
                            </th>
                            <th scope='col' style={{ width: '15%' }}>
                              レイ
                              <br />
                              アウト
                            </th>
                            <th scope='col' style={{ width: '15%' }}>
                              添付
                              <br />
                              ファイル
                            </th>
                            <th scope='col' style={{ width: '5%' }}>
                              削除
                            </th>
                          </tr>
                        </thead>
                        <tbody className='cms-content' id='faq-tbody'>
                          {json_faq.map((json, i) => (
                            <tr className='qa qa-faq'>
                              {/* <th className="cms-content__tag" scope="row">
                    {i + 1}
                  </th> */}
                              <td>
                                <select
                                  className='cms-content__select'
                                  defaultValue={json.category}
                                  name='category'
                                  onChange={handleChange}
                                >
                                  {categoryName_faq.map((categoryName) => (
                                    <option value={categoryName}>
                                      {categoryName}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                <textarea
                                  className='cms-content__textarea'
                                  name='question'
                                  onChange={handleChange}
                                  defaultValue={json.question}
                                />
                              </td>
                              <td>
                                <textarea
                                  className='cms-content__textarea'
                                  name='answer'
                                  onChange={handleChange}
                                  defaultValue={json.answer}
                                />
                              </td>
                              <td>
                                <select
                                  className='cms-content__layout'
                                  defaultValue={json.layout}
                                  name='layout'
                                  onChange={handleChange}
                                >
                                  <option value='1'>テキスト</option>
                                  <option value='2'>画像1枚</option>
                                  <option value='3'>画像複数</option>
                                </select>
                              </td>
                              <td>
                                <label className='cms-content__file'>
                                  <input
                                    className='cms-content__clipIcon'
                                    type='file'
                                    accept='image/*'
                                    onChange={onFileInputChange}
                                    multiple={true}
                                    style={{ display: 'none' }}
                                  />
                                  {/* <p className={
                                    json.fileName ? 'selectFiles' : ''
                                  }>
                                    {json.fileName ? json.fileName : 'なし'}
                                  </p> */}
                                </label>

                                {/*消してはならない*/}
                                <input
                                  type='text'
                                  name='fileName'
                                  defaultValue={json.fileName}
                                  className='cms-tmpFileName__input'
                                  style={{ display: 'none' }}
                                />
                                {/*消してはならない*/}
                                {
                                  //fileNameを|区切りで分割して表示
                                  json.fileName ? (
                                    json.fileName.split('|').map((file) => (
                                      <p
                                        className='selectFiles'
                                        onClick={imgClick}
                                      >
                                        {file}
                                      </p>
                                    ))
                                  ) : (
                                    <p>なし</p>
                                  )
                                }
                              </td>
                              <td>
                                <button
                                  type='button'
                                  className='cms-content__delete btn'
                                  onClick={handleDelete}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </form>
                  </>
                ) : (
                  <></>
                )}

                {jsonData_content.map((json, index) =>
                  jsonData_content === json ? (
                    <>
                      <div className='react-tabs__text'>aabbcc</div>
                    </>
                  ) : (
                    <></>
                  )
                )}
              </TabPanel>
            ))}
          </Tabs>
          <div className='cms-submit'>
            <input
              className='cms-submit__btn'
              type='submit'
              value=''
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Keiri_cms;
