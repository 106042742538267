// @ts-nocheck
import { useState, useLayoutEffect, useEffect } from 'react';
function Footer() {
  const [viewFlag, setViewFlag] = useState(false);
  useLayoutEffect(() => {
    //現在のURLを取得
    const url = window.location.href;
    // console.log(url);
    //loginページではない場合
    if (url.indexOf('login') === -1) {
      setViewFlag(true);
    }
  }, []);

  // 環境に応じたベースのURL
  const baseUrl = process.env.REACT_APP_PUBLIC_URL || '';

  return (
    <footer>
      <div className="footer">
        <p className="m-0 copyright" lang="en">
          (C)2024 Zenken Corporation
        </p>
        <button
          className="button page_top_btn"
          onClick={() => {
            window.scroll({ top: 0, behavior: 'smooth' })
          }}
        >
          <span className="visually-hidden">ページトップへ戻る</span>
        </button>
        {
          viewFlag ?
            <div className="lunch-map-wrap">
              <a
                href="https://www.google.com/maps/@35.6949916,139.6863866,16z/data=!4m3!11m2!2sbZA5oja7ShuVGMIz4EW0ug!3e3?entry=ttu"
                target="_blank"
                className="btn btn-lunch-map"
              >
                ランチMAP
              </a>
              <a href={baseUrl + '/lunchmap'} className='link-lunch-map'>【開発中】ランチマップβ版</a>
            </div>
          :<></>
        }

      </div>
    </footer>
  );
}

export default Footer;
