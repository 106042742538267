// @ts-nocheck
import React, { Component, useEffect, useRef, useState, useContext } from 'react';
import { EqualHeight, EqualHeightElement } from 'react-equal-height';
import { Helmet } from 'react-helmet-async';

//ヘッダー
import Header from '../../component/page_parts/header';

//サイドバー
import Sidebar from '../../component/page_parts/common/sidebar';

// フッター
import Footer from '../../component/page_parts/footer';

// レイアウト
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// 別ページの特定の要素へのリンク
import { HashLink } from 'react-router-hash-link';

// パンくずリスト
import TableOfContents from "../../component/page_parts/common/table_of_contents";

const baseUrl = process.env.REACT_APP_PUBLIC_URL;

function Keiri_startGuide() {
  return (
    <>
      <Helmet>
        <title>Z-link 経費関連 利用開始案内</title>
      </Helmet>
      <Header />
      <div className='theme-keiri theme-keiri-start-guide'>
        <div className="content-header">
          <div className="container">
            <Row>
              <Col sm={9}>
                <h1 className='page-title type-pig'>経費関連（楽楽精算）</h1>{/* ページタイトル */}
                <p className="page-description">
                  これから楽楽精算を利用される方は、<br />次の内容を参考に、楽楽精算の利用を開始できるように対応しましょう！
                  <br />
                  各種申請方法については、マニュアルを参考に申請を行ってみてください。
                </p>{/* ページの説明 */}
              </Col>
              <Col sm={3} className='btn-wrap'>
                <a
                  href='https://rsdesign.rakurakuseisan.jp/_5ZwGywYVPa/'
                  className='btn-link-area rakuraku__link position-fixed'
                  target='_brank'
                >
                  利用する
                </a>
              </Col>
            </Row>
          </div>
        </div>
        <TableOfContents />
        <Container className='contents-wrap'>
          <section className="">
            <h2 className='tag-title'>利用開始案内</h2>
            <div className="numbering-wrap type-step">
              <section className="">
                <h3 className="numbering-title mb-2">楽楽精算のアカウントを取得しよう！</h3>
                <p className=''>楽楽精算のアカウント取得は、次の流れで取得しましょう。</p>
                <ol>
                  <li>
                    所属部門の上長、あるいはIT担当者へ、アカウント取得の対応を依頼してください
                    <br />
                    （各部門のIT担当者より総務部へアカウント申請を行います）。</li>
                  <li>総務部にてアカウント作成後、IT担当者を通じて楽楽精算のアカウント情報を受領します。</li>
                </ol>
                <p className='mt-4'>➤各部門のIT担当者は、以下リンク先よりご確認頂けます。</p>
                <p>
                  ITヘルプデスク　IT担当者一覧：
                  <HashLink
                    to={baseUrl + "/johsys?jump=itPerson"}
                  >
                    https://ztree.cfbx.jp/johsys
                  </HashLink>
                </p>
              </section>

              <section className='step1-box my-5'>
                <h3 className='numbering-title mt-0 mb-2'>アカウントを取得したら、<br />楽楽精算へログインしてみよう。</h3>
                <p>パソコン・スマホを利用してログインすることができます。<br />初めて楽楽精算を利用する場合は、パソコンを利用してログインしてください。</p>
                <Row className='pc-box'>
                  <Col sm={12}>
                    <h4 className='fw-bold'>ＰＣ</h4>
                  </Col>
                  <Col sm={4}>
                    <p className='b-border pb-2 mb-4'>
                      楽楽精算の
                      <a href='https://rsdesign.rakurakuseisan.jp/_5ZwGywYVPa/' target='_brank' rel='noopener noreferrer' className='text-link type-external'>ログインページ</a>
                      より、取得したID・PWを入力してログインします。
                      <br />
                      ※ログインIDは各自の社員IDです。
                    </p>
                    <div className='img-wrap'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          '/keiri_pic/keiri_sg-login_method_pc.png'
                        }
                        alt='ログイン方法PC'
                        width='896'
                        height='608'
                        loading='lazy'
                      />
                    </div>
                  </Col>
                </Row>
                <Row className='sp-box'>
                  <Col sm={12}>
                    <h4 className='fw-bold'>スマホ</h4>
                    <p>「楽楽精算」アプリを利用して、楽楽精算へログインします。<br />手順は次のとおりです。</p>
                    <EqualHeight>
                      <ol className='row num-list'>
                        <li className='col-sm-4'>
                          <div className='num-list-item b-border'>
                            <EqualHeightElement name='step2'>
                              <p className='mb-0'>「楽楽精算」アプリをインストールし、アプリを起動します。</p>
                            </EqualHeightElement>
                          </div>
                          <div className='img-wrap px-2'>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                '/keiri_pic/keiri_sg-login_method_sp1.jpg'
                              }
                              alt='ログイン方法スマホ1'
                              width='1080'
                              height='1409'
                              loading='lazy'
                            />
                          </div>
                        </li>
                        <li className='col-sm-4'>
                          <div className='num-list-item b-border'>
                            <EqualHeightElement name='step2'>
                              <p className='mb-0'>アプリが起動したら、楽楽精算のURL（https://rsdesign.rakurakuseisan.jp/_5ZwGywYVPa/）を入力します。</p>
                            </EqualHeightElement>
                          </div>
                          <div className='img-wrap px-2'>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                '/keiri_pic/keiri_sg-login_method_sp2.jpg'
                              }
                              alt='ログイン方法スマホ2'
                              width='1080'
                              height='2400'
                              loading='lazy'
                            />
                          </div>
                        </li>
                        <li className='col-sm-4'>
                          <div className='num-list-item b-border'>
                            <EqualHeightElement name='step2'>
                              <p className='mb-0'>取得したID・PWを入力してログインしてください。</p>
                            </EqualHeightElement>
                          </div>
                          <div className='img-wrap px-2'>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                '/keiri_pic/keiri_sg-login_method_sp3.jpg'
                              }
                              alt='ログイン方法スマホ3'
                              width='1080'
                              height='2400'
                              loading='lazy'
                            />
                          </div>
                        </li>
                      </ol>
                    </EqualHeight>
                  </Col>
                </Row>
              </section>

              <section className='step3-box'>
                <Row className=''>
                  <Col sm={7}>
                    <h3 className='numbering-title mt-0 mb-2'>初めてログインしたら、定期区間登録を行いましょう。</h3>
                    <p className='mb-0'>初めて楽楽精算へログインしたら、次の対応を行ってください。</p>
                  </Col>
                  <Col sm={12} className='mt-4'>
                    <EqualHeight>
                      <ol className='row num-list mt-0'>
                        <li className='col-sm-4'>
                          <div className='num-list-item b-border mb-3'>
                            <EqualHeightElement name='step3'>
                              <p className='mb-4'>定期区間の登録（必須）<br />次の流れで定期区間の登録を行ってください。</p>
                              <p className='mb-4'>①TOP画面右上の「個人設定」→定期区間をクリック</p>
                              <p className='mb-4 pt-2'>②定期区間の”期間”と”区間”を入力し、<br />　経路が正しいことを確認して申請を完了してください。</p>
                              <p className='mb-0'>※交通費精算、出張精算は、<br />　定期区間登録承認後に申請を行うようにしてください。</p>
                            </EqualHeightElement>
                          </div>
                          <div className='img-wrap'>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                '/keiri_pic/keiri_sg-registration_of_regular_section1.png'
                              }
                              alt='定期区間の登録1'
                              width='681'
                              height='244'
                              loading='lazy'
                            />
                            <img
                              className='mt-4'
                              src={
                                process.env.PUBLIC_URL +
                                '/keiri_pic/keiri_sg-registration_of_regular_section2.png'
                              }
                              alt='定期区間の登録2'
                              width='797'
                              height='348'
                              loading='lazy'
                            />
                          </div>
                        </li>
                        <li className='col-sm-4'>
                          <div className='num-list-item b-border'>
                            <EqualHeightElement name='step3'>
                              <p className='mb-4'>「楽楽精算ICカードリーダー」アプリをスマホにインストール<br />（交通費精算を行う場合）<br />本アプリの利用開始方法は次のとおりです。</p>
                              <p className='mb-4 pt-1'>①「楽楽精算ICカードリーダー」アプリをインストールし、起動させます。</p>
                              <p className='mb-4 pt-1'>
                                ②表示されるURL設定画面に、利用している楽楽精算のURLを入力し、<br />　「確定」をクリックします。<br />　→本アプリの利用が可能となります。</p>
                              <p className='mb-1'>※本アプリでのモバイルSuica・PASMOの読み取りは対象外です。</p>
                            </EqualHeightElement>
                          </div>
                        </li>
                      </ol>
                    </EqualHeight>
                  </Col>
                </Row>
              </section>

              <section className='step4-box'>
                <Row className=''>
                  <Col sm={7}>
                    <h3 className='numbering-title mt-0 mb-2'>申請で利用するボタンについて</h3>
                    <p>楽楽精算TOP画面上で、主に利用する項目は次のとおりです。</p>
                  </Col>
                  <Col sm={8} className='mt-3 pt-1'>
                    <div className='img-wrap'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          '/keiri_pic/keiri_sg-buttons_used_for_application.png'
                        }
                        alt='申請で利用するボタン'
                        width='728'
                        height='483'
                        loading='lazy'
                      />
                    </div>
                  </Col>
                  <Col sm={12} className='mt-4 pt-3'>
                    <EqualHeight>
                      <ol className='row num-list mt-0 mb-5 pb-4'>
                        <li className='col-sm-4'>
                          <div className='num-list-item b-border mb-0'>
                            <EqualHeightElement name='step4'>
                              <p className=''>立替払い精算（交通費精算や経費精算など）・支払依頼（取引先への支払依頼）</p>
                              <p className='mt-1 mb-0'>会社が負担する経費（交通費や消耗品の購入など）を一時的に立替払いした場合の精算や、取引先への支払依頼のために利用します。</p>
                            </EqualHeightElement>
                          </div>
                        </li>
                        <li className='col-sm-4'>
                          <div className='num-list-item b-border mb-0'>
                            <EqualHeightElement name='step4'>
                              <p className=''>稟議書・連絡書等のワークフロー申請</p>
                              <p className=''>稟議書は、社内で（購入やサービスの導入などについて）承認を得るために利用します。</p>
                              <p className='mb-0'>連絡書は、社内での連絡事項や依頼事項を申請するために利用します。</p>
                            </EqualHeightElement>
                          </div>
                        </li>
                        <li className='col-sm-4'>
                          <div className='num-list-item b-border mb-0'>
                            <EqualHeightElement name='step4'>
                              <p className=''>領収書／請求書のアップロード・交通系ＩＣカードとりこみ・個人設定（定期区間登録、PW変更）</p>
                              <p className='mt-1 mb-0'>立替精算や支払依頼申請に必要な領収書や請求書の登録や交通系ICカードの取り込みを行うために利用します。</p>
                            </EqualHeightElement>
                          </div>
                        </li>
                      </ol>
                    </EqualHeight>
                  </Col>
                </Row>
              </section>
              <div className='return_button'>
                <a href={baseUrl + '/keiri'} className='px-5'>
                  経費関連（楽楽精算）トップに戻る
                </a>
              </div>
            </div>
          </section>
        </Container>
      </div>
      <Footer />
    </>
  );
}
export default Keiri_startGuide;
