import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

//Smooth Scroll
import { Link } from "react-scroll";

function Contents6() {
  useEffect(() => { }, []);

  return (
    <>
      <h2 className="tag-title">IT担当者向け</h2>
      <Row className="mt-5">
        <Col sm={6}>
          <a
            href="https://docs.google.com/document/d/1UknFAJG-CCrXM0hO1T4dVLggk4qVa4IwcyQJ2hzp2zg/edit"
            target="_blank"
            className="btn main-btn type-external"
          >
            作業依頼テンプレート
          </a>
        </Col>
      </Row>
      <div className="discription">
        <p>
          各事業部から情報システム課への作業依頼は、こちらの書式にて申請してください。
        </p>
      </div>
      <Row>
        <Col sm={4}>
          <Link
            className="btn main-btn type-internal"
            to="about_license"
            smooth={true}
            duration={500}
            offset={-100}
          >
            情シスが管理している
            <br />
            ライセンスについて
          </Link>
        </Col>
        <Col sm={4}>
          <Link
            className="btn main-btn type-internal"
            to="about_ss1"
            smooth={true}
            duration={500}
            offset={-100}
          >
            SS1について
          </Link>
        </Col>
        <Col sm={4}>
          <Link
            className="btn main-btn type-internal"
            to="screen_management"
            smooth={true}
            duration={500}
            offset={-100}
          >
            ロケーション管理
            <br />
            （社内備品管理）について
          </Link>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Link
            className="btn main-btn type-internal"
            to="lifestyle_about"
            smooth={true}
            duration={500}
            offset={-100}
          >
            人事手続きについて
          </Link>
        </Col>
        {/* <Col sm={4} className='mb-2 px-1'>
          <a href="https://drive.google.com/file/d/1Q79z3-wXAdxrEUh_qPPgwcpmuCtBh6lg/view?usp=sharing" target="_blank" className="btn main-btn type-external">
            地方拠点からのPC返却手順
          </a>
        </Col> */}
        <Col sm={4}>
          <Link
            className="btn main-btn type-internal"
            to="pc_return_for_region"
            smooth={true}
            duration={500}
            offset={-100}
          >
            地方拠点からのPC返却手順
          </Link>
        </Col>
      </Row>
      <h3 id="about_license" className="icon-title type-pencil">
        情シスが管理しているライセンスについて
      </h3>
      <p>
        情報システム課でライセンス数を管理しているものは以下のみとなります。
        <br />
        下記以外のライセンスにつきましては、<strong>各部門で運用管理</strong>
        をお願い致します。
      </p>
      <div className="johsys-related">
        <div className="point-list-border mb-3">
          <div className="point-title">
            <span>CHECK</span>情シスが管理しているライセンス一覧
          </div>
          <ul className="dot-list type-tree">
            <li>Google Workspace</li>
            <li>Active!gateSS</li>
            <li>Chatwork</li>
            <li>FileMaker Pro 18 Advanced</li>
            <li>Docker Team</li>
            <li>Zoom</li>
            <li>ComponentOne for WinForms</li>
            <li>Adobeソフト</li>
            <li>Microsoft 365</li>
            <li>ESET Endpoint Encryption</li>
            <li>MORISAWA PASSPORT</li>
            <li>Optimal Biz</li>
            <li>KDDI Smart Mobile Safety Manager</li>
            <li>CrowdStrike</li>
          </ul>
        </div>
      </div>

      <h3 id="about_ss1" className="icon-title type-pencil">
        SS1について
      </h3>
      <p>
        SS1（System  Support  best1）とは会社のPCやスマートフォン、タブレットなどを管理する「IT資産管理ソフト」です。
        <br />
        ※運営会社：
        <a
          href="https://www.dos-osaka.co.jp/company/outline.html"
          target="_blank"
          rel="noopener noreferrer"
          className="text-link type-external"
        >
          株式会社ディー・オー・エス
        </a>
      </p>
      <p>
        詳細については、以下資料をご確認ください。※IT担当者のみアクセス権限があります。
      </p>
      <Row className="mt-5 pb-5">
        <Col sm={4}>
          <a
            href="https://docs.google.com/spreadsheets/d/1fDDrvEacx05OPCG9Ceobg5nwZFKYJ6ACxqSSpfrNSME/"
            target="_blank"
            className="btn main-btn type-external"
          >
            SS1_インストール可能
            <br />
            アプリ一覧
          </a>
        </Col>
        <Col sm={4}>
          <a
            href="https://drive.google.com/file/d/1XyV6bTMKqcBxSF1tQ4DXy1Bh6rj0dF8A/"
            target="_blank"
            className="btn main-btn type-external"
          >
            SS1操作説明
          </a>
        </Col>
        <Col sm={4}>
          <a
            href="https://drive.google.com/file/d/18wWT2_aB8VzfiZw2rYVcV42Sgn_j191x/"
            target="_blank"
            className="btn main-btn type-external"
          >
            SS1勉強会
            <br />
            (2022-04-18 22:00 GMT-7)
          </a>
        </Col>
      </Row>
      <div className="johsys-related">
        <div className="point-list-border mb-3">
          <div className="point-title">
            <span>POINT</span>SS1を使用してできることの例
          </div>
          <ul>
            <li>
              自部署で使われている機器やその利用者、インストールされているアプリケーションを調べる
            </li>
            <li>
              IT担当者様ご自身で、利用者が希望するアプリケーションをインストールさせる
            </li>
          </ul>
        </div>
      </div>
      <h3 id="screen_management" className="icon-title type-pencil">
        ロケーション管理（社内備品管理）について
      </h3>
      <p>
        デスク・キャビネット・ロッカー・鍵・モニターなどの管理は総務課が行っております。
        <br />
        管理方法、棚卸方法につきましては、以下の資料をご覧ください。
      </p>
      <Row className="mt-5">
        <Col sm={6}>
          <a
            href="https://docs.google.com/presentation/d/1t7cl5qo1E1UzsPzWpsi_uLZuZoq-zw93k_tKCiQu6hg/"
            target="_blank"
            className="btn main-btn type-external"
          >
            ロケーション管理方法・棚卸方法
          </a>
        </Col>
      </Row>
      <div className="johsys-related">
        <div className="point-list-border mb-3">
          <div className="point-title">
            <span>CHECK</span>
            原則、デスク（引き出し含む）とモニターは、移動をさせない
          </div>
          <p>人員の異動がある場合は、人のみ移動を徹底お願い致します。</p>
        </div>
      </div>
      <h3 id="lifestyle_about" className="icon-title type-pencil">
        人事手続きについて
      </h3>
      <p>
        人事手続きについては以下をご確認の上、必要に応じて作業依頼を申請してください。
        <br />
        管理方法、棚卸方法につきましては、以下資料をご確認ください。
      </p>
      <Row className="mt-5">
        <Col sm={6}>
          <a
            href="https://docs.google.com/document/d/1iS_SeVVtqFwYyHnI_wdfAD4-aBVFBRZw4-hTp6u9iHk/edit"
            target="_blank"
            className="btn main-btn type-external"
          >
            人事手続きについて<br />（入社・退職・異動・休職/復職・氏名変更）
          </a>
        </Col>
      </Row>
      <h3 id="pc_return_for_region" className="icon-title type-pencil">
        地方拠点からのPC返却手順
      </h3>
      <p>
        地方拠点管理番号(E~)PC返却時のフローと、そのための準備につきましては、以下資料をご確認ください。
      </p>
      <Row className="mt-5">
        <Col sm={6}>
          <a
            href="https://drive.google.com/file/d/1Q79z3-wXAdxrEUh_qPPgwcpmuCtBh6lg/view?usp=sharing"
            target="_blank"
            className="btn main-btn type-external"
          >
            地方拠点からのPC返却手順
          </a>
        </Col>
      </Row>
    </>
  );
}

export default Contents6;
