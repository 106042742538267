import React, { useState, useEffect } from 'react';
import * as Icon from 'react-bootstrap-icons';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//Smooth Scroll
import { Link } from 'react-scroll';

function Contents4() {

  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  useEffect(() => { }, []);

  return (
    <>
      <h2 className="tag-title">利用可能アプリ</h2>
      <p>
        従業者の皆さんが業務で使用するアプリケーションにつきましては、情報システム課で許可しているアプリケーションのみ使用可能です。<br />
        許可済のアプリケーションの一覧は各シートよりご確認ください。
      </p>
      <Row className="mt-5">
        <Col sm={6}>
          <a href="https://docs.google.com/spreadsheets/d/1_qu3E-QQdAE_FxSCtUdybh1jBHAipJqfQxC1-z0bLGA/edit#gid=1356583184
" target="_blank" className="btn main-btn type-external h-auto">
            許可済アプリ
          </a>
          <p className="mt-3 small">※アプリ：PCやスマホ端末にインストールするアプリケーション、Web上で使用するアプリケーション等</p>
        </Col>
        <Col sm={6}>
          <a href="https://docs.google.com/spreadsheets/d/1_qu3E-QQdAE_FxSCtUdybh1jBHAipJqfQxC1-z0bLGA/edit#gid=2087370447" target="_blank" className="btn main-btn type-external h-auto">
            許可済アドオン(拡張機能)
          </a>
          <p className="mt-3 small">※アドオン：「Google Chrome」「Firefox」「Visual Studio Code」等、特定のアプリケーションの機能を増やしたり強化したりする専用の追加プログラム</p>
        </Col>
      </Row>
      <p className="mt-3">※必要なアプリは「すべてのシートで検索」すると便利です</p>

      <div id='johsys-content4' className='johsys-content4'>
        <div className='continer johsys-related'>
          <div className='content4_section'>
            <div className='point-list-border mb-3'>
              <div className='point-title'>
                <span>CHECK</span>
                許可済リストに掲載されていないアプリケーションを使用したい場合はどうすれば良い？
              </div>
              <p>
                リストに掲載されていないアプリケーションを使用したい場合は、<br />
                各部署のIT担当者からアプリケーション利用調査を情報システム課に依頼頂く必要があります。<br />
                詳細は
                <a href={baseUrl + "/johsys?jump=itPerson"} className='text-link'>
                  各部署のIT担当者
                </a>
                までご相談ください。
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contents4;
