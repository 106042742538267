function AppViewHead() {
    const viewClose = (e:any) => {
        //現在位置が「/」以外の場合は、現在位置を「/」に遷移
        if (window.location.pathname !== "/") {
            window.location.href = "/";
        }
        //クリックされた要素の親要素を取得
        var parent = e.target.parentNode;
        //親要素からopenクラスを削除
        parent.classList.remove("open");

    }
    return (
        <>
            <button onClick={viewClose}>×</button>
        </>
    );
}
export default AppViewHead;