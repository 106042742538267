import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-scroll';

// 委員会一覧ページ 個人情報保護管理委員会タブ
function Contents1() {

  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  return (
    <div id="committee-content1">
      <h2 className="tag-title">個人情報保護管理委員会</h2>
      <div className="committee-related">
        <div className="point-list-border mb-3">
          <div className="point-title">
            <span>CHECK</span>個人情報保護管理委員会とは
          </div>
          <ul className="dot-list type-list">
            <li>管理責任者を委員長とし、委員長により選任された者で構成されております。</li>
            <li>毎月１回、「個人情報保護・情報セキュリティー会議」を開催し、個人情報保護管理事務局員とともに、<br />個人情報保護に関連する規程の内容や個人情報に関する重要事項について、協議・検討・決定しております。</li>
            <li>PMS の実施状況や受け付けた個人情報に関する苦情・相談、また発生したインシデント等の共有を実施し、必要な対応について協議しております。</li>
            <li>個人情報に関する各部門からの問合せや要望があれば共有し、対応について協議しております。</li>
          </ul>
        </div>
      </div>
      <p className='mt-4'>
        詳しくは
        <a href='https://sites.google.com/zenken.co.jp/contracts-regulations/regulations/Personal-Information-Protection-Regulations/Personal-Information-Protection-Regulation?authuser=0' target='blank' className='text-link type-external'>個人情報保護管理規程（A.3.3.4.1 その他役割及び権限e)等）</a>
        をご確認ください。
      </p>
      <Row className="toc-btnArea">
        <Col sm={3} className='toc-btn'>
          <Link
            to="chapter01"
            smooth={true}
            duration={500}
            offset={-100}
            className='btn main-btn type-internal w-100'
          >
            個人情報保護<br />管理体制図
          </Link>
        </Col>
        <Col sm={3} className='toc-btn'>
          <Link
            to="chapter02"
            smooth={true}
            duration={500}
            offset={-100}
            className='btn main-btn type-internal w-100'
          >
            個人情報保護管理事務<br />局・部内担当一覧
          </Link>
        </Col>
        <Col sm={3} className='toc-btn'>
          <Link
            to="chapter03"
            smooth={true}
            duration={500}
            offset={-100}
            className='btn main-btn type-internal w-100'
          >
            インシデント発生時<br />の報告フロー
          </Link>
        </Col>
        <Col sm={3} className='toc-btn'>
          <Link
            to="chapter04"
            smooth={true}
            duration={500}
            offset={-100}
            className='btn main-btn type-internal w-100'
          >
            Pマークについて
          </Link>
        </Col>
      </Row>
      <h3 id='chapter01' className='icon-title type-pencil'>Zenken株式会社の個人情報保護管理体制図</h3>
      <iframe src="https://docs.google.com/presentation/d/1oZJFJCxkOxHec7UA2Z4DMuDwIjAPT_Yn/preview#slide=id.p1" width="1000" height="500" allow="autoplay"></iframe>
      <h3 id='chapter02' className='icon-title type-pencil'>個人情報保護管理事務局・個人情報保護管理部内担当者一覧</h3>
      <iframe src="https://docs.google.com/spreadsheets/d/1uf_7Sk8cAl1I_6DkHGE3vjJDpfOeZZm-GaPfNTePJ9c/preview#gid=1798003960" width="1000" height="500" allow="autoplay"></iframe>
      <p className='mt-4'>
        各担当者の内線番号の確認は、
        <a href={baseUrl + "/employee_list"} className='text-link'>従業員名簿</a>
        よりご確認ください。
      </p>
      <h3 id='chapter03' className='icon-title type-pencil'>インシデント発生時の報告フロー</h3>
      <div className="ruleBox">
        <div className='flowimg--box'>
          <img src={process.env.PUBLIC_URL + '/committee_pic/incident.svg'} alt="" />
        </div>
        <p className='mt-4'>
          <strong>ノートPCの破損・故障</strong>については、
          <a href={baseUrl + "/johsys"} className='text-link'>ITヘルプデスク</a>
          内の「情シスへのお問い合わせ」を参照
        </p>
      </div>
      <h3 id='chapter04' className='icon-title type-pencil'>Pマークについて</h3>
      <p>
        また、Zenken株式会社では、「Pマーク」を取得しております。
      </p>
      <p>
        認定日：2017年7月14日 <br />登録番号：21001344 <br />
      </p>
      <p>
        詳しくは
        <a href='https://www.zenken.co.jp/news/1844' target='blank' className='text-link type-external'>Zenkenオフィシャルサイトのインフォメーション記事</a>
        をご参照ください。
      </p>
      <p>
        個人情報保護管理委員会では、「Pマーク」取得を維持し続けるため、<br />
        日本産業規格「JIS Q 15001個人情報保護マネジメントシステム－要求事項」に準拠した「プライバシーマークにおける個人情報保護マネジメントシステム構築・運用指針」に基づいて、個人情報について適切な保護措置を講ずる体制を整備しています。<br />
        「Pマーク」制度に関することや、必要な帳票類については
        <a href='https://sites.google.com/zenken.co.jp/z-pmark/home' target='blank' className='text-link type-external'>Z-Pmark</a>
        から確認してください。
      </p>
      <Row className="mt-5">
        <Col sm={6}>
          <a href="https://sites.google.com/zenken.co.jp/contracts-regulations/regulations/Personal-Information-Protection-Regulations?authuser=0" target="_blank" className="btn main-btn type-external h-auto">
            個人情報保護に関する規程を見る
          </a>
        </Col>
        <Col sm={6}>
          <a href="https://sites.google.com/zenken.co.jp/contracts-regulations/regulations/Information-Security-Related-Regulations?authuser=0" target="_blank" className="btn main-btn type-external h-auto">
            情報セキュリティに関する規程を見る
          </a>
        </Col>
      </Row>
    </div >
  );
}

export default Contents1;