// react
// @ts-nocheck
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import Button from 'react-bootstrap/Button';

// デコード
import jwt_decode from 'jwt-decode';

// json
import departmentWpJson from '../../../files/wp_info_department.json';

// css
import '../../../css/App.css';

import { data } from 'cheerio/lib/api/attributes';

// 未読一覧
function AnreadList() {

  // トークンをデコードして取得したユーザー情報を保持
  const [loginData, setLoginData] = useState({});

  // 組織idと、その組織が属する先祖組織idの配列を保持
  const [departmentIds, setDepartmentIds] = useState([]);

  // トークンをデコードして取得したjtiを保持（パラメータに付与する用）
  const [cookieJti, setCookieJti] = useState();

  //インフォの未読一覧表示用のstate
  const [dispInfoData, setDispInfoData] = useState<any>([]);

  //Cookie
  const [cookies, setCookie, removeCookie] = useCookies(['sessionToken']);

  // WP設定値の写しを保持
  const [departmentWpAll, setDepartmentWpAll] = useState(departmentWpJson);

  // 未読0件時のメッセージ表示
  const [massage, setMassage] = useState('');

  //Cookie-buildingSlug
  const [
    cookiesBuildingSlug,
    setCookiesBuildingSlug,
    removeCookiesBuildingSlug,
  ] = useCookies(['buildingSlug']);

  // WP RESTAPI 環境別ベースURL
  const urlBase = process.env.REACT_APP_WP_REST_API_PATH_INFO;

  // WPインフォURL
  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  /* ログイン時に生成されたトークンをデコードしたデータをstateに保持 */
  function SettingLonginData() {

    // Cookieからトークンを取得
    const token = cookies.sessionToken;
    // デコード
    const decodedToken = jwt_decode<{ [name: string]: string }>(token);
    setLoginData(decodedToken.data);

    /* パラメータ（zenken）にセットしてリクエストするためのjtiの値を取得してstateにセット
    →WP側で値の確認を行う（確認できないリクエストには401を返すようにする）*/
    setCookieJti(decodedToken.jti);
  }

  // 部署スラッグ取得処理
  function getDepartmentSlug() {
    // DB（トークン）の所属組織のスラッグから、WP側の所属情報を取得
    let departmentSlug = null;
    if (loginData.SECTION_SLUG3) {
      departmentSlug = loginData.SECTION_SLUG3;
    } else if (loginData.SECTION_SLUG2) {
      departmentSlug = loginData.SECTION_SLUG2;
    } else if (loginData.SECTION_SLUG1) {
      departmentSlug = loginData.SECTION_SLUG1;
    } else if (loginData.DEPARTMENT_SLUG) {
      departmentSlug = loginData.DEPARTMENT_SLUG;
    } else if (loginData.OFFICE_SLUG) {
      departmentSlug = loginData.OFFICE_SLUG;
    } else {
      // 所属スラッグがない場合、 all 所属扱いにする
      departmentSlug = 'all';
    }
    return departmentSlug;
  }

  // 最新記事1件の日付取得処理
  async function getLatestPostDate() {

    var wpRestUrl = urlBase + `wp-json/wp/v2/information?per_page=1&orderby=date&order=desc&zenken=` + cookieJti;

    // 最新記事1件取得
    const postResponse = await fetch(wpRestUrl)
      .then(response => {
        if (!response.ok) {
        }
        return response;
      })
      .catch(error => {
      });
    const data = await postResponse.json();
    return data[0].date;
  }

  // async関数を呼び出す
  async function main() {
    const result = await getLatestPostDate();
    return result;
  }

  /* - ユーザーの所属する組織id（WP側）を取得 */
  /* - 所属組織のビル内外に応じてパラメータをセット */
  function getParameter() {

    let element = document.getElementById('bellInfoCount');

    // ローカルストレージに一括既読処理が行われた確認
    if (localStorage.getItem("bulk_read_last_execution_date") != null) {
      // 既に一括既読がされている場合
      // 最新記事の投稿日時を取得
      main().then((result) => {
        var latestPostsTime = new Date(result);
        var localTime = new Date(localStorage.getItem("bulk_read_last_execution_date"));
        if (latestPostsTime > localTime) {
          // 前一括既読実行日時より最新記事作成日時が新しい場合
          let wpDepartment = departmentWpAll.find((v) => v.slug == getDepartmentSlug());

          // wpDepartmentがnullの場合は、all 所属扱いにする
          if (wpDepartment == null) {
            wpDepartment = departmentWpAll.find((v) => v.slug == 'all');
          }

          // WP側の所属組織とその祖先組織idの配列を取得し、stateを更新
          const departmentIdsArr = wpDepartment.ancestors_ids;
          setDepartmentIds(departmentIdsArr);

          // ユーザー別既読情報件数取得
          unreadPostsDetectionForBulkRead(wpDepartment, result);

        } else if (latestPostsTime < localTime) {
          // 前一括既読実行日時より最新記事作成日時が古い場合
          element.textContent = ''
          setMassage('全て既読です。');
          element.style.display = 'none';
          return;
        }
      });
    } else {
      // 一括既読が一度も押されていない場合
      let wpDepartment = departmentWpAll.find((v) => v.slug == getDepartmentSlug());

      // wpDepartmentがnullの場合は、all 所属扱いにする
      if (wpDepartment == null) {
        wpDepartment = departmentWpAll.find((v) => v.slug == 'all');
      }

      // WP側の所属組織とその祖先組織idの配列を取得し、stateを更新
      const departmentIdsArr = wpDepartment.ancestors_ids;
      setDepartmentIds(departmentIdsArr);

      // ユーザー別既読情報件数取得
      unreadPostsDetection(wpDepartment);
    };
  }

  async function unreadPostsDetectionForBulkRead(wpDepartment, result) {

    // 内部処理用の投稿格納変数
    const info: string[] = [];

    // 内部処理用の閲覧可能投稿格納変数
    const possibleInfo: string[] = [];

    // 一覧表示用の投稿格納変数
    const dispInfo: string[] = [];

    // 該当記事の配信先ビル内外にユーザーの所属組織が該当しているか
    const buildingState =
      cookiesBuildingSlug.buildingSlug === 'inBuilding' ? 'in-building' : '';

    // ユーザー別インフォメーション既読情報をカンマ(,)区切りでリストに変換
    const readInfoId = localStorage.getItem('readInfoIds').split(',');

    // ユーザー別インフォメーション既読情報リストの要素をStringからInt型に変更する
    const numReadInfoIdByUserList = readInfoId.map((str) =>
      parseInt(str, 10)
    );

    const inBuildingList: string[] = [];

    // ユーザーのビル内外判定
    if (buildingState == 'in-building') {
      // ビル内
      inBuildingList.push('in-building');
    }
    var date = new Date(result);

    date.setSeconds(date.getSeconds() + 1);

    const updatedResult = date.toISOString();

    // 前回の一括既読実行日時から最新記事の日時までの記事を最大100件取得する
    var getWpRestUrl = urlBase + `wp-json/wp/v2/information/?per_page=100&after=` + localStorage.getItem("bulk_read_last_execution_date") + `&before=` + updatedResult;

    let reqesutUrl = getWpRestUrl + '&zenken=';


    // 投稿取得リクエスト
    const responce = await fetch(
      reqesutUrl +
      cookieJti
    );
    let info_data = await responce.json();

    for (var i = 0; i < info_data.length; i++) {
      info.push(info_data[i]);
    }

    for (var i = 0; i < info.length; i++) {
      if (numReadInfoIdByUserList.indexOf(info[i].id) != -1) {
      } else {
        possibleInfo.push(info[i]);
      }
    }

    // // ユーザー別インフォメーション既読情報リストの数に応じて、既読判定処理を行う
    for (var i = 0; i < possibleInfo.length; i++) {

      // ビル内外と配信先設定の投稿閲覧判定
      // 投稿のビル内外設定なし、かつ配信先対象の場合
      if (
        possibleInfo[i].information_building_slug.length == 0 &&
        wpDepartment.ancestors_ids.indexOf(
          possibleInfo[i].information_department[0]
        ) != -1
      ) {
        // 閲覧可能な投稿が既読済みの場合
        if (numReadInfoIdByUserList.indexOf(possibleInfo[i].id) != -1) {
        } else {
          dispInfo.push(possibleInfo[i]);
        }
      } else if (
        inBuildingList[0] == 'in-building' && inBuildingList[0] == possibleInfo[i].information_building_slug &&
        wpDepartment.ancestors_ids.indexOf(
          possibleInfo[i].information_department[0]
        ) != -1
      ) {
        // 閲覧可能な投稿が既読済みの場合
        if (numReadInfoIdByUserList.indexOf(possibleInfo[i].id) != -1) {
        } else {
          dispInfo.push(possibleInfo[i]);
        }
      } else {
        // 上記の分岐に該当しない投稿は未読・既読判定に含まない
      }


    }
    let element = document.getElementById('bellInfoCount');

    // 既読件数をヘッダーに追加
    if (dispInfo.length == 0) {
      // 0件は、数字表示なし
      element.textContent = ''
      setMassage('全て既読です。');
      element.style.display = 'none';
    } else if (dispInfo.length < 11) {
      // 10件以下は、未読件数を表示 例)全投稿数200件、既読件数が195件の場合は5件と表示
      element.textContent = dispInfo.length;
      setMassage('')
    } else {
      // 11件以上は、10+と表示
      element.textContent = '10+';
      setMassage('')
    }

    // ベル押下時の未読一覧表示件数
    setDispInfoData(dispInfo.slice(0, 10));
  }

  async function periodSpecificationMain() {
    const result = await unreadPostsDetectionForBulkRead();
    return result;
  }

  async function unreadPostsDetection(wpDepartment) {

    var wpRestUrl = urlBase + `wp-json/wp/v2/information/?per_page=100&zenken=`;

    // ページ数の取得の為に、headers情報の取得
    const responceData = await fetch(
      wpRestUrl +
      cookieJti
    );

    // 内部処理用の投稿格納変数
    const info: string[] = [];

    // 内部処理用の閲覧可能投稿格納変数
    const possibleInfo: string[] = [];

    // 一覧表示用の投稿格納変数
    const dispInfo: string[] = [];

    // 該当記事の配信先ビル内外にユーザーの所属組織が該当しているか
    const buildingState =
      cookiesBuildingSlug.buildingSlug === 'inBuilding' ? 'in-building' : '';

    // ユーザー別インフォメーション既読情報をカンマ(,)区切りでリストに変換
    const readInfoId = localStorage.getItem('readInfoIds').split(',');

    // ユーザー別インフォメーション既読情報リストの要素をStringからInt型に変更する
    const numReadInfoIdByUserList = readInfoId.map((str) =>
      parseInt(str, 10)
    );

    const inBuildingList: string[] = [];

    // ユーザーのビル内外判定
    if (buildingState == 'in-building') {
      // ビル内
      inBuildingList.push('in-building');
    }

    // ページ数取得
    var pages = Number(responceData.headers.get('x-wp-totalpages'));

    var getWpRestUrl = urlBase + `wp-json/wp/v2/information/?per_page=100&page=`;

    // ページ分()リクエストを行い、投稿の全取得を行う
    for (var i = 1; i < pages + 1; i++) {
      let reqesutUrl = getWpRestUrl + i + '&zenken=';

      // 投稿取得リクエスト
      const responce = await fetch(
        reqesutUrl +
        cookieJti
      );
      let info_data = await responce.json();
      for (var ii = 0; ii < info_data.length; ii++) {
        info.push(info_data[ii]);
      }
    }

    for (var iii = 0; iii < info.length; iii++) {
      if (numReadInfoIdByUserList.indexOf(info[iii].id) != -1) {
      } else {
        possibleInfo.push(info[iii]);
      }
    }

    // ユーザー別インフォメーション既読情報リストの数に応じて、既読判定処理を行う
    for (var i = 0; i < possibleInfo.length; i++) {

      // ビル内外と配信先設定の投稿閲覧判定
      // 投稿のビル内外設定なし、かつ配信先対象の場合
      if (
        possibleInfo[i].information_building_slug.length == 0 &&
        wpDepartment.ancestors_ids.indexOf(
          possibleInfo[i].information_department[0]
        ) != -1
      ) {
        // 閲覧可能な投稿が既読済みの場合
        if (numReadInfoIdByUserList.indexOf(possibleInfo[i].id) != -1) {
        } else {
          dispInfo.push(possibleInfo[i]);
        }
      } else if (
        inBuildingList[0] == 'in-building' && inBuildingList[0] == possibleInfo[i].information_building_slug &&
        wpDepartment.ancestors_ids.indexOf(
          possibleInfo[i].information_department[0]
        ) != -1
      ) {
        // 閲覧可能な投稿が既読済みの場合
        if (numReadInfoIdByUserList.indexOf(possibleInfo[i].id) != -1) {
        } else {
          dispInfo.push(possibleInfo[i]);
        }
      } else {
        // 上記の分岐に該当しない投稿は未読・既読判定に含まない
      }
    }

    // ベル押下時の未読一覧表示件数
    setDispInfoData(dispInfo.slice(0, 10));

    let element = document.getElementById('bellInfoCount');

    // 既読件数をヘッダーに追加
    if (dispInfo.length == 0) {
      // 0件は、数字表示なし
      element.textContent = ''
      setMassage('全て既読です。');
      element.style.display = 'none';
    } else if (dispInfo.length < 11) {
      // 10件以下は、未読件数を表示 例)全投稿数200件、既読件数が195件の場合は5件と表示
      element.textContent = dispInfo.length;
      setMassage('')
    } else {
      // 11件以上は、10+と表示
      element.textContent = '10+';
      setMassage('')
    }
  }

  // 記事投稿日の日付フォマット▶　n年n月j日(D)
  function doDateFormat(date) {
    let dateFormat = new Date(date);
    dateFormat =
      dateFormat.getFullYear() +
      "/" +
      ("0" + (dateFormat.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + dateFormat.getDate()).slice(-2)
    return dateFormat;
  }

  // 残日数の返却処理
  function calculateRemainingDays(deadline: string) {
    const today = new Date();
    const deadlineDate = new Date(deadline);
    const timeDiff = deadlineDate.getTime() - today.getTime();
    const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24)); // ミリ秒を日数に変換
    return daysRemaining; // 残日数が負の場合はそのまま負の数字を返す
  }

  // ログイン時に生成されたトークンをデコードしたデータをstateに格納
  useEffect(() => {
    SettingLonginData();
  }, []);

  // 配信先指定に必要なパラメータ情報を取得
  useEffect(() => {
    if (Object.keys(loginData).length) {
      // loginData が空ではない時
      getParameter();
    }
  }, [loginData]);

  // 閉じるボタン
  function anreadInfoDisp() {
    const element = document.getElementById("anreadArea");
    //@ts-ignore
    if (element.style.transform == 'translateX(516px)') {
      element.style.transform = 'translateX(0)';
      element.style.opacity = '1';
      element.style.visibility = 'visible';
    } else {
      element.style.transform = 'translateX(516px)';
      element.style.opacity = '0';
      element.style.visibility = 'hidden';
    }
  }

  // 一括既読
  function allRead() {

    // 実行日時分秒を取得
    const today = new Date();

    // 日付をISO形式でフォーマットする関数
    const formatDateToISO = (date: Date) => {
      const pad = (num: number) => String(num).padStart(2, '0'); // 0埋め処理
      return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
    };

    // bulk_read_last_execution_dateを設定
    localStorage.setItem("bulk_read_last_execution_date", formatDateToISO(today));

    let element = document.getElementById('bellInfoCount');
    element.textContent = ''
    setMassage('全て既読です。');
    element.style.display = 'none';

    // ベル押下時の未読一覧表示件数
    setDispInfoData([]);

    // レンダリングしたいので再読み込み
    window.location.reload()
  }

  return (
    <>
      <div className='anreadArea' id='anreadArea' style={{ transform: "translateX(516px)", visibility: "hidden" }}>
        <div className='unread-header'>
          <div>未読一覧</div>
          <button
            className='bulk-read-btn'
            onClick={allRead}>
            一括で既読にする
          </button>
          <a href={baseUrl + '/info_lists/'}>インフォメーション一覧</a>
          <Button onClick={anreadInfoDisp} className='unread-close-btn'>
            <span className="visually-hidden">閉じる</span>
          </Button>
        </div>
        <div className='unread-list'>
          {
            dispInfoData.code !== 'disabled' ?
              dispInfoData.map((post) => {
                const { date, acf, content, information_taxonomy_name } = post;
                // インフォ一覧に出すテキストの設定
                let description;
                if (acf.i_list_description) {
                  // 一覧用テキストが設定されている場合は、そのテキストを設定
                  description = acf.i_list_description;
                } else {
                  // 一覧用テキストが設定されていない場合、タグを除去して本文冒頭70文字までを切り出し
                  description = content.rendered.replace(/(<([^>]+)>)/gi, '').slice(0, 70);
                }
                return (

                  <div className='unread-detail'>
                    <a
                      href={baseUrl + '/info_lists/deteil?id=' + post.id}
                    >
                      <span className="outgoing_division">{information_taxonomy_name[0]}</span>
                      <span className="data">{doDateFormat(date)}</span>
                      <div className="info_text">
                        {new Date(post.date) >= new Date(Date.now() - 3 * 24 * 60 * 60 * 1000) && (
                          <div dangerouslySetInnerHTML={{
                            __html: '<span class="new-icon">NEW</span>'
                          }} />
                        )}
                        {acf.important_decision && (
                          <div className="info_deadline_date">
                            {acf.important_decision === true && (
                              <span className="deadline-mark">期日あり</span>
                            )}
                          </div>
                        )}
                        {acf.important_decision && (
                          <div className="info_deadline_date">
                            {acf.deadline_date && new Date(acf.deadline_date) >= new Date(new Date().setHours(0, 0, 0, 0)) && (
                              <p>
                                {acf.deadline_date}まで</p>
                            )}
                          </div>
                        )}
                        <p className='info-title'
                          dangerouslySetInnerHTML={{
                            __html: post.title && post.title.rendered,
                          }}
                        ></p>
                      </div>
                      <p className="info-description-txt"
                        dangerouslySetInnerHTML={{
                          __html: description + '...'
                        }}
                      ></p>
                    </a>
                  </div>
                );
              }) : (
                <div className="information">
                  <p>記事はありません。</p>
                </div>
              )
          }
          <div className="information-massage">
            <p>{massage}</p>
          </div>
        </div>
      </div>
    </>
  );
}
export default AnreadList;
