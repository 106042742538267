// @ts-nocheck
import { useLayoutEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useCookies } from "react-cookie";
import { async } from "q";

function View_auth() {
  const [cookies, setCookie, removeCookie] = useCookies(["sessionToken"]);
  const [viewAuth, setViewAuth] = useState(false);

  //テスト環境
  const baseUrl = process.env.REACT_APP_PUBLIC_URL || '';

  useLayoutEffect(() => {
    // console.log("***************************");
    // console.log("**view_auth**");
    //トークンの有無を確認
    if (!cookies.sessionToken) {
      //エラー処理
      onError();
    } else {
      const decoded = jwt_decode<{ [name: string]: string }>(
        cookies.sessionToken
      );
      // console.log(decoded);
      AuthCheck(decoded);
    }
  }, []);

  async function AuthCheck(token) {
    // console.log(process.env.REACT_APP_API_PATH);
    // console.log("decodeed!!!!!!!!!!!!!!!!!!!!!");
    var res = await fetch(
      process.env.REACT_APP_API_PATH +
      "user/PageViewAuth.php?token=" +
      token.jti
    );
    var data = await res.json();
    // console.log(data);
    //リターンにより処理を分岐
    if (data === "error") {
      //エラー処理
      onError();
    } else {
      setViewAuth(true);
    }
  }

  //トークンなし(未ログイン)or致命的なエラー時の処理
  function onError() {
    //現在のページのURLを取得し、ログイン後に遷移できるようにする
    var url = window.location.href;
    localStorage.setItem("loginAfterJump", url);

    //ユーザー関連のローカルストレージを削除
    localStorage.removeItem("readList");
    localStorage.removeItem("deleteCount");
    localStorage.removeItem("user_name");
    localStorage.removeItem("urlList");
    localStorage.removeItem("user_Image");
    localStorage.removeItem("user_id");
    localStorage.removeItem("birthday");
    localStorage.removeItem("result_id");
    //トークンを削除
    removeCookie("sessionToken");
    removeCookie("userId");
    removeCookie("email");

    // //ログインページに遷移
    // window.location.href = "/dev/login";

    //ログインページに遷移
    window.location.href = baseUrl + "/login";
  }

  return (
    <>
      {viewAuth ? (
        <></>
      ) : (
        <div
          className="loading"
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: "999999",
            backgroundColor: "#fff",
          }}
        ></div>
      )}
    </>
  );
}

export default View_auth;
