import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-scroll';

// 委員会一覧ページ WEB統制委員会タブ
function Contents3() {

  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  return (
    <div id="committee-content1">
      <h2 className="tag-title">WEB統制委員会</h2>
      <div className="committee-related">
        <div className="point-list-border mb-3">
          <div className="point-title">
            <span>CHECK</span>WEB統制委員会とは
          </div>
          <ul className="dot-list type-list">
            <li>Zenkenが所有するメディア（Webサイト）やSNSアカウントを、どの部署の誰が運営しているのかを管理しています。</li>
            <li>3ヶ月に1回、WEB統制委員会を開催し、ネットワーク障害やサーバ関連情報など、事業部毎に情報共有などを行っています。</li>
            <li>Z-link「メディア一覧」に掲載するWebサイトやSNSの掲載管理も行っています。</li>
          </ul>
        </div>
      </div>
      <Row className="toc-btnArea">
        <Col sm={6} className='toc-btn'>
          <Link
            to="chapter08"
            smooth={true}
            duration={500}
            offset={-100}
            className='btn main-btn type-internal w-100'
          >
            WEB統制委員会<br />委員一覧
          </Link>
        </Col>
        <Col sm={6} className='toc-btn'>
          <Link
            to="chapter09"
            smooth={true}
            duration={500}
            offset={-100}
            className='btn main-btn type-internal w-100'
          >
            お問合せフォーム
          </Link>
        </Col>
      </Row>
      <h3 id='chapter08' className='icon-title type-pencil'>WEB統制委員会 委員一覧</h3>
      <iframe src="https://docs.google.com/spreadsheets/d/1f_vB9LMgNq8pY5h0JgsmY4LAPW-kiiMT1XD4mXZIY7M/preview##gid=830867068" width="1000" height="375" allow="autoplay"></iframe>
      <p className='mt-4'>
        各担当者の内線番号の確認は、
        <a href={baseUrl + "/employee_list"} className='text-link'>従業員名簿</a>
        よりご確認ください。
      </p>
      <div id="chapter09" className="joining text-center">
        <p>Z-link「メディア一覧」へのメディア（Webサイト）やSNSアカウント掲載については、<br />WEB統制委員会メンバーもしくはお問い合わせフォームからご連絡ください</p>
        <Row className="btn-wrap justify-content-center">
          <Col sm={6}>
            <a href="https://forms.gle/EJvF9xi7RRRYQE2c6" target="_blank" className="btn main-btn type-external">
              お問い合わせフォーム
            </a>
          </Col>
        </Row>
      </div>
    </div >
  );
}

export default Contents3;