// @ts-nocheck
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Helmet } from 'react-helmet-async';

//tabs
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

// Accordion
import Accordion from 'react-bootstrap/Accordion';

//Smooth Scroll
import { Link } from 'react-scroll';

//downloads
import download from 'downloadjs';

import React from 'react';
import { useState, useEffect } from 'react';

//ヘッダー
import Header from '../../component/page_parts/header';

// フッター
import Footer from '../../component/page_parts/footer';

//検索
import Search from '../../component/page_parts/keiri_content/search';

//モーダル
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';

// レイアウト
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

//icon
import * as Icon from 'react-bootstrap-icons';
import { Hits, InstantSearch } from 'react-instantsearch-hooks-web';

//scrolltop
import ReturnTopButton from '../../component/page_parts/common/scrolltop';

// パンくずリスト
import TableOfContents from "../../component/page_parts/common/table_of_contents";

function Keiri() {
  //各種申請用のstate
  const [jsonData_sinsei, setJsonData_sinsei] = useState([]);
  const [category_sinsei, setCategory_sinsei] = useState([]);

  //faq用のstate
  const [jsonData_faq, setJsonData_faq] = useState([]);
  const [category_faq, setCategory_faq] = useState([]);
  const [sliderImages, setSliderImages] = useState([]);
  const [sliderImages_sin, setSliderImages_sin] = useState([]);

  //マニュアル用のstate
  const [jsonData_manual, setJsonData_manual] = useState([]);

  //モーダル用のstate
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showModalIndex, setShowModalIndex] = useState(null);
  const [showModalIndex_sin, setShowModalIndex_sin] = useState(null);

  //スライダーの設定
  const settings = {
    dots: true,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    swipe: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  useEffect(() => {
    fetch(process.env.REACT_APP_API_PATH + 'keiri_cms/?read')
      .then((res) => res.json())
      .then((json) => {
        // console.log(json);
        //各種申請描画用の関数
        sinsei_ryui(json);

        //マニュアル描画用の関数
        manual(json);

        //faq描画用の関数
        faq(json);
      });
  }, []);

  //各種申請描画用の関数
  const sinsei_ryui = (json) => {
    var json1 = json[0].sinsei_ryui;
    // console.log(json1);
    var sliderObject = [];
    //jsonのfileNameが「|」区切りになっているものを探す
    for (let i = 0; i < json1.length; i++) {
      if (json1[i].fileName.includes('|')) {
        //「|」区切りになっているものを配列に変換
        var fileName = json1[i].fileName.split('|');

        //スペースを削除
        for (let j = 0; j < fileName.length; j++) {
          fileName[j] = fileName[j].replace(/\s+/g, '');
        }

        //質問をキーに、画像名を配列に格納
        sliderObject.push({
          question: json1[i].text,
          fileName: fileName,
        });
      }
    }
    // console.log(sliderObject);
    //スライダーに表示する画像をstateに格納
    setSliderImages_sin(sliderObject);

    // cosple.jog(json[0].sinsei_ryu);
    // カテゴリーを取り出し、重複を削除
    var category_value = json[0].sinsei_ryui
      .map((json) => json.category)
      .filter((x, i, self) => self.indexOf(x) === i);
    //カテゴリーをstateに格納
    setCategory_sinsei(category_value);

    //jsonをstateに格納
    setJsonData_sinsei(json[0].sinsei_ryui);
  };

  //マニュアル描画用の関数
  const manual = (json) => {
    // console.log(json[0].manual);
    //jsonをstateに格納
    setJsonData_manual(json[0].manual);
    // console.log(json[0].manual);
  };

  //FAQ描画用の関数
  const faq = (json) => {
    /*やりたいこと
     *jsonからcategoryを取り出す
     *カテゴリーをキーにして、ソートして、jsonを分ける
     *分けたjsonを表示する
     */
    //jsonのキーがfaqのものを取り出す
    var json = json[0].faq;
    var sliderObject = [];
    //jsonのfileNameが「|」区切りになっているものを探す
    for (let i = 0; i < json.length; i++) {
      if (json[i].fileName.includes('|')) {
        //「|」区切りになっているものを配列に変換
        var fileName = json[i].fileName.split('|');

        //スペースを削除
        for (let j = 0; j < fileName.length; j++) {
          fileName[j] = fileName[j].replace(/\s+/g, '');
        }

        //質問をキーに、画像名を配列に格納
        sliderObject.push({
          question: json[i].question,
          fileName: fileName,
        });
      }
    }
    // console.log(sliderObject);
    //スライダーに表示する画像をstateに格納
    setSliderImages(sliderObject);
    //カテゴリーを取り出す
    var category_value = json.map((json) => json.category);
    // console.log(category_value);
    //重複を削除
    var category_value_unique = category_value.filter(
      (x, i, self) => self.indexOf(x) === i
    );
    //重複を削除したカテゴリーをstateに格納
    setCategory_faq(category_value_unique);
    // console.log(category_value_unique);

    var json_sort = [];
    //カテゴリーの数だけfor文を回す
    for (let i = 0; i < category_value_unique.length; i++) {
      //カテゴリーをキーにして、jsonを分ける
      var json_category = json.filter(
        (json) => json.category === category_value_unique[i]
      );
      //カテゴリーごとに分けたjsonを配列に格納
      json_sort.push(json_category);
    }
    //カテゴリーごとに分けた配列をstateに格納
    setJsonData_faq(json_sort);
  };

  var bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  var content = 'あいうえお,かきくけこ,さしすせそ';
  var blob = new Blob([bom, content], { type: 'text/csv' });

  return (
    <>
      <Helmet>
        <title>Z-link 経費関連</title>
      </Helmet>
      <Header />
      <div className='theme-keiri theme-keiri-top'>
        <div className='content-header'>
          <div className='container'>
            <Row>
              <Col sm={9} className='pe-1'>
                <h1 className='page-title type-pig'>経費関連（楽楽精算）</h1>
                {/* ページタイトル */}
                <p className='page-description'>
                  交通費精算、出張精算、振込依頼など経費関連の各種申請は全て「楽楽精算」にて
                </p>
                {/* ページの説明 */}
              </Col>
              <Col sm={3} className='btn-wrap d-flex align-self-end'>
                <a
                  href='https://rsdesign.rakurakuseisan.jp/_5ZwGywYVPa/'
                  className='btn-link-area rakuraku__link position-fixed'
                  target='_brank'
                >
                  利用する
                </a>
                {/* ページタイトルの横にコンテンツがある場合はこちらに記入 */}
                <a
                  href={baseUrl + '/keiri/start_guide'}
                  className='link-to-start_guide'
                >
                  利用開始案内
                </a>
              </Col>
            </Row>
          </div>
        </div>
        <TableOfContents />

        <Container className='contents-wrap mt-5'>
          <section className='main mb-5'>
            <div>
              <h2 className='tag-title'>経費の各種事前チェック項目！</h2>
              <Tabs>
                <TabList>
                  {category_sinsei.map((category, index) => (
                    <Tab key={index}>{category}</Tab>
                  ))}
                </TabList>

                {category_sinsei.map((category, index) => (
                  <TabPanel key={index}>
                    {/* 元々の文言➣申請時の留意点 */}
                    {/* <p className='react-tabs__heading'>{category}のチェック事項</p> */}
                    <ol>
                      {jsonData_sinsei.map((json, i) =>
                        json.category === category ? (
                          <li className='react-tabs__text'>
                            {
                              //改行コードを反映
                              json.text.split('\n').map((line, index) => {
                                if (
                                  json.text.split('\n').length - 1 !==
                                  index
                                ) {
                                  // 最後の要素以外
                                  return (
                                    <>
                                      {line}
                                      <br />
                                    </>
                                  );
                                } else {
                                  // 最後の要素の場合、末尾に<br>は入れない
                                  if (
                                    (json.fileName !== '' &&
                                      json.layout != '3') ||
                                    (json.fileName !== '' && json.layout == '3')
                                  ) {
                                    // 画像アイコンがつく場合、余白用にspan追加
                                    return (
                                      <span className='add-img-icon'>
                                        {line}
                                      </span>
                                    );
                                  } else {
                                    return <>{line}</>;
                                  }
                                }
                              })
                            }
                            {
                              //画像がある場合(画像1枚)
                              json.fileName !== '' && json.layout == '2' ? (
                                <>
                                  <button
                                    className={
                                      'btn p-0 jsonData_tatekae_ryui' + i
                                    }
                                    variant='secondary'
                                    onClick={() =>
                                      setShowModalIndex(
                                        'jsonData_tatekae_ryui' + i
                                      )
                                    }
                                  >
                                    <img
                                      className='react-tabs__icon'
                                      src={
                                        process.env.PUBLIC_URL +
                                        '/keiri_pic/icon-camera.png'
                                      }
                                      alt='画像モーダル起動ボタン'
                                      width='84'
                                      height='23'
                                      loading='lazy'
                                    />
                                  </button>

                                  <Modal
                                    show={
                                      showModalIndex ===
                                      'jsonData_tatekae_ryui' + i
                                    }
                                    onHide={() => setShowModalIndex(null)}
                                    className='keiri-modal'
                                    size='lg'
                                    centered
                                    scrollable
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>
                                        {' '}
                                        {
                                          //改行コードを反映
                                          json.text.split('\n').map((line) => {
                                            return (
                                              <>
                                                {line}
                                                <br />
                                              </>
                                            );
                                          })
                                        }
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                      <img
                                        src={
                                          process.env.REACT_APP_SITE_URL +
                                          '/keiri_pic/' +
                                          json.fileName
                                        }
                                        alt='img'
                                      />
                                    </Modal.Body>
                                  </Modal>
                                </>
                              ) : (
                                <></>
                              )
                            }
                            {
                              //モーダルのレイアウトが3の場合(画像複数)
                              json.fileName !== '' && json.layout == '3' ? (
                                <>
                                  <button
                                    className={`btn p-0 ${json.category + i}`}
                                    variant='secondary'
                                    onClick={() =>
                                      setShowModalIndex_sin(json.category + i)
                                    }
                                  >
                                    <img
                                      className='react-tabs__icon'
                                      src={
                                        process.env.PUBLIC_URL +
                                        '/keiri_pic/icon-camera.png'
                                      }
                                      alt='画像モーダル起動ボタン hogehoge'
                                      width='84'
                                      height='23'
                                      loading='lazy'
                                    />
                                  </button>
                                  <Modal
                                    show={
                                      showModalIndex_sin === json.category + i
                                    }
                                    onHide={() => setShowModalIndex_sin(null)}
                                    className='keiri-modal'
                                    size='lg'
                                    centered
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>
                                        {
                                          //改行コードを反映
                                          json.text.split('\n').map((line) => {
                                            return (
                                              <>
                                                {line}
                                                <br />
                                              </>
                                            );
                                          })
                                        }
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                      {sliderImages_sin.map((sliderImage) => (
                                        <Slider {...settings}>
                                          {sliderImage.question ===
                                            json.text ? (
                                            sliderImage.fileName.map(
                                              (fileName, i) => (
                                                <div>
                                                  <img
                                                    src={
                                                      process.env
                                                        .REACT_APP_SITE_URL +
                                                      '/keiri_pic/' +
                                                      fileName
                                                    }
                                                    alt='img'
                                                    style={{
                                                      display: 'block',
                                                      margin: 'auto',
                                                    }}
                                                  />
                                                </div>
                                              )
                                            )
                                          ) : (
                                            <></>
                                          )}
                                        </Slider>
                                      ))}
                                    </Modal.Body>
                                  </Modal>
                                </>
                              ) : (
                                <></>
                              )
                            }
                            {
                              //モーダルのレイアウトが4の場合
                              json.layout == '4' && json.movieUrl != '' ? (
                                <>
                                  <Col
                                    sm={3}
                                    className='d-flex align-bottom align-self-end mb-3'
                                    id='keiri-movie'
                                  >
                                    <Button
                                      className='btn main-btn type-movie'
                                      variant='primary'
                                      onClick={() =>
                                        setShowModalIndex_sin(json.category + i)
                                      }
                                    >
                                      {json.movieTitle}
                                    </Button>
                                    <Modal
                                      show={
                                        showModalIndex_sin === json.category + i
                                      }
                                      onHide={() => setShowModalIndex_sin(null)}
                                      className='keiri-modal movie'
                                      size='lg'
                                      centered
                                    >
                                      <Modal.Header closeButton>
                                        <Modal.Title>
                                          {
                                            //改行コードを反映
                                            json.text
                                              .split('\n')
                                              .map((line) => {
                                                return (
                                                  <>
                                                    {line}
                                                    <br />
                                                  </>
                                                );
                                              })
                                          }
                                        </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <div>
                                          <iframe
                                            width='1280'
                                            height='720'
                                            src={json.movieUrl}
                                            title={json.movieTitle}
                                            frameborder='0'
                                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                            allowfullscreen
                                            className='keiri-movie'
                                          ></iframe>
                                        </div>
                                      </Modal.Body>
                                    </Modal>
                                  </Col>
                                </>
                              ) : (
                                <></>
                              )
                            }
                          </li>
                        ) : (
                          <></>
                        )
                      )}
                    </ol>
                    <div className='react-tabs__bottom d-flex justify-content-start mt-4'>
                      {jsonData_manual.map((json) =>
                        json.category === category ? (
                          <button
                            className='react-tabs__manual'
                            onClick={() =>
                              download(
                                process.env.PUBLIC_URL +
                                '/keiri_pic/' +
                                json.fileName
                              )
                            }
                          >
                            {json.fileName_jp}
                          </button>
                        ) : (
                          <></>
                        )
                      )}

                      <div className='keiri-link-btn'>
                        {category_faq.map((cate, index) => (
                          <>
                            {cate == category ? (
                              <Link
                                to={'cateID-' + index}
                                smooth={true}
                                duration={500}
                                offset={-100}
                                key={category}
                              >
                                {category}のFAQへ
                              </Link>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  </TabPanel>
                ))}
              </Tabs>
            </div>
          </section>
          <section className='faq'>
            <div className='contents'>
              <h2 className='tag-title mb-5' id='faq'>
                FAQ どんなことでお困りでしょうか？
              </h2>
              {/* <Search /> */}
            </div>
            <Row className='contents__tab'>
              {category_faq.map((cate, i) => (
                <Col sm={3} className='contents__tab-btn'>
                  <Link
                    className='btn main-btn type-internal down-link-btn col-sm-3'
                    to={'cateID-' + i}
                    smooth={true}
                    duration={500}
                    offset={-100}
                  >
                    {cate}
                  </Link>
                </Col>
              ))}
            </Row>
            {category_faq.map((cate, i) => (
              <div className='contents__box' id={'cateID-' + i}>
                <h3 className='icon-title type-pencil mt-small'>{cate}</h3>
                <Accordion alwaysOpen flush>
                  {jsonData_faq.map((json) =>
                    json.map((json, index) => {
                      return (
                        <>
                          {json.category === cate ? (
                            <Accordion.Item
                              eventKey={index}
                              className='contents__qa border-0'
                            >
                              <Accordion.Header
                                className='contents__qa--question'
                                as='p'
                              >
                                {json.question}
                              </Accordion.Header>
                              <Accordion.Body>
                                <p className='contents__qa--answer'>
                                  {
                                    //改行コードを反映
                                    json.answer.split('\n').map((line) => {
                                      return (
                                        <>
                                          {line}
                                          <br />
                                        </>
                                      );
                                    })
                                  }
                                </p>
                                {
                                  /**jsonに画像があり、レイアウトが3でない場合*/
                                  json.fileName !== '' && json.layout != '3' ? (
                                    <>
                                      <Button
                                        className='btn-camera'
                                        variant='secondary'
                                        onClick={() =>
                                          setShowModalIndex(
                                            json.category + index
                                          )
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            '/keiri_pic/icon-camera.png'
                                          }
                                          alt='画像モーダル起動ボタン'
                                          width='84'
                                          height='23'
                                          loading='lazy'
                                        />
                                      </Button>
                                      <Modal
                                        show={
                                          showModalIndex ===
                                          json.category + index
                                        }
                                        onHide={() => setShowModalIndex(null)}
                                        className='keiri-modal'
                                        size='lg'
                                        centered
                                        scrollable
                                      >
                                        <Modal.Header closeButton>
                                          <Modal.Title>
                                            A.
                                            {
                                              //改行コードを反映
                                              json.answer
                                                .split('\n')
                                                .map((line) => {
                                                  return (
                                                    <>
                                                      {line}
                                                      <br />
                                                    </>
                                                  );
                                                })
                                            }
                                          </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                          <img
                                            id={json.id}
                                            src={
                                              process.env.REACT_APP_SITE_URL +
                                              '/keiri_pic/' +
                                              json.fileName
                                            }
                                            alt='img'
                                            style={{
                                              display: 'block',
                                              margin: 'auto',
                                            }}
                                          />
                                        </Modal.Body>
                                      </Modal>
                                    </>
                                  ) : (
                                    <></>
                                  )
                                }
                                {
                                  /**jsonに画像があり、レイアウトが3の場合*/
                                  json.fileName !== '' && json.layout == '3' ? (
                                    <div>
                                      <Button
                                        className='btn-camera'
                                        variant='primary'
                                        onClick={() =>
                                          setShowModalIndex(
                                            json.category + index
                                          )
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            '/keiri_pic/icon-camera.png'
                                          }
                                          alt='画像モーダル起動ボタン'
                                          width='84'
                                          height='23'
                                          loading='lazy'
                                        />
                                      </Button>
                                      <Modal
                                        show={
                                          showModalIndex ===
                                          json.category + index
                                        }
                                        onHide={() => setShowModalIndex(null)}
                                        className='keiri-modal'
                                        size='lg'
                                        centered
                                      >
                                        <Modal.Header closeButton>
                                          <Modal.Title>
                                            A.
                                            {
                                              //改行コードを反映
                                              json.answer
                                                .split('\n')
                                                .map((line) => {
                                                  return (
                                                    <>
                                                      {line}
                                                      <br />
                                                    </>
                                                  );
                                                })
                                            }
                                          </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                          {sliderImages.map((sliderImage) => (
                                            <Slider {...settings}>
                                              {sliderImage.question ===
                                                json.question ? (
                                                sliderImage.fileName.map(
                                                  (fileName, i) => (
                                                    <div>
                                                      <img
                                                        src={
                                                          process.env
                                                            .REACT_APP_SITE_URL +
                                                          '/keiri_pic/' +
                                                          fileName
                                                        }
                                                        alt='img'
                                                        style={{
                                                          display: 'block',
                                                          margin: 'auto',
                                                        }}
                                                      />
                                                    </div>
                                                  )
                                                )
                                              ) : (
                                                <></>
                                              )}
                                            </Slider>
                                          ))}
                                        </Modal.Body>
                                      </Modal>
                                    </div>
                                  ) : (
                                    <></>
                                  )
                                }
                              </Accordion.Body>
                            </Accordion.Item>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })
                  )}
                </Accordion>
              </div>
            ))}
          </section>
        </Container>
      </div>
      {/* <ReturnTopButton /> */}
      <Footer />
    </>
  );
}

export default Keiri;
