// @ts-nocheck
import { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { useCookies } from 'react-cookie';

//ヘッダー
import Header from '../../component/page_parts/header';
// フッター
import Footer from '../../component/page_parts/footer';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';

// モーダル
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { index } from 'cheerio/lib/api/traversing';
import { get } from 'http';

//JSONデータ
import BoardVoice from '../../files/board-voice.json';

// パンくずリスト
import TableOfContents from "../../component/page_parts/common/table_of_contents";

function TorisimariyakuBlog() {

  /* 配信先指定と認証に必要なstate */
  // Cookieから取得したトークンを保持
  const [cookies, setCookie, removeCookie] = useCookies(["sessionToken"]);
  // トークンをデコードして取得したjtiを保持（パラメータに付与する用）
  const [cookieJti, setCookieJti] = useState();

  //トークンをデコードして取得したemailを保持（パラメータに付与する用）
  const [cookieEmail, setCookieEmail] = useState();

  //ローディング
  const [loading, setLoading] = useState(true);

  //restAPI取得
  const [posts, setPosts] = useState([]);

  //ブログ詳細切替用
  const [viewBlog, setViewBlog] = useState(1);

  const [ViewBlogLike, setViewBlogLike] = useState([]);

  const [notFound, setNotFound] = useState(false);

  // WP RESTAPI 環境別ベースURL
  const urlBase = process.env.REACT_APP_WP_REST_API_PATH_BIGWIG;

  //モーダル
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [modalImg, setModalImg] = useState('');

  // モーダルの表示
  const showModal = (e) => {
    //モーダルの中身を切り替える
    setModalImg(e.target.src);
    //表示切り替え用のステート
    setShow(true);
  };

  // 記事のnew表示用時間
  const [newTime, setNewTime] = useState();

  // 別ページから遷移し、スクロールしたかどうかのフラグ
  const [jumpFlg, setJumpFlg] = useState(false);

  // ログイン時に生成されたトークンをデコードしたデータをstateに格納
  useEffect(() => {

    const now = new Date();
    now.setDate(now.getDate() - 8);
    setNewTime(now);

    if (cookieJti === undefined) {
      SettingLonginData();
    }

  }, []);
  /* ログイン時に生成されたトークンをデコードしたデータをstateに保持 */
  function SettingLonginData() {
    // Cookieからトークンを取得
    const token = cookies.sessionToken;
    // デコード
    const decodedToken = jwt_decode<{ [name: string]: string }>(token);

    /* パラメータ（zenken）にセットしてリクエストするためのjtiの値を取得してstateにセット
    →WP側で値の確認を行う（確認できないリクエストには401を返すようにする）*/
    setCookieJti(decodedToken.jti);

    /* パラメータ（email）にセットしてリクエストするためのemailの値を取得してstateにセット*/
    setCookieEmail(decodedToken.data.EMAIL);
  }

  useEffect(() => {
    //初期表示時用
    //URLパラメータ「default」の有無をチェック
    const params = new URLSearchParams(window.location.search);
    const defaultParam = params.get('default');
    console.log(defaultParam);
    if (defaultParam !== null) {

      for (let i = 0; i < BoardVoice.length; i++) {
        // 現在の選択アイコンが左から何番目か判断
        if (BoardVoice[i].catId == defaultParam) {
          //valueをstateにセット
          setViewBlog(BoardVoice[i].dispno);

        }
      }

      // setViewBlog(defaultParam);
      getPosts(defaultParam);
    } else {
      getPosts(1);
    }
    newPost();
  }, [cookieJti])

  useEffect(() => {
    //.wp-block-image imgを取得
    var img = document.querySelectorAll('.wp-block-image img');
    //imgにshowModalを付与
    img.forEach(function (e) {
      e.addEventListener('click', showModal);
    });

  }, [posts])

  // 新着から遷移した際に、該当の記事までスクロール、記事を開く処理
  useEffect(() => {

    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');
    var str = "postNo" + id;
    const jumpId = document.getElementById(str);
    if (jumpId != null && jumpFlg == false) {
      jumpId.scrollIntoView({
        behavior: 'smooth'
      });
      // ついでに記事も開いておく
      var moreReadButton = document.getElementById(id);
      moreReadButton.textContent = '閉じる';
      moreReadButton.classList.add('active');
      var pareant = moreReadButton?.parentNode;
      var prevEle = pareant.previousElementSibling;
      prevEle.style.height = 'auto';
      prevEle.classList.add("active");
      setJumpFlg(true);
    }

  }, [posts])

  function viewBlogChange(e) {

    var moreReadIds = localStorage.getItem("moreReadBoardId");
    var moreReadIdList = moreReadIds?.split(',')

    if (moreReadIds != null) {
      for (let i = 0; i < moreReadIdList.length; i++) {
        var moreReadId = document.getElementById(moreReadIdList[i]);
        if (moreReadId != null) {
          moreReadId.textContent = 'さらに読む';
          moreReadId.classList.remove('active');
          var pareant = moreReadId?.parentNode;
          var prevEle = pareant.previousElementSibling;
          prevEle.style.height = '120px';
          prevEle.classList.remove("active");
        }
      }
    }

    for (let i = 0; i < BoardVoice.length; i++) {
      // 現在の選択アイコンが左から何番目か判断
      if (BoardVoice[i].catId == e.target.value) {
        //valueをstateにセット
        setViewBlog(BoardVoice[i].dispno);

      }
    }

    //いいね数を初期化
    ViewBlogLike.length = 0;

    //データを取得
    getPosts(e.target.value);
  }

  function getPosts(catId) {
    var wpRestUrl = urlBase + 'wp-json/wp/v2/posts?per_page=100&categories=' + catId + '&zenken='
    if (cookieJti !== undefined) {
      const fetchUrl = wpRestUrl + cookieJti;
      fetch(fetchUrl, { method: 'GET' })
        .then(res => res.json())
        .then(data => {
          if (data.length == 0) {
            setNotFound(true);
          } else {
            setNotFound(false);
          }

          //ローカルストレージ「likeData」を取得
          var likeData = JSON.parse(localStorage.getItem('likeData'));

          //日付を取得
          var now = new Date();
          var year = now.getFullYear();
          var month = now.getMonth() + 1;
          var day = now.getDate();
          var hour = now.getHours();
          var min = now.getMinutes();
          var sec = now.getSeconds();
          //一日前の日付を取得
          var yesterday = year + '/' + month + '/' + (day - 1) + ' ' + hour + ':' + min + ':' + sec;

          //likeDataの中から一日前の日付より前のものを削除
          if (likeData !== null) {
            for (let i = 0; i < likeData.length; i++) {
              //日付形式に変換
              var likeDataDate = new Date(likeData[i].date);
              var yesterdayDate = new Date(yesterday);
              //日付比較
              if (likeDataDate < yesterdayDate) {
                likeData.splice(i, 1);
              }
            }
          } else {
            likeData = [];
          }

          localStorage.setItem('likeData', JSON.stringify(likeData));

          //いいね数を初期化
          ViewBlogLike.length = 0;
          //いいね数を取得
          for (let i = 0; i < data.length; i++) {
            //記事タイトル、いいね数を1つにまとめる
            ViewBlogLike.push({
              title: data[i].title.rendered,
              like_count: data[i].like_count
            });

            //存在する場合
            if (likeData !== null) {
              //likeDataの中からtitleが一致するものを探す
              const likeDataFind = likeData.find((likeData) => likeData.title === data[i].title.rendered);
              if (likeDataFind !== undefined) {
                //dataにlikeBtn:falseを追加
                data[i].likeBtn = false;
              } else {
                //dataにlikeBtn:trueを追加
                data[i].likeBtn = true;
              }
            }
          }
          setPosts(data)
          setLoading(false);
        })
    }
  }

  //addLike
  function addLike(e) {
    var title = e.target.dataset.title;
    var category = e.target.dataset.category;

    var wpRestUrl = urlBase + "wp-json/wp/custom/addLikeData?post_title=" + title + "&post_category=" + category + "&email=" + cookieEmail + "&zenken=";
    if (cookieJti !== undefined && title !== undefined && category !== undefined) {

      const fetchUrl = wpRestUrl + cookieJti;
      fetch(fetchUrl, { method: 'GET' })
        .then(res => res.json())
        .then(data => {

          //更新後のデータを取得
          getPosts(category)

          //日付を取得
          var now = new Date();
          var year = now.getFullYear();
          var month = now.getMonth() + 1;
          var day = now.getDate();
          var hour = now.getHours();
          var min = now.getMinutes();
          var sec = now.getSeconds();
          //日付を整形
          var date = year + '/' + month + '/' + day + ' ' + hour + ':' + min + ':' + sec;

          //ローカルストレージ「likeData」を取得
          var likeData = JSON.parse(localStorage.getItem('likeData'));

          //存在するか否か
          if (likeData !== null) {
            //存在する場合
            //json形式でデータを追加
            likeData.push({
              title: title,
              date: date,
              category: category
            });
          } else {
            //存在しない場合
            //json形式でデータを追加
            likeData = [{
              title: title,
              date: date,
              category: category
            }];
          }
          //ローカルストレージに保存
          localStorage.setItem('likeData', JSON.stringify(likeData));
        })
    }
  }

  // moreRead
  function moreRead(e) {

    var moreReadButton = document.getElementById(e.target.id);
    var postNo = document.getElementById("postNo" + moreReadButton.id);

    if (moreReadButton.classList.contains('active')) {

      // 記事エリアを閉じているとき
      moreReadButton.textContent = 'さらに読む';
      moreReadButton.classList.remove('active');
      var pareant = moreReadButton?.parentNode;
      var prevEle = pareant.previousElementSibling;
      prevEle.style.height = '120px';
      prevEle.classList.remove("active");

    } else {

      // 記事エリアを開いているとき
      moreReadButton.textContent = '閉じる';
      moreReadButton.classList.add('active');
      var pareant = moreReadButton?.parentNode;
      var prevEle = pareant.previousElementSibling;
      prevEle.style.height = 'auto';
      prevEle.classList.add("active");
    }

    const targetDOMRect = postNo.getBoundingClientRect();
    const targetTop = targetDOMRect.top + window.pageYOffset;
    document.documentElement.scrollTop = targetTop - 50;

    if (localStorage.getItem("moreReadBoardId") == null) {
      localStorage.setItem("moreReadBoardId", e.target.id);
    } else {
      var moreReadId = localStorage.getItem("moreReadBoardId");
      if (moreReadId?.indexOf(e.target.id) == -1) {
        var newMoreReadId = moreReadId + "," + e.target.id;
        localStorage.setItem("moreReadBoardId", newMoreReadId)
      }
    }
  }

  // 1週間以内の新着記事有無確認
  function newPost() {

    // ISO 8601形式で現在時刻より7日前の日付を取得
    const now = new Date();
    now.setDate(now.getDate() - 7);
    var timeISO = now.toISOString();

    // 7日前の日付をRESTAPIのクエリパラメータ用に整形
    var postAfter = '&after=' + timeISO.substring(0, timeISO.indexOf('.'));

    var newPostCatList = [];

    var wpRestUrl = urlBase + 'wp-json/wp/v2/posts?_fields=categories&per_page=100' + postAfter + '&zenken=';

    if (cookieJti !== undefined) {
      const fetchUrl = wpRestUrl + cookieJti;
      fetch(fetchUrl, { method: 'GET' })
        .then(res => res.json())
        .then(data => {
          for (let i = 0; i < data.length; i++) {
            newPostCatList.push(data[i]["categories"][0]);
          }
          const newPostCatFilterList = newPostCatList.filter((element, index) => {
            return newPostCatList.indexOf(element) == index;
          })
          for (let i = 0; i < newPostCatFilterList.length; i++) {
            var targetId = document.getElementById(newPostCatFilterList[i]);
            targetId?.classList.add('newOn');
          };
        })
    }
  };

  return (
    <>
      <Helmet>
        <title>Z-link 役員ブログ</title>
      </Helmet>
      <Header />
      <div className="theme-hogehoge">{/* ページ独自のクラスで囲む(theme-) */}
        <div className="content-header">
          <div className="container">
            <Row>
              <Col sm={12}>
                <h1 className='page-title type-board-voice no-description'>役員ブログ</h1>{/* ページタイトル */}
              </Col>
            </Row>
          </div>
        </div>
        <TableOfContents />
        <Container className='boardBVoiceContents'>
          <section className="iconSec">
            {BoardVoice.map((data, index) => {
              return (
                <div className="iconBtnBox"
                  onClick={() =>
                    viewBlogChange({ target: { value: BoardVoice[index].catId } })
                  }
                >
                  <div
                    className='iconWrap'
                    id={data.catId}
                  >
                    <img className={(index + 1) == viewBlog ? "viewOn" : ""} src={process.env.PUBLIC_URL + '/board_pic/' + data.icon + ".png"} alt="" />
                    <p className='view'>View</p>
                  </div>
                  <div className={(index + 1) == viewBlog ? "blogTitle viewOn" : "blogTitle"}>
                    <span className='a-name'>{data.author}</span><br />
                    <span className='title' style={{ whiteSpace: 'pre-line' }}>{data.title}</span>
                  </div>
                </div>
              )
            }
            )}
          </section>
          <section className="mb-5 blogSec">
            <div className="titleArea">
              {BoardVoice.map((data, index) => {
                return (
                  <>
                    {
                      data.banner != "" ?
                        <img className={(index + 1) == viewBlog ? "titlesImg" : "titlesImg viewOff"} src={process.env.PUBLIC_URL + '/board_pic/' + data.banner + ".jpg"} alt="" />
                        :
                        <p className={(index + 1) == viewBlog ? "titles" : "titles viewOff"}>
                          「{
                            data.title
                          }」<br />
                          バナー準備中！乞うご期待！
                        </p>
                    }
                  </>
                )
              }
              )}
            </div>
            <div className="contentArea">
              {
                loading ? (
                  <div className="loading_blog" data-text="Loading...">
                    Loading...
                  </div>
                ) : (
                  <>
                    {
                      notFound ? (
                        <div className="notFound">
                          <p>投稿が存在しません。<br />
                            更新をお楽しみに！！
                          </p>
                        </div>
                      ) : (
                        <>
                          {
                            posts.map((post) => {
                              return (
                                <div className={"contents mb-5"} id={"postNo" + post.id} >
                                  <div className="author_box">
                                    <img src={process.env.PUBLIC_URL + '/board_pic/' + BoardVoice[viewBlog - 1].icon + ".png"} alt="" />
                                    <div className="blogTitle">
                                      <p className='titleEtc'>
                                        <span className={
                                          //投稿日が1週間以内の場合はnewを表示
                                          new Date(post.date) > newTime ? 'new' : 'new viewOff'
                                        }>
                                          NEW
                                        </span>
                                        <span className='date'>
                                          {post.date.slice(0, 10).replace(/-/g, '/')}
                                        </span>
                                      </p>
                                      <p className='titleText'>
                                        {post.title.rendered}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="postBox">
                                    <div className="blogText" dangerouslySetInnerHTML={{ __html: post.content.rendered }}>
                                    </div>
                                    <div className='moreReadArea'>
                                      <button
                                        className="moreReadButton"
                                        onClick={moreRead}
                                        id={post.id}
                                      >
                                        さらに読む
                                      </button>
                                      <div className="likeCountBox">
                                        <button
                                          className={"likeBtn" + (post.likeBtn ? '' : ' is-animatied')}
                                          onClick={addLike}
                                          data-title={post.title.rendered}
                                          data-category={BoardVoice[viewBlog - 1].catId}
                                          disabled={post.likeBtn ? false : true}
                                        >
                                        </button>
                                        <p className='likeCount'>
                                          {
                                            ViewBlogLike.map((data) => {
                                              if (data.title == post.title.rendered) {
                                                return (
                                                  data.like_count
                                                )
                                              }
                                            })
                                          }
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </>
                      )
                    }
                  </>
                )
              }
            </div>
          </section>
        </Container>
      </div >
      <Modal
        show={show}
        onHide={handleClose}
        className="modal-lg evaluation-modal"
        size="lg"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={modalImg} alt="" />
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}

export default TorisimariyakuBlog;