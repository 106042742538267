// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';

import jwt_decode from "jwt-decode";
import { useCookies } from "react-cookie";
//ヘッダー
import Header from "../../component/page_parts/header";

// フッター
import Footer from "../../component/page_parts/footer";
import { set } from "react-hook-form";

/**
 * MEMO:
 * おそらく更新が多く発生するページかつ、
 * 色々忘れそうな仕様があるためメモを残しておく。
 *
 * ざっくりやってること:
 * 事業所、所属本部、所属課1,2,3の選択肢と、それぞれの選択肢により表示される選択肢を制御している。
 * DBから取得したコードテーブルを元に選択肢などを表示していて、PARENT_IDを元に次の選択肢の判断と表示を行っている。
 *
 * ■ 処理の流れ
 * 1. トークンからユーザー情報を取得
 * 2. ユーザー情報から所属部署のコードID、ユーザーを情報を取得(API: user/profile/nomalGet.php)
 * 3.OFFICE_CODE_IDの有無でモードを切り替える
 * 編集or新規編集
 *
 * ■ 編集モード
 * 1. 2で取得したユーザー情報を元に、事業所、所属本部、所属課1,2,3の選択肢を表示
 *
 * ■ 新規編集モード
 * 1.各選択肢が選択された場合、その選択肢の子要素を表示、ない場合は最終選択フラグをtrueにする
 * 2.最終選択フラグがtrueの場合、更新ボタンを押せるようにする
 *
 * ISSUE:
 * 現在は全研本社以下のみ部署や課を選択できるようになっているが、
 * 今後は全研本社以外の部署や課も選択できるさせる対応が発生する可能性がある。
 */

function NormalEdit() {
  const [cookies, setCookie, removeCookie] = useCookies(["sessionToken"]);
  const [codeList, setCodeList] = useState([]);
  const [profile, setProfile] = useState([]);

  //アカウント名
  const [userName, setUserName] = useState("");

  //事業所
  const [office, setOffice] = useState("");

  //所属本部
  const [department, setDepartment] = useState("");

  //部署の配列
  const [section1, setSection1] = useState("");
  const [section2, setSection2] = useState("");
  const [section3, setSection3] = useState("");

  //連絡先
  const [extensionNumber, setExtensionNumber] = useState("");

  //誕生日
  //月
  const [birthMonth, setBirthMonth] = useState("");
  //日
  const [birthDay, setBirthDay] = useState("");

  //フラグ用
  const [lastedFlag, setLastedFlag] = useState(false);
  const [EditFlag, setEditFlag] = useState(false);

  // よみがな
  const [kana, setKana] = useState("");

  // 更新可能フラグ
  const [sendFlg, setSendFlg] = useState(false);

  // 電話番号チェックテキスト
  const [numberCheckText, setNumberCheckText] = useState("（半角数字とスラッシュ(/)とハイフン(-)のみで入れてください）");

  // 更新成功時メッセージ
  const [upSuccessMessage, setUpSuccessMessage] = useState("ユーザ情報の更新に成功しました。");

  // 更新失敗時メッセージ
  const [upErrorMessage, setUpErrorMessage] = useState("ユーザ情報の更新に失敗しました。入力情報が変更されていないか、入力情報に不足があります。");

  useEffect(() => {
    //トークンの有無を確認
    if (!cookies.sessionToken) {
      //トークンがない
    } else {
      const decoded = jwt_decode<{ [name: string]: string }>(
        cookies.sessionToken
      );
      getProfile(decoded.data.EMAIL);
    }
  }, []);

  function numberCheck(value) {
    return /^[\x20-\x7e]*$/.test(value);
  }

  //プロフィール情報取得
  async function getProfile(email) {
    var res = await fetch(
      process.env.REACT_APP_API_PATH +
      "user/profile/nomalGet.php?email=" +
      email
    );
    var data = await res.json();

    //文字から配列に変換
    var code_data = JSON.parse(data.code_data);
    var user_data = JSON.parse(data.user_data);
    setCodeList(code_data);
    setProfile(user_data);
    setUserName(user_data.USER_NAME == "" ? "" : user_data.USER_NAME);
    //user_data内のOFFICE_CODE_IDの有無でモードを切り替える
    if (user_data.OFFICE_CODE_ID == "") {
      //SECTION_CODE_ID1,2,3が0ではない場合を配列に格納
      var section_array = [];
      if (user_data.SECTION_CODE_ID1 != 0) {
        section_array.push(user_data.SECTION_CODE_ID1);
      }
      if (user_data.SECTION_CODE_ID2 != 0) {
        section_array.push(user_data.SECTION_CODE_ID2);
      }
      if (user_data.SECTION_CODE_ID3 != 0) {
        section_array.push(user_data.SECTION_CODE_ID3);
      }
      console.log(section_array);
      if (section_array[0] == null && section_array[1] == null && section_array[2] == null) {
        return;
      }
      //せっと
      setSectionArray(section_array);
    } else {
      //編集モード
      setEditFlag(true);
      setOffice(user_data.OFFICE_CODE_ID == "" ? "" : user_data.OFFICE_CODE_ID);
      setDepartment(
        user_data.DEPARTMENT_CODE_ID == "" ? "" : user_data.DEPARTMENT_CODE_ID
      );
      setSection1(
        user_data.SECTION_CODE_ID1 == "" ? "" : user_data.SECTION_CODE_ID1
      );
      setSection2(
        user_data.SECTION_CODE_ID2 == "" ? "" : user_data.SECTION_CODE_ID2
      );
      setSection3(
        user_data.SECTION_CODE_ID3 == "" ? "" : user_data.SECTION_CODE_ID3
      );
      setExtensionNumber(user_data.EXTENSION == "" ? "" : user_data.EXTENSION);
      setKana(user_data.KANA == "" ? "" : user_data.KANA);

      //誕生日
      if (user_data.BIRTHDAY != "" || user_data.BIRTHDAY != null) {
        var birthList = user_data.BIRTHDAY.split("/");
        var birthMon = birthList[0];
        var birthDay = birthList[1];
        setBirthMonth(birthMon);
        setBirthDay(birthDay);
      } else {
        setBirthMonth("1");
        setBirthDay("1");
      }
    }
  }
  const handleChange = (e) => {

    //空ではない場合(選択してくださいではない場合)
    //選択された事業所のコードIDにより分岐
    //クソコード
    var nowChoice = "";
    if (e.target.value >= 100 && e.target.value < 200) {
      setOffice(e.target.value);
      var nameOffice = document.getElementById("name-office");
      nameOffice.textContent = "※選択必須";
      nameOffice.nextElementSibling?.classList.remove("error");
    } else if (e.target.value >= 200 && e.target.value < 300) {
      setDepartment(e.target.value);
      var nameDepartment = document.getElementById("name-department");
      nameDepartment.textContent = "※選択必須";
      nameDepartment.nextElementSibling?.classList.remove("error");
    } else if (e.target.value >= 300 && e.target.value < 400) {
      setSection1(e.target.value);
      var nameSection1 = document.getElementById("name-section1");
      nameSection1.textContent = "※選択必須";
      nameSection1.nextElementSibling?.classList.remove("error");
    } else if (e.target.value >= 400 && e.target.value < 450) {
      setSection2(e.target.value);
      var nameSection2 = document.getElementById("name-section2");
      nameSection2.textContent = "※選択必須";
      nameSection2.nextElementSibling?.classList.remove("error");
    } else if (e.target.value >= 450 && e.target.value < 500) {
      setSection3(e.target.value);
      setLastedFlag(true);
    }

    nowChoice = e.target.getAttribute("data-select-child");
    //選択された内容よりあとの階層を初期化
    if (nowChoice == "office") {
      setDepartment("");
      setSection1("");
      setSection2("");
      setSection3("");
    } else if (nowChoice == "department") {
      setSection1("");
      setSection2("");
      setSection3("");
    } else if (nowChoice == "section1") {
      setSection2("");
      setSection3("");
    } else if (nowChoice == "section2") {
      setSection3("");
    }

    //選択されたコードIDに子要素がない場合、最終選択フラグをtrueにする
    if (
      !codeList.filter((code) => code.PARENT_ID == e.target.value).length > 0 &&
      e.target.value != ""
    ) {
      setLastedFlag(true);
    } else {
      setLastedFlag(false);
    }
  };

  //profile更新処理
  const handleClick = (e) => {
    e.preventDefault();

    localStorage.setItem("dept", department);
    localStorage.setItem("s1", section1);
    localStorage.setItem("s2", section2);

    // 所属に応じたビル内外を設定
    let building = "";
    if (department === "" && section1 === "" && section2 === "" && section3 === "") { // office までの組織
      building = "601"; // ビル外で設定
    } else if (section1 === "" && section2 === "" && section3 === "") { // department までの組織
      building = "600"; // デフォルトでビル内を設定
    } else if (section2 === "" && section3 === "") { // section1 までの組織
      building = "600"; // デフォルトでビル内を設定
      // ビル外組織のみ、ビル外用のコードを更新
      if (section1 === "305") {
        building = "601";
      }
    } else if (section3 === "") { // section2 までの組織
      building = "600"; // デフォルトでビル内を設定
      // ビル外組織のみ、ビル外用のコードを更新
      if (section2 === "401" || section2 === "402" || section2 === "403" || section2 === "412" || section2 === "413") {
        building = "601";
      }
    }
    // console.log("ビル内外: ", building);

    // 所属部署2の選択が必要がどうか確認
    if (document.getElementById("section2") != null) {
      var nameSection2 = document.getElementById("name-section2");
      // 所属部署2の選択フォームが存在しているので、入力値の確認
      if (section2 == "") {
        // 未選択の為、これ以上の更新処理をしない
        nameSection2.nextElementSibling?.classList.add("error");
        nameSection2.textContent = "※所属部署2が選択されていません。";
        kanaCheck();
        alert(upErrorMessage);
        return;
      }
    }

    // 所属部署1の選択が必要がどうか確認
    if (document.getElementById("section1") != null) {
      var nameSection1 = document.getElementById("name-section1");
      // 所属部署1の選択フォームが存在しているので、入力値の確認
      if (section1 == "") {
        // 未選択の為、これ以上の更新処理をしない
        nameSection1.nextElementSibling?.classList.add("error");
        nameSection1.textContent = "※所属部署1が選択されていません。";
        kanaCheck();
        alert(upErrorMessage);
        return;
      }
    }

    // 所属本部の選択が必要がどうか確認
    if (document.getElementById("department") != null) {
      var nameDepartment = document.getElementById("name-department");
      // 所属本部の選択フォームが存在しているので、入力値の確認
      if (department == "") {
        // 未選択の為、これ以上の更新処理をしない
        nameDepartment.nextElementSibling?.classList.add("error");
        nameDepartment.textContent = "※所属本部が選択されていません。";
        kanaCheck();
        alert(upErrorMessage);
        return;
      }
    }

    // 事業所の選択が必要がどうか確認
    if (document.getElementById("office") != null) {
      var nameOffice = document.getElementById("name-office");
      // 事業所の選択フォームが存在しているので、入力値の確認
      if (office == "") {
        // 未選択の為、これ以上の更新処理をしない
        nameOffice.nextElementSibling?.classList.add("error");
        nameOffice.textContent = "※事業所が選択されていません。";
        kanaCheck();
        alert(upErrorMessage);
        return;
      }
    }

    // よみがなの入力値の確認
    if (kana == "") {
      var nameAlert = document.getElementById("name-alert");
      nameAlert.nextElementSibling?.classList.add("error");
      nameAlert.textContent = "※よみがなが入力されていません。（氏名の間は全角スペースを入れてください）";
      // 未入力の為、これ以上の処理をしない
      alert(upErrorMessage);
      return;
    }

    setSendFlg(false);

    //更新用URLパラメータを作成
    var param =
      "?email=" +
      profile.EMAIL +
      "&office=" +
      office +
      "&department=" +
      department +
      "&section1=" +
      section1 +
      "&section2=" +
      section2 +
      "&section3=" +
      section3 +
      "&extension=" +
      extensionNumber +
      "&birthday=" +
      birthMonth + "/" + birthDay +
      "&kana=" +
      kana +
      "&building=" +
      building;

    //API
    fetch(process.env.REACT_APP_API_PATH + "user/profile/nomalEdit.php" + param)
      .then((response) => response.json())
      .then((data) => {
        alert(upSuccessMessage);
      });
  };

  const extensionNumberChange = (e) => {

    if (e.target.value.match(/[^\x01-\x7E\xA1-\xDF]+/)) {
      setNumberCheckText("（全角が含まれています。半角数字とスラッシュ(/)とハイフン(-)のみで入れてください）");
      if (e.target.value != extensionNumber) {
        setExtensionNumber(e.target.value);
      }
      return;
    } else {
      setNumberCheckText("（半角数字とスラッシュ(/)とハイフン(-)のみで入れてください）");
      if (e.target.value != extensionNumber) {
        setExtensionNumber(e.target.value);
      }
    }

    if (e.target.value.match(/^[0-9\-\/]*$/)) {
      if (e.target.value != extensionNumber) {
        setExtensionNumber(e.target.value);
      }
      return true;
    } else {
      setNumberCheckText("（英字が含まれています。半角数字とスラッシュ(/)とハイフン(-)のみで入れてください））");
      if (e.target.value != extensionNumber) {
        setExtensionNumber(e.target.value);
      }
      return false;
    }
  };

  const birthChange = (e) => {
    var nowChoice = e.target.getAttribute("data-select-child");
    //選択された内容を月と日か判定
    if (nowChoice == "month") {
      setBirthMonth(e.target.value);
      setLastedFlag(true);
    } else {
      setBirthDay(e.target.value);
      setLastedFlag(true);
    }

  }

  const kanaChange = (e) => {
    setKana(e.target.value);
    setLastedFlag(true);
    var nameAlert = document.getElementById("name-alert");
    // 入力値が空文字の場合、必須文言を変える
    if (e.target.value == "") {
      nameAlert.nextElementSibling?.classList.add("error");
      nameAlert.textContent = "※よみがなが入力されていません。（氏名の間は全角スペースを入れてください）";
    } else {
      nameAlert.nextElementSibling?.classList.remove("error");
      nameAlert.textContent = "※入力必須（氏名の間は全角スペースを入れてください）";
    }
  };

  function kanaCheck() {
    // よみがなの入力値の確認
    if (kana == "") {
      var nameAlert = document.getElementById("name-alert");
      nameAlert.nextElementSibling?.classList.add("error");
      nameAlert.textContent = "※よみがなが入力されていません。（氏名の間は全角スペースを入れてください）";
    }
  }

  return (
    <>
      <Helmet>
        <title>Z-link ユーザー編集</title>
      </Helmet>
      <Header />
      <div className="user-wrapper">
        <div className="container">
          <h1>ユーザー編集</h1>
        </div>
        <hr />

        <div className="container contents-wrapper">
          {/**
           * ■ 表示項目
           * ・よみがな
           * ・事業所
           * ・所属部署
           * ・所属課名
           *
           */}
          <>
            <div>
              {userName == "" ? (
                <p className="user-name">ユーザー情報が取得できませんでした</p>
              ) : (
                <p className="user-name">{userName}</p>
              )}
            </div>
            <div className="form-group" style={{ width: "80%" }}>
              <label htmlFor="lastName" style={{ whiteSpace: "nowrap" }}>よみがな</label>
              <label className="alert" id="name-alert">※入力必須（氏名の間は全角スペースを入れてください）</label>
              <input
                type="text"
                className="form-control wrap-for-input"
                onChange={kanaChange}
                defaultValue={kana ? kana : ""}
                placeholder="例)ぜんけん たろう"
              ></input>
            </div>
            {/* New*/}
            {/* 事業所のみ表示 */}
            <div className="form-group put_arrow">
              <label htmlFor="office">事業所</label>
              <label className="alert" id="name-office">※選択必須</label>
              <select
                className="form-control"
                id="office"
                data-select-child="office"
                onChange={handleChange}
                value={office}
              >
                <option>選択してください</option>
                {
                  //CODE_IDが100番台のものを表示
                  codeList.map((code) => {
                    if (code.CODE_ID >= 101 && code.CODE_ID < 200) {
                      return (
                        <option value={code.CODE_ID}>{code.CODE_MEAN}</option>
                      );
                    }
                  })
                }
              </select>
            </div>
            {
              //TODO: リリース時は全研本社のみ以降の処理を実行
              office != "" && office == "101" ? (
                <>
                  {/* 所属本部 */}
                  <div className="form-group put_arrow">
                    <label htmlFor="department">所属本部</label>
                    <label className="alert" id="name-department">※選択必須</label>
                    <select
                      className="form-control"
                      id="department"
                      data-select-child="department"
                      onChange={handleChange}
                      value={department}
                    >
                      <option value="">選択してください</option>
                      {
                        //CODE_IDが200番台のものを表示
                        codeList.map((code) => {
                          if (code.CODE_ID >= 200 && code.CODE_ID < 300) {
                            return (
                              <option value={code.CODE_ID}>
                                {code.CODE_MEAN}
                              </option>
                            );
                          }
                        })
                      }
                    </select>
                  </div>
                </>
              ) : (
                <></>
              )
            }
            {
              //所属本部が選択されている場合かつ内部監査室(203)ではない場合
              department != "" && department != "205" ? (
                <>
                  {/* 所属部署 */}
                  <div className="form-group put_arrow">
                    <label htmlFor="section">所属部署1</label>
                    <label className="alert" id="name-section1">※選択必須</label>
                    <select
                      className="form-control"
                      id="section1"
                      onChange={handleChange}
                      data-select-child="section1"
                      value={section1}
                    >
                      <option value="">選択してください</option>
                      {
                        //CODE_IDが300番台のものを表示
                        codeList.map((code) => {
                          if (
                            code.CODE_ID >= 300 &&
                            code.CODE_ID < 400 &&
                            code.PARENT_ID == department
                          ) {
                            return (
                              <option value={code.CODE_ID}>
                                {code.CODE_MEAN}
                              </option>
                            );
                          }
                        })
                      }
                    </select>
                  </div>
                </>
              ) : (
                <></>
              )
            }
            {
              //所属部署1が選択されているかつさらに下層部署がある場合
              section1 != "" &&
                codeList.filter((code) => code.PARENT_ID == section1).length >
                0 ? (
                <>
                  {/* 所属部署2 */}
                  <div className="form-group put_arrow">
                    <label htmlFor="section">所属部署2</label>
                    <label className="alert" id="name-section2">※選択必須</label>
                    <select
                      className="form-control"
                      id="section2"
                      onChange={handleChange}
                      data-select-child="section2"
                      value={section2}
                    >
                      <option value="">選択してください</option>
                      {
                        //CODE_IDが400番台のものを表示
                        codeList.map((code) => {
                          if (
                            code.CODE_ID >= 400 &&
                            code.CODE_ID < 500 &&
                            code.PARENT_ID == section1
                          ) {
                            return (
                              <option value={code.CODE_ID}>
                                {code.CODE_MEAN}
                              </option>
                            );
                          }
                        })
                      }
                    </select>
                  </div>
                </>
              ) : (
                <></>
              )
            }
            {
              //所属部署2が選択されているかつさらに下層部署がある場合
              section2 != "" &&
                codeList.filter((code) => code.PARENT_ID == section2).length >
                0 ? (
                <>
                  {/* 所属部署3 */}
                  <div className="form-group put_arrow">
                    <label htmlFor="section" id="name-section3">所属部署3</label>
                    <select
                      className="form-control"
                      id="section3"
                      onChange={handleChange}
                      data-select-child="section3"
                      value={section3}
                    >
                      <option value="">選択してください</option>
                      {
                        //CODE_IDが400番台のものを表示
                        codeList.map((code) => {
                          if (
                            code.CODE_ID >= 450 &&
                            code.CODE_ID < 500 &&
                            code.PARENT_ID == section2
                          ) {
                            return (
                              <option value={code.CODE_ID}>
                                {code.CODE_MEAN}
                              </option>
                            );
                          }
                        })
                      }
                    </select>
                  </div>
                </>
              ) : (
                <></>
              )
            }
            <div className="form-group">
              <label htmlFor="name">内線番号/携帯番号{numberCheckText}</label>
              <input
                type="text"
                className="form-control wrap-for-input"
                id="extensionNumber"
                defaultValue={extensionNumber ? extensionNumber : ""}
                onChange={extensionNumberChange}
                placeholder="例)123/090-1234-5678"
              ></input>
            </div>

            <div className="d-flex" style={{ width: "30%" }}>
              <div style={{ width: "35%", flexWrap: "nowrap", marginRight: "10px" }} className="row">
                <div className="form-group put_arrow col-8">
                  <label htmlFor="birthMonth" style={{ whiteSpace: "nowrap" }}>誕生日(※設定していただくと当日に何かが起きるかも…！？)</label>
                  <select
                    className="form-control"
                    id="birthMonth"
                    onChange={birthChange}
                    data-select-child="month"
                    value={
                      birthMonth
                    }
                  >
                    {
                      //1-12月を表示
                      [...Array(12)].map((_, i) => {
                        return (
                          <option value={i + 1}>
                            {i + 1}
                          </option>
                        );
                      })
                    }
                  </select>
                </div>
                <div className="col-4" style={{ margin: "0", padding: "0" }}>
                  <label>　</label>
                  <div style={{ marginTop: "5px" }}>月</div>
                </div>
              </div>
              <div style={{ width: "35%", flexWrap: "nowrap", marginLeft: "10px" }} className="row">
                <div className="form-group put_arrow col-8">
                  <label htmlFor="birthDay">　</label>
                  <select
                    className="form-control"
                    id="birthDay"
                    onChange={birthChange}
                    data-select-child="day"
                    value={
                      birthDay
                    }
                  >
                    {
                      //1-31日を表示
                      [...Array(31)].map((_, i) => {
                        return (
                          <option value={i + 1}>
                            {i + 1}
                          </option>
                        );
                      })
                    }
                  </select>
                </div>
                <div className="col-4" style={{ margin: "0", padding: "0" }}>
                  <label>　</label>
                  <div style={{ marginTop: "5px" }}>日</div>
                </div>
              </div>
            </div>
          </>
          {/* 送信ボタン */}
          <button
            type="button"
            className="btn w-50 shadow update-btn"
            onClick={handleClick}
          >
            更新
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default NormalEdit;
