import React, { useState, useEffect } from 'react';
import * as Icon from 'react-bootstrap-icons';

// liに付与するクラス
// new    = 新規
// update = 更新
// revise = 修正
//

function UpdateHistory() {

  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  useEffect(() => {

  }, []);

  return (
    <div className="update-history">
      <ul className=''>
        <li className="icon new"><a href="https://docs.google.com/document/d/15ihRkhnD_hbmwKGxPp6n4d_vvFR41orlE6JT7acvSgI/edit#heading=h.igsn6tukduol" target="_blank">「本社内からnascaに入れなくなったら」</a>を追加しました(2024/9/30)</li>
        <li className="icon new"><a href="https://docs.google.com/document/d/1-7aptVK-RZHTicidonQY0UoT6w1Ds_CwnEI30YA48yU/edit#heading=h.igsn6tukduol" target="_blank">「ネットへの接続ができなくなったときの対策-既知のネットワークの削除」</a>を追加しました(2024/9/12)</li>
        <li className="icon"><a href="https://drive.google.com/file/d/1O65x_4UnhC1YYrHceBVjnXpHnpWcXIRB/view" target="_blank">「複合機から印刷ができない場合の原因解明と解決法」</a>を追加しました(2024/8/30)</li>
        <li className="icon"><a href="https://docs.google.com/document/d/1gkERDZv9wwcmWuB3oXH2F8JQoddA2pa1drLaxHuBA3Q/edit?usp=sharing" target="_blank">「圧縮されたzipファイルが開けない場合の対応策」</a>を追加しました(2024/8/20)</li>
        <li className="icon">知りたい情報を知りたい時にすぐ見つけられるようITヘルプデスク全体をリニューアルしました（2024/7/31）</li>
        <li className="icon"><a href="https://drive.google.com/file/d/1HaxziyVvIcAA6uh_Z28_eUdiTXOtnUyQ/view?usp=sharing" target="_blank">「モニターが映らない場合の原因解明と解決法」</a>を追加しました(2024/7/19)</li>
        <li className="icon">ハンドブックの【第7ページ】へ<a href="https://drive.google.com/file/d/1TYu4Qqd9KZ4PePhgMvf4UuzEjs8jac5c/view?usp=sharing" target="_blank">「充電コードなどの雑な扱いは自腹修理につながります」</a>を追加しました(2024/7/1)</li>
        <li className="icon"><a href="https://docs.google.com/document/d/1iS_SeVVtqFwYyHnI_wdfAD4-aBVFBRZw4-hTp6u9iHk/edit" target="_blank">「人事手続きについて(入社・退職・異動・休職/復職・改姓)」</a>を公開しました(2024/3/7)</li>
        <li className="icon">GWS手順書に「GoogleChat」を追加し、 <br /><a href="https://drive.google.com/file/d/1hnwpMDeVr3EH1t-RWr86ZeO2Ajaxl4cf/view" target="_blank">「【社内編】GoogleChat使い方ガイド①」</a> <br /><a href="https://drive.google.com/file/d/1TKxeKKKTvfxQLBIE9yuE2REY9KIAyOeD/view" target="_blank">「【社外編】GoogleChat使い方ガイド②」</a> <br /><a href="https://drive.google.com/file/d/188uJxzOqdV-7bty7l-e9qYPY5SKbNb9y/view" target="_blank">「チャットツールの切替について」</a>を公開しました(2024/3/1)</li>
        <li className="icon">「社内FreeWiFiアクセス情報」を更新しました(2023/12/1)</li>
        <li className="icon"><a href={baseUrl + "/johsys?jump=itPerson"}>「IT担当者一覧」を更新しました(2023/12/1)</a></li>
        <li className="icon"><a href="https://docs.google.com/spreadsheets/d/1_qu3E-QQdAE_FxSCtUdybh1jBHAipJqfQxC1-z0bLGA/edit#gid=328967683" target="_blank">「業務で使用するアプリケーションについて」を公開しました(2023/7/3）</a></li>
        <li className="icon"><a href="https://docs.google.com/document/d/1SotQu1GSqDy-NVgd8YJ_VHLg5FNMmjEon9DYHGjKWVo/edit#heading=h.bperyvxb7zj" target="_blank">「テレワークトラブル対応フロー」を公開しました(2023/7/3）</a></li>
        <li className="icon"><a href="https://docs.google.com/document/d/1tedvuhk83cgMoxvUcG1pvOybbPi41EjflbXSKE2vNJk/edit" target="_blank">「ノートPC貸与時にやること」を公開しました（2023/7/3）</a></li>
      </ul>
    </div>
  );
}

export default UpdateHistory;