// @ts-nocheck
import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet-async';

//ヘッダー
import Header from '../../component/page_parts/header';
// フッター
import Footer from '../../component/page_parts/footer';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

// 更新履歴
import UpdateHistory from './update_history';

//タブコンテンツ
import Contents1 from './tab-content/content1';
import Contents2 from './tab-content/content2';
import Contents3 from './tab-content/content3';
import Contents4 from './tab-content/content4';
import Contents5 from './tab-content/content5';
import Contents6 from './tab-content/content6';
import Contents7 from './tab-content/content7';

// パンくずリスト
import TableOfContents from "../../component/page_parts/common/table_of_contents";

function Johsys() {

  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  // GAID
  const gaId = process.env.REACT_APP_GA_ID;

  var element = document.getElementById("left-tabs-example-tab-first1");
  var userName = localStorage.getItem("user_name");
  ReactGA.initialize(gaId);
  ReactGA.send({
    hitType: "event",
    eventCategory: userName,
    eventAction: 'ITヘルプデスク-タブ切り替え',
    eventLabel: element?.textContent,
  });

  useEffect(() => {
    //URLパラメーターjumpがあるかどうか
    const url = new URL(window.location.href);
    const jump = url.searchParams.get('jump');
    if (jump) {
      // console.log('jump to ' + jump);
      const element = document.getElementById(jump);
      // console.log(element);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  // GA4タブ押下時計測
  function sendTabNameToGoogleAnalytics(e) {
    var element = document.getElementById(e.target.id);
    var userName = localStorage.getItem("user_name")
    ReactGA.initialize(gaId);
    ReactGA.send({
      hitType: "event",
      eventCategory: userName,
      eventAction: 'ITヘルプデスク-タブ切り替え',
      eventLabel: element?.textContent,
    });
  }

  return (
    <>
      <Header />
      <Helmet>
        <title>Z-link ITヘルプデスク</title>
      </Helmet>
      <div className="theme-johsys">
        <div className="content-header">
          <div className="container">
            <Row>
              <Col sm={7}>
                <h1 className='page-title type-desktop'>ITヘルプデスク</h1>
                <p className="description">PC・モバイル機器、ネットワーク、その他IT関連に関する情報を公開しています。</p>
              </Col>
              <Col sm={5}>
                <dl className="wifi-access-information">
                  <dt>新宿本社「来客用」FreeWiFiアクセス情報
                    <div className='wifi-data-area'>
                      <dl className="wifi-data">
                        <dt>SSID</dt>
                        <dd>ZENKEN-WiFi</dd>
                      </dl>
                      <dl className="wifi-data">
                        <dt>パスワード</dt>
                        <dd>SSCPT@6783</dd>
                      </dl>
                    </div>
                  </dt>
                  <dt className='wifi-access-note'>
                    <dl className='wifi-recommendation'>新宿本社の従業者は<a href="https://drive.google.com/file/d/1FdNA4GaffGTEKThQmHcoAgMY0SIPpe4K/view" target='_blank' className="text-link type-external">こちら</a>を使用してください。
                      <dt className='wifi-caution'>※従業員の方はFreeWiFiの使用禁止</dt>
                    </dl>
                  </dt>
                </dl>
              </Col>
            </Row>
          </div>
        </div>
        <TableOfContents />
        <div className="above-contents container">
          <Row>
            <Col sm={8}>
              <p className="icon-title type-calendar mt-0 mb-2">更新情報一覧</p>
              <UpdateHistory />
            </Col>
            <Col sm={4}>
              <p className="icon-title type-calendar mt-0 mb-2">まず最初にお読みください</p>
              <a href="https://drive.google.com/file/d/1TYu4Qqd9KZ4PePhgMvf4UuzEjs8jac5c/" target="_blank" className="btn main-btn type-external h-auto p-0">
                ハンドブック<small>PC/付属機器の取り扱い注意事項</small>
              </a>
            </Col>
          </Row>
        </div>
        <div className="container">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first1">
            <Nav variant="pills" className="">
              <Nav.Item>
                <Nav.Link className="text-center" eventKey="first1" onClick={sendTabNameToGoogleAnalytics}>情シスへの<br />お問い合わせ</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="text-center" eventKey="first2" onClick={sendTabNameToGoogleAnalytics}>機器の使用時<br />注意事項</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="text-center" eventKey="first3" onClick={sendTabNameToGoogleAnalytics}>トラブルが<br />発生したら</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="text-center" eventKey="first4" onClick={sendTabNameToGoogleAnalytics}>利用可能アプリ</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="text-center" eventKey="first5" onClick={sendTabNameToGoogleAnalytics}>GoogleWork<br />Space 使い方</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="text-center" eventKey="first6" onClick={sendTabNameToGoogleAnalytics}>IT担当者向け</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="text-center" eventKey="first7" onClick={sendTabNameToGoogleAnalytics}>その他<br />お役立ち情報</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first1">
                <Contents1 />
              </Tab.Pane>
              <Tab.Pane eventKey="first2">
                <Contents2 />
              </Tab.Pane>
              <Tab.Pane eventKey="first3">
                <Contents3 />
              </Tab.Pane>
              <Tab.Pane eventKey="first4">
                <Contents4 />
              </Tab.Pane>
              <Tab.Pane eventKey="first5">
                <Contents5 />
              </Tab.Pane>
              <Tab.Pane eventKey="first6">
                <Contents6 />
              </Tab.Pane>
              <Tab.Pane eventKey="first7">
                <Contents7 />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Johsys;