// @ts-nocheck
import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet-async';

//ヘッダー
import Header from "../../component/page_parts/header";

// フッター
import Footer from "../../component/page_parts/footer";

// レイアウト
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// モーダル
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// パンくずリスト
import TableOfContents from "../../component/page_parts/common/table_of_contents";

function Evaluation() {
  // 説明切り替えのstate
  const [explanationChangeValue, setExplanationChangeValue] =
    useState<string>("1");

  // ボタンクリックActive処理
  const btn = document.getElementsByClassName("btnToggle");
  for (var i = btn.length - 1; i >= 0; i--) {
    btnAction(btn[i], i);
  }
  function btnAction(btnDOM, btnId) {
    btnDOM.addEventListener("click", function () {
      this.classList.toggle("is-active");
      this.classList.add("is-active");

      // activeOnを全て外す
      for (var i = btn.length - 1; i >= 0; i--) {
        if (btnId !== i) {
          if (btn[i].classList.contains("is-active")) {
            btn[i].classList.remove("is-active");
          }
        }
      }
    });
  }

  // 概要動画モーダル用のstate
  const [showIntroduction, setShowIntroduction] = useState(false);
  const handleCloseIntroduction = () => setShowIntroduction(false);
  const handleShowIntroduction = () => setShowIntroduction(true);
  //モーダル用のstate
  const [show, setShow] = useState(false);
  const [modalMovieUrl, setModalMovieUrl] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // 視聴済みボタンのstate
  const [watchLogState, setWatchLogState] = useState([]);

  // GAID
  const gaId = process.env.REACT_APP_GA_ID;

  // モーダルの表示
  const showModal = (modalMovieUrl, currentId) => {
    // モーダルの中の動画のURLを変更する
    setModalMovieUrl(modalMovieUrl);
    setShow(true);

    if (localStorage.getItem("watchLog")) {
      // ローカルストレージにwatchLogというキーがある場合
      const btnId = localStorage.getItem("watchLog");
      const btnIdArray = JSON.parse(btnId);
      // ローカルストレージに同じ値がなければ追加する
      if (btnIdArray.indexOf(currentId) === -1) {
        //ボタンのIDを追加
        btnIdArray.push(currentId);
        // ローカルストレージに保存
        localStorage.setItem("watchLog", JSON.stringify(btnIdArray));
      }
      // useState化する
      setWatchLogState(btnIdArray);
    } else {
      // ローカルストレージにwatchLogというキーがない場合
      localStorage.setItem("watchLog", "[]");
      // 配列の作成
      const btnIdArray = [];
      // 作成した配列にボタンのIDを追加
      btnIdArray.push(currentId);
      // ローカルストレージに保存
      localStorage.setItem("watchLog", JSON.stringify(btnIdArray));
      // useState化する
      setWatchLogState(btnIdArray);
    }
  };

  useEffect(() => {
    // ローカルストレージ有無を確認する
    if (localStorage.getItem("watchLog")) {
      setWatchLogState(localStorage.getItem("watchLog"));
    }
  }, []);

  // 動画ボタン
  const videoButtonList = [
    {
      title: "① 等級制度について<br />06:14~",
      movieUrl: `${process.env.PUBLIC_URL}/jinji_mov/jinji_e01.mp4#t=00:06:14,00:07:17`,
      threeLines: false,
    },
    {
      title: "② 役割等級定義<br />07:17~",
      movieUrl: `${process.env.PUBLIC_URL}/jinji_mov/jinji_e01.mp4#t=00:07:17,00:10:49`,
      threeLines: false,
    },
    {
      title: "③ 昇降格ルール<br />10:49~",
      movieUrl: `${process.env.PUBLIC_URL}/jinji_mov/jinji_e01.mp4#t=00:10:49,00:13:22`,
      threeLines: false,
    },
    {
      title: "④ 報酬制度について<br />13:22~",
      movieUrl: `${process.env.PUBLIC_URL}/jinji_mov/jinji_e01.mp4#t=00:13:22,00:14:36`,
      threeLines: false,
    },
    {
      title: "⑤ 新給与体系<br />14:36~",
      movieUrl: `${process.env.PUBLIC_URL}/jinji_mov/jinji_e01.mp4#t=00:14:36,00:16:38`,
      threeLines: false,
    },
    {
      title: "⑥ 報酬レンジ<br />16:38~",
      movieUrl: `${process.env.PUBLIC_URL}/jinji_mov/jinji_e01.mp4#t=00:16:38,00:19:15`,
      threeLines: false,
    },
    {
      title: "⑦ 新賞与制度<br />19:15~",
      movieUrl: `${process.env.PUBLIC_URL}/jinji_mov/jinji_e01.mp4#t=00:19:15,00:23:47`,
      threeLines: false,
    },
    {
      title: "⑧ 考課制度について<br />23:47~",
      movieUrl: `${process.env.PUBLIC_URL}/jinji_mov/jinji_e01.mp4#t=00:23:47,00:30:12`,
      threeLines: false,
    },
    {
      title: "⑨ 人事考課シート<br />の記入方法<br />30:12~",
      movieUrl: `${process.env.PUBLIC_URL}/jinji_mov/jinji_e01.mp4#t=00:30:12,00:37:41`,
      threeLines: true,
    },
    {
      title: "⑩ 人事考課時期・<br />考課期間の変更<br />37:41~",
      movieUrl: `${process.env.PUBLIC_URL}/jinji_mov/jinji_e01.mp4#t=00:37:41`,
      threeLines: true,
    },
  ];

  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  var element = document.getElementById("first");
  var userName = localStorage.getItem("user_name");
  ReactGA.initialize(gaId);
  ReactGA.send({
    hitType: "event",
    eventCategory: userName,
    eventAction: '人事評価制度-タブ切り替え',
    eventLabel: element?.textContent,
  });

  // タブ切り替え処理
  function changeTab(e) {
    setExplanationChangeValue(e.target.id);
    // GA4タブ押下時計測
    var element = document.getElementById(e.target.id);
    var userName = localStorage.getItem("user_name");
    console.log(element?.textContent);
    ReactGA.initialize(gaId);
    ReactGA.send({
      hitType: "event",
      eventCategory: userName,
      eventAction: '人事評価制度-タブ切り替え',
      eventLabel: element?.textContent,
    });
  }

  return (
    <>
      <Helmet>
        <title>Z-link 人事評価制度</title>
      </Helmet>
      <Header />

      <div className="main-wrapper jinji-related theme-jinji-evaluation">
        <div className="content-header">
          <div className="container">
            <Row>
              <Col sm={9}>
                <h1 className='page-title type-human'>人事評価制度</h1>{/* ページタイトル */}
                <p className="page-description">
                  Zenkenがビジョンに掲げる「そこにない未来を創る」ことができる人材を活用すべく、
                  <br />
                  役割等級制度、報酬制度、考課制度の3点を中心として、人事制度をリニューアルいたしました。
                </p>{/* ページの説明 */}
              </Col>
              <Col sm={3} className="d-flex align-bottom align-self-end mb-3">
                <Button
                  className="btn main-btn type-movie"
                  variant="primary"
                  onClick={handleShowIntroduction}
                >
                  新人事制度の概要動画<br /><small>40:08</small>
                </Button>
                <Modal
                  show={showIntroduction}
                  onHide={handleCloseIntroduction}
                  className="evaluation-modal"
                  size="lg"
                  centered
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <video
                      className="evaluation-modal__video"
                      preload="none"
                      autoPlay
                      controls
                      loop
                      muted
                      playsInline
                      poster="#"
                    >
                      <source
                        src={`${process.env.PUBLIC_URL}/jinji_mov/jinji_e01.mp4#t=0,00:40:08`}
                        type="video/mp4"
                      />
                    </video>
                  </Modal.Body>
                </Modal>
              </Col>
            </Row>
          </div>
        </div>
        <TableOfContents />
        <Container className='mt-5'>
          <div>
            <div className="d-flex flex-wrap justify-content-between select-tags mt-4">
              <div
                className="tag flex-fill btnToggle px-2 is-active"
                id="1"
                onClick={changeTab}
              >
                役割等級制度とは？
              </div>
              <div
                className="tag flex-fill btnToggle px-2"
                id="2"
                onClick={changeTab}
              >
                報酬制度とは？
              </div>
              <div
                className="tag flex-fill btnToggle px-2"
                id="3"
                onClick={changeTab}
              >
                考課制度とは？
              </div>
            </div>
          </div>
          {/* 初期表示する内容を記載する */}
          <div className="content" style={{ marginBottom: "10%" }}>
            {explanationChangeValue === "1" ? (
              <div className="contentOne">
                <div className="">
                  <h2 className="tag-title">役割等級制度とは？</h2>
                  <h3 className="icon-title type-pencil">貢献度に応じて処遇する 「役割等級制度」</h3>
                  <ul>
                    <li>
                      <p>
                        仕事の責任の重さ（役割）に基づいた役割等級制度を導入し、役職と等級の関わりの明確化を図る
                      </p>
                    </li>
                    <li>
                      <p>
                        「役割等級定義」と各等級の職務責任としての「役割行動」を1つにまとめた役割等級定義書を作成し、能力開発・キャリア開発の指針として活用する
                      </p>
                    </li>
                    <li>
                      <p>
                        人事考課に基づいた昇降格ルールを定め、透明性を担保すると共に役割遂行能力を満たした人材を昇格させることで、実力主義の浸透を図る
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <></>
            )}
            {explanationChangeValue === "2" ? (
              <div className="contentOne">
                <div className="">
                  <h2 className="tag-title">報酬制度とは？</h2>
                  <h3 className="icon-title type-pencil">仕事の責任と成果にむくいる「報酬制度」</h3>
                  <ul>
                    <li>
                      <p>
                        本給・加給を役割給に一本化すると共に、同一役職でも金額差がある役付手当を維持することで、仕事の責任の度合いに応じてメリハリのついた処遇とする
                      </p>
                    </li>
                    <li>
                      <p>
                        世帯構成や住居形態が多様化する中で、公平感の薄れている住宅手当・家族手当を役割給に統合する
                      </p>
                    </li>
                    <li>
                      <p>
                        賞与の個人配分は、基礎部分・考課連動部分・業績連動部分（冬季賞与のみ）として、安定的な賞与支給を制度化すると共に、個人や組織の考課に応じて賞与配分額に変動があることで、パフォーマンスの高い人材への貢献
                        度を評価する
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <></>
            )}
            {explanationChangeValue === "3" ? (
              <div className="contentThree">
                <h2 className="tag-title">考課制度とは？</h2>
                <h3 className="icon-title type-pencil">会社が求める人材を育成する「考課制度」</h3>
                <ul>
                  <li>
                    <p>
                      成果や役割行動の振り返りがおこないやすい人事考課シートを活用し、人材育成をサポートする
                    </p>
                  </li>
                  <li>
                    <p>
                      人事考課を昇降格や昇給、賞与と連動することで会社が求める人材を育成する
                    </p>
                  </li>
                </ul>
              </div>
            ) : (
              <></>
            )}
            <div className="d-flex flex-wrap mx-auto movie-btn-wrap">
              {videoButtonList.map((movie, index) => (
                <Button
                  id={"movie-btn-" + (index + 1)}
                  className={
                    "btn main-btn type-movie" +
                    // watchLogStateに”movie-btn- + (index + 1)”から始まるものがあるかないか、あればbg-success
                    (watchLogState.indexOf("movie-btn-" + (index + 1)) !== -1
                      ? " bg-success"
                      : "") +
                    // ボタン内テキストが3行になる場合はスタイル調整用classを追加
                    (movie.threeLines === true ? " custom-adjustment" : "")
                  }
                  key={index}
                  variant="primary"
                  onClick={(e) => showModal(movie.movieUrl, e.currentTarget.id)}
                  dangerouslySetInnerHTML={{
                    __html: movie.title,
                  }}
                >
                </Button>
              ))}
            </div>
            <Modal
              show={show}
              onHide={handleClose}
              className="modal-lg evaluation-modal"
              size="lg"
              centered
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <video
                  className="evaluation-modal__video"
                  preload="none"
                  autoPlay
                  controls
                  loop
                  muted
                  playsInline
                  poster="#"
                >
                  <source src={modalMovieUrl} type="video/mp4" />
                </video>
              </Modal.Body>
            </Modal>
          </div>
        </Container>
      </div>

      <Footer />
    </>
  );
}
export default Evaluation;
