// @ts-nocheck
import { useState, useEffect } from 'react';

// パンくずリスト
function TableOfContents() {

  // コンテンツ別URL
  const [contentUrl, setContentUrl] = useState('');

  // パンくずリスト名1
  const [tableOfContentsTxt1, setTableOfContentsTxt1] = useState('');

  // パンくずリスト名2
  const [tableOfContentsTxt2, setTableOfContentsTxt2] = useState('');

  // コンテンツトップページかどうかのフラグ
  const [contentTopFlg, setContentTopFlg] = useState(false);

  // TOP用URL
  const [homeUrlStr, setHomeUrlStr] = useState("/");

  useEffect(() => {

    var urlForCombination = "";

    // devかどうか判断して、TOP用のURL変数を決定する
    if (process.env.REACT_APP_PUBLIC_URL == "/dev") {
      setHomeUrlStr(process.env.REACT_APP_PUBLIC_URL);
      urlForCombination = process.env.REACT_APP_PUBLIC_URL;
    }

    let depthCount = 0;
    const chk = '/';
    let checkUrl = window.location.pathname.replace('/dev');

    // 現在のURLの階層を/の数で確認する
    for (let i = 0; i < checkUrl.length; i++) {
      if (checkUrl.charAt(i) == chk) {
        depthCount += 1;
      }
    }

    // ページの階層を確認し、コンテンツトップページではない場合、フラグをfalseにする
    if (depthCount == "2") {
      setContentTopFlg(true);
    }

    // URLのパス情報に応じて、リンク名を変更
    if (checkUrl.includes('deteil')) {
      // インフォメーション-詳細
      setTableOfContentsTxt1('インフォメーション');
      setTableOfContentsTxt2('詳細');
      setContentUrl(urlForCombination + '/info_lists');
    } else if (checkUrl.includes('start_guide')) {
      // 経費関連-利用開始案内
      setTableOfContentsTxt1('経費関連（楽楽精算）');
      setTableOfContentsTxt2('利用開始案内');
      setContentUrl(urlForCombination + '/keiri');
    } else if (checkUrl.includes('evaluation')) {
      // 人事評価制度
      setTableOfContentsTxt1('人事手続き');
      setTableOfContentsTxt2('人事評価制度');
      setContentUrl(urlForCombination + '/lifestyle');
    } else if (checkUrl.includes('info_lists')) {
      // インフォメーション一覧
      setTableOfContentsTxt1('インフォメーション');
    } else if (checkUrl.includes('jinjer')) {
      //勤怠・給与（ジンジャー）
      setTableOfContentsTxt1('勤怠・給与（ジンジャー）');
    } else if (checkUrl.includes('keiri')) {
      // 経費関連（楽楽精算）
      setTableOfContentsTxt1('経費関連（楽楽精算）');
    } else if (checkUrl.includes('welfare')) {
      // 福利厚生
      setTableOfContentsTxt1('福利厚生');
    } else if (checkUrl.includes('employee_list')) {
      // 従業員名簿
      setTableOfContentsTxt1('従業員名簿');
    } else if (checkUrl.includes('lifestyle')) {
      // 人事手続き
      setTableOfContentsTxt1('人事手続き');
    } else if (checkUrl.includes('division')) {
      // 事業部一覧
      setTableOfContentsTxt1('事業部一覧');
    } else if (checkUrl.includes('media')) {
      // メディア一覧
      setTableOfContentsTxt1('メディア一覧');
    } else if (checkUrl.includes('johsys')) {
      // ITヘルプデスク
      setTableOfContentsTxt1('ITヘルプデスク');
    } else if (checkUrl.includes('facility')) {
      // 社内のこと
      setTableOfContentsTxt1('社内のこと');
    } else if (checkUrl.includes('board-voice')) {
      // 役員ブログ
      setTableOfContentsTxt1('役員ブログ');
    } else if (checkUrl.includes('committee')) {
      // 委員会
      setTableOfContentsTxt1('委員会');
    } else if (checkUrl.includes('deptBlog')) {
      // 事業部ブログ
      setTableOfContentsTxt1('事業部ブログ');
    } else if (checkUrl.includes('workstyle')) {
      // ワークスタイル
      setTableOfContentsTxt1('ワークスタイル');
    } else if (checkUrl.includes('template')) {
      // テンプレート
      setTableOfContentsTxt1('テンプレート');
    }

  }, []);

  return (
    <nav aria-label="breadcrumb" className="container">
      {contentTopFlg! ? (
        <ol className="breadcrumb my-m">
          <li className="breadcrumb-item"><a href={homeUrlStr}>TOP</a></li>
          <li className="breadcrumb-item active" aria-current="page"><a href={contentUrl}>{tableOfContentsTxt1}</a></li>
          <li className="breadcrumb-item active" aria-current="page">{tableOfContentsTxt2}</li>
        </ol>
      ) : (
        <ol className="breadcrumb my-m">
          <li className="breadcrumb-item"><a href={homeUrlStr}>TOP</a></li>
          <li className="breadcrumb-item active" aria-current="page">{tableOfContentsTxt1}</li>
        </ol>
      )}
    </nav>
  );
}

export default TableOfContents;
