// @ts-nocheck
import { useState, useLayoutEffect, useEffect } from 'react';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function Sidebar() {
  const [data, setData] = useState();
  const [active, setActive] = useState(false);

  //テスト環境
  const baseUrl = process.env.REACT_APP_PUBLIC_URL || '';

  useEffect(() => {
    fetch(process.env.REACT_APP_SITE_URL)
      .then((json) => setData(json.url))
      .catch(() => console.log('error'));

    // sidebarState がない場合（初回）はローカルストレージにセット ※デフォルトはサイドバー表示
    if (localStorage.getItem('sidebarState') === null) {
      localStorage.setItem('sidebarState', 'opened')
    }

    // 開いたページの項目に背景色を付けるための処理
    settingIsActive();
  }, []);

  function Openmenu() {
    let el = document.getElementById('sidebar__bar');
    let arrow = document.querySelector('.sidebar__bar--arrow');
    let acc = document.getElementById('accordion');
    el.classList.toggle('sidebarIs-open');
    arrow.classList.toggle('sidebarIs-open');
    if (el.classList.contains('sidebarIs-open')) {
      acc.style.transform = 'translateX(0)';
    } else {
      acc.style.transform = 'translateX(-146px)';
    }

    // ページ遷移後も表示・非表示を保持するための処理
    if (localStorage.getItem('sidebarState') === 'opened') {
      localStorage.setItem('sidebarState', 'closed')
    } else {
      localStorage.setItem('sidebarState', 'opened')
    }
  }

  function Search() {
    const baseUrl = 'https://secure.okbiz.jp/zenken/category/search?keyword=';
    let target = document.getElementById('header-search-form').value;
    window.open(baseUrl + target);
  }

  function SearchClick(e) {
    if (e.keyCode === 13) {
      const baseUrl = 'https://secure.okbiz.jp/zenken/category/search?keyword=';
      let target = document.getElementById('header-search-form').value;
      window.open(baseUrl + target);
    } else {
      return;
    }
  }

  /**
   * 開いたページの項目に背景色を付けるための処理
   */
  function settingIsActive() {
    const elements = document.getElementsByClassName('sidebar__link')
    // console.log('elements: ', elements);
    const currentUrl = window.location.pathname;
    // console.log('currentUrl: ', currentUrl)
    for (let i = 0, len = elements.length; i < len; i++) {
      const element = elements[i];
      // console.log('element: ', element)
      const href = element.getAttribute('href');
      // console.log('href: ', href);
      if (currentUrl === href) {
        element.classList.add('is-active');
        break;
      }
    }
  }


  return (
    <>
      <div
        className='sidebar side-accordion'
        id='accordion'
        style={
          localStorage.getItem('sidebarState') === 'opened' ? {
            transform: 'translateX(0)'
          } : {
            transform: 'translateX(-146px)'
          }
        }
      >
        <div className='sidebar__content'>
          <div className='sidebar__purpose'>
            <ul className='sidebar__list-wrap'>
              <li className='sidebar__list'>
                <a
                  href={baseUrl + '/info_lists'}
                  className='sidebar__link type-info'
                >
                  インフォメーション
                </a>
              </li>
              <li className='sidebar__list'>
                <a
                  href={baseUrl + '/workstyle'}
                  className='sidebar__link type-people'
                >
                  ワークスタイル
                </a>
              </li>
              <li className='sidebar__list'>
                <a
                  href={baseUrl + '/jinjer'}
                  className='sidebar__link type-briefcase'
                >
                  勤怠・給与<br /><small>(ジンジャー)</small>
                </a>
              </li>
              <li className='sidebar__list'>
                <a
                  href={baseUrl + '/keiri'}
                  className='sidebar__link type-pig'
                >
                  経費関連<small>(楽楽精算)</small>
                </a>
              </li>
              <li className='sidebar__list'>
                <a
                  href='https://sites.google.com/zenken.co.jp/contracts-regulations/home'
                  className='sidebar__link type-file type-external'
                  target='_blank'
                >
                  契約書・規程
                </a>
              </li>
              <li className='sidebar__list'>
                <a
                  href={baseUrl + '/welfare'}
                  className='sidebar__link type-bag'
                >
                  福利厚生
                </a>
              </li>
              <li className='sidebar__list'>
                <a
                  href={baseUrl + '/employee_list'}
                  className='sidebar__link type-address-book'
                >
                  従業員名簿
                </a>
              </li>
              <li className='sidebar__list'>
                <a
                  href={baseUrl + '/lifestyle'}
                  className='sidebar__link type-human'
                >
                  人事手続き
                </a>
              </li>
              <li className='sidebar__list'>
                <a
                  href={baseUrl + '/division'}
                  className='sidebar__link type-sitemap'
                >
                  事業部一覧
                </a>
              </li>
              <li className='sidebar__list'>
                <a
                  href={baseUrl + '/media'}
                  className='sidebar__link type-media'
                >
                  メディア一覧
                </a>
              </li>
              <li className='sidebar__list'>
                <a
                  href={baseUrl + '/johsys'}
                  className='sidebar__link type-desktop'
                >
                  ITヘルプデスク
                </a>
              </li>
              <li className='sidebar__list'>
                <a
                  href={baseUrl + '/committee'}
                  className='sidebar__link type-committee'
                >
                  委員会一覧
                </a>
              </li>
              <li className='sidebar__list'>
                <a
                  href={baseUrl + '/facility'}
                  className='sidebar__link type-building'
                >
                  社内のこと
                </a>
              </li>
              <li className='sidebar__list'>
                <a href={baseUrl + '/board-voice'} className="btn main-btn type-external h-auto page-board-voice">役員ブログ</a>
              </li>
              <li className='sidebar__list'>
                <a href={baseUrl + '/deptBlog'} className="btn main-btn type-external h-auto page-departmentBlog">事業部ブログ</a>
              </li>
            </ul>
          </div>
          <div className='sidebar__tool'>
            <ul className='sidebar__list-wrap'>
              <li className='sidebar__list'>
                <a
                  href='https://kintai.jinjer.biz/sign_in'
                  className='sidebar__link type-jinjer type-external'
                  target='_blank'
                  title='ジンジャー'
                >
                </a>
              </li>
              <li className='sidebar__list'>
                <a
                  href='https://rsdesign.rakurakuseisan.jp/_5ZwGywYVPa/'
                  className='sidebar__link type-rakuraku type-external'
                  target='_blank'
                  title='楽楽精算'
                >
                </a>
              </li>

            </ul>
            <ul className='sidebar__list-wrap'>
              <li className='sidebar__list'>
                <a
                  href='https://www.meishi-hanjyo.jp/'
                  className='sidebar__link type-meishi type-external'
                  target='_blank'
                  title='名刺de商売繁盛'
                >
                </a>
              </li>
              <li className='sidebar__list'>
                <a
                  href='https://tvyj3455.talent-p.net/'
                  className='sidebar__link type-talent type-external'
                  target='_blank'
                  title='タレントパレット'
                >
                </a>
              </li>
            </ul>
          </div>
          {/* 検索フォーム */}
          <div className='header-search-box'>
            <input
              type='text'
              className='form-control'
              id='header-search-form'
              placeholder='Z-BOSSで検索'
              onKeyUp={SearchClick}
            />
            <OverlayTrigger
              overlay={<Tooltip id={`header-search`}>検索する</Tooltip>}
            >
              <Button
                className='header-search-botton'
                type='button'
                id='header-search-button'
                onClick={Search}
              >
                <img
                  src='/search_icon.svg'
                  alt='検索する'
                  className='notice-bell'
                />
              </Button>
            </OverlayTrigger>
          </div>

          {/* ./検索フォーム */}
        </div>
        <div
          className={
            `sidebar__bar${localStorage.getItem('sidebarState') === 'opened'
              ? ' sidebarIs-open'
              : ''
            }`
          } id='sidebar__bar' onClick={Openmenu}
        >
          <div
            className={
              `sidebar__bar--arrow${localStorage.getItem('sidebarState') === 'opened'
                ? ' sidebarIs-open'
                : ''
              }`
            }
          >
          </div>
        </div>
      </div >
    </>
  );
}
export default Sidebar;
