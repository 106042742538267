// @ts-nocheck
//Smooth Scroll
import { Link } from 'react-scroll';

// レイアウト
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function F_seatmap() {
  return (
    <div className="facility-lower">
      <h2 className='tag-title'>シャトルバス・社用車</h2>
      <Row className="my-4" as='dl'>
        <Col sm={3} as='dt' className='toc-btn-title'>
          シャトルバス
        </Col>
        <Col sm={9} as='dd' className='mb-0'>
          <Row className='toc-btnArea'>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter01"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                シャトルバスの<br />乗り場について
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter02"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                シャトルバスの<br />利用券について
              </Link>

            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter03"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                シャトルバス内の<br />忘れ物について
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter04"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                シャトルバスの<br />お客様利用について
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="my-4" as='dl'>
        <Col sm={3} as='dt' className='toc-btn-title'>
          社用車
        </Col>
        <Col sm={9} as='dd' className='mb-0'>
          <Row className='toc-btnArea'>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter05"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                社用車の予約方法について
              </Link>
            </Col>
            <Col sm={4} className='toc-btn px-1'>
              <Link
                to="chapter06"
                smooth={true}
                duration={500}
                offset={-100}
                className='btn main-btn type-internal w-100'
              >
                貸出駐車場の<br />手続きについて
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>

      <section className="sec-main_office">
        <h3 id="chapter01" className='icon-title type-pencil'>シャトルバスの乗り場について</h3>
        <div className="img-wrap ct">
          <div className="flame">
            <img
              src={process.env.PUBLIC_URL + '/facility_pic/main_office01.png'}
              alt="シャトルバスの乗り場について"
              className="img-wrap__img"
            />
          </div>
          <div className="flame">
            <img
              src={process.env.PUBLIC_URL + '/facility_pic/main_office02.png'}
              alt="シャトルバスの乗り場について"
              className="img-wrap__img"
            />
          </div>
        </div>

        <h3 id="chapter02" className='icon-title type-pencil'>シャトルバスの利用券について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>セントラルパークタワー勤務の従業員で、利用を希望される方にはお渡しいたします。<br />その場合は、総務課にご連絡ください。単発利用の場合は、社員証のご提示でも乗車可能です。</p>
        </div>

        <h3 id="chapter03" className='icon-title type-pencil'>シャトルバス内の忘れ物について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>総務課にご連絡していただき、ビル管理会社へ確認いたします。</p>
        </div>

        <h3 id="chapter04" className='icon-title type-pencil'>シャトルバスのお客様利用について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>ご利用いただけます。乗車の際はバス運転手に「Zenkenへ行きます」とお伝えください。</p>
        </div>

        <h3 id="chapter05" className='icon-title type-pencil'>社用車の予約方法について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>以下の「Googleカレンダー運用ルール」をご参照ください。【№３】</p>
        </div>
        <div className="img-wrap ct">
          <div className="flame">
            <img
              src={process.env.PUBLIC_URL + '/facility_pic/main_office03.png'}
              alt="社用車の予約方法について"
              className="img-wrap__img"
            />
          </div>
        </div>
        <Row className="btnArea justify-content-center">
          <Col sm={5}>
            <a
              href="https://docs.google.com/presentation/d/1OuqyBt9erFPrEqn68h0aXym7fK1YoQjLPYgbikRbik8/present?slide=id.p3"
              target="_blank"
              className='btn main-btn type-external'
            >
              Googleカレンダー 運用ルール
            </a>
          </Col>
        </Row>

        <h3 id="chapter06" className='icon-title type-pencil'>貸出駐車場の手続きについて</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>お客様用として駐車場を１台ご用意しております。<br />使用したい場合は事前に総務課へご連絡ください。</p>
        </div>
        <div className="border-box">
          <p className="todo-catch"><span>POINT</span>※必要情報</p>
          <div className="l-number-wrapper">
            <ol>
              <li>使用日時</li>
              <li>車種（機械駐車場のため車種によっては入庫出来ない場合があります）</li>
              <li>お客様名</li>
            </ol>
          </div>
        </div>
      </section>

    </div>

  );
}

export default F_seatmap;

