// @ts-nocheck
import React from 'react';
import { useState, useEffect } from 'react';
import ReactGA from "react-ga4";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet-async';

//ヘッダー
import Header from '../../component/page_parts/header';
// フッター
import Footer from '../../component/page_parts/footer';
import FacilityFixture from './f_fixture';
import FacilityLounge from './f_lounge';
import FacilityEmployee from './f_employee';
import FacilityBiztrip from './f_biztrip';
import FacilityTelex from './f_telex';
import FacilitySeatmap from './f_seatmap';
import FacilityDelivery from './f_delivery';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// パンくずリスト
import TableOfContents from "../../component/page_parts/common/table_of_contents";

function Facility() {

  // GAID
  const gaId = process.env.REACT_APP_GA_ID;

  const [explanationChangeValue, setExplanationChangeValue] =
    useState<string>('1');

  // ボタンクリックActive処理
  const selectCategory = (value) => (e) => {
    setExplanationChangeValue(value);

    //全てのbtnToggleのis-activeを外す
    const btnToggleAll = document.querySelectorAll(".btnToggle");
    btnToggleAll.forEach((btnToggle) => {
      btnToggle.classList.remove("is-active");
    });

    //クリックしたbtnToggleにis-activeを付与
    const btnToggle = document.getElementById(value);
    btnToggle.classList.add("is-active");

    var element = document.getElementById(e.target.id);
    var userName = localStorage.getItem("user_name");
    console.log(element?.textContent);
    ReactGA.initialize(gaId);
    ReactGA.send({
      hitType: "event",
      eventCategory: userName,
      eventAction: '社内のこと-タブ切り替え',
      eventLabel: element?.textContent,
    });
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const videoButtonOne = [
    '【動画】等級制度について　0:30',
    '【動画】役割等級定義　1:30',
    '【動画】昇降格ルール　3:26',
    '【動画】等級制度について　2:54',
    '【動画】等級制度について　0:36',
    '【動画】等級制度について　2:45',
    '【動画】等級制度について　2:45',
    '【動画】等級制度について　2:45',
  ];

  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  var element = document.getElementById("1");
  var userName = localStorage.getItem("user_name");
  ReactGA.initialize(gaId);
  ReactGA.send({
    hitType: "event",
    eventCategory: userName,
    eventAction: '社内のこと-タブ切り替え',
    eventLabel: element?.textContent,
  });

  return (
    <>
      <Helmet>
        <title>Z-link 社内のこと</title>
      </Helmet>
      <Header />
      <div className="theme-facility theme-facility-top">
        <div className="content-header">
          <div className="container">
            <Row>
              <Col sm={12}>
                <h1 className='page-title type-building'>社内のこと</h1>{/* ページタイトル */}
                <p className="page-description">備品の発注やゴミの出し方、お弁当の販売時間など、社内のことはココで解決！</p>{/* ページの説明 */}
              </Col>
            </Row>
          </div>
        </div>
        <TableOfContents />
        <Container className="main-wrapper">
          {/* 初期表示する内容を記載する */}
          <div className='content'>
            <div className='d-flex flex-wrap mt-4 mb-4 select-tags'>
              <div className='tag btnToggle col-6 col-sm-3 px-2 is-active'
                id='1'
                onClick={selectCategory('1')}>
                備品・ゴミ・<br />落とし物
              </div>
              <div className='tag btnToggle col-6 px-0 col-sm-3'
                id='2'
                onClick={selectCategory('2')}>
                <span className='custom-space d-block'>オフィスラウンジ・</span>会議室
              </div>
              <div className='tag btnToggle col-6 px-1 col-sm-3'
                id='3'
                onClick={selectCategory('3')}>
                社員証・<br />セキュリティ
              </div>
              <div className='tag btnToggle col-6 px-1 col-sm-3'
                id='4'
                onClick={selectCategory('4')}>
                出張
              </div>
              <div className='tag btnToggle col-6 px-1 col-sm-3'
                id='5'
                onClick={selectCategory('5')}>
                電話・内線
              </div>
              <div className='tag btnToggle col-6 px-1 col-sm-3'
                id='6'
                onClick={selectCategory('6')}>
                郵便・宅急便
              </div>
              <div className='tag btnToggle col-6 px-1 col-sm-3'
                id='7'
                onClick={selectCategory('7')}>
                シャトルバス・<br />社用車
              </div>
            </div>
            {explanationChangeValue === '1' ? (
              <div className='contentOne'>
                <FacilityFixture />
              </div>
            ) : (
              <></>
            )}
            {explanationChangeValue === '2' ? (
              <div className='contentOne'>
                <FacilityLounge />
              </div>
            ) : (
              <></>
            )}
            {explanationChangeValue === '3' ? (
              <div className='contentOne'>
                <FacilityEmployee />
              </div>
            ) : (
              <></>
            )}
            {explanationChangeValue === '4' ? (
              <div className='contentOne'>
                <FacilityBiztrip />
              </div>
            ) : (
              <></>
            )}
            {explanationChangeValue === '5' ? (
              <div className='contentOne'>
                <FacilityTelex />
              </div>
            ) : (
              <></>
            )}
            {explanationChangeValue === '6' ? (
              <div className='contentOne'>
                <FacilityDelivery />
              </div>
            ) : (
              <></>
            )}
            {explanationChangeValue === '7' ? (
              <div className='contentOne'>
                <FacilitySeatmap />
              </div>
            ) : (
              <></>
            )}
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Facility;
