// react
// @ts-nocheck
import { useState, useEffect } from "react";
import jwt_decode from 'jwt-decode';
import { useCookies } from 'react-cookie';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// css
import '../../css/normalize.css';
import '../../css/App.css';

// json
// dev環境
import departmentWpJsonDev from '../../files/wp_info_department_dev.json';
import buildingWpJsonDev from '../../files/wp_building_dev.json';
// 本番環境
import departmentWpJsonProd from '../../files/wp_info_department.json';
import buildingWpJsonProd from '../../files/wp_building.json';

// トップのインフォメーション
function TopInformationList() {

  // WP RESTAPI 環境別ベースURL
  const urlBase = process.env.REACT_APP_WP_REST_API_PATH_INFO;

  // 環境に応じたJSONの内容をstate初期値にする変数に格納
  let departmentWpJson, buildingWpJson;
  if (urlBase === 'https://ztree.cfbx.jp/info-wp-dev/') { // 開発環境の場合
    departmentWpJson = departmentWpJsonDev;
    buildingWpJson = buildingWpJsonDev;
  } else { // 本番環境の場合
    departmentWpJson = departmentWpJsonProd;
    buildingWpJson = buildingWpJsonProd;
  }

  // State
  // Cookieから取得したトークンを保持
  const [cookies, setCookie, removeCookie] = useCookies(['sessionToken']);

  // トークンをデコードして取得したユーザー情報を保持
  const [loginData, setLoginData] = useState({});

  // トークンをデコードして取得したjtiを保持（パラメータに付与する用）
  const [cookieJti, setCookieJti] = useState();

  // WPの部署設定値の写し
  const [departmentWpAll, setDepartmentWpAll] = useState(departmentWpJson);

  // WPのビル内外設定値の写し
  const [buildingWp, setBuildingWp] = useState(buildingWpJson);

  // 組織idと、その組織が属する先祖組織idの配列を保持
  const [departmentIds, setDepartmentIds] = useState([]);

  // ビル内外に応じてパラメータを保持
  const [buildingParam, setBuildingParam] = useState('');

  // 取得した記事情報を保持
  const [posts, setPosts] = useState<any>([]);

  // 既読情報を保持
  const [readInfoList, setReadInfoList] = useState([]);

  //Cookie-userId
  const [cookiesUserId, setCookiesUserId, removeCookiesUserId] = useCookies([
    'userId',
  ]);

  // 誕生日フラグ
  const [birthdayFlag, setBirthdayFlag] = useState(false);

  // WPインフォ記事取得用URLパラメータ
  const paramPost = '/wp-json/wp/v2/information?information_department=' + departmentIds + buildingParam + '&_embed&_fields=id,slug,title,date,content,acf,information_taxonomy_name&per_page=4';

  // WPインフォ記事取得用URL
  const url = urlBase + paramPost + '&zenken=' + cookieJti;

  // WPインフォURL
  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  // ログイン時に生成されたトークンをデコードしたデータをstateに格納
  useEffect(() => {
    // 既読ローカルストレージを取得
    if (localStorage.getItem("readInfoIds") == undefined) {
      // 既読ローカルストレージがundefineの場合は、空の既読ローカルストレージを作成する
      localStorage.setItem('readInfoIds', "0");
    } else {
      // 既読ローカルストレージがundefineではない場合は、既読ローカルストレージから既読情報を取得し、既読情報ステートに格納する
      setReadInfoList(localStorage.getItem("readInfoIds"));
    }
    SettingLonginData();
  }, []);

  // 配信先指定に必要なパラメータ情報を取得
  useEffect(() => {
    if (Object.keys(loginData).length) {
      // loginData が空ではない時
      getParameter();
    }
  }, [loginData]);

  // 組織idが入ってから記事取得
  useEffect(() => {
    if (departmentIds.length !== 0 && cookieJti !== undefined) {
      getPosts();
    }
  }, [departmentIds]);

  // ログイン時に生成されたトークンをデコードしたデータをstateに保持
  function SettingLonginData() {
    // Cookieからトークンを取得
    const token = cookies.sessionToken;
    // デコード
    const decodedToken = jwt_decode<{ [name: string]: string }>(token);
    checkBirthday(decodedToken.data.BIRTHDAY);
    localStorage.setItem('user_id', decodedToken.data.USER_ID)
    setLoginData(decodedToken.data);
    // パラメータ（zenken）にセットしてリクエストするためのjtiの値を取得してstateにセット→WP側で値の確認を行う（確認できないリクエストには401を返すようにする）
    setCookieJti(decodedToken.jti);
  }

  // ユーザーの誕生日を判定する
  function checkBirthday(birthday: string) {
    //今日の日付を取得
    const today = new Date();
    const todayYear = today.getFullYear();
    const todayMonth = today.getMonth() + 1;
    const todayDate = today.getDate();
    //今日の日付を「月/日」の形式に変換
    const todayMonthDate = todayMonth + '/' + todayDate;
    //localStorageに保存する誕生日の形式
    //monthとdateが1桁の場合は、0を付けて2桁にする
    const localStorageBirthday = todayYear + "/" +
      (todayMonth < 10 ? "0" + todayMonth : todayMonth) + "/" +
      (todayDate < 10 ? "0" + todayDate : todayDate);
    //比較
    if (birthday === todayMonthDate) {
      localStorage.setItem('birthday', localStorageBirthday);
      setBirthdayFlag(true);
    }
  }

  // ユーザーの所属する組織id（WP側）を取得し、所属組織のビル内外に応じてパラメータをセット
  function getParameter() {
    // トークンから、所属組織のビル内外に応じてパラメータをセット
    if (loginData.BUILDING_SLUG !== 'inBuilding') { // 所属がビル内でない場合
      const excludeBuildingId = buildingWp[0].id;
      setBuildingParam('&information_building_exclude=' + excludeBuildingId);
    }
    // DB（トークン）の所属組織のスラッグから、WP側の所属情報を取得
    let departmentSlug = null;
    if (loginData.SECTION_SLUG3) {
      departmentSlug = loginData.SECTION_SLUG3;
    } else if (loginData.SECTION_SLUG2) {
      departmentSlug = loginData.SECTION_SLUG2;
    } else if (loginData.SECTION_SLUG1) {
      departmentSlug = loginData.SECTION_SLUG1;
    } else if (loginData.DEPARTMENT_SLUG) {
      departmentSlug = loginData.DEPARTMENT_SLUG;
    } else if (loginData.OFFICE_SLUG) {
      departmentSlug = loginData.OFFICE_SLUG;
    } else { // 所属スラッグがない場合、 all 所属扱いにする
      departmentSlug = 'all';
    }
    // DBのスラッグは大文字で登録されていることがあるので、WPの仕様に合わせて小文字変換
    departmentSlug = departmentSlug.toLowerCase();
    let wpDepartment = departmentWpAll.find((v) => v.slug == departmentSlug);
    // wpDepartmentがnullの場合は、all 所属扱いにする
    if (wpDepartment == null) {
      wpDepartment = departmentWpAll.find((v) => v.slug == 'all');
    }
    // WP側の所属組織とその祖先組織idの配列を取得し、stateを更新
    const departmentIdsArr = wpDepartment.ancestors_ids;
    setDepartmentIds(departmentIdsArr);
  }

  // WP REST API で記事情報を取得
  async function getPosts() {
    const postResponse = await fetch(url)
      .then(response => {
        if (!response.ok) {
        }
        return response;
      })
      .catch(error => {
      });
    const data = await postResponse.json();
    data.forEach((item, index) => {
      // 新しいキーを追加して、acf.deadline_dateの値を設定
      item.newDeadline = item.acf.deadline_date;
      setPosts(data);
    });
  }

  // 年月日、曜日のフォーマット
  function doDateFormat(date) {
    let dateFormat = new Date(date);
    dateFormat =
      dateFormat.getFullYear() + '/'
      + ('0' + (dateFormat.getMonth() + 1)).slice(-2) + '/'
      + ('0' + dateFormat.getDate()).slice(-2);
    return dateFormat;
  }

  // 残日数の返却処理
  function calculateRemainingDays(deadline: string) {
    const today = new Date();
    const deadlineDate = new Date(deadline);
    const timeDiff = deadlineDate.getTime() - today.getTime();
    const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysRemaining;
  }

  return (
    <>
      <Row className="align-items-end title-area">
        <Col sm={8}>
          <h2 className="icon-title type-information mb-0">インフォメーション</h2>
        </Col>
        <Col sm={4}>
          <p className="text-end to_list">
            <a href={baseUrl + "/info_lists"} className="">一覧</a>
          </p>
        </Col>
      </Row>
      <div className="information_wrap">
        <ul className="information_list">
          {
            birthdayFlag ?
              <li className={`information is-anread`}>
                <span className="information_detail">
                  <div className="info_category">
                    <span className="outgoing_division">システムメッセージ</span>
                  </div>
                  <div className="info_data">
                    <div className="data">{
                      localStorage.getItem('birthday') ? localStorage.getItem('birthday') : ""
                    }</div>
                  </div>
                  <div className="info_text">
                    <p className="info_title">Happy Birthday！！！</p>
                    <p className="info_description">
                      お誕生日おめでとうございます。<br />
                      この一年が {
                        localStorage.getItem('user_name') ? localStorage.getItem('user_name') : "ユーザー"
                      } 様にとって、たくさんの幸福と可能性に満ちた日々で ありますように。</p>
                  </div></span>
              </li>
              :
              <></>
          }
          {
            posts.code !== 'disabled' ?
              posts.map((post) => {
                const {
                  id,
                  date,
                  title,
                  acf,
                  content,
                  information_taxonomy_name
                } = post;
                // インフォ一覧に出すテキストの設定
                let description;
                if (acf.i_list_description) {
                  // 一覧用テキストが設定されている場合は、そのテキストを設定
                  description = acf.i_list_description;
                } else {
                  // 一覧用テキストが設定されていない場合、タグを除去して本文冒頭70文字までを切り出し
                  description = content.rendered.replace(/(<([^>]+)>)/gi, '').slice(0, 70);
                }
                return (
                  // ... existing code ...
                  <li className={`information${readInfoList.includes(id) || new Date(date) < new Date(new Date(localStorage.getItem('bulk_read_last_execution_date')).getTime()) ? '' : ' is-anread'}`} key={id}>
                    <a href={baseUrl + `/info_lists/deteil?id=${id}`} className="information_detail">
                      <div className="info_category">
                        <span className="outgoing_division">{information_taxonomy_name[0]}</span>
                      </div>
                      <div className="info_data">
                        <div className="data">{doDateFormat(date)}</div>
                      </div>
                      <div>
                        <div className="info_text">
                          {new Date(post.date) >= new Date(Date.now() - 3 * 24 * 60 * 60 * 1000) && (
                            <div dangerouslySetInnerHTML={{
                              __html: '<span class="new-icon">NEW</span>'
                            }} />
                          )}
                          {acf.important_decision && (
                            <div className="info_deadline_date">
                              {acf.important_decision === true && (
                                <span className="deadline-mark">期日あり</span>
                              )}
                              {/* 使うかもだから今は残す */}
                              {/* {acf.important_decision === true && acf.deadline_date && (
                                <p>
                                  残日数：{calculateRemainingDays(acf.deadline_date) >= 0
                                    ? `${calculateRemainingDays(acf.deadline_date)}日`
                                    : "対応期限切れ"}
                                </p>
                              )} */}
                            </div>
                          )}
                          {acf.important_decision && (
                            <div className="info_deadline_date">
                              {acf.deadline_date && new Date(acf.deadline_date) >= new Date(new Date().setHours(0, 0, 0, 0)) && (
                                <p>
                                  {acf.deadline_date}まで</p>
                              )}
                              {/* 使うかもだから今は残す */}
                              {/* {acf.important_decision === true && acf.deadline_date && (
                                <p>
                                  残日数：{calculateRemainingDays(acf.deadline_date) >= 0
                                    ? `${calculateRemainingDays(acf.deadline_date)}日`
                                    : "対応期限切れ"}
                                </p>
                              )} */}
                            </div>
                          )}
                          <p className="info_title" dangerouslySetInnerHTML={{ __html: title.rendered.replace(/<span class="new-icon">NEW<\/span>/g, '') }}></p>
                        </div>
                        <p className="info_description" dangerouslySetInnerHTML={{ __html: description }}></p>
                      </div>
                    </a>
                  </li>
                );
              }) : (
                <li className="information">
                  <p>記事はありません。</p>
                </li>
              )
          }
        </ul>
      </div>
    </>
  );
}
export default TopInformationList;