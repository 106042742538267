import React, { useState, useEffect } from "react";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link } from 'react-scroll';

// 委員会一覧ページ 時差出勤（スライドワーク）タブ
function Contents2() {

  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  useEffect(() => { }, []);

  return (
    <div id="committee-content1">
      <h2 className="tag-title">時差出勤（スライドワーク）</h2>
      <Row className="toc-btnArea">
        <Col sm={4} className='toc-btn'>
          <Link
            to="chapter04"
            smooth={true}
            duration={500}
            offset={-100}
            className='btn main-btn type-internal w-100'
          >
            時差出勤制度
          </Link>
        </Col>
        <Col sm={4} className='toc-btn'>
          <Link
            to="chapter05"
            smooth={true}
            duration={500}
            offset={-100}
            className='btn main-btn type-internal w-100'
          >
            申請方法・注意点
          </Link>
        </Col>
        <Col sm={4} className='toc-btn'>
          <Link
            to="chapter06"
            smooth={true}
            duration={500}
            offset={-100}
            className='btn main-btn type-internal w-100'
          >
            承認者向け
          </Link>
        </Col>
      </Row>
      <h3 id="chapter04" className='icon-title type-pencil mt-small'>時差出勤制度</h3>
      <div className="committee-related">
        <div className="point-list-border no-title mb-3">
          <p>時差出勤（スライドワーク）とは、勤務時間の総時間数を変えず、始業時間を変更する勤務形態です。スライドワークを活用することで、個々の業務やプライベートの都合に合わせて、柔軟な働き方が可能になります。</p>
          <ul className="dot-list type-list">
            <li>事前の上長承認が必要です。計画的に活用しましょう！</li>
            <li>対象者は、所定時間７時間40分の「正社員」「契約社員」です。</li>
          </ul>
          <p>事業部ごとの、選択可能な始業時間は下記資料をご確認ください。</p>
          <iframe
            src="https://www.ztree.cfbx.jp/info-wp/wp-content/uploads/2024/09/【確定】勤務制度の変更案内（人事部）_240927.pdf"
            width="100%"
            height="450px"
            title="PDF Viewer"
          />
        </div>
      </div>
      <div className="committee-related">
        <h3 id="chapter05" className='icon-title type-pencil mt-small'>申請方法</h3>
        <div className="point-list-border mb-3">
          <div className="point-title">
            <span>CHECK</span>ジンジャー申請方法
          </div>
          <p>ジンジャーで事前申請し、上長の承認で勤務時間を変更できます。原則として、水曜までに翌週の申請を実施してください。</p>
        </div>
      </div>
      <h3 className='icon-title type-pencil mt-small'>申請の注意点</h3>
      <div className="committee-related">
        <div className="point-list-border mb-3">
          <div className="point-title">
            <span>CHECK</span>部署ごとに決まっているスライドワークの範囲で申請してください
          </div>
          <p>自部署のスライドワーク範囲が①～⑥の場合でも、⑦を選択して申請することができます。
            上長が否認しますが、間違った申請をしないように気をつけてください！</p>
        </div>
        <div className="point-list-border mb-3">
          <div className="point-title">
            <span>CHECK</span>スライドワークと「半休」や「時間有給」を組み合わせる場合は
            スライドワークの上長承認を確認してから、「半休」「時間有給」を申請してください
          </div>
          <ul className="dot-list type-list">
            <li>スライドワーク予定日に既に半休/時間有給を申請している場合
              →半休/時間休の申請取下げ（承認済の場合は上長に「否認」をお願い）が最優先
              ⇒手順を間違えるとお給料が減ってしまう場合があります・・・！締め前に確認する癖をつけましょう！
            </li>
          </ul>
        </div>
        <div className="point-list-border mb-3">
          <div className="point-title">
            <span>CHECK</span>入社1年以上の社員には、執務室のセキュリティ権限を持っていただきます
            執務室は最初に出社した社員が解錠し最後に退出する社員が施錠してください
          </div>
          <ul className="dot-list type-list">
            <li>管理監督者（SM以上）がいない場合は、22時までに最終退社しましょう！</li>
            <li>セキュリティ権限の付与は年2回です。（7/1・1/1時点で在籍1年以上の社員）</li>
          </ul>
        </div>
      </div>
      <h3 id="chapter06" className='icon-title type-pencil mt-small'>承認者向け</h3>
      <p>承認の注意点（ジンジャー承認者）</p>
      <div className="committee-related">
        <div className="point-list-border mb-3">
          <div className="point-title">
            <span>CHECK</span>部署ごとに決められているスライドワークの範囲で承認
          </div>
          <ul className="dot-list type-list">
            <li>システムガードはかけていません。</li>
          </ul>
          <p>自部署が認めていない始業時間でも申請ができてしまいます<br />承認前に確認をお願いします。</p>
        </div>
        <div className="point-list-border mb-3">
          <div className="point-title">
            <span>CHECK</span>「半休」「時間有給」を承認している日にスライドワーク申請があったら
          </div>
          <ul className="dot-list type-list">
            <li>「半休/時間有給」を一度否認してから、スライドワークを承認</li>
            <li>その上で必要に応じて「半休/時間休」を再申請するよう本人にコミュニケーションしてください
              <br />※同時に申請があった場合は「スライドワーク承認」→「半休/時間休承認」の順番で対応
            </li>
          </ul>
          <p>全ての承認は、月次締め前であれば変更可能。最終確認をお願いいたします！</p>
        </div>
      </div>
    </div >
  );
}

export default Contents2;
