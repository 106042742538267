// @ts-nocheck
// import React from 'react';
import { useState, useEffect } from "react";
import jwt_decode from 'jwt-decode';
import { useCookies } from 'react-cookie';
// import GetSessionToken from "../getSessionToken";

// //jsonファイルを読み込む
// import userJson from '../files/sample.json';

//モーダル用
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

//スライダー
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { json } from 'stream/consumers';

function Test() {
  //ユーザー用のstate
  // const [user, setUser] = useState(userJson);

  //モーダル用のstate
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showModalIndex, setShowModalIndex] = useState(null);

  //スライダーの設定
  const settings = {
    dots: true,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    swipe: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  // State
  const [posts, setPosts] = useState([]);
  // // 取得した記事情報を保持
  // const [posts, setPosts] = useState<any>([]);
  //トークン保存用
  const [cookies, setCookie, removeCookie] = useCookies(['sessionToken']);
  const [cookieJti, setCookieJti] = useState();

  /**
   * URL
   */
  //
  const urlBase = 'https://www.ai-chan-xd.com/rest';
  const paramPost = '/wp-json/wp/v2/posts?_fields=id,title,date,content';
  const combineUrl = urlBase + paramPost;

  useEffect(() => {
    getCookies();
    // console.log('関数呼び出し: ', GetSessionToken());
  }, []);

  useEffect(() => {
    getPost();
  }, [cookieJti]);

  /**
   * パラメータ（zenken）にjtiの値をセットしてリクエスト
   * →WP側で値の確認を行う（確認できないリクエストには401を返すようにする）
   */
  // cookieのsessionTokenから、リクエスト時に確認を行いたい値だけ絞ってstateにセット
  function getCookies() {
    if (cookies.sessionToken) { // ログイン済みの場合
      // console.log('cookies.sessionToken: ', cookies.sessionToken);
      const decoded = jwt_decode<{ [name: string]: string }>(cookies.sessionToken);
      // console.log('decoded: ', decoded);
      // console.log('decoded.jti: ', decoded.jti);
      setCookieJti(decoded.jti);
    }
  }

  // パラメータ（zenken）に値をセットしてリクエスト
  const getPost = async () => {
    console.log('getPost() 実行');

    if (cookieJti !== undefined) {
      console.log('cookieJti: ', cookieJti);

      const url = combineUrl + '&zenken=' + cookieJti;
      console.log(url);
      const testUrl = 'https://jsonplaceholder.typicode.com/users';

      const response = await fetch(url)
        .then(response => {
          if (!response.ok) {
            console.log('サーバーエラー');
          }
          // 成功時の処理
          console.log('response 成功');
          return response;
        })
        .catch(error => {
          console.log('通信に失敗しました', error);
        });
      const data = await response.json();
      console.log('data: ', data);
      setPosts(data);
    }
  }

  return (
    <div>
      <h1>Test</h1>
      <Button variant="primary" onClick={handleShow}>
        modal
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>header</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Slider {...settings}>
            <img
              src={process.env.PUBLIC_URL + '/keiri_pic/dousite.png'}
              alt="img"
            />
            <img
              src={
                process.env.PUBLIC_URL +
                '/keiri_pic/animal_character_uma_ninjin.png'
              }
              alt="img"
            />
            <img
              src={process.env.PUBLIC_URL + '/keiri_pic/logo512.png'}
              alt="img"
            />
          </Slider>
        </Modal.Body>
      </Modal>
      {/* 
      {user.map((user) => (
        <div>
          <p>{user.name}</p>
        </div>
      ))} */}
      {posts.map((post) => (
        <div>
          <p>{post.title.rendered}</p>
        </div>
      ))}
    </div >
  );
}
export default Test;
