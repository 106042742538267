// @ts-nocheck
//Smooth Scroll
import { Link } from 'react-scroll';

// レイアウト
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function F_biztrip() {

  return (
    <div className="facility-lower">
      <h2 className="tag-title">出張</h2>
      <Row className="toc-btnArea">
        <Col sm={4} className='toc-btn'>
          <Link
            to="chapter01"
            smooth={true}
            duration={500}
            offset={-100}
            className='btn main-btn type-internal w-100'
          >
            出張手配について
          </Link>
        </Col>
        <Col sm={4} className='toc-btn'>
          <Link
            to="chapter02"
            smooth={true}
            duration={500}
            offset={-100}
            className='btn main-btn type-internal w-100'
          >
            出張日当について
          </Link>
        </Col>
      </Row>
      <section className="sec-biztrip">
        <h3 id="chapter01" className='icon-title type-pencil'>出張手配について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>出張手配の種類は、個人手配（立替え）と、部署手配（システム利用※1）の2種類があります。<br />個人手配（立替え）と 部署手配（システム利用※1）のフローは異なりますので、出張旅費規程の範囲内でどちらかご選択ください。</p>
          <div className="l-number-wrapper">
            <ol>
              <li>
                個人手配（立替え）<br />必要な申請<br />1）「楽楽精算 ＞ 出張申請」<br />2）「楽楽精算 ＞ 出張精算」
              </li>
              <li>
                部署手配（システム利用※1）<br />必要な申請<br />1）「楽楽精算 ＞ 出張申請」<br />2）「楽楽精算 ＞ ワークフロー ＞ 購入稟議」の起案も必要となりますので、ご注意ください。<br />3）「楽楽精算 ＞ 出張精算」<br />4）<a href='https://www.elc.co.jp/plus/mypages' target='_brank'>https://www.elc.co.jp/plus/mypages</a>へアクセス（マニュアルは、システム内にあります。）<br />※1 事前に登録が必要ですので、必要な部署は総務までご連絡ください。
              </li>
            </ol>
          </div>
          詳しいフローは、「出張手配の種類」からご確認ください。
          <iframe src="https://docs.google.com/presentation/d/1xzEhOXCQ8biRGRm-3EiJB-HHTLS8fOEAYx8eWqSvPzw/preview" width="960" height="480" allow="autoplay"></iframe>
        </div>

        <h3 id="chapter02" className='icon-title type-pencil'>出張日当について</h3>
        <div className="border-box">
          <p className="todo-catch"><span>To Do</span>日当については下記の表をご確認ください。</p>
        </div>
        <div className="img-wrap ct">
          <div className="flame">
            <img
              src={process.env.PUBLIC_URL + '/facility_pic/biztrip02.jpg'}
              alt="出張日当について"
              className="img-wrap__img"
            />
          </div>
        </div>
      </section>

    </div>

  );
}

export default F_biztrip;

