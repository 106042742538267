// @ts-nocheck
import { useState, useLayoutEffect, useEffect } from "react";
import { useCookies } from 'react-cookie';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function CustomizeHome() {
    const [cookies, setCookie, removeCookie] = useCookies(['sessionToken']);
    //URLリスト
    const [urlList, setUrlList] = useState([]);
    //ボタンの押された回数をカウント
    const [count, setCount] = useState(0);

    useEffect(() => {
        //DBアクセス回数を減らしたい
        //ローカルストレージにURLリストがあるかどうか
        if (localStorage.getItem("urlList") == null) {
            // console.log("URLリストが空");
            const res = fetch(process.env.REACT_APP_API_PATH + "home/urlGet.php?email=" + cookies.email);
            res.then((response) => response.json())
                .then((data) => {
                    // dataがnullではない場合
                    if (data != null) {
                        //キーの数を取得
                        const keyLength = Object.keys(data).length;
                        //キーの数だけループ
                        var array = [];
                        for (let i = 1; i <= keyLength; i++) {
                            //URLリストに追加
                            array.push(data[i]);
                        }
                        // console.log(array);
                        //URLリストを更新
                        setUrlList([...array]);

                        //DBアクセス回数を減らす
                        localStorage.setItem("urlList", JSON.stringify([...array]));
                    }
                });
        } else {
            // console.log("URLリストが空ではない");
            //ローカルストレージからURLリストを取得
            const array = JSON.parse(localStorage.getItem("urlList"));
            console.log(array);
            //arrayの内容が正常かどうか
            for (let i = 0; i < array.length; i++) {
                //URLかどうかのチェック
                if (!array[i].match(/https?:\/\/[\w!?/+\-_~;.,*&@#$%()'[\]]+/)) {
                    //異常だった場合ローカルストレージを削除し空でstateを更新、再レンダリング
                    localStorage.removeItem("urlList");
                    setUrlList([]);
                }
            }

            //URLリストを更新
            setUrlList([...array]);
        }
    }, []);

    const handleAdd = (e) => {
        e.preventDefault();

        //初回押された場合
        if (count === 0) {
            //入力フォームを表示
            document.getElementById("add_iframe").style.display = "block";
            //カウントを1増やす
            setCount(count + 1);
        } else {
            //入力フォームの値を取得
            const url = document.getElementById("add_iframe").value;

            //URLかどうかのチェック
            if (!url.match(/https?:\/\/[\w!?/+\-_~;.,*&@#$%()'[\]]+/)) {
                alert("URLを入力してください");
                return;
            }
            //APIの実行
            var ac = fetch(process.env.REACT_APP_API_PATH + "home/urlUpdate.php?email=" + cookies.email + "&custom_url=" + url);
            ac.then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                });


            //URLリストに追加
            setUrlList([...urlList, url]);

            localStorage.setItem("urlList", JSON.stringify([...urlList, url]));

            //入力フォームを非表示
            document.getElementById("add_iframe").style.display = "none";

            //入力フォームの値を空にする
            document.getElementById("add_iframe").value = "";

            //カウントを0に戻す
            setCount(0);
        }
    }

    const handleDelete = (e) => {
        e.preventDefault();
        //削除ボタンが押された要素の親要素のdata-idを取得
        const index = e.target.parentNode.getAttribute("data-id");
        //APIの実行
        var ac = fetch(process.env.REACT_APP_API_PATH + "home/urlUpdate.php?email=" + cookies.email + "&custom_url=delete:" + index);
        // console.log(ac);
        ac.then((response) => response.json())
            .then((data) => {
                console.log(data);
            });

        //URLリストから削除
        urlList.splice((index - 1), 1);

        localStorage.setItem("urlList", JSON.stringify(urlList));
        //URLリストを更新
        setUrlList([...urlList]);
    }

    return (
        <>
            <Row className="align-items-end title-area">
                <Col sm={12}>
                    <h2 className="icon-title type-custom my-0">カスタム</h2>
                </Col>
            </Row>
            <div className="customize_wrap">
                <div className="customize_list_wrap">
                    {
                        urlList.map((url, index) => {
                            return (
                                urlList.length != 0 ?
                                    <div className="customize_list" data-id={index + 1} style={{ display: "flex" }}>
                                        <iframe src={url} width="100%" height="650px" />
                                        <button className="btn delete-btn" onClick={handleDelete}><span className="visually-hidden">閉じる</span></button>
                                    </div>
                                    : <></>
                            );
                        })
                    }

                </div>
                <form>
                    <input type="text" name="URL" id="add_iframe" className="text-form" style={{ display: "none", width: "100%" }} placeholder="埋め込みたいurlを入力して、追加ボタンをクリック"></input>
                </form>
                <p className="">
                    <a className="btn main-btn" onClick={handleAdd}
                        style={
                            count === 0 ? {} : { color: "#fff" }
                        }>
                        <span>
                            {
                                count === 0 ?
                                    "URLの追加"
                                    : "追加"

                            }
                        </span>
                    </a>
                </p>
            </div>
        </>
    );
}

export default CustomizeHome;