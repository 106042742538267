// @ts-nocheck
import { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet-async';

//ヘッダー
import Header from "../../component/page_parts/header";

// フッター
import Footer from "../../component/page_parts/footer";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// パンくずリスト
import TableOfContents from "../../component/page_parts/common/table_of_contents";

function Jinjer() {
  // 説明切り替えのstate
  const [explanationChangeValue, setExplanationChangeValue] =
    useState<string>("1");

  // GAID
  const gaId = process.env.REACT_APP_GA_ID;

  var element = document.getElementById("1");
  var userName = localStorage.getItem("user_name");
  ReactGA.initialize(gaId);
  ReactGA.send({
    hitType: "event",
    eventCategory: userName,
    eventAction: '勤怠・給与（ジンジャー）-タブ切り替え',
    eventLabel: element?.textContent,
  });

  const selectCategory = (value) => (e) => {

    console.log(e.target.id);
    setExplanationChangeValue(value);

    //全てのbtnToggleのis-activeを外す
    const btnToggleAll = document.querySelectorAll(".btnToggle");
    btnToggleAll.forEach((btnToggle) => {
      btnToggle.classList.remove("is-active");
    });

    //クリックしたbtnToggleにis-activeを付与
    const btnToggle = document.getElementById(value);
    btnToggle.classList.add("is-active");

    // GA4タブ押下時計測
    var element = document.getElementById(e.target.id);
    var userName = localStorage.getItem("user_name")
    ReactGA.initialize(gaId);
    ReactGA.send({
      hitType: "event",
      eventCategory: userName,
      eventAction: '勤怠・給与（ジンジャー）-タブ切り替え',
      eventLabel: element?.textContent,
    });
  };

  //質問をクリック時の処理
  const toggleView = (e) => {
    //クリックされた要素の次の要素を取得
    const p = e.target.nextElementSibling;
    const el = e.target;
    if (p.style.display === "none") {
      p.style.display = "block";
      el.classList.toggle("open");
    } else {
      p.style.display = "none";
      el.classList.remove("open");
    }
  };

  // 勤怠‐ログイン
  const questionListOne = [
    {
      id: 1,
      question: "ジンジャーにログインするには？",
      link: "https://jinjer-kintai.zendesk.com/hc/ja/articles/900002000063",
      linkTwo: "https://jinjer-kintai.zendesk.com/hc/ja/articles/4405208601113",
      linkTxt: "従業員画面へのログイン方法と画面の概要説明",
      linkTxt02: "ジンジャーにログインができません",
    },
    {
      id: 2,
      question: "パスワードを変更するには？",
      link: "https://jinjer-kintai.zendesk.com/hc/ja/articles/900002009483",
      linkTxt: "パスワードの変更方法",
    },
  ];

  const accordionOne = questionListOne.map((list) => {
    return (
      <>
        <p
          id={list.id}
          className="contents__qa--question content-one-q"
        >
          {list.question}
        </p>
        <p className="contents__qa--answer kintai">
          こちらよりご確認ください。
          <br />
          <a href={list.link} target="_blank" rel="noopener noreferrer" className='text-link type-external'>
            {list.linkTxt}
          </a>
          <p>
            <a href={list.linkTwo} target="_blank" rel="noopener noreferrer" className='text-link type-external'>
              {list.linkTxt02}
            </a>
          </p>
        </p>
      </>
    );
  });

  // 勤怠‐スマホ
  const questionListTwo = [
    {
      id: 1,
      question: "スマホで打刻するには？",
      link: "https://jinjer-kintai.zendesk.com/hc/ja/articles/360035939452",
      linkTxt: "スマホアプリの打刻方法",
    },
    {
      id: 2,
      question: "スマホで有休を申請するには？",
      link: "https://jinjer-kintai.zendesk.com/hc/ja/articles/900004216543",
      linkTxt: "スマホで休日を申請する",
    },

    {
      id: 3,
      question: "スマホで打刻を修正するには？",
      link: "https://jinjer-kintai.zendesk.com/hc/ja/articles/360036490951",
      linkTxt: "スマホで打刻修正を申請する",
    },
    {
      id: 4,
      question: "スマホで申請を確認するには？",
      link: "https://jinjer-kintai.zendesk.com/hc/ja/articles/360036546751",
      linkTxt: "スマホで申請履歴を確認する/申請を取り消す",
    },
    {
      id: 5,
      question: "スマホで申請を取り消すには？",
      link: "https://jinjer-kintai.zendesk.com/hc/ja/articles/360036546751",
      linkTxt: "スマホで申請履歴を確認する/申請を取り消す",
    },
    {
      id: 6,
      question: "スマホで休暇残日数を確認するには？",
      link: "https://jinjer-kintai.zendesk.com/hc/ja/articles/360036185672",
      linkTxt: "スマホで休暇の残日数を確認する",
    },
    {
      id: 7,
      question: "スマホアプリが起動しません。",
      link: "https://jinjer-kintai.zendesk.com/hc/ja/articles/900001205546",
      linkTxt: "アプリが正常に動作しない場合の対処方法",
    },
    {
      id: 8,
      question: "アラートを確認するには？",
      link: "https://jinjer-kintai.zendesk.com/hc/ja/articles/4405077604633-%E3%82%B9%E3%83%9E%E3%83%9B%E3%82%A2%E3%83%97%E3%83%AA%E3%81%A7%E3%81%AE%E3%82%A2%E3%83%A9%E3%83%BC%E3%83%88%E7%A2%BA%E8%AA%8D%E6%96%B9%E6%B3%95",
      linkTxt: "スマホアプリでのアラート確認方法",
    },
  ];

  const accordionTwo = questionListTwo.map((list) => {
    return (
      <>
        <p
          id={list.id}
          className="contents__qa--question content-two-q"
        >
          {list.question}
        </p>

        <p className="contents__qa--answer">
          こちらよりご確認ください。
          <br />
          <a href={list.link} target="_blank" rel="noopener noreferrer" className='text-link type-external'>
            {list.linkTxt}
          </a>
        </p>
      </>
    );
  });

  // 勤怠‐申請
  const questionListThree = [
    {
      id: 1,
      question: "出勤・退勤打刻するには？",
      link: "https://jinjer-kintai.zendesk.com/hc/ja/articles/900002011046",
      linkTxt: "出退勤・直行直帰打刻",
    },
    {
      id: 2,
      question: "残業申請をするには？",
      link: "https://jinjer-kintai.zendesk.com/hc/ja/articles/900002012283",
      linkTxt: "残業申請",
    },
    {
      id: 3,
      question: "有休を申請するには？",
      link: "https://jinjer-kintai.zendesk.com/hc/ja/articles/900002011863",
      linkTxt: "休日休暇申請",
    },
    {
      id: 4,
      question: "打刻漏れをしてしまったので修正したい",
      link: "https://jinjer-kintai.zendesk.com/hc/ja/articles/900002010763",
      linkTxt: "打刻修正申請・欠勤申請",
    },
    {
      id: 5,
      question: "申請状況を確認するには？",
      link: "https://jinjer-kintai.zendesk.com/hc/ja/articles/900002014046",
      linkTxt: "申請一覧の確認方法",
    },
    {
      id: 6,
      question: "申請を取り消すには？",
      link: "https://jinjer-kintai.zendesk.com/hc/ja/articles/360036181092",
      linkTxt: "申請履歴の確認・申請の取消",
    },
    {
      id: 7,
      question: "エラーメッセージが表示されます。",
      link: "https://jinjer-kintai.zendesk.com/hc/ja/articles/360037627351",
      linkTxt: "【勤怠】よくあるエラーの原因と対処方法",
    },
    {
      id: 8,
      question: "アラートを確認するには？",
      link: "https://jinjer-kintai.zendesk.com/hc/ja/articles/360036547731",
      linkTxt: "PCでアラート内容を確認する",
    },
  ];

  const accordionThree = questionListThree.map((list) => {
    return (
      <>
        <p
          id={list.id}
          className="contents__qa--question content-three-q"
        >
          {list.question}
        </p>

        <p className="contents__qa--answer" >
          こちらよりご確認ください。
          <br />
          <a href={list.link} target="_blank" rel="noopener noreferrer" className='text-link type-external'>
            {list.linkTxt}
          </a>
        </p>
      </>
    );
  });

  // 給与
  const questionListFour = [
    {
      id: 1,
      question: "Web明細を確認するには？",
      answer:
        "にログインしてご確認ください。「PDF」は印刷または添付用のデータとなり、画面のみで確認したい場合は「HTML」をクリックしてご確認ください。賞与明細及び源泉徴収票も、今後はこちらの画面でご確認いただけます。",
      link: "https://employee.jinjer.biz/payroll",
      linkTxt: "「こちらの給与明細画面」",
    },
  ];

  const accordionFour = questionListFour.map((list) => {
    return (
      <>
        <p
          id={list.id}
          className="contents__qa--question  content-one-q"
        >
          {list.question}
        </p>

        <p className="contents__qa--answer" >
          <a href={list.link} target="_blank" rel="noopener noreferrer" className='text-link type-external'>
            {list.linkTxt}
          </a>{list.answer}
        </p>
      </>
    );
  });

  // 人事労務-年末調整
  const questionListFive = [
    {
      id: 1,
      question: "PCから年調収集の回答を行うには？",
      link: "https://jinjer-jinji.zendesk.com/hc/ja/articles/900004403463",
      linkTxt: "PCにて年調収集の回答を行う",
    },
    {
      id: 2,
      question: "スマホから年調収集の回答を行うには？",
      link: "https://jinjer-jinji.zendesk.com/hc/ja/articles/10010305042969",
      linkTxt: "スマートフォンにて年調収集の回答を行う",
    },
    {
      id: 3,
      question: "添付ファイルをアップロードするには？",
      link: "https://jinjer-jinji.zendesk.com/hc/ja/articles/10010808718105",
      linkTxt: "年調収集の回答に必要な画像を添付する",
    },
    {
      id: 4,
      question: "回答を中断／再開するには？",
      link: "https://jinjer-jinji.zendesk.com/hc/ja/articles/10011917648025",
      linkTxt: "年調収集の回答を中断・再開する方法",
    },
    {
      id: 5,
      question: "回答が差し戻されたらどうすればいい？",
      link: "https://jinjer-jinji.zendesk.com/hc/ja/articles/4409678405657",
      linkTxt: "年調収集が差し戻された場合の対応方法",
    },
    {
      id: 6,
      question: "年調収集の対応状況を確認するには？",
      link: "https://jinjer-jinji.zendesk.com/hc/ja/articles/10011332223385",
      linkTxt: "従業員が年調収集の依頼/対応状況を確認する",
    },
  ];

  const accordionFive = questionListFive.map((list) => {
    return (
      <>
        <p
          id={list.id}
          className="contents__qa--question  content-one-q"
        >
          {list.question}
        </p>
        <p className="contents__qa--answer">
          こちらからご確認ください。
          <br />
          <a href={list.link} target="_blank" rel="noopener noreferrer" className='text-link type-external'>
            {list.linkTxt}
          </a>
        </p>
      </>
    );
  });

  // 人事労務-源泉徴収票
  const questionListSix = [
    {
      id: 1,
      question: "源泉徴収票を確認するには？",
      answer:
        "にログインしてご確認ください。「PDF」は印刷または添付用のデータとなり、画面のみで確認したい場合は「HTML」をクリックしてご確認ください。",
      link: "https://employee.jinjer.biz/payroll",
      linkTxt: "「こちらの給与明細画面」",
    },
  ];

  const accordionSix = questionListSix.map((list) => {
    return (
      <>
        <p
          id={list.id}
          className="contents__qa--question  content-two-q"
        >
          {list.question}
        </p>
        <p className="contents__qa--answer">
          <a href={list.link} target="_blank" rel="noopener noreferrer" className='text-link type-external'>
            {list.linkTxt}
          </a>{list.answer}

        </p>
      </>
    );
  });

  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  return (
    <>
      <Helmet>
        <title>Z-link 勤怠・給与（ジンジャー）</title>
      </Helmet>
      <Header />
      <div className="theme-jinjer">
        <div className="content-header">
          <div className="container">
            <Row>
              <Col sm={9}>
                <h1 className='page-title type-briefcase'>勤怠・給与（ジンジャー）</h1>{/* ページタイトル */}
                <p className="page-description">毎日の勤怠や各種申請、給与明細、年末調整、ライフイベントが発生した時の届出等をジンジャーで行います。</p>{/* ページの説明 */}
              </Col>
              <Col sm={3} className='btn-wrap jinji-related'>
                <a
                  href="https://kintai.jinjer.biz/staffs/top"
                  className="btn-link-area jinjer position-fixed"
                  target="_blank"
                >
                  利⽤する
                </a>
              </Col>
            </Row>
          </div>
        </div>

        <TableOfContents />
        <div className="jinji-related theme-jinjer">
          <div className="contents-wrap container mt-5">
            <div className="d-flex flex-wrap justify-content-between select-tags">
              <div
                className="tag flex-fill px-2 btnToggle is-active"
                id="1"
                onClick={selectCategory("1")}
              >
                打刻・各種申請
              </div>
              <div
                className="tag flex-fill btnToggle px-2"
                id="2"
                onClick={selectCategory("2")}
              >
                給与明細確認
              </div>
              <div
                className="tag flex-fill btnToggle px-2"
                id="3"
                onClick={selectCategory("3")}
              >
                年末調整
              </div>
            </div>


            {/* 初期表示する内容を記載する */}
            <div className="explanation mx-auto">
              {explanationChangeValue === "1" ? (
                <div className="contentOne row-12">
                  <h2 className="tag-title">打刻・各種申請（ジンジャー勤怠）</h2>
                  <p>ここに掲載されているのはジンジャーの使用方法のため、社内ルール・フローについては<a href="https://secure.okbiz.jp/zenken/category?site_domain=default" className="text-link type-external" target="_blank">Z-BOSS</a>をご確認ください。</p>
                  <Row className='mt-4'>
                    <Col sm={4}>
                      <Link
                        to="chapter01"
                        smooth={true}
                        duration={500}
                        offset={-100}
                        className="btn main-btn type-internal"
                      >
                        ログイン方法が知りたい
                      </Link>
                    </Col>
                    <Col sm={4}>
                      <Link
                        to="chapter02"
                        smooth={true}
                        duration={500}
                        offset={-100}
                        className="btn main-btn type-internal"
                      >
                        スマホでも使いたい
                      </Link>
                    </Col>
                    <Col sm={4}>
                      <Link
                        to="chapter03"
                        smooth={true}
                        duration={500}
                        offset={-100}
                        className="btn main-btn type-internal"
                      >
                        各種申請のやり方が知りたい
                      </Link>
                    </Col>
                  </Row>

                  {/* ログイン方法 */}
                  <h3 className="icon-title type-pencil" id="chapter01">ログイン方法が知りたい</h3>

                  {accordionOne}

                  {/* スマホでも */}
                  <h3 className="icon-title type-pencil" id="chapter02">スマホでも使いたい</h3>
                  {accordionTwo}

                  {/* 各種申請 */}
                  <h3 className="icon-title type-pencil" id="chapter03">各種申請のやり方が知りたい</h3>
                  {accordionThree}
                </div>
              ) : (
                <></>
              )}
              {explanationChangeValue === "2" ? (
                <div className="contentTwo">
                  <h2 className="tag-title">ジンジャー給与</h2>
                  <Row className='mt-4'>
                    <Col sm={6}>
                      <Link
                        to="chapter04"
                        smooth={true}
                        duration={500}
                        offset={-100}
                        className="btn main-btn type-internal"
                      >
                        給与明細を確認するには？
                      </Link>
                    </Col>
                  </Row>
                  <h3 className="icon-title type-pencil" id="chapter04">Web明細を確認するには？</h3>
                  {accordionFour}
                </div>
              ) : (
                <></>
              )}
              {explanationChangeValue === "3" ? (
                <div className="contentThree">
                  <h2 className="tag-title">ジンジャー人事労務</h2>
                  <Row className='mt-4'>
                    <Col sm={6}>
                      <Link
                        to="chapter05"
                        smooth={true}
                        duration={500}
                        offset={-100}
                        className="btn main-btn type-internal"
                      >
                        年末調整でやるべきことは？
                      </Link>
                    </Col>
                    <Col sm={6}>
                      <Link
                        to="chapter06"
                        smooth={true}
                        duration={500}
                        offset={-100}
                        className="btn main-btn type-internal"
                      >
                        源泉徴収票を確認するには？
                      </Link>
                    </Col>
                  </Row>
                  <h3 className="icon-title type-pencil" id="chapter05">年末調整でやるべきことは？</h3>
                  {accordionFive}
                  <h3 className="icon-title type-pencil" id="chapter06">源泉徴収票を確認するには？</h3>
                  {accordionSix}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Jinjer;
